import { createAction, props } from '@ngrx/store';
import { User } from '@app/state/models/user';

// User authentication actions
export const login = createAction(
  '[User] Login',
  props<{ email: string; password: string }>()
);

export const loginSuccess = createAction(
  '[User] Login Success',
  props<{ user: any }>()
);

export const loginFailure = createAction(
  '[User] Login Failure',
  props<{ error: string }>()
);

export const logout = createAction('[User] Logout');

export const logoutSuccess = createAction('[User] Logout Success');

export const signup = createAction(
  '[User] Signup',
  props<{ email: string; password: string; displayName?: string }>()
);

export const signupSuccess = createAction(
  '[User] Signup Success',
  props<{ user: any }>()
);

export const signupFailure = createAction(
  '[User] Signup Failure',
  props<{ error: string }>()
);

export const verifyEmail = createAction(
  '[User] Verify Email', 
  props<{ code: string }>()
);

export const verifyEmailSuccess = createAction(
  '[User] Verify Email Success',
  props<{ user: any }>()
);

export const verifyEmailFailure = createAction(
  '[User] Verify Email Failure',
  props<{ error: string }>()
);

// User CRUD actions
export const userUpsert = createAction(
  '[User] Upsert User',
  props<{ user: any }>()
);

export const userUpsertSuccess = createAction(
  '[User] Upsert User Success',
  props<{ user: any }>()
);

export const userUpsertFailure = createAction(
  '[User] Upsert User Failure',
  props<{ error: string }>()
);

export const userUpdate = createAction(
  '[User] Update User',
  props<{ user: any }>()
);

export const userUpdateSuccess = createAction(
  '[User] Update User Success',
  props<{ user: any }>()
);

export const userUpdateFailure = createAction(
  '[User] Update User Failure',
  props<{ error: string }>()
);

export const userGet = createAction(
  '[User] Get User',
  props<{ id: string }>()
);

export const userGetSuccess = createAction(
  '[User] Get User Success',
  props<{ user: any }>()
);

export const userGetFailure = createAction(
  '[User] Get User Failure',
  props<{ error: string }>()
);

export const userGetAll = createAction('[User] Get All Users');

export const userGetAllSuccess = createAction(
  '[User] Get All Users Success',
  props<{ users: any[] }>()
);

export const userGetAllFailure = createAction(
  '[User] Get All Users Failure',
  props<{ error: string }>()
);

export const userSignUp = createAction(
  '[User] Sign Up',
  props<{ data: any }>()
);

export const updateNotificationSettings = createAction(
  '[User] Update Notification Settings',
  props<{ userId: string; settings: any }>()
);

export const updateNotificationSettingsSuccess = createAction(
  '[User] Update Notification Settings Success',
  props<{ user: any }>()
);

export const updateNotificationSettingsFailure = createAction(
  '[User] Update Notification Settings Failure',
  props<{ error: string }>()
);