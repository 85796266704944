import { Component, OnInit, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule, AlertController } from '@ionic/angular';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';

import { ChildProfileService } from '../child.service';
import { ChildProfile } from '../../../shared/models/child-profile.model';
import { AvatarComponent } from '../../../shared/ui/avatar/avatar.component';
import { EmptyStateComponent } from '../../../shared/ui/empty-state/empty-state.component';
import { PageHeaderComponent } from '../../../shared/ui/page-header/page-header.component';

@Component({
  selector: 'app-child-list',
  templateUrl: './child-list.component.html',
  styleUrls: ['./child-list.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule,
    AvatarComponent,
    EmptyStateComponent,
    PageHeaderComponent
  ]
})
export class ChildListComponent implements OnInit, OnDestroy {
  children: ChildProfile[] = [];
  private childrenSubscription: Subscription;
  
  constructor(
    private childService: ChildProfileService,
    private router: Router,
    private alertController: AlertController
  ) {}
  
  ngOnInit(): void {
    this.childrenSubscription = this.childService.getChildren()
      .subscribe(children => {
        this.children = children;
      });
  }
  
  /**
   * Navigate to add a new child
   */
  addChild(): void {
    this.router.navigate(['/profile/child/new']);
  }
  
  /**
   * Navigate to edit a child
   */
  editChild(childId: string): void {
    this.router.navigate(['/profile/child', childId]);
  }
  
  /**
   * Delete a child after confirmation
   */
  async deleteChild(child: ChildProfile): Promise<void> {
    const alert = await this.alertController.create({
      header: 'Confirm Deletion',
      message: `Are you sure you want to remove ${child.name}'s profile?`,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel'
        },
        {
          text: 'Delete',
          role: 'destructive',
          handler: () => {
            this.childService.deleteChild(child.id)
              .subscribe(success => {
                if (success) {
                  this.showSuccessToast(`${child.name}'s profile has been removed`);
                } else {
                  this.showErrorToast('Failed to delete profile. Please try again.');
                }
              });
          }
        }
      ]
    });
    
    await alert.present();
  }
  
  /**
   * Show a success toast message
   */
  private async showSuccessToast(message: string): Promise<void> {
    const alert = await this.alertController.create({
      header: 'Success',
      message: message,
      buttons: ['OK']
    });
    
    await alert.present();
  }
  
  /**
   * Show an error toast message
   */
  private async showErrorToast(message: string): Promise<void> {
    const alert = await this.alertController.create({
      header: 'Error',
      message: message,
      buttons: ['OK']
    });
    
    await alert.present();
  }
  
  ngOnDestroy(): void {
    this.childrenSubscription?.unsubscribe();
  }
}