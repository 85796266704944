import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { WebSocketService } from './websocket.service';
import { Observable, BehaviorSubject, Subject, of } from 'rxjs';
import { SafetyAlert } from '@app/state/models/safety.model';
import { catchError, map } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { castObservable } from '../utils/rxjs-compat';

/**
 * Comprehensive safety service that handles all safety-related functionality
 * including emergency protocols, verification, location tracking, and safety preferences
 */
@Injectable({
  providedIn: 'root'
})
export class SafetyService {
  private apiUrl = 'api/safety';
  private alertSubject = new Subject<SafetyAlert>();
  private alertsSubject = new BehaviorSubject<SafetyAlert[]>([]);
  private childSafetyCodeSubject = new BehaviorSubject<string | null>(null);
  private locationTrackingActive = new BehaviorSubject<boolean>(false);

  constructor(
    private http: HttpClient,
    private wsService: WebSocketService,
    private firestore: AngularFirestore
  ) {
    this.initializeWebSocket();
  }

  private initializeWebSocket() {
    this.wsService.connect('safety').subscribe(
      (message: any) => {
        if (message.type === 'safety_alert') {
          this.alertSubject.next(message.data);
          
          const currentAlerts = this.alertsSubject.getValue();
          this.alertsSubject.next([message.data, ...currentAlerts]);
        }
      }
    );
  }

  /**
   * Get user safety preferences
   * @param userId The user ID to get preferences for
   */
  getUserSafetyPreferences(userId: string): Observable<any> {
    const firebaseObservable = this.firestore.collection('users').doc(userId).collection('preferences').doc('safety')
      .valueChanges();
      
    return castObservable<any>(firebaseObservable)
      .pipe(
        map(prefs => prefs || {
          locationTracking: true,
          photoVerification: true,
          emergencySharing: true,
          safetyCode: true,
          shareRideDetails: true,
          sendRideNotifications: true,
          automaticEmergencyDetection: false
        }),
        catchError(err => {
          console.error('Error fetching safety preferences:', err);
          return of({
            locationTracking: true,
            photoVerification: true,
            emergencySharing: true,
            safetyCode: true,
            shareRideDetails: true,
            sendRideNotifications: true,
            automaticEmergencyDetection: false
          });
        })
      );
  }

  /**
   * Update user safety preferences
   * @param userId The user ID to update preferences for
   * @param preferences The new safety preferences
   */
  updateSafetyPreferences(userId: string, preferences: any): Observable<boolean> {
    return new Observable<boolean>(observer => {
      this.firestore.collection('users').doc(userId).collection('preferences').doc('safety')
        .set(preferences, { merge: true })
        .then(() => {
          observer.next(true);
          observer.complete();
        })
        .catch(err => {
          console.error('Error updating safety preferences:', err);
          observer.next(false);
          observer.complete();
        });
    });
  }

  /**
   * Get emergency contacts for a user
   * @param userId The user ID to get emergency contacts for
   */
  getEmergencyContacts(userId: string): Observable<any[]> {
    const firebaseObservable = this.firestore.collection('users').doc(userId)
      .collection('emergencyContacts').valueChanges({ idField: 'id' });
      
    return castObservable<any[]>(firebaseObservable)
      .pipe(
        catchError(err => {
          console.error('Error fetching emergency contacts:', err);
          return of([]);
        })
      );
  }

  /**
   * Add an emergency contact
   * @param userId The user ID to add the contact to
   * @param contact The contact details to add
   */
  addEmergencyContact(userId: string, contact: any): Observable<string | null> {
    return new Observable<string | null>(observer => {
      this.firestore.collection('users').doc(userId)
        .collection('emergencyContacts').add(contact)
        .then(docRef => {
          observer.next(docRef.id);
          observer.complete();
        })
        .catch(err => {
          console.error('Error adding emergency contact:', err);
          observer.next(null);
          observer.complete();
        });
    });
  }

  /**
   * Update an emergency contact
   * @param userId The user ID the contact belongs to
   * @param contactId The ID of the contact to update
   * @param contact The updated contact details
   */
  updateEmergencyContact(userId: string, contactId: string, contact: any): Observable<boolean> {
    return new Observable<boolean>(observer => {
      this.firestore.collection('users').doc(userId)
        .collection('emergencyContacts').doc(contactId).update(contact)
        .then(() => {
          observer.next(true);
          observer.complete();
        })
        .catch(err => {
          console.error('Error updating emergency contact:', err);
          observer.next(false);
          observer.complete();
        });
    });
  }

  /**
   * Delete an emergency contact
   * @param userId The user ID the contact belongs to
   * @param contactId The ID of the contact to delete
   */
  deleteEmergencyContact(userId: string, contactId: string): Observable<boolean> {
    return new Observable<boolean>(observer => {
      this.firestore.collection('users').doc(userId)
        .collection('emergencyContacts').doc(contactId).delete()
        .then(() => {
          observer.next(true);
          observer.complete();
        })
        .catch(err => {
          console.error('Error deleting emergency contact:', err);
          observer.next(false);
          observer.complete();
        });
    });
  }

  /**
   * Trigger an emergency alert
   * @param userId The user ID triggering the emergency
   * @param location The current location
   */
  triggerEmergency(userId: string, location: google.maps.LatLngLiteral): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/emergency`, { userId, location });
  }

  /**
   * Get all safety alerts
   */
  getAlerts(): Observable<SafetyAlert[]> {
    return this.alertsSubject.asObservable();
  }

  /**
   * Get the latest safety alert
   */
  getLatestAlert(): Observable<SafetyAlert> {
    return this.alertSubject.asObservable();
  }

  /**
   * Set the child safety code
   * @param code The safety code to set
   */
  setChildSafetyCode(code: string): void {
    this.childSafetyCodeSubject.next(code);
  }

  /**
   * Get the child safety code
   */
  getChildSafetyCode(): Observable<string | null> {
    return this.childSafetyCodeSubject.asObservable();
  }

  /**
   * Set location tracking status
   * @param active Whether location tracking is active
   */
  setLocationTrackingActive(active: boolean): void {
    this.locationTrackingActive.next(active);
  }

  /**
   * Get location tracking status
   */
  getLocationTrackingActive(): Observable<boolean> {
    return this.locationTrackingActive.asObservable();
  }

  /**
   * Check if location tracking is active
   */
  isLocationTrackingActive(): Observable<boolean> {
    return this.locationTrackingActive.asObservable();
  }

  /**
   * Upload driver verification photo
   * @param rideId The ride ID to verify
   * @param photo The photo file to upload
   */
  uploadVerificationPhoto(rideId: string, photo: File): Observable<any> {
    const formData = new FormData();
    formData.append('photo', photo);
    formData.append('rideId', rideId);
    return this.http.post<any>(`${this.apiUrl}/verify-photo`, formData);
  }

  /**
   * Update location for a ride
   * @param rideId The ride ID to update
   * @param location The current location
   */
  updateLocation(rideId: string, location: google.maps.LatLngLiteral): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}/location`, { rideId, location });
  }

  /**
   * Generate a safety code for child verification
   * @param childId The child ID to generate a code for
   */
  generateChildSafetyCode(childId: string): Observable<string> {
    return this.http.post<{code: string}>(`${this.apiUrl}/generate-code`, { childId })
      .pipe(
        map(response => response.code),
        catchError(err => {
          console.error('Error generating safety code:', err);
          // Generate a fallback random code
          const code = Math.floor(100000 + Math.random() * 900000).toString();
          return of(code);
        })
      );
  }

  /**
   * Verify a child safety code
   * @param code The code to verify
   * @param rideId The ride ID to verify for
   */
  verifyChildSafetyCode(code: string, rideId: string): Observable<boolean> {
    return this.http.post<{valid: boolean}>(`${this.apiUrl}/verify-code`, { code, rideId })
      .pipe(
        map(response => response.valid),
        catchError(err => {
          console.error('Error verifying safety code:', err);
          return of(false);
        })
      );
  }
}