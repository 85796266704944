import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';

/**
 * Reusable page header component with consistent styling and navigation options
 */
@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
  standalone: true,
  imports: [CommonModule, IonicModule, RouterModule]
})
export class PageHeaderComponent {
  @Input() title: string = '';
  @Input() backPath: string = '';
  @Input() hideBackButton: boolean = false;
  @Input() color: string = 'primary';
}