import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

export interface CalendarEvent {
  id: string;
  title: string;
  start: Date;
  end: Date;
  location?: string;
  description?: string;
}

@Injectable({
  providedIn: 'root'
})
export class CalendarIntegrationService {
  constructor() {}

  // Desktop file-based calendar import functionality
  async parseCalendarFile(file: File): Promise<CalendarEvent[]> {
    const fileExtension = this.getFileExtension(file.name).toLowerCase();
    const fileContent = await this.readFileContent(file);
    
    switch (fileExtension) {
      case 'ics':
      case 'ical':
      case 'icalendar':
        return this.parseICSContent(fileContent);
      case 'csv':
        return this.parseCSVContent(fileContent);
      case 'json':
        return this.parseJSONContent(fileContent);
      default:
        throw new Error(`Unsupported file format: ${fileExtension}`);
    }
  }

  // Method for compatibility with existing code
  hasPermission(): Observable<boolean> {
    // Desktop users don't need special calendar permissions
    return of(true);
  }

  // Method for compatibility with existing code
  requestPermission(): Observable<boolean> {
    // Desktop users don't need special calendar permissions
    return of(true);
  }

  // Method for compatibility with existing code
  getCalendars(): Observable<any[]> {
    // Return an empty list since we're using file-based import
    return of([]);
  }

  // Method for compatibility with existing code
  getEvents(calendarId: string, startDate: string, endDate: string): Observable<any[]> {
    // Return empty list since we're using file-based import
    return of([]);
  }

  // Method for compatibility with existing code
  eventCreate(event: any): Observable<any> {
    return of({ success: true });
  }

  // Method for compatibility with existing code
  deleteEvent(id: string): Observable<any> {
    return of({ success: true });
  }

  private getFileExtension(filename: string): string {
    return filename.split('.').pop() || '';
  }

  private async readFileContent(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        if (event.target?.result) {
          resolve(event.target.result as string);
        } else {
          reject(new Error('Failed to read file content'));
        }
      };
      reader.onerror = () => reject(reader.error);
      reader.readAsText(file);
    });
  }

  private parseICSContent(content: string): CalendarEvent[] {
    const events: CalendarEvent[] = [];
    // Simple ICS parser - in a real app, you might want to use a library like ical.js
    const lines = content.split(/\r\n|\n|\r/);
    
    let currentEvent: Partial<CalendarEvent> | null = null;
    
    for (const line of lines) {
      if (line === 'BEGIN:VEVENT') {
        currentEvent = {
          id: this.generateUniqueId()
        };
      } else if (line === 'END:VEVENT' && currentEvent) {
        if (currentEvent.title && currentEvent.start && currentEvent.end) {
          events.push(currentEvent as CalendarEvent);
        }
        currentEvent = null;
      } else if (currentEvent) {
        const [key, value] = this.parseICSLine(line);
        
        if (key && value) {
          switch (key) {
            case 'SUMMARY':
              currentEvent.title = value;
              break;
            case 'DTSTART':
              currentEvent.start = this.parseICSDate(value);
              break;
            case 'DTEND':
              currentEvent.end = this.parseICSDate(value);
              break;
            case 'LOCATION':
              currentEvent.location = value;
              break;
            case 'DESCRIPTION':
              currentEvent.description = value;
              break;
          }
        }
      }
    }
    
    return events;
  }

  private parseICSLine(line: string): [string, string] {
    const colonIndex = line.indexOf(':');
    if (colonIndex > 0) {
      const key = line.substring(0, colonIndex).split(';')[0]; // Handle parameters
      const value = line.substring(colonIndex + 1);
      return [key, value];
    }
    return ['', ''];
  }

  private parseICSDate(dateString: string): Date {
    // Basic ICS date parsing logic
    // Format: YYYYMMDDTHHMMSSZ or YYYYMMDD
    let year, month, day, hour = 0, minute = 0, second = 0;
    
    if (dateString.includes('T')) {
      // Has time component
      const datePart = dateString.split('T')[0];
      const timePart = dateString.split('T')[1].replace('Z', '');
      
      year = parseInt(datePart.substring(0, 4), 10);
      month = parseInt(datePart.substring(4, 6), 10) - 1; // JS months are 0-based
      day = parseInt(datePart.substring(6, 8), 10);
      
      hour = parseInt(timePart.substring(0, 2), 10);
      minute = parseInt(timePart.substring(2, 4), 10);
      
      if (timePart.length >= 6) {
        second = parseInt(timePart.substring(4, 6), 10);
      }
    } else {
      // Date only
      year = parseInt(dateString.substring(0, 4), 10);
      month = parseInt(dateString.substring(4, 6), 10) - 1;
      day = parseInt(dateString.substring(6, 8), 10);
    }
    
    return new Date(year, month, day, hour, minute, second);
  }

  private parseCSVContent(content: string): CalendarEvent[] {
    const events: CalendarEvent[] = [];
    const lines = content.split(/\r\n|\n|\r/).filter(line => line.trim() !== '');
    
    if (lines.length === 0) {
      return [];
    }
    
    // Parse header to identify columns
    const headers = lines[0].split(',').map(h => h.trim().toLowerCase());
    
    // Find indices of required fields
    const titleIndex = headers.findIndex(h => h === 'title' || h === 'summary' || h === 'subject' || h === 'event');
    const startIndex = headers.findIndex(h => h === 'start' || h === 'start date' || h === 'start time' || h === 'begin' || h === 'from');
    const endIndex = headers.findIndex(h => h === 'end' || h === 'end date' || h === 'end time' || h === 'finish' || h === 'to');
    const locationIndex = headers.findIndex(h => h === 'location' || h === 'place' || h === 'venue');
    const descriptionIndex = headers.findIndex(h => h === 'description' || h === 'details' || h === 'notes');
    
    // Ensure required fields are present
    if (titleIndex === -1 || startIndex === -1) {
      throw new Error('CSV file must contain at least title/summary and start date columns');
    }
    
    // Parse each row
    for (let i = 1; i < lines.length; i++) {
      const values = this.parseCSVLine(lines[i]);
      
      if (values.length <= Math.max(titleIndex, startIndex, endIndex, locationIndex, descriptionIndex)) {
        continue; // Skip row if it doesn't have enough columns
      }
      
      const title = values[titleIndex];
      const start = new Date(values[startIndex]);
      
      // Use end date if available, otherwise set it to 1 hour after start
      let end: Date;
      if (endIndex !== -1 && values[endIndex]) {
        end = new Date(values[endIndex]);
      } else {
        end = new Date(start.getTime() + 60 * 60 * 1000); // 1 hour later
      }
      
      const event: CalendarEvent = {
        id: this.generateUniqueId(),
        title,
        start,
        end
      };
      
      if (locationIndex !== -1 && values[locationIndex]) {
        event.location = values[locationIndex];
      }
      
      if (descriptionIndex !== -1 && values[descriptionIndex]) {
        event.description = values[descriptionIndex];
      }
      
      if (!isNaN(event.start.getTime()) && !isNaN(event.end.getTime())) {
        events.push(event);
      }
    }
    
    return events;
  }

  private parseCSVLine(line: string): string[] {
    const result: string[] = [];
    let insideQuotes = false;
    let currentValue = '';
    
    for (let i = 0; i < line.length; i++) {
      const char = line[i];
      
      if (char === '"') {
        insideQuotes = !insideQuotes;
      } else if (char === ',' && !insideQuotes) {
        result.push(currentValue.trim());
        currentValue = '';
      } else {
        currentValue += char;
      }
    }
    
    // Add the last value
    result.push(currentValue.trim());
    
    // Remove quotes from values
    return result.map(val => val.replace(/^"|"$/g, ''));
  }

  private parseJSONContent(content: string): CalendarEvent[] {
    try {
      const data = JSON.parse(content);
      
      if (Array.isArray(data)) {
        return data.map(item => this.normalizeJsonEvent(item));
      } else if (data.events && Array.isArray(data.events)) {
        return data.events.map(item => this.normalizeJsonEvent(item));
      } else {
        throw new Error('JSON file must contain an array of events or an object with an events array');
      }
    } catch (error) {
      if (error instanceof SyntaxError) {
        throw new Error('Invalid JSON format');
      }
      throw error;
    }
  }

  private normalizeJsonEvent(event: any): CalendarEvent {
    const title = event.title || event.summary || event.subject || event.name || 'Untitled Event';
    let start: Date;
    let end: Date;
    
    // Handle start date
    if (event.start) {
      if (typeof event.start === 'string' || typeof event.start === 'number') {
        start = new Date(event.start);
      } else if (event.start.dateTime) {
        start = new Date(event.start.dateTime);
      } else if (event.start.date) {
        start = new Date(event.start.date);
      } else {
        throw new Error('Invalid start date format');
      }
    } else {
      throw new Error('Event must have a start date');
    }
    
    // Handle end date
    if (event.end) {
      if (typeof event.end === 'string' || typeof event.end === 'number') {
        end = new Date(event.end);
      } else if (event.end.dateTime) {
        end = new Date(event.end.dateTime);
      } else if (event.end.date) {
        end = new Date(event.end.date);
      } else {
        end = new Date(start.getTime() + 60 * 60 * 1000); // 1 hour later
      }
    } else {
      end = new Date(start.getTime() + 60 * 60 * 1000); // 1 hour later
    }
    
    return {
      id: event.id || this.generateUniqueId(),
      title,
      start,
      end,
      location: event.location || event.venue || undefined,
      description: event.description || event.notes || event.details || undefined
    };
  }

  private generateUniqueId(): string {
    return Math.random().toString(36).substring(2) + Date.now().toString(36);
  }
}
