import { Injectable } from '@angular/core';
import { Ride } from '../models/ride.interface';
import { createSafeDate, safeDateOnly, safeToISOString } from '../utils/date-helpers';

@Injectable({
  providedIn: 'root'
})
export class RideDataService {
  constructor() {}
  
  /**
   * Ensures a ride has all necessary properties for filtering and display
   * @param ride The ride object to enhance
   * @returns A ride object with all required properties
   */
  ensureRideProperties(ride: Partial<Ride>): Ride {
    const now = new Date();
    const enhancedRide = { ...ride } as Ride;
    
    // Ensure basic properties exist
    enhancedRide.createdAt = enhancedRide.createdAt || now.toISOString();
    enhancedRide.updatedAt = now.toISOString();
    
    // ESSENTIAL: Ensure status and flags which are critical for filtering
    enhancedRide.status = enhancedRide.status || 'scheduled';
    enhancedRide.cancelled = enhancedRide.cancelled === true;
    enhancedRide.hidden = enhancedRide.hidden === true;
    
    // ESSENTIAL: Ensure consistent time properties which are used in filtering
    // Safely handle startTime date conversion using date helpers
    let startTimeDate = createSafeDate(enhancedRide.startTime) || now;
    
    // Set startTime if missing or invalid
    if (!enhancedRide.startTime) {
      startTimeDate = now;
      enhancedRide.startTime = safeToISOString(now) || now.toISOString();
    }
    
    // Set departureTime if missing
    if (!enhancedRide.departureTime) {
      enhancedRide.departureTime = enhancedRide.startTime;
    }
    
    // Set endTime if missing
    if (!enhancedRide.endTime) {
      // Default to 1 hour after start time
      const endTime = new Date(startTimeDate.getTime() + 3600000);
      enhancedRide.endTime = safeToISOString(endTime) || endTime.toISOString();
    }
    
    // ESSENTIAL: Ensure date string for UI and filtering
    if (!enhancedRide.startDate) {
      enhancedRide.startDate = safeDateOnly(startTimeDate) || now.toISOString().split('T')[0];
    }
    
    // ESSENTIAL: Ensure location properties which are used in filtering
    // Handle pickup/start location
    if (!enhancedRide.pickupAddress) {
      enhancedRide.pickupAddress = 'Not specified';
    }
    
    // Handle dropoff/end location
    if (!enhancedRide.dropAddress) {
      enhancedRide.dropAddress = 'Not specified';
    }
    
    // ESSENTIAL: Ensure structured location data for map display and filtering
    if (!enhancedRide.pickupLocation || !enhancedRide.pickupLocation.address) {
      enhancedRide.pickupLocation = {
        address: enhancedRide.pickupAddress,
        latitude: enhancedRide.pickupLocation?.latitude || 0,
        longitude: enhancedRide.pickupLocation?.longitude || 0
      };
    }
    
    if (!enhancedRide.dropoffLocation || !enhancedRide.dropoffLocation.address) {
      enhancedRide.dropoffLocation = {
        address: enhancedRide.dropAddress,
        latitude: enhancedRide.dropoffLocation?.latitude || 0,
        longitude: enhancedRide.dropoffLocation?.longitude || 0
      };
    }
    
    // ESSENTIAL: Ensure journey type which is used in filtering
    enhancedRide.journeyType = enhancedRide.journeyType || 'oneWay';
    
    // ESSENTIAL: Ensure seats availability which is used in filtering
    enhancedRide.availableSeats = enhancedRide.availableSeats || 1;
    
    // ESSENTIAL: Ensure seatsAvailable structure needed for booking
    if (!enhancedRide.seatsAvailable) {
      enhancedRide.seatsAvailable = {
        onwardRide: enhancedRide.availableSeats
      };
      
      // Add return ride seats if it's a round trip
      if (enhancedRide.journeyType === 'roundTrip') {
        enhancedRide.seatsAvailable.returnRide = enhancedRide.availableSeats;
      }
    }
    
    // ESSENTIAL: Ensure ride details structure which is used in filtering and display
    if (!enhancedRide.onwardRideDetails || !enhancedRide.onwardRideDetails.startDate) {
      try {
        const timeString = startTimeDate instanceof Date && !isNaN(startTimeDate.getTime()) 
          ? startTimeDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
          : '12:00';
        
        enhancedRide.onwardRideDetails = {
          startDate: enhancedRide.startDate || (
            startTimeDate instanceof Date && !isNaN(startTimeDate.getTime())
              ? startTimeDate.toISOString().split('T')[0] 
              : new Date().toISOString().split('T')[0]
          ),
          startTime: timeString,
          passengerCount: enhancedRide.availableSeats
        };
      } catch (error) {
        // Provide fallback values
        enhancedRide.onwardRideDetails = {
          startDate: enhancedRide.startDate || new Date().toISOString().split('T')[0],
          startTime: '12:00',
          passengerCount: enhancedRide.availableSeats
        };
      }
    }
    
    // Add return ride details if it's a round trip
    if (enhancedRide.journeyType === 'roundTrip' && !enhancedRide.returnRideDetails) {
      try {
        // Safely handle returnDate
        let returnDate;
        if (enhancedRide.endTime) {
          returnDate = createSafeDate(enhancedRide.endTime) || new Date();
        } else {
          returnDate = new Date(); // Default fallback
        }
        
        const timeString = returnDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        
        enhancedRide.returnRideDetails = {
          startDate: returnDate.toISOString().split('T')[0],
          startTime: timeString,
          passengerCount: enhancedRide.availableSeats
        };
      } catch (error) {
        // Provide fallback values
        enhancedRide.returnRideDetails = {
          startDate: enhancedRide.startDate || new Date().toISOString().split('T')[0],
          startTime: '12:00',
          passengerCount: enhancedRide.availableSeats
        };
      }
    }
    
    // ESSENTIAL: Ensure driver info is consistent with createdBy which is used in filtering
    if (!enhancedRide.driverId && enhancedRide.createdBy) {
      enhancedRide.driverId = enhancedRide.createdBy;
    } else if (!enhancedRide.driverId) {
      // This is needed for data consistency, but should be replaced with correct ID when available
      enhancedRide.driverId = 'unknown-driver';
    }
    
    // Set driver name if missing
    if (!enhancedRide.driverName) {
      enhancedRide.driverName = 'Anonymous Driver';
    }
    
    // ESSENTIAL: Ensure bookedBy is always an array which is used in filtering
    if (!enhancedRide.bookedBy) {
      enhancedRide.bookedBy = [];
    }
    
    // ESSENTIAL: Ensure bookedByUsers structure which is used in filtering
    if (!enhancedRide.bookedByUsers) {
      enhancedRide.bookedByUsers = {
        onwardRide: [],
        returnRide: []
      };
    }
    
    // Ensure ride preferences are set (these are used in filtering)
    enhancedRide.isPetFriendly = enhancedRide.isPetFriendly === true;
    enhancedRide.isSmokingAllowed = enhancedRide.isSmokingAllowed === true;
    enhancedRide.isWheelchairAccessible = enhancedRide.isWheelchairAccessible === true;
    
    // Ensure trusted network properties are set (these are used in filtering)
    enhancedRide.trustedOnly = enhancedRide.trustedOnly === true;
    if (!enhancedRide.trustedGroups) enhancedRide.trustedGroups = [];
    
    // Set default description if missing
    if (!enhancedRide.description) {
      enhancedRide.description = '';
    }
    
    // Set default price if missing
    if (enhancedRide.price === undefined || enhancedRide.price === null) {
      enhancedRide.price = 0;
    }
    
    // Sanitize ride for Firestore
    return this.sanitizeForFirestore(enhancedRide) as Ride;
  }
  
  /**
   * Deeply sanitizes data for Firestore
   * Removes undefined values, functions, and non-serializable fields recursively
   */
  sanitizeForFirestore(data: any): any {
    // Handle null, primitive values, and non-objects
    if (data === null || typeof data !== 'object') {
      return data;
    }
    
    // Handle arrays
    if (Array.isArray(data)) {
      return data
        .map(item => this.sanitizeForFirestore(item))
        .filter(item => item !== undefined && item !== null);
    }
    
    // Handle objects
    const sanitized = {};
    
    Object.keys(data).forEach(key => {
      // Skip internal properties (starting with _)
      if (key.startsWith('_')) {
        return;
      }
      
      const value = data[key];
      
      // Skip undefined values, the ID field, and non-serializable types
      if (value === undefined || 
          typeof value === 'function' || 
          typeof value === 'symbol') {
        return;
      }
      
      // Recursively sanitize objects and arrays
      if (value !== null && typeof value === 'object') {
        sanitized[key] = this.sanitizeForFirestore(value);
      } else {
        sanitized[key] = value;
      }
    });
    
    return sanitized;
  }

  /**
   * Check if a ride needs migration to add missing properties for filtering
   */
  rideNeedsMigration(ride: Ride): boolean {
    // Check for missing or invalid createdBy property
    if (!ride.createdBy || ride.createdBy === 'anonymous') {
      return true;
    }
    
    // Check for essential missing properties that would affect filtering
    const missingEssentialProps = 
      !ride.departureTime ||
      !ride.pickupAddress ||
      !ride.dropAddress ||
      !ride.journeyType ||
      !ride.seatsAvailable ||
      !ride.onwardRideDetails ||
      ride.status === undefined ||
      ride.cancelled === undefined;
      
    if (missingEssentialProps) {
      return true;
    }
    
    // Check for missing location structures
    if (!ride.pickupLocation || !ride.dropoffLocation) {
      return true;
    }
    
    // Check for missing driver info
    if (!ride.driverId && ride.createdBy) {
      return true;
    }
    
    return false;
  }
} 