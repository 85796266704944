import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WebSocketService {
  private socket$: WebSocketSubject<any>;
  private messagesSubject = new Subject<any>();

  constructor() {}

  public connect(channel: string): Observable<any> {
    if (!this.socket$ || this.socket$.closed) {
      this.socket$ = webSocket({
        url: `${environment.wsUrl}/${channel}`,
        openObserver: {
          next: () => {
            console.log('WebSocket connected');
          }
        },
      });

      this.socket$.subscribe(
        (message) => this.messagesSubject.next(message),
        (error) => console.error('WebSocket error:', error),
        () => console.log('WebSocket connection closed')
      );
    }

    return this.messagesSubject.asObservable();
  }

  public sendMessage(message: any): void {
    if (this.socket$ && !this.socket$.closed) {
      this.socket$.next(message);
    } else {
      console.error('WebSocket is not connected.');
    }
  }

  public disconnect(): void {
    if (this.socket$) {
      this.socket$.complete();
    }
  }
}