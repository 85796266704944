import { createAction, createReducer, on, props } from '@ngrx/store';
import { CalendarEvent } from '@app/shared/models/calendar-event.model';

// Rename the local interface to CalendarEventState
export interface CalendarEventState {
  id: string;
  title: string;
  startDate: string;
  endDate: string;
  location?: string;
  notes?: string;
  isImported: boolean;
}

// Calendar State interface - update references to use CalendarEvent from import
export interface CalendarState {
  events: CalendarEvent[];
  selectedCalendarId: string | null;
  hasPermission: boolean;
  isLoading: boolean;
  error: string | null;
  importedEventIds: string[];
  selectedEvent: CalendarEvent | null;
  availableCalendars: Array<{ id: string; name: string }>;
}

// Initial state
export const initialState: CalendarState = {
  events: [],
  selectedCalendarId: null,
  hasPermission: false,
  isLoading: false,
  error: null,
  importedEventIds: [],
  selectedEvent: null,
  availableCalendars: []
};

// Event CRUD Actions
export const eventCreate = createAction(
  '[Calendar] Create Event',
  props<{ event: CalendarEvent }>()
);

export const eventCreateSuccess = createAction(
  '[Calendar] Create Event Success',
  props<{ event: CalendarEvent }>()
);

export const eventCreateFailure = createAction(
  '[Calendar] Create Event Failure',
  props<{ error: any }>()
);

export const eventGet = createAction(
  '[Calendar] Get Event',
  props<{ id: string }>()
);

export const eventGetSuccess = createAction(
  '[Calendar] Get Event Success',
  props<{ event: CalendarEvent }>()
);

export const eventGetFailure = createAction(
  '[Calendar] Get Event Failure',
  props<{ error: any }>()
);

export const eventGetAll = createAction(
  '[Calendar] Get All Events'
);

export const eventGetAllSuccess = createAction(
  '[Calendar] Get All Events Success',
  props<{ events: CalendarEvent[] }>()
);

export const eventGetAllFailure = createAction(
  '[Calendar] Get All Events Failure',
  props<{ error: any }>()
);

export const eventDelete = createAction(
  '[Calendar] Delete Event',
  props<{ id: string }>()
);

export const eventDeleteSuccess = createAction(
  '[Calendar] Delete Event Success'
);

export const eventDeleteFailure = createAction(
  '[Calendar] Delete Event Failure',
  props<{ error: any }>()
);

// Calendar Import Actions
export const loadEvents = createAction(
  '[Calendar] Load Events',
  props<{ calendarId: string; startDate: string; endDate: string }>()
);

export const loadEventsSuccess = createAction(
  '[Calendar] Load Events Success',
  props<{ events: CalendarEvent[] }>()
);

export const loadEventsFailure = createAction(
  '[Calendar] Load Events Failure',
  props<{ error: string }>()
);

export const importEvent = createAction(
  '[Calendar] Import Event',
  props<{ event: CalendarEvent }>()
);

export const importEventSuccess = createAction(
  '[Calendar] Import Event Success',
  props<{ eventId: string }>()
);

export const importEventFailure = createAction(
  '[Calendar] Import Event Failure',
  props<{ error: string }>()
);

export const setCalendarPermission = createAction(
  '[Calendar] Set Calendar Permission',
  props<{ hasPermission: boolean }>()
);

export const selectCalendar = createAction(
  '[Calendar] Select Calendar',
  props<{ calendarId: string }>()
);

export const clearEvents = createAction(
  '[Calendar] Clear Events'
);

export const checkPermission = createAction('[Calendar] Check Permission');
export const setPermission = createAction(
  '[Calendar] Set Permission',
  props<{ hasPermission: boolean }>()
);

export const loadCalendars = createAction('[Calendar] Load Calendars');
export const loadCalendarsSuccess = createAction(
  '[Calendar] Load Calendars Success',
  props<{ calendars: Array<{ id: string; name: string }> }>()
);
export const loadCalendarsFailure = createAction(
  '[Calendar] Load Calendars Failure',
  props<{ error: string }>()
);

export const clearError = createAction('[Calendar] Clear Error');

// Reducer
export const calendarReducer = createReducer(
  initialState,
  
  // Event CRUD reducers
  on(eventCreate, state => ({
    ...state,
    isLoading: true
  })),
  
  on(eventCreateSuccess, (state, { event }) => ({
    ...state,
    events: [...state.events, event],
    isLoading: false
  })),
  
  on(eventCreateFailure, (state, { error }) => ({
    ...state,
    error,
    isLoading: false
  })),
  
  on(eventGet, state => ({
    ...state,
    isLoading: true
  })),
  
  on(eventGetSuccess, (state, { event }) => ({
    ...state,
    selectedEvent: event,
    isLoading: false
  })),
  
  on(eventGetFailure, (state, { error }) => ({
    ...state,
    error,
    isLoading: false
  })),
  
  on(eventGetAll, state => ({
    ...state,
    isLoading: true
  })),
  
  on(eventGetAllSuccess, (state, { events }) => ({
    ...state,
    events,
    isLoading: false
  })),
  
  on(eventGetAllFailure, (state, { error }) => ({
    ...state,
    error,
    isLoading: false
  })),
  
  on(eventDelete, state => ({
    ...state,
    isLoading: true
  })),
  
  on(eventDeleteSuccess, state => ({
    ...state,
    selectedEvent: null,
    isLoading: false
  })),
  
  on(eventDeleteFailure, (state, { error }) => ({
    ...state,
    error,
    isLoading: false
  })),
  
  // Calendar Import reducers
  on(loadEvents, state => ({
    ...state,
    isLoading: true,
    error: null
  })),
  
  on(loadEventsSuccess, (state, { events }) => ({
    ...state,
    events,
    isLoading: false,
    error: null
  })),
  
  on(loadEventsFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error
  })),
  
  on(importEventSuccess, (state, { eventId }) => ({
    ...state,
    importedEventIds: [...state.importedEventIds, eventId],
    events: state.events.map(event => 
      event.id === eventId 
        ? { ...event, isImported: true }
        : event
    )
  })),
  
  on(setCalendarPermission, (state, { hasPermission }) => ({
    ...state,
    hasPermission
  })),
  
  on(selectCalendar, (state, { calendarId }) => ({
    ...state,
    selectedCalendarId: calendarId
  })),
  
  on(clearEvents, state => ({
    ...state,
    events: [],
    selectedCalendarId: null,
    selectedEvent: null
  })),

  on(setPermission, (state, { hasPermission }) => ({
    ...state,
    hasPermission,
    error: null
  })),
  on(loadCalendars, (state) => ({
    ...state,
    isLoading: true,
    error: null
  })),
  on(loadCalendarsSuccess, (state, { calendars }) => ({
    ...state,
    availableCalendars: calendars,
    isLoading: false,
    error: null
  })),
  on(loadCalendarsFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error
  })),
  on(selectCalendar, (state, { calendarId }) => ({
    ...state,
    selectedCalendarId: calendarId,
    events: [],
    error: null
  })),
  on(clearError, (state) => ({
    ...state,
    error: null
  }))
);