import { Injectable } from '@angular/core';
import { AngularFirestore, CollectionReference, Query, DocumentData } from '@angular/fire/compat/firestore';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { User } from '@app/state/models/user';
import { Profile } from '@app/state/models/profile.model';
import { Observable, of, from, throwError, BehaviorSubject } from 'rxjs';
import { map, catchError, switchMap, tap } from 'rxjs/operators';
import firebase from 'firebase/compat/app';
import { castObservable } from '../utils/rxjs-compat';

/**
 * Comprehensive service for managing user data, including both auth user data and profile data
 * This service consolidates user-related operations to avoid duplication between UserService and ProfileService
 */
@Injectable({
    providedIn: 'root'
})
export class UserService {
    // Track current user and profile with BehaviorSubjects
    private userSubject = new BehaviorSubject<User | null>(null);
    public user$ = this.userSubject.asObservable();
    
    private profileSubject = new BehaviorSubject<Profile | null>(null);
    public profile$ = this.profileSubject.asObservable();

    constructor(
        private firestore: AngularFirestore,
        private afAuth: AngularFireAuth
    ) {
        // Listen to auth state changes to keep user data fresh
        const authState$ = castObservable<firebase.User | null>(this.afAuth.authState);
        
        authState$.pipe(
            switchMap(user => {
                if (user) {
                    // When authenticated, get user data
                    this.getUserById(user.uid).subscribe(userData => {
                        this.userSubject.next(userData);
                    });
                    
                    // Also get profile data
                    this.getProfileById(user.uid).subscribe(profileData => {
                        this.profileSubject.next(profileData);
                    });
                    
                    return of(user);
                } else {
                    // When not authenticated, clear data
                    this.userSubject.next(null);
                    this.profileSubject.next(null);
                    return of(null);
                }
            })
        ).subscribe();
    }

    /**
     * Get a user by ID
     * @param id User ID
     * @returns Observable with user data
     */
    getUserById(id: string): Observable<User | null> {
        if (!id) return of(null);
        
        const firebaseObservable = this.firestore.doc<User>(`users/${id}`).valueChanges();
        // Use our compatibility helper to fix the Observable type issues
        return castObservable<User | null>(firebaseObservable)
            .pipe(
                map(user => {
                    if (!user) return null;
                    // Create a new object with the user data plus id
                    const userData: User = { 
                        uid: id,
                        email: user.email,
                        displayName: user.displayName,
                        photoURL: user.photoURL,
                        emailVerified: user.emailVerified,
                        phoneNumber: user.phoneNumber,
                        // Copy any other user properties
                        ...user
                    };
                    return userData;
                }),
                catchError(error => {
                    console.error('Error getting user:', error);
                    return of(null);
                })
            );
    }

    /**
     * Get all users (admin function)
     * @returns Observable with array of users
     */
    getUserAll(): Observable<User[]> {
        const firebaseObservable = this.firestore.collection<User>('users').valueChanges({ idField: 'uid' });
        // Use our compatibility helper to fix the Observable type issues
        return castObservable<User[]>(firebaseObservable)
            .pipe(
                catchError(error => {
                    console.error('Error getting all users:', error);
                    return of([]);
                })
            );
    }

    /**
     * Update a user record
     * @param user User data to update
     * @returns Observable of the operation
     */
    updateUser(user: User): Observable<void> {
        if (!user || !user.uid) {
            return throwError(() => new Error('User ID is required'));
        }
        
        return from(this.firestore.doc(`users/${user.uid}`).update({
            ...user,
            updatedAt: firebase.firestore.FieldValue.serverTimestamp()
        })).pipe(
            catchError(error => {
                console.error('Error updating user:', error);
                return throwError(() => error);
            })
        );
    }

    /**
     * Create or update a user
     * @param user User data
     * @returns Observable of the operation
     */
    setUser(user: User): Observable<void> {
        if (!user || !user.uid) {
            return throwError(() => new Error('User ID is required'));
        }
        
        return from(this.firestore.doc(`users/${user.uid}`).set({
            ...user,
            updatedAt: firebase.firestore.FieldValue.serverTimestamp()
        }, { merge: true })).pipe(
            catchError(error => {
                console.error('Error setting user:', error);
                return throwError(() => error);
            })
        );
    }
    
    /**
     * Delete a user
     * @param id User ID to delete
     * @returns Observable of the operation
     */
    deleteUser(id: string): Observable<void> {
        if (!id) {
            return throwError(() => new Error('User ID is required'));
        }
        
        return from(this.firestore.doc(`users/${id}`).delete()).pipe(
            catchError(error => {
                console.error('Error deleting user:', error);
                return throwError(() => error);
            })
        );
    }
    
    /**
     * Get current authenticated user ID
     * @returns Observable with user ID or null
     */
    getCurrentUserId(): Observable<string | null> {
        return from(this.afAuth.currentUser).pipe(
            map(user => user ? user.uid : null),
            catchError(error => {
                console.error('Error getting current user:', error);
                return of(null);
            })
        );
    }
    
    /**
     * Get a profile by ID
     * @param id Profile/User ID
     * @returns Observable with profile data
     */
    getProfileById(id: string): Observable<Profile | null> {
        if (!id) return of(null);
        
        const firebaseObservable = this.firestore.doc<Profile>(`profiles/${id}`).valueChanges();
        // Use our compatibility helper to fix the Observable type issues
        return castObservable<Profile | null>(firebaseObservable)
            .pipe(
                map(profile => {
                    if (!profile) return null;
                    
                    // Create a proper Profile object with required fields
                    const profileData = profile as Record<string, any>;
                    const completeProfile: Profile = {
                        id: id,
                        uid: id, // Required by Profile interface
                        email: profileData.email || '',
                        verificationStatus: profileData.verificationStatus || {
                            idVerified: false,
                            phoneVerified: false,
                            emailVerified: false
                        },
                        schoolEmailVerified: profileData.schoolEmailVerified || false,
                        backgroundCheckConsent: profileData.backgroundCheckConsent || false,
                        backgroundCheckStatus: profileData.backgroundCheckStatus || 'not_started',
                        // Add all other fields from profile
                        ...profileData
                    };
                    
                    return completeProfile;
                }),
                catchError(error => {
                    console.error('Error getting profile:', error);
                    return of(null);
                })
            );
    }
    
    /**
     * Update a profile
     * @param id Profile/User ID
     * @param profileData Profile data to update
     * @returns Promise resolving to success boolean
     */
    async updateProfile(id: string, profileData: Partial<Profile>): Promise<boolean> {
        if (!id) {
            console.error('Cannot update profile: ID is required');
            return false;
        }
        
        try {
            // Create update object manually to avoid spread issues
            const dataToUpdate: Record<string, any> = { 
                updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
                id: id,
                userId: id // Ensure userId is set
            };
            
            // Copy each property individually
            Object.keys(profileData).forEach(key => {
                dataToUpdate[key] = (profileData as Record<string, any>)[key];
            });
            
            await this.firestore.doc(`profiles/${id}`).set(dataToUpdate, { merge: true });
            
            // Refresh local data
            this.refreshCurrentProfile(id);
            
            return true;
        } catch (error) {
            console.error('Error updating profile:', error);
            return false;
        }
    }
    
    /**
     * Create a new profile
     * @param profileData Profile data
     * @returns Promise resolving to success boolean
     */
    async createProfile(profileData: Profile): Promise<boolean> {
        if (!profileData.id) {
            console.error('Cannot create profile: ID is required');
            return false;
        }
        
        const id = profileData.id;
        
        try {
            // Create a new profile document
            await this.firestore.doc(`profiles/${id}`).set({
                ...profileData,
                createdAt: new Date().toISOString(),
                updatedAt: new Date().toISOString()
            });
            
            // Refresh local data
            this.refreshCurrentProfile(id);
            
            return true;
        } catch (error) {
            console.error('Error creating profile:', error);
            return false;
        }
    }
    
    /**
     * Refresh the current profile data from Firestore
     * @param id Profile/User ID
     * @returns Promise with the refreshed profile
     */
    async refreshCurrentProfile(id: string): Promise<Profile | null> {
        if (!id) {
            console.error('Cannot refresh profile: ID is required');
            return null;
        }
        
        try {
            const doc = await this.firestore.doc(`profiles/${id}`).get().toPromise();
            
            if (doc.exists) {
                const data = doc.data() || {};
                
                // Create a proper Profile object with required fields
                const profileData = data as Record<string, any>;
                const profile: Profile = {
                    id: id,
                    uid: id, // Required by Profile interface
                    email: profileData.email || '',
                    verificationStatus: profileData.verificationStatus || {
                        idVerified: false,
                        phoneVerified: false,
                        emailVerified: false
                    },
                    schoolEmailVerified: profileData.schoolEmailVerified || false,
                    backgroundCheckConsent: profileData.backgroundCheckConsent || false,
                    backgroundCheckStatus: profileData.backgroundCheckStatus || 'not_started',
                    // Add all other fields from profile
                    ...profileData
                };
                
                // Update the local BehaviorSubject
                this.profileSubject.next(profile);
                return profile;
            } else {
                console.log(`No profile found for ID: ${id}`);
                return null;
            }
        } catch (error) {
            console.error('Error refreshing profile:', error);
            return null;
        }
    }
    
    /**
     * Get all profiles with optional query constraints
     * @param query Optional query constraints
     * @returns Observable of profiles array
     */
    getProfiles(query?: any): Observable<Profile[]> {
        if (query) {
            // If query parameters are provided, build a query
            const queryFn = (ref: CollectionReference<DocumentData>) => this.buildProfileQuery(ref, query);
            const firebaseObservable = this.firestore.collection<Profile>(
                'profiles', 
                queryFn
            ).valueChanges({ idField: 'id' });
            
            return castObservable<Profile[]>(firebaseObservable)
                .pipe(
                    catchError(error => {
                        console.error('Error getting profiles:', error);
                        return of([]);
                    })
                );
        } else {
            // Otherwise, get all profiles
            const firebaseObservable = this.firestore.collection<Profile>('profiles')
                .valueChanges({ idField: 'id' });
                
            return castObservable<Profile[]>(firebaseObservable)
                .pipe(
                    catchError(error => {
                        console.error('Error getting profiles:', error);
                        return of([]);
                    })
                );
        }
    }
    
    /**
     * Helper method to build Firestore query for profiles
     */
    private buildProfileQuery(ref: CollectionReference<DocumentData>, query: any): Query<DocumentData> {
        let queryRef: Query<DocumentData> = ref;
        
        if (query.userId) {
            queryRef = queryRef.where('userId', '==', query.userId);
        }
        
        if (query.type) {
            queryRef = queryRef.where('type', '==', query.type);
        }
        
        if (query.limit) {
            queryRef = queryRef.limit(query.limit);
        }
        
        return queryRef;
    }
} 