import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { DriverService } from '../driver.service';
import { Driver, DriverStats, DriverVerification } from '../../../shared/models/driver.model';
import { PageHeaderComponent } from '../../../shared/ui/page-header/page-header.component';
import { StarRatingComponent } from '../../../shared/ui/star-rating/star-rating.component';

@Component({
  selector: 'app-driver-profile',
  templateUrl: './driver-profile.component.html',
  styleUrls: ['./driver-profile.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    IonicModule,
    PageHeaderComponent,
    StarRatingComponent
  ]
})
export class DriverProfileComponent implements OnInit {
  driver: Driver | null = null;
  driverStats: DriverStats | null = null;
  verificationInfo: DriverVerification | null = null;
  isLoading = true;
  currentSegment = 'overview';
  selectedImageUrl: string | null = null;
  ratingBreakdown: { [key: number]: number } = {};
  reviews: any[] = [];
  selectedPhoto: string | null = null;
  photoDescription: string = '';
  imageLoaded = false;
  isZoomed = false;
  private initialTouchDistance = 0;
  private currentScale = 1;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private driverService: DriverService
  ) {}

  ngOnInit(): void {
    const driverId = this.route.snapshot.paramMap.get('id');
    if (driverId) {
      this.loadDriverProfile(driverId);
    }
  }

  private async loadDriverProfile(driverId: string): Promise<void> {
    try {
      const [driver, stats, verification] = await Promise.all([
        this.driverService.getDriver(driverId).toPromise(),
        this.driverService.getDriverStats(driverId).toPromise(),
        this.driverService.getDriverVerification(driverId).toPromise()
      ]);

      this.driver = driver;
      this.driverStats = stats;
      this.verificationInfo = verification;
      this.calculateRatingBreakdown();
      
      // Load reviews separately to avoid blocking the main profile load
      this.loadDriverReviews(driverId);
    } catch (error) {
      console.error('Error loading driver profile:', error);
    } finally {
      this.isLoading = false;
    }
  }

  private async loadDriverReviews(driverId: string): Promise<void> {
    try {
      this.reviews = await this.driverService.getDriverReviews(driverId).toPromise();
    } catch (error) {
      console.error('Error loading driver reviews:', error);
    }
  }

  private calculateRatingBreakdown(): void {
    if (!this.driver?.reviews) return;

    this.ratingBreakdown = this.driver.reviews.reduce((acc, review) => {
      acc[review.rating] = (acc[review.rating] || 0) + 1;
      return acc;
    }, {} as { [key: number]: number });
  }

  segmentChanged(event: any): void {
    this.currentSegment = event.detail.value;
  }

  getVerificationStatusColor(status: string): string {
    switch (status?.toLowerCase()) {
      case 'approved':
        return 'success';
      case 'pending':
        return 'warning';
      case 'rejected':
        return 'danger';
      default:
        return 'medium';
    }
  }

  getReviewSentiment(rating: number): string {
    if (rating >= 4) return 'success';
    if (rating >= 3) return 'warning';
    return 'danger';
  }

  getRatingPercentage(rating: number): number {
    if (!this.driver?.reviews.length) return 0;
    return (this.ratingBreakdown[rating] || 0) / this.driver.reviews.length * 100;
  }

  formatDate(date: string | Date): string {
    return new Date(date).toLocaleDateString();
  }

  formatVehicleFeatures(features: string[]): string {
    return features?.join(', ') || 'No additional features';
  }

  viewPhoto(url: string): void {
    this.selectedImageUrl = url;
  }

  async requestDriver(): Promise<void> {
    if (!this.driver?.availability.immediatelyAvailable) return;
    this.router.navigate(['/rides/schedule'], {
      queryParams: { driverId: this.driver.id }
    });
  }

  openPhotoModal(photoUrl: string, description?: string) {
    this.selectedPhoto = photoUrl;
    this.photoDescription = description || 'Driver photo';
    this.imageLoaded = false;
    document.body.style.overflow = 'hidden';
  }

  closePhotoModal(event: MouseEvent) {
    if (event.target === event.currentTarget) {
      this.selectedPhoto = null;
      this.selectedImageUrl = null;
      this.imageLoaded = false;
      this.isZoomed = false;
      this.currentScale = 1;
      document.body.style.overflow = '';
    }
  }

  onImageLoad() {
    this.imageLoaded = true;
  }

  handleZoom(event: WheelEvent) {
    event.preventDefault();
    const zoomDelta = event.deltaY * -0.01;
    this.currentScale = Math.min(Math.max(1, this.currentScale + zoomDelta), 3);
    this.isZoomed = this.currentScale > 1;
    const img = event.target as HTMLImageElement;
    img.style.transform = `scale(${this.currentScale})`;
  }

  handleTouchStart(event: TouchEvent) {
    if (event.touches.length === 2) {
      this.initialTouchDistance = Math.hypot(
        event.touches[0].pageX - event.touches[1].pageX,
        event.touches[0].pageY - event.touches[1].pageY
      );
    }
  }

  handleTouchMove(event: TouchEvent) {
    if (event.touches.length === 2) {
      event.preventDefault();
      const currentDistance = Math.hypot(
        event.touches[0].pageX - event.touches[1].pageX,
        event.touches[0].pageY - event.touches[1].pageY
      );
      const scale = currentDistance / this.initialTouchDistance;
      this.currentScale = Math.min(Math.max(1, scale * this.currentScale), 3);
      this.isZoomed = this.currentScale > 1;
      const img = event.target as HTMLImageElement;
      img.style.transform = `scale(${this.currentScale})`;
    }
  }

  handleTouchEnd() {
    this.initialTouchDistance = 0;
  }
}