import { Component, OnInit, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { IonicModule, AlertController } from '@ionic/angular';

import { Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { ChildProfileService } from '../child.service';
import { ChildProfile, EmergencyContact, SafetyPreferences } from '../../../shared/models/child-profile.model';

@Component({
  selector: 'app-child-form',
  templateUrl: './child-form.component.html',
  styleUrls: ['./child-form.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    IonicModule,
    ReactiveFormsModule
  ]
})
export class ChildFormComponent implements OnInit, OnDestroy {
  childProfileForm: FormGroup;
  isEditing = false;
  childId: string;
  loading = false;
  
  // Form step control
  currentStep = 0;
  
  private subscriptions = new Subscription();
  
  constructor(
    private formBuilder: FormBuilder,
    private childProfileService: ChildProfileService,
    private route: ActivatedRoute,
    private router: Router,
    private alertController: AlertController
  ) {
    this.initializeForm();
  }
  
  ngOnInit(): void {
    // Check if we're editing an existing profile
    const paramSub = this.route.params
      .pipe(
        switchMap(params => {
          if (params['id']) {
            this.childId = params['id'];
            this.isEditing = true;
            return this.childProfileService.getChildProfile(this.childId);
          }
          return Promise.resolve(null);
        })
      )
      .subscribe(
        (profile) => {
          if (profile) {
            this.patchFormWithExistingProfile(profile);
          }
        },
        (error) => {
          console.error('Error loading child profile:', error);
          this.showErrorAlert('Failed to load child profile information');
        }
      );
    
    this.subscriptions.add(paramSub);
  }
  
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
  
  initializeForm(): void {
    this.childProfileForm = this.formBuilder.group({
      // Basic Information
      basicInfo: this.formBuilder.group({
        firstName: ['', [Validators.required]],
        lastName: ['', [Validators.required]],
        dateOfBirth: ['', [Validators.required]],
        gender: [''],
        photo: [''],
        grade: ['', [Validators.required]],
        schoolName: ['', [Validators.required]]
      }),
      
      // Medical Information
      medicalInfo: this.formBuilder.group({
        allergies: [''],
        medications: [''],
        conditions: [''],
        bloodType: [''],
        insuranceProvider: [''],
        insuranceNumber: [''],
        physicianName: [''],
        physicianPhone: ['']
      }),
      
      // Emergency Contacts
      emergencyContacts: this.formBuilder.array([]),
      
      // Safety Preferences
      safetyPreferences: this.formBuilder.group({
        locationSharing: [true],
        photoVerification: [true],
        trustedParentsOnly: [true],
        requirePhotoCheckIn: [true]
      })
    });
  }
  
  patchFormWithExistingProfile(profile: ChildProfile): void {
    // Basic Info
    this.childProfileForm.get('basicInfo').patchValue({
      firstName: profile.firstName,
      lastName: profile.lastName,
      dateOfBirth: profile.dateOfBirth,
      gender: profile.gender,
      photo: profile.photo,
      grade: profile.grade,
      schoolName: profile.schoolName
    });
    
    // Medical Info
    if (profile.medicalInfo) {
      this.childProfileForm.get('medicalInfo').patchValue(profile.medicalInfo);
    }
    
    // Emergency Contacts would typically be set in a separate method that adds form arrays
    
    // Safety Preferences
    if (profile.safetyPreferences) {
      this.childProfileForm.get('safetyPreferences').patchValue(profile.safetyPreferences);
    }
  }
  
  async saveChildProfile(): Promise<void> {
    if (this.childProfileForm.invalid) {
      this.markAllFieldsAsTouched();
      return;
    }
    
    this.loading = true;
    
    try {
      const formValues = this.childProfileForm.value;
      
      // Compute display name from first and last name
      const fullName = `${formValues.basicInfo.firstName} ${formValues.basicInfo.lastName}`.trim();
      // Compute approximate age from date of birth
      const birthDate = new Date(formValues.basicInfo.dateOfBirth);
      const today = new Date();
      const age = today.getFullYear() - birthDate.getFullYear();
      
      const childProfile: ChildProfile = {
        id: this.childId || '', // Will be empty for new profiles
        name: fullName, // Required field
        age: age, // Required field
        firstName: formValues.basicInfo.firstName,
        lastName: formValues.basicInfo.lastName,
        dateOfBirth: formValues.basicInfo.dateOfBirth,
        gender: formValues.basicInfo.gender,
        photo: formValues.basicInfo.photo,
        grade: formValues.basicInfo.grade,
        schoolName: formValues.basicInfo.schoolName,
        medicalInfo: formValues.medicalInfo,
        emergencyContacts: formValues.emergencyContacts,
        safetyPreferences: formValues.safetyPreferences,
        approvedPickupPersons: [] // Required field
      };
      
      if (this.isEditing) {
        await this.childProfileService.updateChildProfile(this.childId, childProfile);
      } else {
        const newProfileId = await this.childProfileService.createChildProfile(childProfile);
        this.childId = newProfileId;
      }
      
      this.showSuccessAlert(this.isEditing ? 'Profile updated successfully' : 'Profile created successfully');
      this.router.navigate(['/parent/children']);
    } catch (error) {
      console.error('Error saving child profile:', error);
      this.showErrorAlert('Failed to save child profile');
    } finally {
      this.loading = false;
    }
  }
  
  markAllFieldsAsTouched(): void {
    Object.keys(this.childProfileForm.controls).forEach(controlName => {
      const control = this.childProfileForm.get(controlName);
      if (control instanceof FormGroup) {
        Object.keys(control.controls).forEach(nestedControlName => {
          control.get(nestedControlName).markAsTouched();
        });
      } else {
        control.markAsTouched();
      }
    });
  }
  
  nextStep(): void {
    const currentFormGroup = this.getCurrentFormGroup();
    if (currentFormGroup.valid) {
      this.currentStep++;
    } else {
      // Mark fields as touched to show validation errors
      Object.keys(currentFormGroup.controls).forEach(controlName => {
        currentFormGroup.get(controlName).markAsTouched();
      });
    }
  }
  
  prevStep(): void {
    this.currentStep--;
  }
  
  getCurrentFormGroup(): FormGroup {
    switch (this.currentStep) {
      case 0:
        return this.childProfileForm.get('basicInfo') as FormGroup;
      case 1:
        return this.childProfileForm.get('medicalInfo') as FormGroup;
      case 2: // Emergency contacts - handle differently because it's a FormArray
        return this.childProfileForm;
      case 3:
        return this.childProfileForm.get('safetyPreferences') as FormGroup;
      default:
        return this.childProfileForm.get('basicInfo') as FormGroup;
    }
  }
  
  async showSuccessAlert(message: string): Promise<void> {
    const alert = await this.alertController.create({
      header: 'Success',
      message,
      buttons: ['OK']
    });
    
    await alert.present();
  }
  
  async showErrorAlert(message: string): Promise<void> {
    const alert = await this.alertController.create({
      header: 'Error',
      message,
      buttons: ['OK']
    });
    
    await alert.present();
  }
}