export class CarClass {
    make: string;
    model: string;
    year: number;
    color: string;
    capacity: number;
    licenseNumber: string;
    ownerId: number;
    id: string;

    constructor() {
        this.make = '';
        this.model = '';
        this.year = null;
        this.color = '';
        this.capacity = null;
        this.licenseNumber = null;
        this.ownerId = null;
        this.id = '';
    }
}

export interface Car extends CarClass { }
export type CarPropsArray = Array<keyof Car> 