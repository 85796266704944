import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { LocationData } from '@app/models/ride.model';

import { JourneyActions } from '@app/state/journey';
import * as fromJourney from '@app/state/journey/journey.selectors';

/**
 * DataService - NgRx-based ride journey state management service
 * 
 * This service manages transient ride data as users move through app flows using NgRx.
 * It has been refactored to:
 * 1. Use strongly typed models
 * 2. Leverage NgRx for state management
 * 3. Use modern Angular patterns with observables
 * 4. Provide a clean and consistent API
 */
@Injectable({
  providedIn: 'root'
})
export class DataService {
  // Expose state observables directly
  readonly pickupLocation$: Observable<LocationData | null>;
  readonly dropOffLocation$: Observable<LocationData | null>;
  readonly routeData$: Observable<any>;
  readonly selectedDate$: Observable<string>;
  readonly selectedReturnDate$: Observable<string>;
  readonly journeyType$: Observable<'oneWay' | 'roundTrip'>;
  readonly passengerCount$: Observable<number>;
  readonly selectedLuggage$: Observable<string>;
  readonly rideOptions$: Observable<{pets: boolean, smoking: boolean, wheelchair: boolean}>;
  readonly locationSelectionMode$: Observable<string>;
  readonly isEditingRide$: Observable<boolean>;
  readonly currentTab$: Observable<string>;
  
  // Constructor
  constructor(private store: Store) {
    // Initialize observables in constructor
    this.pickupLocation$ = this.store.select(fromJourney.selectPickupLocation);
    this.dropOffLocation$ = this.store.select(fromJourney.selectDropOffLocation);
    this.routeData$ = this.store.select(fromJourney.selectRouteData);
    this.selectedDate$ = this.store.select(fromJourney.selectSelectedDate);
    this.selectedReturnDate$ = this.store.select(fromJourney.selectSelectedReturnDate);
    this.journeyType$ = this.store.select(fromJourney.selectJourneyType);
    this.passengerCount$ = this.store.select(fromJourney.selectPassengerCount);
    this.selectedLuggage$ = this.store.select(fromJourney.selectSelectedLuggage);
    this.rideOptions$ = this.store.select(fromJourney.selectRideOptions);
    this.locationSelectionMode$ = this.store.select(fromJourney.selectLocationSelectionMode);
    this.isEditingRide$ = this.store.select(fromJourney.selectIsEditingRide);
    this.currentTab$ = this.store.select(fromJourney.selectCurrentTab);
  }

  // Getters and setters for compatibility with direct property access
  get locationSelectionMode(): string {
    let mode = '';
    this.store.select(fromJourney.selectLocationSelectionMode)
      .subscribe(value => mode = value)
      .unsubscribe();
    return mode;
  }
  
  set locationSelectionMode(value: string) {
    this.store.dispatch(JourneyActions.setLocationSelectionMode({ mode: value }));
  }
  
  get currentTab(): string {
    let tab = '';
    this.store.select(fromJourney.selectCurrentTab)
      .subscribe(value => tab = value)
      .unsubscribe();
    return tab;
  }
  
  set currentTab(value: string) {
    this.store.dispatch(JourneyActions.setCurrentTab({ tab: value }));
  }
  
  // Additional properties synced with store
  get _journey(): boolean {
    let journey = true;
    this.store.select(fromJourney.selectJourneyFlag)
      .subscribe(value => journey = value)
      .unsubscribe();
    return journey;
  }
  
  set _journey(value: boolean) {
    this.store.dispatch(JourneyActions.setJourneyFlag({ isJourney: value }));
  }
  
  // Journey flag - public property
  get journey(): boolean {
    return this._journey;
  }
  
  set journey(value: boolean) {
    this._journey = value;
  }
  
  // Legacy properties needed by existing components - now synced with store
  get selectedRideDetails(): any {
    let details = {};
    this.store.select(fromJourney.selectSelectedRideDetails)
      .subscribe(value => details = value)
      .unsubscribe();
    return details;
  }
  
  set selectedRideDetails(value: any) {
    this.store.dispatch(JourneyActions.setSelectedRideDetails({ details: value }));
  }
  
  get findRideDetails(): any {
    let details = {};
    this.store.select(fromJourney.selectFindRideDetails)
      .subscribe(value => details = value)
      .unsubscribe();
    return details;
  }
  
  set findRideDetails(value: any) {
    this.store.dispatch(JourneyActions.setFindRideDetails({ details: value }));
  }
  
  get selectedJourneyForEdit(): any {
    let journey = {};
    this.store.select(fromJourney.selectSelectedJourneyForEdit)
      .subscribe(value => journey = value)
      .unsubscribe();
    return journey;
  }
  
  set selectedJourneyForEdit(value: any) {
    this.store.dispatch(JourneyActions.setSelectedJourneyForEdit({ journey: value }));
  }
  
  get selectedLocation(): any {
    let location = null;
    this.store.select(fromJourney.selectSelectedLocation)
      .subscribe(value => location = value)
      .unsubscribe();
    return location;
  }
  
  set selectedLocation(value: any) {
    this.store.dispatch(JourneyActions.setSelectedLocation({ location: value }));
  }
  
  get onwardRideDetails(): any {
    let details = { instantBooking: true };
    this.store.select(fromJourney.selectOnwardRideDetails)
      .subscribe(value => details = value)
      .unsubscribe();
    return details;
  }
  
  set onwardRideDetails(value: any) {
    this.store.dispatch(JourneyActions.setOnwardRideDetails({ details: value }));
  }
  
  get returnRideDetails(): any {
    let details = { instantBooking: true };
    this.store.select(fromJourney.selectReturnRideDetails)
      .subscribe(value => details = value)
      .unsubscribe();
    return details;
  }
  
  set returnRideDetails(value: any) {
    this.store.dispatch(JourneyActions.setReturnRideDetails({ details: value }));
  }
  
  get stopovers(): any[] {
    let stops = [];
    this.store.select(fromJourney.selectStopovers)
      .subscribe(value => stops = value)
      .unsubscribe();
    return stops;
  }
  
  set stopovers(value: any[]) {
    this.store.dispatch(JourneyActions.setStopovers({ stopovers: value }));
  }
  
  get rideDirection(): string {
    let direction = 'onward';
    this.store.select(fromJourney.selectRideDirection)
      .subscribe(value => direction = value)
      .unsubscribe();
    return direction;
  }
  
  set rideDirection(value: string) {
    this.store.dispatch(JourneyActions.setRideDirection({ direction: value }));
  }
  
  get rideType(): string {
    let type = 'offer';
    this.store.select(fromJourney.selectRideType)
      .subscribe(value => type = value)
      .unsubscribe();
    return type;
  }
  
  set rideType(value: string) {
    this.store.dispatch(JourneyActions.setRideType({ rideType: value }));
  }
  
  get totalFare(): string {
    let fare = '';
    this.store.select(fromJourney.selectTotalFare)
      .subscribe(value => fare = value)
      .unsubscribe();
    return fare;
  }
  
  set totalFare(value: string) {
    this.store.dispatch(JourneyActions.setTotalFare({ fare: value }));
  }
  
  get bookRideConfirmation(): boolean {
    let confirmation = false;
    this.store.select(fromJourney.selectBookRideConfirmation)
      .subscribe(value => confirmation = value)
      .unsubscribe();
    return confirmation;
  }
  
  set bookRideConfirmation(value: boolean) {
    this.store.dispatch(JourneyActions.setBookRideConfirmation({ confirmation: value }));
  }
  
  get bookedRides(): any[] {
    let rides = [];
    this.store.select(fromJourney.selectBookedRides)
      .subscribe(value => rides = value)
      .unsubscribe();
    return rides;
  }
  
  set bookedRides(value: any[]) {
    this.store.dispatch(JourneyActions.setBookedRides({ rides: value }));
  }
  
  get offeredRides(): any[] {
    let rides = [];
    this.store.select(fromJourney.selectOfferedRides)
      .subscribe(value => rides = value)
      .unsubscribe();
    return rides;
  }
  
  set offeredRides(value: any[]) {
    this.store.dispatch(JourneyActions.setOfferedRides({ rides: value }));
  }

  // Location methods - dispatching to store
  
  /**
   * Get the current pickup location
   * @returns Current pickup location or null
   */
  getPickupLocation(): LocationData | null {
    let location = null;
    this.store.select(fromJourney.selectPickupLocation)
      .subscribe(value => location = value)
      .unsubscribe();
    return location;
  }

  /**
   * Set the pickup location
   * @param location The pickup location data
   */
  setPickupLocation(location: LocationData | null): void {
    this.store.dispatch(JourneyActions.setPickupLocation({ location }));
  }

  /**
   * Get the current drop-off location
   * @returns Current drop-off location or null
   */
  getDropOffLocation(): LocationData | null {
    let location = null;
    this.store.select(fromJourney.selectDropOffLocation)
      .subscribe(value => location = value)
      .unsubscribe();
    return location;
  }

  /**
   * Set the drop-off location
   * @param location The drop-off location data
   */
  setDropOffLocation(location: LocationData | null): void {
    this.store.dispatch(JourneyActions.setDropOffLocation({ location }));
  }

  /**
   * Clear both pickup and drop-off locations
   */
  clearLocations(): void {
    this.store.dispatch(JourneyActions.clearLocations());
  }

  /**
   * Set route data
   * @param data The route data
   */
  setRouteData(data: any): void {
    this.store.dispatch(JourneyActions.setRouteData({ data }));
  }

  /**
   * Get current route data
   * @returns Current route data or null
   */
  getRouteData(): any {
    let data = null;
    this.store.select(fromJourney.selectRouteData)
      .subscribe(value => data = value)
      .unsubscribe();
    return data;
  }

  // Date and journey methods - dispatching to store
  
  /**
   * Set the selected date
   * @param date Selected date as ISO string
   */
  setSelectedDate(date: string): void {
    this.store.dispatch(JourneyActions.setSelectedDate({ date }));
  }

  /**
   * Get the selected date
   * @returns Selected date as ISO string
   */
  getSelectedDate(): string {
    let date = '';
    this.store.select(fromJourney.selectSelectedDate)
      .subscribe(value => date = value)
      .unsubscribe();
    return date;
  }

  /**
   * Set the selected return date
   * @param date Selected return date as ISO string
   */
  setSelectedReturnDate(date: string): void {
    this.store.dispatch(JourneyActions.setSelectedReturnDate({ date }));
  }

  /**
   * Get the selected return date
   * @returns Selected return date as ISO string
   */
  getSelectedReturnDate(): string {
    let date = '';
    this.store.select(fromJourney.selectSelectedReturnDate)
      .subscribe(value => date = value)
      .unsubscribe();
    return date;
  }

  /**
   * Set the journey type
   * @param journeyType Journey type (oneWay or roundTrip)
   */
  setJourneyType(journeyType: 'oneWay' | 'roundTrip'): void {
    this.store.dispatch(JourneyActions.setJourneyType({ journeyType }));
  }

  /**
   * Get the current journey type
   * @returns Current journey type
   */
  getJourneyType(): 'oneWay' | 'roundTrip' {
    let type: 'oneWay' | 'roundTrip' = 'oneWay';
    this.store.select(fromJourney.selectJourneyType)
      .subscribe(value => type = value)
      .unsubscribe();
    return type;
  }

  // Ride options methods - dispatching to store
  
  /**
   * Set passenger count
   * @param count Number of passengers
   */
  setPassengerCount(count: number): void {
    this.store.dispatch(JourneyActions.setPassengerCount({ count }));
  }

  /**
   * Get current passenger count
   * @returns Number of passengers
   */
  getPassengerCount(): number {
    let count = 1;
    this.store.select(fromJourney.selectPassengerCount)
      .subscribe(value => count = value)
      .unsubscribe();
    return count;
  }

  /**
   * Set selected luggage size
   * @param luggage Luggage size
   */
  setSelectedLuggage(luggage: string): void {
    this.store.dispatch(JourneyActions.setSelectedLuggage({ luggage }));
  }

  /**
   * Get current luggage size
   * @returns Selected luggage size
   */
  getSelectedLuggage(): string {
    let luggage = 'medium';
    this.store.select(fromJourney.selectSelectedLuggage)
      .subscribe(value => luggage = value)
      .unsubscribe();
    return luggage;
  }

  /**
   * Set ride options
   * @param options Key-value pairs of ride options
   */
  setRideOptions(options: {pets: boolean, smoking: boolean, wheelchair: boolean}): void {
    this.store.dispatch(JourneyActions.setRideOptions({ options }));
  }

  /**
   * Get current ride options
   * @returns Current ride options
   */
  getRideOptions(): {pets: boolean, smoking: boolean, wheelchair: boolean} {
    let options = { pets: false, smoking: false, wheelchair: false };
    this.store.select(fromJourney.selectRideOptions)
      .subscribe(value => options = value)
      .unsubscribe();
    return options;
  }

  // UI state methods - dispatching to store
  
  /**
   * Set location selection mode
   * @param mode Selection mode identifier
   */
  setLocationSelectionMode(mode: string): void {
    this.store.dispatch(JourneyActions.setLocationSelectionMode({ mode }));
  }

  /**
   * Get current location selection mode
   * @returns Current selection mode
   */
  getLocationSelectionMode(): string {
    let mode = '';
    this.store.select(fromJourney.selectLocationSelectionMode)
      .subscribe(value => mode = value)
      .unsubscribe();
    return mode;
  }

  /**
   * Set editing ride state
   * @param isEditing Whether currently editing a ride
   */
  setIsEditingRide(isEditing: boolean): void {
    this.store.dispatch(JourneyActions.setIsEditingRide({ isEditing }));
  }

  /**
   * Get current editing ride state
   * @returns Whether currently editing a ride
   */
  getIsEditingRide(): boolean {
    let isEditing = false;
    this.store.select(fromJourney.selectIsEditingRide)
      .subscribe(value => isEditing = value)
      .unsubscribe();
    return isEditing;
  }

  /**
   * Set current tab
   * @param tab Current tab identifier
   */
  setCurrentTab(tab: string): void {
    this.store.dispatch(JourneyActions.setCurrentTab({ tab }));
  }

  /**
   * Get current tab
   * @returns Current tab identifier
   */
  getCurrentTab(): string {
    let tab = 'home';
    this.store.select(fromJourney.selectCurrentTab)
      .subscribe(value => tab = value)
      .unsubscribe();
    return tab;
  }

  /**
   * Check if locations are valid for route calculation
   * @returns True if both pickup and dropoff locations are set
   */
  hasValidLocations(): Observable<boolean> {
    return this.store.select(fromJourney.selectHasValidLocations);
  }
  
  /**
   * Initialize ride details with defaults
   */
  initializeRideDetails(): void {
    this.store.dispatch(JourneyActions.initializeRideDetails());
  }

  /**
   * Reset all ride data
   */
  resetAll(): void {
    this.store.dispatch(JourneyActions.resetAll());
  }
}
