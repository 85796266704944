import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as EventActions from './event.actions';

@Injectable()
export class EventEffects {
  constructor(
    private actions$: Actions,
    // Uncomment and add the event service when it's available
    // private eventService: EventService
  ) {}

  // Placeholder for the event effects
  // Uncomment and implement when the event service is available
  /*
  createEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EventActions.eventCreate),
      mergeMap(action =>
        this.eventService.createEvent(action.event).pipe(
          map(event => EventActions.eventCreateSuccess({ event })),
          catchError(error => of(EventActions.eventCreateFailure({ error })))
        )
      )
    )
  );

  getEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EventActions.eventGet),
      mergeMap(action =>
        this.eventService.getEvent(action.id).pipe(
          map(event => EventActions.eventGetSuccess({ event })),
          catchError(error => of(EventActions.eventGetFailure({ error })))
        )
      )
    )
  );

  getAllEvents$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EventActions.eventGetAll),
      mergeMap(() =>
        this.eventService.getAllEvents().pipe(
          map(events => EventActions.eventGetAllSuccess({ events })),
          catchError(error => of(EventActions.eventGetAllFailure({ error })))
        )
      )
    )
  );

  updateEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EventActions.eventUpdate),
      mergeMap(action =>
        this.eventService.updateEvent(action.event).pipe(
          map(event => EventActions.eventUpdateSuccess({ event })),
          catchError(error => of(EventActions.eventUpdateFailure({ error })))
        )
      )
    )
  );

  deleteEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EventActions.eventDelete),
      mergeMap(action =>
        this.eventService.deleteEvent(action.id).pipe(
          map(() => EventActions.eventDeleteSuccess()),
          catchError(error => of(EventActions.eventDeleteFailure({ error })))
        )
      )
    )
  );
  */
} 