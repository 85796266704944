import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { BehaviorSubject } from 'rxjs';

export enum ThemeMode {
  System = 'system',
  Light = 'light',
  Dark = 'dark',
  Apple = 'apple'
}

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  private renderer: Renderer2;
  private _selectedTheme = new BehaviorSubject<ThemeMode>(ThemeMode.System);
  public selectedTheme$ = this._selectedTheme.asObservable();
  
  private readonly THEME_KEY = 'selected_theme';
  private mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');

  constructor(
    private rendererFactory: RendererFactory2,
    private storage: Storage
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
    this.initStorage();
  }

  async initStorage() {
    await this.storage.create();
    const storedTheme = await this.storage.get(this.THEME_KEY) as ThemeMode;
    
    if (storedTheme) {
      this._selectedTheme.next(storedTheme);
      this.applyTheme(storedTheme);
    } else {
      // Default to system theme if none is stored
      this.applyTheme(ThemeMode.System);
    }

    // Listen for system theme changes
    this.mediaQuery.addEventListener('change', (e) => {
      if (this._selectedTheme.value === ThemeMode.System) {
        this.applySystemTheme();
      }
    });
  }

  async setTheme(theme: ThemeMode) {
    await this.storage.set(this.THEME_KEY, theme);
    this._selectedTheme.next(theme);
    this.applyTheme(theme);
  }

  private applyTheme(theme: ThemeMode) {
    const body = document.querySelector('body');
    
    // Remove all theme classes first
    body.classList.remove('theme-light', 'theme-dark', 'theme-apple');
    
    switch (theme) {
      case ThemeMode.Light:
        body.classList.add('theme-light');
        this.renderer.setAttribute(document.documentElement, 'color-scheme', 'light');
        break;
      case ThemeMode.Dark:
        body.classList.add('theme-dark');
        this.renderer.setAttribute(document.documentElement, 'color-scheme', 'dark');
        break;
      case ThemeMode.Apple:
        body.classList.add('theme-apple');
        this.renderer.setAttribute(document.documentElement, 'color-scheme', 'light');
        break;
      case ThemeMode.System:
        this.applySystemTheme();
        break;
    }
  }

  private applySystemTheme() {
    const prefersDark = this.mediaQuery.matches;
    const body = document.querySelector('body');
    
    body.classList.remove('theme-light', 'theme-dark', 'theme-apple');
    
    if (prefersDark) {
      body.classList.add('theme-dark');
      this.renderer.setAttribute(document.documentElement, 'color-scheme', 'dark');
    } else {
      body.classList.add('theme-light');
      this.renderer.setAttribute(document.documentElement, 'color-scheme', 'light');
    }
  }
} 