import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument, DocumentChangeAction, DocumentSnapshot, QueryFn } from '@angular/fire/compat/firestore';
import { Observable, from } from 'rxjs';
import { map, take } from 'rxjs/operators';

type CollectionPredicate<T> = string | AngularFirestoreCollection<T>;
type DocPredicate<T> = string | AngularFirestoreDocument<T>;

@Injectable({
  providedIn: 'root'
})
export class FirestoreService {
  constructor(private afs: AngularFirestore) {}

  /**
   * Get a reference to a Firestore collection
   */
  collection<T>(path: string, queryFn?: QueryFn): AngularFirestoreCollection<T> {
    return this.afs.collection<T>(path, queryFn);
  }

  /**
   * Get a reference to a Firestore document
   */
  doc<T>(path: string): AngularFirestoreDocument<T> {
    return this.afs.doc<T>(path);
  }

  /**
   * Create a new Firestore ID
   */
  createId(): string {
    return this.afs.createId();
  }

  /**
   * Get a document by path
   */
  async getDoc<T>(path: string): Promise<T | null> {
    const doc = this.afs.doc<T>(path);
    const snapshot = await doc.get().pipe(take(1)).toPromise();
    
    if (snapshot && snapshot.exists) {
      return snapshot.data() as T;
    }
    
    return null;
  }

  /**
   * Get all documents in a collection
   */
  async getCollection<T>(path: string, queryFn?: QueryFn): Promise<T[]> {
    const collection = this.afs.collection<T>(path, queryFn);
    return collection.valueChanges({ idField: 'id' }).pipe(take(1)).toPromise();
  }

  /**
   * Get collection with document IDs
   */
  getCollectionWithIds<T>(path: string, queryFn?: QueryFn): Observable<T[]> {
    const collection = this.afs.collection<T>(path, queryFn);
    return collection.snapshotChanges().pipe(
      map((actions: DocumentChangeAction<T>[]) => {
        return actions.map(a => {
          const data = a.payload.doc.data() as T;
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    );
  }

  /**
   * Set document data
   */
  async setDoc<T>(path: string, data: any): Promise<void> {
    const doc = this.afs.doc<T>(path);
    return doc.set(data);
  }

  /**
   * Update document data
   */
  async updateDoc<T>(path: string, data: any): Promise<void> {
    const doc = this.afs.doc<T>(path);
    return doc.update(data);
  }

  /**
   * Delete a document
   */
  async deleteDoc<T>(path: string): Promise<void> {
    const doc = this.afs.doc<T>(path);
    return doc.delete();
  }

  /**
   * Add a document to a collection
   */
  async addDoc<T>(path: string, data: any): Promise<any> {
    const collection = this.afs.collection<T>(path);
    return collection.add(data);
  }

  /**
   * Get document changes as an observable
   */
  docChanges<T>(path: string): Observable<T> {
    const doc = this.afs.doc<T>(path);
    return doc.valueChanges();
  }
} 