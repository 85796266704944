import { Injectable } from '@angular/core';
import { Ride, RideSearchParams, RideFilterParams, RideFilterResult } from '../models/ride.interface';
import { sameDateOnly, safeTimestamp } from '../utils/date-helpers';

@Injectable({
  providedIn: 'root'
})
export class RideSearchFilterService {
  constructor() {}

  /**
   * Apply search filters client-side to an array of rides
   * @param rides Source array of rides
   * @param params Search parameters
   * @returns Filtered array of rides
   */
  applySearchFilters(rides: Ride[], params: RideSearchParams): Ride[] {
    if (!rides || rides.length === 0) return [];
    
    console.log(`[RideSearchFilter] Filtering ${rides.length} rides with params:`, params);
    
    // Start with all rides
    let filteredRides = [...rides];
    
    // Always filter out rides with departure times in the past
    const currentDateTime = new Date();
    filteredRides = filteredRides.filter(ride => {
      try {
        // Handle cases where date fields are empty objects ({})
        const isEmptyObject = (value) => value && typeof value === 'object' && Object.keys(value).length === 0;
        
        // Try to get a valid date from multiple possible fields
        let rideDate = null;
        
        // Check onwardRideDetails first as it seems more reliable
        if (ride.onwardRideDetails?.startDate) {
          if (typeof ride.onwardRideDetails.startDate === 'string') {
            rideDate = new Date(ride.onwardRideDetails.startDate);
          }
        }
        
        // If no date yet, check startDate
        if (!rideDate && ride.startDate && typeof ride.startDate === 'string') {
          rideDate = new Date(ride.startDate);
        }
        
        // Then try other fields, but only if they're not empty objects
        if (!rideDate && ride.departureTime && !isEmptyObject(ride.departureTime)) {
          rideDate = new Date(ride.departureTime);
        }
        
        if (!rideDate && ride.startTime && !isEmptyObject(ride.startTime)) {
          rideDate = new Date(ride.startTime);
        }
        
        // If we can't determine the departure time, include it in results
        if (!rideDate || isNaN(rideDate.getTime())) {
          console.log('[RideSearchFilter] Including ride with undefined date:', ride.id);
          return true;
        }
        
        // Filter out rides where departure time is before current time
        return rideDate >= currentDateTime;
      } catch (error) {
        // Include rides we can't parse (better than filtering them out)
        console.log('[RideSearchFilter] Error parsing ride date, including ride:', ride.id, error);
        return true;
      }
    });
    
    console.log(`[RideSearchFilter] After filtering past rides: ${filteredRides.length} rides remain`);
    
    // If we're getting all rides, just return what we have after the time filter
    if (params.getAllRides) {
      console.log(`[RideSearchFilter] getAllRides=true, returning ${filteredRides.length} rides`);
      return filteredRides;
    }
    
    // Filter by pickup location if specified
    if (params.pickupAddress) {
      const pickupLower = params.pickupAddress.toLowerCase();
      filteredRides = filteredRides.filter(ride => {
        const pickupAddr = ride.pickupAddress?.toLowerCase() || '';
        const pickupLocAddr = ride.pickupLocation?.address?.toLowerCase() || '';
        
        return pickupAddr.includes(pickupLower) || pickupLocAddr.includes(pickupLower);
      });
      console.log(`[RideSearchFilter] After pickup filter: ${filteredRides.length} rides remain`);
    }
    
    // Filter by dropoff location if specified
    if (params.dropAddress) {
      const dropLower = params.dropAddress.toLowerCase();
      filteredRides = filteredRides.filter(ride => {
        const dropAddr = ride.dropAddress?.toLowerCase() || '';
        const dropLocAddr = ride.dropoffLocation?.address?.toLowerCase() || '';
        
        return dropAddr.includes(dropLower) || dropLocAddr.includes(dropLower);
      });
      console.log(`[RideSearchFilter] After dropoff filter: ${filteredRides.length} rides remain`);
    }
    
    // Filter by departure time using our safe date helpers
    if (params.departureTime) {
      try {
        const searchDate = new Date(params.departureTime);
        console.log(`[RideSearchFilter] Filtering by date: ${searchDate.toDateString()}`);
        
        // Check if searchDate is today or very close to current time
        const isToday = searchDate.toDateString() === currentDateTime.toDateString();
        const isCurrentTime = Math.abs(searchDate.getTime() - currentDateTime.getTime()) < 60000; // Within a minute
        
        // Skip date filtering if it's today or current time (to show more results)
        if (isToday || isCurrentTime) {
          console.log(`[RideSearchFilter] Not applying date filter because it's today or current time`);
        } else {
          filteredRides = filteredRides.filter(ride => {
            // Helper function to check for empty objects
            const isEmptyObject = (value) => value && typeof value === 'object' && Object.keys(value).length === 0;
            
            // Skip rides with no date information or empty object dates
            if ((!ride.departureTime || isEmptyObject(ride.departureTime)) && 
                (!ride.startTime || isEmptyObject(ride.startTime)) && 
                (!ride.startDate || typeof ride.startDate !== 'string')) {
              
              // Special case: check onwardRideDetails
              if (ride.onwardRideDetails?.startDate && typeof ride.onwardRideDetails.startDate === 'string') {
                try {
                  return sameDateOnly(ride.onwardRideDetails.startDate, params.departureTime);
                } catch (error) {
                  return false;
                }
              }
              
              console.log(`[RideSearchFilter] Skipping ride ${ride.id} due to missing date information`);
              return false;
            }
            
            try {
              // Use sameDateOnly for simple date comparison without risking toISOString errors
              if (ride.departureTime && !isEmptyObject(ride.departureTime)) {
                return sameDateOnly(ride.departureTime, params.departureTime);
              } else if (ride.startTime && !isEmptyObject(ride.startTime)) {
                return sameDateOnly(ride.startTime, params.departureTime);
              } else if (ride.startDate && typeof ride.startDate === 'string') {
                return sameDateOnly(ride.startDate, params.departureTime);
              }
              return false;
            } catch (dateError) {
              console.error(`[RideSearchFilter] Error comparing dates for ride ${ride.id}:`, dateError);
              // Skip this ride if we can't parse the date
              return false;
            }
          });
          console.log(`[RideSearchFilter] After date filter: ${filteredRides.length} rides remain`);
        }
      } catch (error) {
        console.error(`[RideSearchFilter] Error in date filtering:`, error);
        // Return all rides if there's an error with the departure time filtering
      }
    }
    
    // Filter by available seats if specified
    if (params.seatsNeeded && params.seatsNeeded > 0) {
      const seatsNeeded = Number(params.seatsNeeded);
      filteredRides = filteredRides.filter(ride => {
        const availableSeats = ride.availableSeats || 
                              (ride.seatsAvailable?.onwardRide || 0);
        return availableSeats >= seatsNeeded;
      });
      console.log(`[RideSearchFilter] After seats filter: ${filteredRides.length} rides remain`);
    }
    
    // Filter by max price if specified
    if (params.maxPrice && params.maxPrice > 0) {
      const maxPrice = Number(params.maxPrice);
      filteredRides = filteredRides.filter(ride => {
        const price = ride.price || 0;
        return price <= maxPrice;
      });
    }
    
    // Filter by preferences if specified
    if (params.smoking) {
      filteredRides = filteredRides.filter(ride => ride.isSmokingAllowed === true);
    }
    
    if (params.pets) {
      filteredRides = filteredRides.filter(ride => ride.isPetFriendly === true);
    }
    
    if (params.wheelchair) {
      filteredRides = filteredRides.filter(ride => ride.isWheelchairAccessible === true);
    }
    
    // Filter by trusted network properties if specified
    if (params.trustedOnly) {
      filteredRides = filteredRides.filter(ride => ride.trustedOnly !== true);
    }
    
    if (params.trustedGroups && params.trustedGroups.length > 0) {
      filteredRides = filteredRides.filter(ride => {
        // If the ride isn't restricted to trusted groups, include it
        if (!ride.trustedOnly) return true;
        
        // If the ride has no trusted groups, exclude it
        if (!ride.trustedGroups || ride.trustedGroups.length === 0) return false;
        
        // Check if any of the user's trusted groups match the ride's trusted groups
        return params.trustedGroups.some(group => 
          ride.trustedGroups && ride.trustedGroups.includes(group)
        );
      });
    }
    
    return filteredRides;
  }
  
  /**
   * Apply filters to ride arrays for user-specific ride views
   * @param rides Array of rides to filter
   * @param filters Filter parameters
   * @returns Object with bookedRides, offeredRides, and rideHistory
   */
  applyRideFilters(rides: Ride[], filters: RideFilterParams): RideFilterResult {
    if (!Array.isArray(rides) || rides.length === 0) {
      return { bookedRides: [], offeredRides: [], rideHistory: [] };
    }
    
    // Default result
    let result: RideFilterResult = {
      bookedRides: [],
      offeredRides: [],
      rideHistory: []
    };
    
    // Deep validation of rides data before filtering
    const validRides = rides
      .filter(ride => !!ride)
      .map(ride => {
        // Apply minimal property validation to prevent filtering errors
        const validatedRide = { ...ride };
        
        // Ensure essential filtering properties
        if (!validatedRide.createdBy) validatedRide.createdBy = 'anonymous';
        if (!validatedRide.status) validatedRide.status = 'scheduled';
        if (!Array.isArray(validatedRide.bookedBy)) validatedRide.bookedBy = [];
        
        return validatedRide;
      });
    
    // Apply user filter if provided (filter by createdBy or bookedBy)
    let filteredRides = validRides;
    if (filters.userId) {
      filteredRides = validRides.filter(ride => {
        try {
          const isCreator = ride.createdBy === filters.userId;
          const isBooked = Array.isArray(ride.bookedBy) && ride.bookedBy.includes(filters.userId);
          
          // More permissive filtering - allow rides where user is creator or booker
          return isCreator || isBooked;
        } catch (error) {
          // Include the ride on error to avoid filtering out valid rides due to structure issues
          return true;
        }
      });
    }
    
    // Apply date range filter if provided
    if (filters.startDate || filters.endDate) {
      filteredRides = filteredRides.filter(ride => {
        try {
          // Convert ride dates to timestamps for comparison, safely handling various formats
          let rideStartTime: number | undefined;
          
          // Try various date fields in order of preference using the safeTimestamp helper
          if (ride.departureTime) {
            rideStartTime = safeTimestamp(ride.departureTime);
          } else if (ride.startTime) {
            rideStartTime = safeTimestamp(ride.startTime);
          } else if (ride.startDate) {
            rideStartTime = safeTimestamp(ride.startDate);
          } else if (ride.onwardRideDetails?.startDate) {
            rideStartTime = safeTimestamp(ride.onwardRideDetails.startDate);
          }
          
          // If no valid date found or timestamp calculation failed, include the ride by default
          if (rideStartTime === undefined) {
            return true;
          }
          
          // Check start date
          if (filters.startDate) {
            const filterStartDate = safeTimestamp(filters.startDate);
            if (filterStartDate && rideStartTime < filterStartDate) {
              return false;
            }
          }
          
          // Check end date
          if (filters.endDate) {
            const filterEndDate = safeTimestamp(filters.endDate);
            if (filterEndDate && rideStartTime > filterEndDate) {
              return false;
            }
          }
          
          return true;
        } catch (error) {
          // In case of error, include the ride
          return true;
        }
      });
    }
    
    // Apply location filter if provided
    if (filters.location) {
      const locationLower = filters.location.toLowerCase();
      filteredRides = filteredRides.filter(ride => {
        try {
          // Get all possible location fields with fallbacks
          const locationFields = [
            // Pickup/start locations
            typeof ride.pickupAddress === 'string' ? ride.pickupAddress.toLowerCase() : '',
            ride.pickupLocation?.address?.toLowerCase() || '',
            
            // Dropoff/end locations
            typeof ride.dropAddress === 'string' ? ride.dropAddress.toLowerCase() : '',
            ride.dropoffLocation?.address?.toLowerCase() || ''
          ];
          
          // Check if any location field includes the search term
          return locationFields.some(field => field && field.includes(locationLower));
        } catch (error) {
          // In case of error, include the ride
          return true;
        }
      });
    }
    
    // Apply status filter if provided
    if (filters.status) {
      filteredRides = filteredRides.filter(ride => {
        try {
          // Check both status field and cancelled flag
          if (filters.status === 'cancelled') {
            return ride.status === 'cancelled' || ride.cancelled === true;
          }
          return ride.status === filters.status;
        } catch (error) {
          // In case of error, include the ride
          return true;
        }
      });
    }
    
    // Categorize rides into the three buckets
    filteredRides.forEach(ride => {
      try {
        if (!ride) return; // Skip null rides
        
        // Get key properties with safe fallbacks
        const isUserRide = !filters.userId || ride.createdBy === filters.userId;
        const isUserBooked = filters.userId && Array.isArray(ride.bookedBy) && ride.bookedBy.includes(filters.userId);
        
        // Status checks with fallbacks
        const rideStatus = ride.status || 'scheduled';
        const isCancelled = ride.cancelled === true || rideStatus === 'cancelled';
        const isCompleted = rideStatus === 'completed';
        const isScheduled = rideStatus === 'scheduled' && !isCancelled;
        
        // Category 1: Offered rides (created by the user and not cancelled)
        if (isUserRide && isScheduled) {
          result.offeredRides.push(ride);
        }
        
        // Category 2: Booked rides (booked by the user and not cancelled)
        if (isUserBooked && isScheduled) {
          result.bookedRides.push(ride);
        }
        
        // Category 3: History rides (completed or cancelled)
        if (isCompleted || isCancelled) {
          // Only include in history if they're related to the user
          if (!filters.userId || isUserRide || isUserBooked) {
            result.rideHistory.push(ride);
          }
        }
      } catch (error) {
        // Silently ignore categorization errors
      }
    });
    
    return result;
  }
  
  /**
   * Filter rides based on trusted network relationships
   * @param rides Array of rides to filter
   * @param trustedUserIds Array of user IDs in user's trusted network
   * @returns Filtered array of rides
   */
  filterRidesByTrustedNetwork(rides: Ride[], trustedUserIds: string[]): Ride[] {
    if (!trustedUserIds || trustedUserIds.length === 0) {
      return rides;
    }
    
    return rides.filter(ride => 
      ride.createdBy && trustedUserIds.includes(ride.createdBy)
    );
  }
} 