import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { DOCUMENT } from '@angular/common';

import { VerificationService } from '../verification.service';
import { 
  DriverVerification, 
  VerificationDocument, 
  DocumentType 
} from '../../../shared/models/driver-verification.model';
import { PageHeaderComponent } from '../../../shared/ui/page-header/page-header.component';

@Component({
  selector: 'app-verification-process',
  templateUrl: './verification-process.component.html',
  styleUrls: ['./verification-process.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    TranslateModule,
    PageHeaderComponent
  ]
})
export class VerificationProcessComponent implements OnInit, OnDestroy {
  verification: DriverVerification | null = null;
  selectedFile: File | null = null;
  currentStep = '0'; // Changed to string to match ion-accordion value type
  isLoading = false;
  consentFormUrl: string | null = null;
  document: Document;

  private verificationSubscription: Subscription;

  readonly requiredDocuments: DocumentType[] = [
    'drivers_license',
    'vehicle_registration',
    'insurance',
    'vehicle_photo',
    'profile_photo',
    'background_check_consent'
  ];

  constructor(
    private verificationService: VerificationService,
    @Inject(DOCUMENT) document: Document
  ) {
    this.document = document;
  }

  ngOnInit(): void {
    // Load current verification status
    this.verificationService.getCurrentVerification()
      .subscribe(verification => {
        this.verification = verification;
        if (!verification) {
          this.startNewVerification();
        }
      });

    // Get consent form URL
    this.verificationService.getConsentFormUrl()
      .subscribe(url => {
        this.consentFormUrl = url;
      });
  }

  /**
   * Start a new verification process
   */
  private startNewVerification(): void {
    this.verificationService.startVerification()
      .subscribe(verification => {
        this.verification = verification;
      });
  }

  /**
   * Handle file selection for document upload
   */
  onFileSelected(event: any, documentType: DocumentType): void {
    const files = event.target.files;
    if (files.length > 0) {
      const file = files[0];
      this.selectedFile = file;

      // Auto-upload when file is selected
      this.isLoading = true;
      this.verificationService.uploadDocument(documentType, file)
        .subscribe(
          document => {
            this.isLoading = false;
            // Move to next step if upload successful
            if (document) {
              // Convert current step to number, increment, then back to string
              this.currentStep = (parseInt(this.currentStep) + 1).toString();
            }
          }
        );
    }
  }

  /**
   * Check if a document has been uploaded
   */
  isDocumentUploaded(type: DocumentType): boolean {
    return this.verification?.documents.some(doc => doc.type === type) ?? false;
  }

  /**
   * Get status of a document
   */
  getDocumentStatus(type: DocumentType): string {
    const document = this.verification?.documents.find(doc => doc.type === type);
    return document?.status || 'pending';
  }

  /**
   * Submit verification for review
   */
  submitVerification(): void {
    if (this.isVerificationComplete()) {
      this.isLoading = true;
      this.verificationService.submitVerification()
        .subscribe(
          verification => {
            this.isLoading = false;
            this.verification = verification;
          }
        );
    }
  }

  /**
   * Check if all required documents are uploaded
   */
  isVerificationComplete(): boolean {
    return this.requiredDocuments.every(type => this.isDocumentUploaded(type));
  }

  /**
   * Get progress percentage
   */
  getProgress(): number {
    if (!this.verification) return 0;
    const uploadedCount = this.requiredDocuments.filter(type => 
      this.isDocumentUploaded(type)).length;
    return Math.round((uploadedCount / this.requiredDocuments.length) * 100);
  }

  /**
   * Open consent form in new tab
   */
  openConsentForm(): void {
    if (this.consentFormUrl) {
      window.open(this.consentFormUrl, '_blank');
    }
  }

  /**
   * Get status icon based on current verification status
   */
  getStatusIcon(): string {
    if (!this.verification) return 'time-outline';
    
    switch (this.verification.status) {
      case 'approved':
        return 'checkmark-circle';
      case 'rejected':
        return 'close-circle';
      case 'needs_info':
        return 'alert-circle';
      default:
        return 'time-outline';
    }
  }

  /**
   * Set the current step
   */
  setStep(step: string) {
    this.currentStep = step;
  }

  ngOnDestroy(): void {
    this.verificationSubscription?.unsubscribe();
  }
}