import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable, from, of, combineLatest, throwError } from 'rxjs';
import { map, catchError, switchMap } from 'rxjs/operators';
import { AuthenticationService } from './auth.service';
import { RideDataService } from './ride-data.service';
import { RideSearchFilterService } from './ride-search-filter.service';
import { Store } from '@ngrx/store';
import * as JourneyActions from '@app/state/journey/journey.actions';
import * as JourneySelectors from '@app/state/journey/journey.selectors';
import { firstValueFrom } from 'rxjs';
import { Ride, RideSearchParams, RideFilterParams, RideFilterResult } from '../models/ride.interface';
import { RideDetailsModel } from '../models/ride.model';
import { CalendarEvent } from './calendar-integration.service';

/**
 * Primary service for ride management
 * 
 * This service handles all ride-related operations including creation, retrieval, 
 * searching, filtering, and management of ride data.
 */
@Injectable({
  providedIn: 'root'
})
export class RideService {
  constructor(
    private firestore: AngularFirestore,
    private authService: AuthenticationService,
    private store: Store,
    private rideDataService: RideDataService,
    private rideSearchFilter: RideSearchFilterService
  ) {}

  /**
   * Creates rides from calendar events
   * @param events Array of calendar events to convert to rides
   */
  async createRidesFromEvents(events: CalendarEvent[]): Promise<void> {
    try {
      const ridesCollection = this.firestore.collection('rides');
      const userId = await this.getCurrentUserId();
      const batch = [];
      
      for (const event of events) {
        try {
          // Create base ride object
          const ride: Partial<Ride> = {
            title: event.title,
            startTime: typeof event.start === 'string' ? event.start : event.start.toISOString(),
            endTime: typeof event.end === 'string' ? event.end : event.end.toISOString(),
            pickupAddress: event.location || 'Not specified',
            description: event.description || '',
            createdAt: new Date().toISOString(),
            createdBy: userId,
            status: 'scheduled'
          };
          
          // Apply all necessary properties
          const enhancedRide = this.rideDataService.ensureRideProperties(ride);
          
          // Create a promise for adding the document
          const addPromise = ridesCollection.add(enhancedRide)
            .then(docRef => docRef.id)
            .catch(error => {
              console.error('Error creating ride from event:', error);
              throw error;
            });
          
          batch.push(addPromise);
        } catch (eventError) {
          console.error('Error processing event:', eventError);
        }
      }
      
      // Execute all document additions in parallel
      if (batch.length === 0) {
        return;
      }
      
      await Promise.all(batch);
    } catch (error) {
      console.error('Error in createRidesFromEvents:', error);
      throw error;
    }
  }
  
  /**
   * Creates a single ride
   * @param ride Basic ride information
   * @returns Observable with the new ride ID
   */
  createRide(rideData: Partial<Ride>): Observable<string> {
    return from(this.getCurrentUserId()).pipe(
      map(userId => {
        // Always set createdBy to current authenticated user ID
        rideData.createdBy = userId;
        
        // Ensure ride has all necessary properties
        return this.rideDataService.ensureRideProperties(rideData);
      }),
      switchMap(enhancedRide => {
        const ridesCollection = this.firestore.collection('rides');
        return from(ridesCollection.add(enhancedRide)).pipe(
          map(docRef => docRef.id)
        );
      }),
      catchError(error => {
        console.error('Error in createRide flow:', error);
        return throwError(() => error);
      })
    );
  }

  /**
   * Updates an existing ride
   * @param ride Ride object with ID and updated fields
   * @returns Observable that completes when update is done
   */
  updateRide(ride: Ride): Observable<void> {
    if (!ride.id) {
      return throwError(() => new Error('Cannot update ride: Missing ride ID'));
    }
    
    // First ensure we have a valid user ID before proceeding
    return from(this.getCurrentUserId()).pipe(
      map(userId => {
        // If createdBy is missing or invalid, set it to the current authenticated user
        if (!ride.createdBy || ride.createdBy === 'anonymous') {
          ride.createdBy = userId;
        }
        
        // Update the updatedAt timestamp
        ride.updatedAt = new Date().toISOString();
        
        // Ensure ride has all required properties
        return this.rideDataService.ensureRideProperties(ride);
      }),
      switchMap(enhancedRide => {
        // Create a clean version without the ID for Firestore
        const { id, ...updateData } = enhancedRide;
        
        console.log(`Updating ride with ID ${ride.id}`);
        const rideRef = this.firestore.doc(`rides/${ride.id}`);
        return from(rideRef.update(updateData));
      }),
      catchError(error => {
        console.error('Error updating ride:', error);
        return throwError(() => error);
      })
    );
  }

  /**
   * Retrieves a single ride by ID
   * @param id Ride ID
   * @returns Observable with the ride or null
   */
  getRide(id: string): Observable<Ride | null> {
    return this.firestore.doc<Ride>(`rides/${id}`).valueChanges({ idField: 'id' }).pipe(
      map(ride => ride || null),
      catchError(error => {
        console.error('Error fetching ride:', error);
        return of(null);
      })
    );
  }

  /**
   * Deletes a ride
   * @param params Object with ride ID
   * @returns Observable that completes when delete is done
   */
  deleteRide(params: { id: string }): Observable<void> {
    const rideRef = this.firestore.doc(`rides/${params.id}`);
    return from(rideRef.delete()).pipe(
      catchError(error => {
        console.error('Error deleting ride:', error);
        return throwError(() => error);
      })
    );
  }

  /**
   * Cancels a ride by setting its status
   * @param id Ride ID
   * @returns Observable that completes when cancel is done
   */
  cancelRide(id: string): Observable<void> {
    const rideRef = this.firestore.doc(`rides/${id}`);
    return from(rideRef.update({ 
      status: 'cancelled', 
      cancelled: true,
      updatedAt: new Date().toISOString()
    })).pipe(
      catchError(error => {
        console.error('Error cancelling ride:', error);
        return throwError(() => error);
      })
    );
  }

  /**
   * Gets available (scheduled) rides
   * @returns Observable with array of available rides
   */
  getAvailableRides(): Observable<Ride[]> {
    return this.firestore.collection<Ride>('rides', ref => 
      ref.where('status', '==', 'scheduled')
    )
    .valueChanges({ idField: 'id' })
    .pipe(
      catchError(error => {
        console.error('Error fetching available rides:', error);
        return of([]);
      })
    );
  }

  /**
   * Gets all rides regardless of status
   * @returns Observable with array of all rides
   */
  rideGetAll(): Observable<Ride[]> {
    return this.firestore.collection<Ride>('rides')
      .valueChanges({ idField: 'id' })
      .pipe(
        catchError(error => {
          console.error('Error fetching all rides:', error);
          return of([]);
        })
      );
  }

  /**
   * Searches for rides based on search parameters
   * @param params Search parameters
   * @returns Observable with matching rides
   */
  searchRides(params: RideSearchParams): Observable<Ride[]> {
    // Start with a base query for scheduled rides
    const query = this.firestore.collection<Ride>('rides', ref => 
      ref.where('status', '==', 'scheduled')
      .where('cancelled', '==', false)
    );
    
    // Convert to observable of rides array
    return query.valueChanges({ idField: 'id' }).pipe(
      map(rides => {
        // Apply client-side filtering based on search parameters
        return this.rideSearchFilter.applySearchFilters(rides, params);
      }),
      catchError(error => {
        console.error('Error searching for rides:', error);
        return of([]);
      })
    );
  }

  /**
   * Gets rides that were created by a specific user
   * @param userId User ID
   * @param limit Maximum number of rides to fetch (for pagination)
   * @param lastDoc Last document from previous page (for pagination)
   * @returns Observable with user's created rides
   */
  getUserRides(userId: string, limit: number = 10, lastDoc?: any): Observable<{rides: Ride[], lastDoc: any}> {
    if (!userId) {
      return of({rides: [], lastDoc: null});
    }
    
    let query = this.firestore.collection('rides').ref
      .where('createdBy', '==', userId)
      .orderBy('departureTime', 'desc')
      .limit(limit);
    
    // If we have a last document, start after it for pagination
    if (lastDoc) {
      query = query.startAfter(lastDoc);
    }
    
    return from(query.get()).pipe(
      map(snapshot => {
        const rides = snapshot.docs.map(doc => {
          const data = doc.data() as Record<string, any>;
          return { id: doc.id, ...data } as Ride;
        });
        // Return the last document for pagination and the rides
        const newLastDoc = snapshot.docs.length > 0 ? snapshot.docs[snapshot.docs.length - 1] : null;
        return { rides, lastDoc: newLastDoc };
      }),
      catchError(error => {
        console.error('Error fetching user rides:', error);
        return of({rides: [], lastDoc: null});
      })
    );
  }

  /**
   * Gets rides that were booked by a specific user
   * @param userId User ID
   * @param limit Maximum number of rides to fetch (for pagination)
   * @param lastDoc Last document from previous page (for pagination)
   * @returns Observable with user's booked rides
   */
  getBookedRidesForUser(userId: string, limit: number = 10, lastDoc?: any): Observable<{rides: Ride[], lastDoc: any}> {
    if (!userId) {
      return of({rides: [], lastDoc: null});
    }
    
    let query = this.firestore.collection('rides').ref
      .where('bookedBy', 'array-contains', userId)
      .where('status', '==', 'scheduled')
      .orderBy('departureTime', 'desc')
      .limit(limit);
    
    // If we have a last document, start after it for pagination
    if (lastDoc) {
      query = query.startAfter(lastDoc);
    }
    
    return from(query.get()).pipe(
      map(snapshot => {
        const rides = snapshot.docs.map(doc => {
          const data = doc.data() as Record<string, any>;
          return { id: doc.id, ...data } as Ride;
        });
        // Return the last document for pagination and the rides
        const newLastDoc = snapshot.docs.length > 0 ? snapshot.docs[snapshot.docs.length - 1] : null;
        return { rides, lastDoc: newLastDoc };
      }),
      catchError(error => {
        console.error('Error fetching booked rides:', error);
        return of({rides: [], lastDoc: null});
      })
    );
  }

  /**
   * Gets rides that a user has completed or cancelled
   * @param userId User ID
   * @param limit Maximum number of rides to fetch (for pagination)
   * @param lastDoc Last document from previous page (for pagination)
   * @returns Observable with user's history rides
   */
  getHistoryRidesForUser(userId: string, limit: number = 10, lastDoc?: any): Observable<{rides: Ride[], lastDoc: any}> {
    if (!userId) {
      return of({rides: [], lastDoc: null});
    }
    
    // For history, pagination is more complex because we need to query both created and booked rides
    // Simple approach: paginate across all history rides by returning combined array
    
    let createdQuery = this.firestore.collection('rides').ref
      .where('createdBy', '==', userId)
      .where('status', 'in', ['completed', 'cancelled'])
      .orderBy('departureTime', 'desc')
      .limit(limit);
    
    let bookedQuery = this.firestore.collection('rides').ref
      .where('bookedBy', 'array-contains', userId)
      .where('status', 'in', ['completed', 'cancelled'])
      .orderBy('departureTime', 'desc')
      .limit(limit);
    
    // If we have a last document, start after it for pagination
    if (lastDoc) {
      createdQuery = createdQuery.startAfter(lastDoc);
      bookedQuery = bookedQuery.startAfter(lastDoc);
    }
    
    return combineLatest([
      from(createdQuery.get()),
      from(bookedQuery.get())
    ]).pipe(
      map(([createdSnapshot, bookedSnapshot]) => {
        const createdRides = createdSnapshot.docs.map(doc => {
          const data = doc.data() as Record<string, any>;
          return { id: doc.id, ...data } as Ride;
        });
        
        const bookedRides = bookedSnapshot.docs.map(doc => {
          const data = doc.data() as Record<string, any>;
          return { id: doc.id, ...data } as Ride;
        });
        
        // Combine and remove duplicates
        const allRides = [...createdRides, ...bookedRides];
        const uniqueRides = allRides.filter((ride, index, self) => 
          index === self.findIndex(r => r.id === ride.id)
        );
        
        // Sort by departure time
        uniqueRides.sort((a, b) => {
          const timeA = new Date(a.departureTime || 0).getTime();
          const timeB = new Date(b.departureTime || 0).getTime();
          return timeB - timeA; // Descending order (newest first)
        });
        
        // Only take the requested number of items
        const paginatedRides = uniqueRides.slice(0, limit);
        
        // Determine the last document for the next query
        const lastDocItem = paginatedRides.length > 0 ? paginatedRides[paginatedRides.length - 1] : null;
        
        // Since we're combining results, we'll use the departureTime as the cursor
        const lastDocTimestamp = lastDocItem?.departureTime || null;
        
        return { 
          rides: paginatedRides, 
          lastDoc: lastDocTimestamp 
        };
      }),
      catchError(error => {
        console.error('Error fetching history rides:', error);
        return of({rides: [], lastDoc: null});
      })
    );
  }

  /**
   * Filters rides into categories (booked, offered, history)
   * @param filters Filter parameters
   * @param allRides Optional pre-fetched rides array
   * @returns Observable with categorized rides
   */
  filterRides(filters: RideFilterParams, allRides?: Ride[]): Observable<RideFilterResult> {
    // If rides are provided, filter them directly
    if (allRides) {
      return of(this.rideSearchFilter.applyRideFilters(allRides, filters));
    }
    
    // Otherwise, get all rides first, then filter
    return this.rideGetAll().pipe(
      map(rides => this.rideSearchFilter.applyRideFilters(rides, filters)),
      catchError(error => {
        console.error('Error filtering rides:', error);
        return of({ bookedRides: [], offeredRides: [], rideHistory: [] });
      })
    );
  }

  /**
   * Stores ride data in the journey state
   * @param data Ride details model
   */
  async storeRideData(data: RideDetailsModel | any): Promise<void> {
    try {
      if (data.pickupLocation) {
        this.store.dispatch(JourneyActions.setPickupLocation({
          location: data.pickupLocation
        }));
      }
      
      if (data.dropLocation) {
        this.store.dispatch(JourneyActions.setDropOffLocation({
          location: data.dropLocation
        }));
      }
      
      if (data.departureTime) {
        this.store.dispatch(JourneyActions.setSelectedDate({
          date: data.departureTime
        }));
      }
      
      if (data.availableSeats) {
        this.store.dispatch(JourneyActions.setPassengerCount({
          count: data.availableSeats
        }));
      }
      
      if (data.luggageSize) {
        this.store.dispatch(JourneyActions.setSelectedLuggage({
          luggage: data.luggageSize
        }));
      }
      
      this.store.dispatch(JourneyActions.setRideOptions({
        options: {
          pets: !!data.petsAllowed,
          smoking: !!data.smokingAllowed,
          wheelchair: !!data.wheelchairAccessible || false
        }
      }));
      
      // Save additional details in onward ride details
      this.store.dispatch(JourneyActions.setOnwardRideDetails({
        details: {
          price: data.price || 0,
          notes: data.notes || '',
          driverId: data.driverId || '',
          trustedOnly: !!data.trustedOnly,
          trustedGroups: data.trustedGroups || [],
          selectedCar: data.selectedCar || null,
          hasStopover: !!data.hasStopover,
          hasReturn: !!data.hasReturn
        }
      }));
    } catch (err) {
      console.error('Error storing ride data:', err);
    }
  }
  
  /**
   * Clear ride data from journey state
   */
  async clearRideData(): Promise<void> {
    try {
      // Clear journey state
      this.store.dispatch(JourneyActions.resetAll());
      
      // For compatibility with older code, clear localStorage items
      localStorage.removeItem('current_ride_data');
      localStorage.removeItem('temp-ride-data');
      localStorage.removeItem('create-ride-form-data');
      sessionStorage.removeItem('rideData');
    } catch (err) {
      console.error('Error clearing ride data:', err);
    }
  }
  
  /**
   * Get ride data from journey state
   */
  async getRideData(): Promise<RideDetailsModel | null> {
    try {
      // Get data from journey state
      const pickupLocation = await firstValueFrom(this.store.select(JourneySelectors.selectPickupLocation));
      const dropOffLocation = await firstValueFrom(this.store.select(JourneySelectors.selectDropOffLocation));
      const selectedDate = await firstValueFrom(this.store.select(JourneySelectors.selectSelectedDate));
      
      if (pickupLocation && dropOffLocation && selectedDate) {
        // Convert journey state to ride details model
        const passengerCount = await firstValueFrom(this.store.select(JourneySelectors.selectPassengerCount));
        const selectedLuggage = await firstValueFrom(this.store.select(JourneySelectors.selectSelectedLuggage));
        const rideOptions = await firstValueFrom(this.store.select(JourneySelectors.selectRideOptions));
        const onwardRideDetails = await firstValueFrom(this.store.select(JourneySelectors.selectOnwardRideDetails));
        
        return {
          pickupAddress: pickupLocation.address,
          dropAddress: dropOffLocation.address,
          departureTime: selectedDate,
          availableSeats: passengerCount || 1,
          price: onwardRideDetails?.price || 0,
          smokingAllowed: rideOptions?.smoking || false,
          petsAllowed: rideOptions?.pets || false,
          luggageSize: (selectedLuggage as "small" | "medium" | "large") || "medium",
          notes: onwardRideDetails?.notes || '',
          driverId: onwardRideDetails?.driverId || '',
          pickupLocation,
          dropLocation: dropOffLocation,
          trustedOnly: onwardRideDetails?.trustedOnly || false,
          trustedGroups: onwardRideDetails?.trustedGroups || [],
          selectedCar: onwardRideDetails?.selectedCar || null
        };
      }
      
      return null;
    } catch (err) {
      console.error('Error getting ride data:', err);
      return null;
    }
  }

  /**
   * Verifies if the user has write permissions to Firestore
   * Useful for debugging when rides aren't being saved
   */
  verifyFirestoreConnection(): Observable<{canWrite: boolean, userId: string}> {
    // First check if the user is authenticated at all
    return from(this.isUserAuthenticated()).pipe(
      switchMap(isAuthenticated => {
        if (!isAuthenticated) {
          return of({canWrite: false, userId: 'not-authenticated'});
        }
        
        return from(this.getCurrentUserId()).pipe(
          switchMap(userId => {
            // Create a temporary document to verify write permissions
            const tempDoc = {
              temporary: true,
              createdAt: new Date(),
              createdBy: userId,
              _tempDoc: true // Mark as temporary
            };
            
            // Try to write to Firestore
            return from(this.firestore.collection('rides').add(tempDoc)).pipe(
              switchMap(docRef => {
                // Clean up temporary document
                return from(docRef.delete()).pipe(
                  map(() => ({canWrite: true, userId})),
                  catchError(() => of({canWrite: true, userId}))
                );
              }),
              catchError(() => of({canWrite: false, userId}))
            );
          }),
          catchError(() => of({canWrite: false, userId: 'unknown'}))
        );
      }),
      catchError(() => of({canWrite: false, userId: 'error'}))
    );
  }
  
  /**
   * Migrates existing rides to ensure they have all necessary properties
   */
  migrateExistingRides(): Observable<{migrated: number, errors: number}> {
    return from(this.getCurrentUserId()).pipe(
      switchMap(currentUserId => {
        return this.rideGetAll().pipe(
          map(rides => {
            if (!rides || rides.length === 0) {
              return {migrated: 0, errors: 0};
            }
            
            let migrated = 0;
            let errors = 0;
            
            // Create batch updates
            const batchPromises = rides.map(ride => {
              try {
                if (!ride.id) {
                  errors++;
                  return Promise.resolve();
                }
                
                // Check if this ride needs migration
                if (!this.rideDataService.rideNeedsMigration(ride)) {
                  return Promise.resolve();
                }
                
                // Ensure createdBy is set to a valid user ID
                if (!ride.createdBy || ride.createdBy === 'anonymous') {
                  ride.createdBy = currentUserId;
                }
                
                // Apply all required properties
                const enhancedRide = this.rideDataService.ensureRideProperties(ride);
                const rideRef = this.firestore.doc(`rides/${ride.id}`);
                
                migrated++;
                return rideRef.update(enhancedRide);
              } catch (error) {
                errors++;
                return Promise.resolve();
              }
            });
            
            // Execute all updates and return results
            Promise.all(batchPromises)
              .catch(error => {
                console.error('Migration batch error:', error);
              });
            
            return {migrated, errors};
          })
        );
      }),
      catchError(error => {
        console.error('Error in ride migration:', error);
        return of({migrated: 0, errors: 1});
      })
    );
  }

  /**
   * Fixes a specific ride by adding all required properties
   * Useful for troubleshooting
   */
  fixRideById(rideId: string): Observable<boolean> {
    if (!rideId) {
      return of(false);
    }
    
    return from(this.getCurrentUserId()).pipe(
      switchMap(currentUserId => {
        return this.getRide(rideId).pipe(
          switchMap(ride => {
            if (!ride) {
              return of(false);
            }
            
            try {
              // Ensure createdBy is set to a valid user ID
              if (!ride.createdBy || ride.createdBy === 'anonymous') {
                ride.createdBy = currentUserId;
              }
              
              // Apply all required properties
              const enhancedRide = this.rideDataService.ensureRideProperties(ride);
              const rideRef = this.firestore.doc(`rides/${ride.id}`);
              
              return from(rideRef.update(enhancedRide)).pipe(
                map(() => true),
                catchError(() => of(false))
              );
            } catch (error) {
              return of(false);
            }
          }),
          catchError(() => of(false))
        );
      }),
      catchError(() => of(false))
    );
  }

  /**
   * Create ride from a calendar event 
   * Needed by calendar.effects.ts
   */
  createRideFromCalendarEvent(event: any): Observable<string> {
    return this.createRide(event);
  }

  /**
   * Calculate distance between two locations
   * Needed by create-ride.page.ts
   */
  async getDistanceBetween(pickupLocation: any, dropLocation: any): Promise<number> {
    if (!pickupLocation || !dropLocation) {
      return 0;
    }
    
    try {
      // For simplicity, return a fixed distance
      // In a real app, this would use Google Maps API or similar
      return 10.5; // km
    } catch (error) {
      console.error('Error calculating distance:', error);
      return 0;
    }
  }

  /**
   * Upsert ride (create or update)
   * Needed by passengers-option.page.ts
   */
  upsertRide(ride: Ride): Observable<string | void> {
    if (!ride) {
      return throwError(() => new Error('Cannot upsert: No ride data'));
    }
    
    if (ride.id) {
      return this.updateRide(ride).pipe(
        map(() => ride.id as string)
      );
    } else {
      return this.createRide(ride);
    }
  }

  /**
   * Find if user is in booked rides
   * Needed by ride-details.page.ts
   */
  findUserInBookedRides(ride: Ride, user: any): boolean {
    if (!ride || !user) {
      return false;
    }
    
    // Extract userId from either a string or a User object
    const userId = typeof user === 'string' ? user : (user.uid || user.id);
    
    if (!userId) {
      return false;
    }
    
    // Check if user is in bookedBy array
    if (Array.isArray(ride.bookedBy) && ride.bookedBy.includes(userId)) {
      return true;
    }
    
    // Check in bookedByUsers structure if available
    if (ride.bookedByUsers) {
      const onwardUsers = ride.bookedByUsers.onwardRide || [];
      const returnUsers = ride.bookedByUsers.returnRide || [];
      
      if (onwardUsers.some((user: any) => user.id === userId || user.userId === userId)) {
        return true;
      }
      
      if (returnUsers.some((user: any) => user.id === userId || user.userId === userId)) {
        return true;
      }
    }
    
    return false;
  }

  // Helper method to get current user ID
  private async getCurrentUserId(): Promise<string> {
    // First try to get from the authenticated user
    try {
      const user = await this.authService.currentUser;
      if (user) {
        return user.uid;
      }
    } catch (error) {
      console.error('Error getting current user ID from Auth:', error);
    }
    
    throw new Error('No authenticated user found');
  }
  
  // Helper method to check if user is authenticated
  private async isUserAuthenticated(): Promise<boolean> {
    try {
      const userId = await this.authService.getCurrentUserId();
      return !!userId;
    } catch (error) {
      return false;
    }
  }
} 