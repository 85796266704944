import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromCar from './car.reducer';

export const selectCarState = createFeatureSelector<fromCar.State>(
  fromCar.carFeatureKey
);

export const selectCar = createSelector(
  selectCarState,
  (state) => state.car
);

export const selectAllCars = createSelector(
  selectCarState,
  (state) => state.cars
);

export const selectCarError = createSelector(
  selectCarState,
  (state) => state.error
);

export const selectCarPending = createSelector(
  selectCarState,
  (state) => state.pending
); 