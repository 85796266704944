import { createReducer, on } from '@ngrx/store';
import * as RideActions from './ride.actions';

export const ridePageFeatureKey = 'ridePage';

export interface RideState {
  rides: any[];
  offeredRides: any[];
  bookedRides: any[];
  rideHistory: any[];
  selectedRide: any;
  loading: boolean;
  loaded: boolean;
  error: any;
  searchResults: any[];
  searchCriteria: any;
  pickUpQuery: string;
  dropOffQuery: string;
  startTimeQuery: string;
  profiles: any[];
  pagination: {
    currentPage: number;
    pageSize: number;
    totalItems: number;
    totalPages: number;
  };
}

export const initialState: RideState = {
  rides: [],
  offeredRides: [],
  bookedRides: [],
  rideHistory: [],
  selectedRide: null,
  loading: false,
  loaded: false,
  error: null,
  searchResults: [],
  searchCriteria: null,
  pickUpQuery: '',
  dropOffQuery: '',
  startTimeQuery: '',
  profiles: [],
  pagination: {
    currentPage: 1,
    pageSize: 10,
    totalItems: 0,
    totalPages: 0
  }
};

export const rideReducer = createReducer(
  initialState,
  
  // Load Rides
  on(RideActions.loadRides, state => ({
    ...state,
    loading: true,
    loaded: false,
    error: null
  })),
  
  on(RideActions.loadRidesSuccess, (state, { rides }) => ({
    ...state,
    rides: rides.map(ride => ({
      ...ride,
      id: ride.id // Ensure ID is preserved
    })),
    loading: false,
    loaded: true
  })),
  
  on(RideActions.loadRidesFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error
  })),
  
  // Load Offered Rides
  on(RideActions.loadOfferedRides, state => ({
    ...state,
    loading: true
  })),
  
  on(RideActions.loadOfferedRidesSuccess, (state, { rides }) => ({
    ...state,
    offeredRides: rides.map(ride => ({
      ...ride,
      id: ride.id // Ensure ID is preserved
    })),
    loading: false
  })),
  
  on(RideActions.loadOfferedRidesFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error
  })),
  
  // Create Ride
  on(RideActions.createRide, state => ({
    ...state,
    loading: true
  })),
  
  on(RideActions.createRideSuccess, (state, { ride }) => ({
    ...state,
    offeredRides: [...state.offeredRides, { ...ride, id: ride.id }],
    loading: false
  })),
  
  on(RideActions.createRideFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error
  })),
  
  // Select Ride
  on(RideActions.selectRide, (state, { ride }) => ({
    ...state,
    loading: true,
    selectedRide: ride
  })),
  
  on(RideActions.selectRideSuccess, (state, { ride }) => ({
    ...state,
    selectedRide: ride ? { ...ride, id: ride.id } : null,
    loading: false
  })),
  
  on(RideActions.selectRideFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error
  })),
  
  // Update Ride
  on(RideActions.updateRide, (state, { ride }) => ({
    ...state,
    loading: true
  })),
  
  on(RideActions.updateRideSuccess, (state, { ride }) => {
    // Update ride in all applicable collections
    const updatedRides = state.rides.map(r => 
      r.id === ride.id ? { ...ride, id: ride.id } : r
    );
    
    const updatedOfferedRides = state.offeredRides.map(r => 
      r.id === ride.id ? { ...ride, id: ride.id } : r
    );
    
    const updatedBookedRides = state.bookedRides.map(r => 
      r.id === ride.id ? { ...ride, id: ride.id } : r
    );
    
    // If this ride was cancelled, remove from active lists
    let finalOfferedRides = updatedOfferedRides;
    let finalBookedRides = updatedBookedRides;
    
    if (ride.status === 'cancelled' || ride.cancelled) {
      finalOfferedRides = updatedOfferedRides.filter(r => r.id !== ride.id);
      finalBookedRides = updatedBookedRides.filter(r => r.id !== ride.id);
    }
    
    return {
      ...state,
      rides: updatedRides,
      offeredRides: finalOfferedRides,
      bookedRides: finalBookedRides,
      // Update selected ride if it matches
      selectedRide: state.selectedRide?.id === ride.id 
        ? { ...ride, id: ride.id } 
        : state.selectedRide,
      loading: false
    };
  }),
  
  on(RideActions.updateRideFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error
  })),
  
  // Delete Ride
  on(RideActions.deleteRide, state => ({
    ...state,
    loading: true
  })),
  
  on(RideActions.deleteRideSuccess, (state, { id }) => ({
    ...state,
    rides: state.rides.filter(ride => ride.id !== id),
    offeredRides: state.offeredRides.filter(ride => ride.id !== id),
    bookedRides: state.bookedRides.filter(ride => ride.id !== id),
    selectedRide: state.selectedRide?.id === id ? null : state.selectedRide,
    loading: false
  })),
  
  on(RideActions.deleteRideFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error
  })),
  
  // Search Rides
  on(RideActions.searchRides, (state, { criteria }) => ({
    ...state,
    loading: true,
    searchCriteria: criteria
  })),
  
  on(RideActions.searchRidesSuccess, (state, { results }) => ({
    ...state,
    searchResults: results.map(ride => ({
      ...ride,
      id: ride.id // Ensure ID is preserved
    })),
    loading: false
  })),
  
  on(RideActions.searchRidesFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error
  })),
  
  // Handle searchRide (singular) actions
  on(RideActions.searchRide, (state, { searchParams }) => ({
    ...state,
    loading: true,
    searchCriteria: searchParams
  })),
  
  on(RideActions.searchRideSuccess, (state, { rides }) => {
    console.log('[RideReducer] searchRideSuccess with rides:', rides?.length || 0);
    const totalItems = rides.length;
    const totalPages = Math.ceil(totalItems / state.pagination.pageSize);
    
    return {
      ...state,
      searchResults: rides.map(ride => ({
        ...ride,
        id: ride.id // Ensure ID is preserved
      })),
      pagination: {
        ...state.pagination,
        currentPage: 1, // Reset to first page on new search
        totalItems,
        totalPages
      },
      loading: false
    };
  }),
  
  on(RideActions.searchRideFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error
  })),
  
  // Book Ride
  on(RideActions.bookRide, state => ({
    ...state,
    loading: true
  })),
  
  on(RideActions.bookRideSuccess, (state, { ride }) => {
    // Add to booked rides and update the ride in other lists
    const updatedRides = state.rides.map(r => 
      r.id === ride.id ? { ...ride, id: ride.id } : r
    );
    
    return {
      ...state,
      rides: updatedRides,
      bookedRides: [
        ...state.bookedRides.filter(r => r.id !== ride.id),
        { ...ride, id: ride.id }
      ],
      loading: false
    };
  }),
  
  on(RideActions.bookRideFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error
  })),
  
  
  // Cancel Booking
  on(RideActions.cancelBooking, state => ({
    ...state,
    loading: true
  })),
  
  on(RideActions.cancelBookingSuccess, (state, { rideId }) => ({
    ...state,
    bookedRides: state.bookedRides.filter(ride => ride.id !== rideId),
    loading: false
  })),
  
  on(RideActions.cancelBookingFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error
  })),
  
  // Filter Rides
  on(RideActions.filterRides, (state, { filters }) => ({
    ...state,
    loading: true
  })),
  
  on(RideActions.filterRidesSuccess, (state, { filteredData }) => ({
    ...state,
    bookedRides: filteredData.bookedRides.map(ride => ({ 
      ...ride, 
      id: ride.id // Ensure ID is preserved
    })),
    offeredRides: filteredData.offeredRides.map(ride => ({
      ...ride,
      id: ride.id // Ensure ID is preserved
    })),
    rideHistory: filteredData.rideHistory.map(ride => ({
      ...ride,
      id: ride.id // Ensure ID is preserved
    })),
    loading: false
  })),
  
  on(RideActions.filterRidesFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error
  })),
  
  // Clear error
  on(RideActions.clearRideError, state => ({
    ...state,
    error: null
  })),
  
  // Clear ride history
  on(RideActions.clearRideHistory, state => ({
    ...state,
    rideHistory: []
  })),
  
  // Reset state
  on(RideActions.resetRideState, () => initialState),
  
  // Handle pagination actions
  on(RideActions.setPage, (state, { page }) => ({
    ...state,
    pagination: {
      ...state.pagination,
      currentPage: page
    }
  })),

  on(RideActions.setPageSize, (state, { pageSize }) => {
    const totalPages = Math.ceil(state.pagination.totalItems / pageSize);
    return {
      ...state,
      pagination: {
        ...state.pagination,
        pageSize,
        totalPages,
        currentPage: 1 // Reset to first page when changing page size
      }
    };
  }),

  on(RideActions.updatePagination, (state, update) => ({
    ...state,
    pagination: {
      ...state.pagination,
      ...update
    }
  }))
);

// Rename these selector functions to avoid conflicts with the selectors in ride.selectors.ts
export const selectRides = (state: RideState) => state.rides;
export const selectOfferedRides = (state: RideState) => state.offeredRides;
export const selectBookedRides = (state: RideState) => state.bookedRides;
export const selectSelectedRide = (state: RideState) => state.selectedRide;
export const selectRideLoading = (state: RideState) => state.loading;
export const selectRideError = (state: RideState) => state.error;
export const selectSearchResults = (state: RideState) => state.searchResults;
export const selectRideHistory = (state: RideState) => state.rideHistory;

export { rideReducer as reducer };







