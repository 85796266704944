import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

/**
 * Reusable empty state component for displaying empty list states,
 * no results found, or other placeholder content
 */
@Component({
  selector: 'app-empty-state',
  templateUrl: './empty-state.component.html',
  styleUrls: ['./empty-state.component.scss'],
  standalone: true,
  imports: [CommonModule, IonicModule, RouterModule, TranslateModule]
})
export class EmptyStateComponent {
  @Input() icon: string = 'alert-circle-outline';
  @Input() title: string = 'No Data Available';
  @Input() message: string = 'There is no data to display at this time.';
  @Input() actionText: string = '';
  @Input() actionLink: string = '';
  @Input() actionIcon: string = 'add-circle-outline';
  @Output() action = new EventEmitter<void>();
  
  // Additional properties from template
  @Input() illustrationType: string = '';
  @Input() iconName: string = 'alert-circle-outline';
  @Input() iconColor: string = 'medium';
  @Input() description: string = '';
  @Input() showActions: boolean = false;
  @Input() primaryButtonColor: string = 'primary';
  @Input() primaryButtonIcon: string = '';
  @Input() primaryButtonText: string = 'Action';
  @Output() primaryAction = new EventEmitter<void>();
  @Input() showSecondaryButton: boolean = false;
  @Input() secondaryButtonColor: string = 'medium';
  @Input() secondaryButtonIcon: string = '';
  @Input() secondaryButtonText: string = 'Cancel';
  @Output() secondaryAction = new EventEmitter<void>();

  onAction(): void {
    this.action.emit();
  }
}