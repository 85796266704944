import { Component, OnInit, OnDestroy } from '@angular/core';
import { SafetyService } from '../../services/safety.service';
import { Subscription } from 'rxjs';
import { CommonModule } from '@angular/common';
import { IonicModule, AlertController } from '@ionic/angular';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SafetyAlert } from '@app/state/models/safety.model';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-safety',
  templateUrl: './safety.component.html',
  styleUrls: ['./safety.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    IonicModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule
  ]
})
export class SafetyComponent implements OnInit, OnDestroy {
  private alertSubscription: Subscription;
  currentRideId: string;
  locationTracking: boolean = false;
  alerts: SafetyAlert[] = [{
    type: 'info',
    title: 'Safety System Active',
    message: 'Safety monitoring is enabled',
    timestamp: new Date()
  }];

  constructor(
    private safetyService: SafetyService, 
    private alertController: AlertController, 
    private router: Router
  ) {}

  ngOnInit() {
    this.alertSubscription = this.safetyService.getAlerts()
      .subscribe(alerts => {
        if (alerts && alerts.length > 0) {
          // Replace the alerts array or merge with existing alerts
          this.alerts = [...alerts, ...this.alerts];
        }
      });
  }

  async triggerEmergency() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(async (position) => {
        const location = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };
        try {
          await this.safetyService.triggerEmergency(
            this.currentRideId,
            location
          ).toPromise();
        } catch (error) {
          console.error('Error triggering emergency:', error);
        }
      });
    }
  }

  async uploadVerificationPhoto(event: any) {
    const file = event.target.files[0];
    if (file) {
      try {
        await this.safetyService.uploadVerificationPhoto(
          this.currentRideId,
          file
        ).toPromise();
      } catch (error) {
        console.error('Error uploading verification photo:', error);
      }
    }
  }

  startLocationTracking() {
    this.locationTracking = true;
    navigator.geolocation.watchPosition(
      (position) => {
        const location = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };
        this.safetyService.updateLocation(this.currentRideId, location)
          .subscribe();
      },
      (error) => console.error('Location error:', error),
      { enableHighAccuracy: true }
    );
  }

  async showEmergencyAlert() {
    const alert = await this.alertController.create({
      header: 'Emergency Assistance',
      message: 'Do you need immediate assistance?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel'
        },
        {
          text: 'Call Emergency Services',
          role: 'destructive',
          handler: () => {
            // Implement emergency call functionality
            window.location.href = 'tel:911';
          }
        }
      ]
    });

    await alert.present();
  }

  ngOnDestroy() {
    this.alertSubscription?.unsubscribe();
  }
}