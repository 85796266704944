import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { ErrorDisplayComponent } from './components/error-display/error-display.component';

// Import UI components from shared/ui
import { AvatarComponent } from './ui/avatar/avatar.component';
import { ConfirmationDialogComponent } from './ui/confirmation-dialog/confirmation-dialog.component';
import { EmergencyDialogComponent } from './ui/emergency-dialog/emergency-dialog.component';
import { EmptyStateComponent } from './ui/empty-state/empty-state.component';
import { LoadingIndicatorComponent } from './ui/loading-indicator/loading-indicator.component';
import { NotificationsComponent } from './ui/notifications/notifications.component';
import { PageHeaderComponent } from './ui/page-header/page-header.component';
import { StarRatingComponent } from './ui/star-rating/star-rating.component';

// Import calendar module instead of component
import { CalendarImportModule } from '../components/calendar-import/calendar-import.module';

// Import pipes
import { PipesModule } from '../pipes/pipes.module';

/**
 * SharedModule provides common components, directives, and pipes
 * that are used across multiple feature modules.
 */
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    RouterModule,
    TranslateModule,
    PipesModule,
    // Import the CalendarImportModule
    CalendarImportModule,
    
    // Import standalone UI components
    AvatarComponent,
    ConfirmationDialogComponent,
    EmergencyDialogComponent,
    EmptyStateComponent,
    LoadingIndicatorComponent,
    NotificationsComponent,
    PageHeaderComponent,
    StarRatingComponent
  ],
  declarations: [
    // Add non-standalone components here
    ErrorDisplayComponent,
    // CalendarImportComponent is now in its own module
  ],
  exports: [
    // Module exports
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    RouterModule,
    TranslateModule,
    PipesModule,
    // Re-export CalendarImportModule
    CalendarImportModule,
    
    // Component exports
    AvatarComponent,
    ConfirmationDialogComponent,
    EmergencyDialogComponent,
    EmptyStateComponent,
    LoadingIndicatorComponent,
    NotificationsComponent,
    PageHeaderComponent,
    StarRatingComponent,
    ErrorDisplayComponent,
    // CalendarImportComponent is now exported via CalendarImportModule
  ]
})
export class SharedModule { }
