import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';
import { Observable, of, throwError, from } from 'rxjs';
import { TrustedGroup, TrustedMember, TrustedNetwork, TrustedInvite } from '@app/state/models/trusted-network.model';
import { retry, catchError, timeout, map, switchMap } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireAuth } from '@angular/fire/compat/auth';

@Injectable({
  providedIn: 'root'
})
export class TrustedNetworkService {
  private readonly RETRY_COUNT = 3;
  private readonly TIMEOUT_MS = 15000; // 15 seconds timeout

  constructor(
    private storageService: StorageService,
    private firestore: AngularFirestore,
    private afAuth: AngularFireAuth
  ) {}

  /**
   * Get trusted groups for a user
   * @param userId The user ID
   * @returns Array of user's trusted groups
   */
  async getGroups(userId: string): Promise<TrustedGroup[]> {
    try {
      // In a production app, we'd use Firestore to get the groups
      // For now, we'll use mock data
      const groups: TrustedGroup[] = [
        { 
          id: '1', 
          userId, 
          name: 'Family',
          description: 'My family members for ridesharing',
          category: 'family',
          members: [
            {
              userId: 'user1',
              name: 'Jane Doe',
              photoURL: 'assets/avatar-placeholder.png',
              email: 'jane@example.com',
              phone: '+1234567890',
              role: 'admin',
              joinedAt: new Date().toISOString()
            },
            {
              userId: 'user2',
              name: 'John Smith',
              photoURL: 'assets/avatar-placeholder.png',
              email: 'john@example.com',
              phone: '+1987654321',
              role: 'member',
              joinedAt: new Date().toISOString()
            }
          ],
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString()
        },
        { 
          id: '2', 
          userId, 
          name: 'Work Colleagues',
          description: 'Colleagues for carpooling',
          category: 'other',
          members: [
            {
              userId: 'user4',
              name: 'Alice Brown',
              photoURL: 'assets/avatar-placeholder.png',
              email: 'alice@example.com',
              phone: '+1122334455',
              role: 'admin',
              joinedAt: new Date().toISOString()
            },
            {
              userId: 'user5',
              name: 'Bob Green',
              photoURL: 'assets/avatar-placeholder.png',
              email: 'bob@example.com',
              phone: '+1234567891',
              role: 'member',
              joinedAt: new Date().toISOString()
            }
          ],
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString()
        },
        { 
          id: '3', 
          userId, 
          name: 'University Friends',
          description: 'Friends from university for trips',
          category: 'friends',
          members: [
            {
              userId: 'user6',
              name: 'Charlie Hall',
              photoURL: 'assets/avatar-placeholder.png',
              email: 'charlie@example.com',
              phone: '+1567891234',
              role: 'admin',
              joinedAt: new Date().toISOString()
            },
            {
              userId: 'user7',
              name: 'Diana Smith',
              photoURL: 'assets/avatar-placeholder.png',
              email: 'diana@example.com',
              phone: '+1456789123',
              role: 'member',
              joinedAt: new Date().toISOString()
            },
            {
              userId: 'user8',
              name: 'Ethan White',
              photoURL: 'assets/avatar-placeholder.png',
              email: 'ethan@example.com',
              phone: '+1345678912',
              role: 'member',
              joinedAt: new Date().toISOString()
            },
            {
              userId: 'user9',
              name: 'Fiona Black',
              photoURL: 'assets/avatar-placeholder.png',
              email: 'fiona@example.com',
              phone: '+1234567899',
              role: 'member',
              joinedAt: new Date().toISOString()
            }
          ],
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString()
        }
      ];
      
      return groups;
    } catch (error) {
      console.error('Error fetching groups:', error);
      return [];
    }
  }

  /**
   * Get a specific trusted group by ID
   * @param groupId The group ID
   * @returns Observable of the group
   */
  getGroup(groupId: string): Observable<TrustedGroup> {
    return from(this.getAllGroups()).pipe(
      map(groups => groups.find(g => g.id === groupId)),
      map(group => {
        if (!group) {
          throw new Error('Group not found');
        }
        return group;
      }),
      timeout(this.TIMEOUT_MS),
      retry(this.RETRY_COUNT),
      catchError(error => {
        console.error('Error fetching group:', error);
        return throwError('Failed to fetch group. Please try again.');
      })
    );
  }

  /**
   * Get a specific trusted group by ID (Promise version)
   * @param groupId The group ID
   * @returns Promise of the group or null if not found
   */
  async getGroupAsync(groupId: string): Promise<TrustedGroup | null> {
    try {
      const groups = await this.getAllGroups();
      return groups.find(group => group.id === groupId) || null;
    } catch (error) {
      console.error('Error fetching group async:', error);
      return null;
    }
  }

  /**
   * Get all trusted groups in the system
   * @returns Array of all trusted groups
   */
  async getAllGroups(): Promise<TrustedGroup[]> {
    try {
      // For demo purposes, return mock data instead of storage
      return this.getGroups('current-user');
    } catch (error) {
      console.error('Error fetching all groups:', error);
      return [];
    }
  }

  /**
   * Add a new trusted group
   * @param group The group data
   * @returns The ID of the created group
   */
  async addGroup(group: TrustedGroup): Promise<string> {
    // Generate ID if not provided
    if (!group.id) {
      group.id = 'group_' + new Date().getTime();
    }
    
    // Add timestamps
    group.createdAt = new Date().toISOString();
    group.updatedAt = new Date().toISOString();
    
    // For demo purposes, just return the ID
    console.log('Added new group:', group);
    
    return group.id;
  }

  /**
   * Update a trusted group
   * @param groupId The group ID
   * @param updatedData Updated group data
   * @returns Boolean indicating success
   */
  async updateGroup(groupId: string, updatedData: Partial<TrustedGroup>): Promise<boolean> {
    console.log(`Updating group ${groupId} with:`, updatedData);
    return true;
  }

  /**
   * Delete a trusted group
   * @param groupId The group ID to delete
   * @returns Boolean indicating success
   */
  async deleteGroup(groupId: string): Promise<boolean> {
    console.log(`Deleting group ${groupId}`);
    return true;
  }

  /**
   * Add a member to a trusted group
   * @param groupId The group ID
   * @param member The member to add
   * @returns Boolean indicating success
   */
  async addMemberToGroup(groupId: string, member: Partial<TrustedMember>): Promise<boolean> {
    console.log(`Adding member to group ${groupId}:`, member);
    return true;
  }

  /**
   * Remove a member from a trusted group
   * @param groupId The group ID
   * @param userId The user ID to remove
   * @returns Boolean indicating success
   */
  async removeMemberFromGroup(groupId: string, userId: string): Promise<boolean> {
    console.log(`Removing member ${userId} from group ${groupId}`);
    return true;
  }

  /**
   * Get a user's trusted network
   * @param userId The user ID
   * @returns Observable of the user's network
   */
  getUserNetwork(userId: string): Observable<TrustedNetwork> {
    return of({
      userId,
      groups: [],
      trustedUsers: []
    }).pipe(
      timeout(this.TIMEOUT_MS),
      retry(this.RETRY_COUNT),
      catchError(error => {
        console.error('Error fetching user network:', error);
        return throwError('Failed to fetch user network. Please try again.');
      })
    );
  }

  /**
   * Create a new trusted group
   * @param group The group data to create
   * @returns Promise of the created group ID
   */
  async createGroup(group: Partial<TrustedGroup>): Promise<string> {
    try {
      // Get current user ID
      const userId = await this.getCurrentUserId();
      
      if (!userId) {
        throw new Error('User not authenticated');
      }
      
      const newGroup: TrustedGroup = {
        id: 'group_' + new Date().getTime(),
        userId,
        name: group.name || 'New Group',
        description: group.description || '',
        category: group.category || 'other',
        members: [
          {
            userId,
            name: 'Current User',
            photoURL: 'assets/avatar-placeholder.png',
            role: 'admin',
            joinedAt: new Date().toISOString()
          }
        ],
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString()
      };
      
      console.log('Created new group:', newGroup);
      return newGroup.id;
    } catch (error) {
      console.error('Error creating group:', error);
      throw error;
    }
  }

  /**
   * Get current user ID
   */
  private async getCurrentUserId(): Promise<string | null> {
    try {
      const user = await this.afAuth.currentUser;
      return user?.uid || 'current-user'; // Fallback for mock data
    } catch (error) {
      console.error('Error getting current user:', error);
      return 'current-user'; // Fallback for mock data
    }
  }

  /**
   * Check if a user is trusted
   * @param userId The user ID to check
   * @returns Boolean indicating if the user is trusted
   */
  async isUserTrusted(userId: string): Promise<boolean> {
    return ['user1', 'user2', 'user3'].includes(userId);
  }

  /**
   * Create a new invite for a group
   * @param groupId The group ID
   * @param email Optional email to invite
   * @param phone Optional phone to invite
   * @returns Promise of the created invite
   */
  async createInvite(groupId: string, email?: string, phone?: string): Promise<TrustedInvite> {
    const inviteCode = this.generateInviteCode();
    
    const invite: TrustedInvite = {
      id: 'invite_' + new Date().getTime(),
      groupId,
      code: inviteCode,
      inviteCode,
      email,
      phone,
      createdAt: new Date().toISOString(),
      expiresAt: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000).toISOString() // 7 days expiry
    };
    
    console.log('Created new invite:', invite);
    return invite;
  }

  /**
   * Join a group with an invite code
   * @param inviteCode The invite code
   * @returns Promise of the group ID joined
   */
  async joinGroupWithCode(inviteCode: string): Promise<string> {
    console.log(`Joining group with code: ${inviteCode}`);
    return 'group_' + new Date().getTime();
  }

  /**
   * Get users in my trusted groups
   * @returns Observable array of trusted members
   */
  getUsersInMyGroups(): Observable<TrustedMember[]> {
    return from(this.getCurrentUserId()).pipe(
      switchMap(userId => {
        if (!userId) {
          throw new Error('User not authenticated');
        }
        
        return from(this.getGroups(userId)).pipe(
          map(groups => {
            // Extract unique members from all groups
            const memberMap = new Map<string, TrustedMember>();
            
            groups.forEach(group => {
              group.members.forEach(member => {
                // Only include members that aren't the current user
                if (member.userId !== userId) {
                  memberMap.set(member.userId, member);
                }
              });
            });
            
            return Array.from(memberMap.values());
          })
        );
      }),
      timeout(this.TIMEOUT_MS),
      retry(this.RETRY_COUNT),
      catchError(error => {
        console.error('Error fetching users in my groups:', error);
        return of([]); // Return empty array on error
      })
    );
  }

  /**
   * Get member details by user ID
   * @param userId The user ID
   * @returns Observable of the member details
   */
  getMemberDetails(userId: string): Observable<TrustedMember | null> {
    return this.getUsersInMyGroups().pipe(
      map(members => members.find(member => member.userId === userId) || null),
      timeout(this.TIMEOUT_MS),
      retry(this.RETRY_COUNT),
      catchError(error => {
        console.error('Error fetching member details:', error);
        return of(null);
      })
    );
  }

  /**
   * Add a new member directly to the trusted network
   * @param member The member data
   * @returns Promise of success
   */
  async addTrustedMember(member: Partial<TrustedMember>): Promise<boolean> {
    try {
      const userId = await this.getCurrentUserId();
      
      if (!userId) {
        throw new Error('User not authenticated');
      }
      
      console.log(`Adding trusted member for user ${userId}:`, member);
      return true;
    } catch (error) {
      console.error('Error adding trusted member:', error);
      return false;
    }
  }

  /**
   * Remove a member from the trusted network
   * @param memberId The member ID to remove
   * @returns Promise of success
   */
  async removeTrustedMember(memberId: string): Promise<boolean> {
    try {
      const userId = await this.getCurrentUserId();
      
      if (!userId) {
        throw new Error('User not authenticated');
      }
      
      console.log(`Removing trusted member ${memberId} for user ${userId}`);
      return true;
    } catch (error) {
      console.error('Error removing trusted member:', error);
      return false;
    }
  }

  /**
   * Get my groups as observable
   */
  get myGroups$(): Observable<TrustedGroup[]> {
    return from(this.getCurrentUserId()).pipe(
      switchMap(userId => {
        if (!userId) {
          return of([]);
        }
        return from(this.getGroups(userId));
      }),
      catchError(error => {
        console.error('Error fetching my groups:', error);
        return of([]);
      })
    );
  }

  /**
   * Generate a random invite code
   * @returns Random 8-character invite code
   */
  private generateInviteCode(): string {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let code = '';
    
    for (let i = 0; i < 8; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      code += characters.charAt(randomIndex);
    }
    
    return code;
  }
} 