import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { CalendarIntegrationService, CalendarEvent } from '@app/services/calendar-integration.service';
import { RideService } from '@app/services/ride.service';
import { ToastController } from '@ionic/angular';

@Component({
  selector: 'app-calendar-import',
  template: `
    <div class="calendar-import-container">
      <input 
        #fileInput 
        type="file" 
        accept=".ics,.ical,.icalendar,.csv,.json" 
        style="display:none" 
        (change)="onFileSelected($event)"
      />
      <ion-button (click)="triggerFileSelection()" expand="block">
        <ion-icon name="calendar-outline" slot="start"></ion-icon>
        Import Calendar
      </ion-button>
      <div *ngIf="isImporting" class="import-status">
        <ion-spinner name="dots"></ion-spinner>
        <span>Importing events...</span>
      </div>
    </div>
  `,
  styles: [`
    .calendar-import-container {
      padding: 10px;
    }
    .import-status {
      display: flex;
      align-items: center;
      margin-top: 10px;
      color: var(--ion-color-primary);
    }
    .import-status span {
      margin-left: 10px;
    }
  `]
})
export class CalendarImportComponent implements OnInit {
  @ViewChild('fileInput') fileInput!: ElementRef;
  @Output() importSuccess = new EventEmitter<CalendarEvent[]>();
  isImporting = false;

  constructor(
    private calendarIntegrationService: CalendarIntegrationService,
    private rideService: RideService,
    private toastController: ToastController
  ) {}

  ngOnInit() {}

  triggerFileSelection() {
    this.fileInput.nativeElement.click();
  }

  async onFileSelected(event: Event) {
    const input = event.target as HTMLInputElement;
    if (!input.files || input.files.length === 0) {
      return;
    }

    const file = input.files[0];
    this.isImporting = true;

    try {
      const events = await this.calendarIntegrationService.parseCalendarFile(file);
      
      if (events.length === 0) {
        await this.presentToast('No events found in the imported file', 'warning');
      } else {
        await this.rideService.createRidesFromEvents(events);
        await this.presentToast(`Successfully imported ${events.length} events`, 'success');
        this.importSuccess.emit(events);
      }
    } catch (error) {
      console.error('Error importing calendar:', error);
      await this.presentToast('Failed to import calendar: ' + this.getErrorMessage(error), 'danger');
    } finally {
      this.isImporting = false;
      // Reset the input so the same file can be selected again
      input.value = '';
    }
  }

  private getErrorMessage(error: any): string {
    return (error instanceof Error) ? error.message : 'Unknown error';
  }

  private async presentToast(message: string, color: 'success' | 'warning' | 'danger') {
    const toast = await this.toastController.create({
      message,
      duration: 3000,
      color,
      position: 'bottom'
    });
    await toast.present();
  }
}
