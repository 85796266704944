// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
    .calendar-import-container {
      padding: 10px;
    }
    .import-status {
      display: flex;
      align-items: center;
      margin-top: 10px;
      color: var(--ion-color-primary);
    }
    .import-status span {
      margin-left: 10px;
    }
  
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImNhbGVuZGFyLWltcG9ydC5jb21wb25lbnQudHMiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IjtJQUNJO01BQ0UsYUFBYTtJQUNmO0lBQ0E7TUFDRSxhQUFhO01BQ2IsbUJBQW1CO01BQ25CLGdCQUFnQjtNQUNoQiwrQkFBK0I7SUFDakM7SUFDQTtNQUNFLGlCQUFpQjtJQUNuQiIsImZpbGUiOiJjYWxlbmRhci1pbXBvcnQuY29tcG9uZW50LnRzIiwic291cmNlc0NvbnRlbnQiOlsiXG4gICAgLmNhbGVuZGFyLWltcG9ydC1jb250YWluZXIge1xuICAgICAgcGFkZGluZzogMTBweDtcbiAgICB9XG4gICAgLmltcG9ydC1zdGF0dXMge1xuICAgICAgZGlzcGxheTogZmxleDtcbiAgICAgIGFsaWduLWl0ZW1zOiBjZW50ZXI7XG4gICAgICBtYXJnaW4tdG9wOiAxMHB4O1xuICAgICAgY29sb3I6IHZhcigtLWlvbi1jb2xvci1wcmltYXJ5KTtcbiAgICB9XG4gICAgLmltcG9ydC1zdGF0dXMgc3BhbiB7XG4gICAgICBtYXJnaW4tbGVmdDogMTBweDtcbiAgICB9XG4gICJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/components/calendar-import/calendar-import.component.ts"],"names":[],"mappings":";IACI;MACE,aAAa;IACf;IACA;MACE,aAAa;MACb,mBAAmB;MACnB,gBAAgB;MAChB,+BAA+B;IACjC;IACA;MACE,iBAAiB;IACnB;;AAEJ,ouBAAouB","sourcesContent":["\n    .calendar-import-container {\n      padding: 10px;\n    }\n    .import-status {\n      display: flex;\n      align-items: center;\n      margin-top: 10px;\n      color: var(--ion-color-primary);\n    }\n    .import-status span {\n      margin-left: 10px;\n    }\n  "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
