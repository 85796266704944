import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { GeocodingService, GeocodingResult } from './geocoding.service';
import { Location } from '@app/shared/models/ride.model';

@Injectable({
  providedIn: 'root'
})
export class GeocodingValidationService {
  constructor(private geocodingService: GeocodingService) {}

  validateLocation(address: string): Observable<{ 
    isValid: boolean;
    location?: Location;
    error?: string;
  }> {
    if (!address) {
      return of({ isValid: false, error: 'No address provided' });
    }

    return this.geocodingService.geocodeAddress(address).pipe(
      map(result => {
        if (!result) {
          return { isValid: false, error: 'Could not validate location' };
        }
        
        return {
          isValid: true,
          location: {
            address,
            lat: result.lat,
            lng: result.lng,
            placeId: result.placeId,
            name: result.name || address
          }
        };
      }),
      catchError(error => of({
        isValid: false,
        error: `Failed to validate location: ${error.message}`
      }))
    );
  }

  validateMultipleLocations(addresses: string[]): Observable<{
    validLocations: Location[];
    invalidAddresses: { address: string; error: string }[];
  }> {
    const validLocations: Location[] = [];
    const invalidAddresses: { address: string; error: string }[] = [];

    return new Observable(observer => {
      const processAddress = async (address: string) => {
        try {
          const result = await this.geocodingService.geocodeAddress(address).toPromise();
          if (!result) {
            invalidAddresses.push({
              address,
              error: 'Location could not be geocoded'
            });
            return;
          }

          validLocations.push({
            address,
            lat: result.lat,
            lng: result.lng,
            placeId: result.placeId,
            name: result.name || address
          });
        } catch (error) {
          invalidAddresses.push({
            address,
            error: error instanceof Error ? error.message : 'Unknown error'
          });
        }
      };

      Promise.all(addresses.map(processAddress))
        .then(() => {
          observer.next({ validLocations, invalidAddresses });
          observer.complete();
        })
        .catch(error => {
          observer.error(error);
        });
    });
  }
}