import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { NavController, MenuController, Platform } from '@ionic/angular';
import { DataService } from '@app/services/data.service';
import { Subscription } from 'rxjs';
import { MessageService } from '../../services/message.service';
import { RideStatusService, RideStatus } from '../../services/ride-status.service';
import { TAB_ROUTING } from '@app/constants';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.page.html',
  styleUrls: ['./tabs.page.scss'],
})
export class TabsPage implements OnInit, OnDestroy {
  public tabRoutes: any;
  public clicked = 'Home';
  unreadMessageCount: number = 0;
  rideStatus: RideStatus = { isActive: false, role: null };
  isDesktop: boolean = false;
  
  private messageSubscription: Subscription;
  private rideStatusSubscription: Subscription;
  private platformSubscription: Subscription;
  
  constructor(
    private router: Router,
    private navCtrl: NavController,
    private dataService: DataService,
    private messageService: MessageService,
    private rideStatusService: RideStatusService,
    private menuCtrl: MenuController,
    private platform: Platform
  ) {
    this.tabRoutes = TAB_ROUTING;
    this.messageSubscription = this.messageService.getUnreadMessageCount().subscribe(count => {
      this.unreadMessageCount = count;
    });
  }
  
  ngOnInit() {
    // Set initial tab
    const currentTab = this.getCurrentTab();
    if (currentTab) {
      this.clicked = this.getTabLabel(currentTab);
      this.dataService.currentTab = currentTab;
    }
    
    // Subscribe to ride status changes
    this.rideStatusSubscription = this.rideStatusService.getRideStatus()
      .subscribe(status => {
        this.rideStatus = status;
      });
      
    // Check for desktop view
    this.checkIfDesktop();
    this.platformSubscription = this.platform.resize.subscribe(() => {
      this.checkIfDesktop();
    });
  }
  
  ngOnDestroy() {
    // Clean up subscriptions when component is destroyed
    if (this.messageSubscription) {
      this.messageSubscription.unsubscribe();
    }
    if (this.rideStatusSubscription) {
      this.rideStatusSubscription.unsubscribe();
    }
    if (this.platformSubscription) {
      this.platformSubscription.unsubscribe();
    }
  }
  
  openSafety() {
    this.navCtrl.navigateForward('/safety', {
      animated: true,
      animationDirection: 'forward'
    });
  }
  
  showTabLabel(tab) {
    this.clicked = tab.label;
    this.dataService.currentTab = tab.tab;
    if (tab.tab === 'find') {
      this.dataService.rideType = 'find';
    }
    if (tab.tab === 'offer') {
      this.dataService.rideType = 'offer';
    }
  }
  
  /**
   * Handle tab click, particularly for the "offer" tab which navigates to pickup page
   * @param tab The tab that was clicked
   * @returns False to prevent default navigation for offer tab, true otherwise
   */
  onTabClick(tab: string) {
    if (tab === 'offer') {
      // Route to pickup page when the offer tab is clicked
      this.navCtrl.navigateForward('/pickup', {
        animated: true,
        animationDirection: 'forward'
      });
      
      // Close the menu if it's open
      this.menuCtrl.close();
      
      // Mark this tab as active in the data service
      this.dataService.currentTab = 'offer';
      this.dataService.rideType = 'offer';
      
      // Return false to prevent the default tab navigation
      return false;
    }
    
    // Allow default navigation for other tabs
    return true;
  }
  
  /**
   * Check if the safety FAB should be displayed
   */
  get showSafetyFab(): boolean {
    return this.rideStatus.isActive && this.rideStatus.role !== null;
  }
  
  /**
   * Check if we're on desktop view
   */
  private checkIfDesktop() {
    const width = this.platform.width();
    this.isDesktop = width >= 768;
  }
  
  private getCurrentTab(): string {
    const url = this.router.url;
    if (url.includes('/home')) return 'home';
    if (url.includes('/find')) return 'find';
    if (url.includes('/offer')) return 'offer';
    if (url.includes('/messages') || url.includes('/inbox')) return 'messages';
    if (url.includes('/profile')) return 'profile';
    return 'home'; // Default
  }
  
  private getTabLabel(tabName: string): string {
    const tab = this.tabRoutes.find(t => t.tab === tabName);
    return tab ? tab.label : 'Home';
  }
}
