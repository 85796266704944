import { createReducer, on } from '@ngrx/store';
import * as ProfileActions from './profile.actions';
import { Profile } from '@app/state/models/profile.model';

export const profileFeatureKey = 'profile';

export interface State {
  profiles: any[];
  loading: boolean;
  error: any;
}

export const initialState: State = {
  profiles: [],
  loading: false,
  error: null
};

export const reducer = createReducer(
  initialState,

  // Profile Get
  on(ProfileActions.profileGet, (state) => {
    return {
      ...state,
      loading: true,
      error: null
    };
  }),

  on(ProfileActions.profileGetSuccess, (state, { profile }) => {
    console.log('ProfileReducer: profile get success:', profile);
    
    // If the profile array is empty, return current state
    if (!profile || profile.length === 0) {
      return {
        ...state,
        loading: false
      };
    }
    
    // Create a new array by replacing or adding the profile
    const updatedProfiles = [...state.profiles];
    const profileData = profile[0];
    const index = updatedProfiles.findIndex(p => p.id === profileData.id);
    
    if (index !== -1) {
      // Replace existing profile
      updatedProfiles[index] = profileData;
    } else {
      // Add new profile
      updatedProfiles.push(profileData);
    }
    
    return {
      ...state,
      profiles: updatedProfiles,
      loading: false,
      error: null
    };
  }),

  on(ProfileActions.profileGetFailure, (state, { error }) => {
    return {
      ...state,
      loading: false,
      error
    };
  }),

  // Profile Get All
  on(ProfileActions.profileGetAll, (state) => {
    return {
      ...state,
      loading: true,
      error: null
    };
  }),

  on(ProfileActions.profileGetAllSuccess, (state, { profiles }) => {
    return {
      ...state,
      profiles: profiles || [],
      loading: false,
      error: null
    };
  }),

  on(ProfileActions.profileGetAllFailure, (state, { error }) => {
    return {
      ...state,
      loading: false,
      error
    };
  }),

  // Profile Upsert
  on(ProfileActions.profileUpsert, (state, { data }) => {
    return {
      ...state,
      loading: true,
      error: null
    };
  }),

  on(ProfileActions.profileUpsertSuccess, (state) => ({
    ...state,
    loading: false,
    error: null
  })),

  on(ProfileActions.profileUpsertFailure, (state, { error }) => {
    return {
      ...state,
      loading: false,
      error
    };
  })
); 