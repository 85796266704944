import { Component, OnInit, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { IonicModule, AlertController } from '@ionic/angular';
import { GoogleMapsModule } from '@angular/google-maps';
import { TranslateModule } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { RideSchedulingService } from '../ride-scheduling.service';
import { ChildProfileService } from '../../profile/child.service';
import { RideSchedule, RideTracking } from '../../../shared/models/ride.model';
import { ChildProfile } from '../../../shared/models/child-profile.model';
import { PageHeaderComponent } from '../../../shared/ui/page-header/page-header.component';
import { EmptyStateComponent } from '../../../shared/ui/empty-state/empty-state.component';

@Component({
  selector: 'app-active-rides',
  templateUrl: './active-rides.component.html',
  styleUrls: ['./active-rides.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    IonicModule,
    GoogleMapsModule,
    TranslateModule,
    PageHeaderComponent,
    EmptyStateComponent
  ]
})
export class ActiveRidesComponent implements OnInit, OnDestroy {
  activeRides: RideSchedule[] = [];
  children: Map<string, ChildProfile> = new Map();
  currentRideTracking: RideTracking | null = null;
  expandedRideId: string | null = null;
  private subscriptions = new Subscription();

  constructor(
    private router: Router,
    private alertController: AlertController,
    private rideService: RideSchedulingService,
    private childService: ChildProfileService
  ) {}

  ngOnInit(): void {
    // Load active rides
    this.subscriptions.add(
      this.rideService.getActiveRides().subscribe(rides => {
        this.activeRides = rides.filter(ride => !!ride); // Filter out null/undefined rides
        if (this.activeRides.length > 0) {
          this.loadChildrenDetails();
        }
      })
    );

    // Listen for ride tracking updates
    this.subscriptions.add(
      this.rideService.getRideTracking().subscribe(tracking => {
        this.currentRideTracking = tracking;
      })
    );
  }

  /**
   * Load children details for all active rides
   */
  private loadChildrenDetails(): void {
    // Get unique child IDs from all rides
    const childIds = new Set(
      this.activeRides.flatMap(ride => ride.childIds || [])
    );

    // Load child profiles
    this.subscriptions.add(
      this.childService.getChildren().subscribe(children => {
        this.children = new Map(
          children
            .filter(child => childIds.has(child.id))
            .map(child => [child.id, child])
        );
      })
    );
  }

  /**
   * Navigate to ride scheduling
   */
  scheduleRide(): void {
    this.router.navigate(['/rides/schedule']);
  }

  /**
   * Start tracking a specific ride
   */
  trackRide(rideId: string): void {
    if (rideId) {
      this.rideService.startTrackingRide(rideId).subscribe();
    }
  }

  /**
   * Toggle ride details expansion
   */
  toggleRideDetails(rideId: string): void {
    if (!rideId) return;
    
    this.expandedRideId = this.expandedRideId === rideId ? null : rideId;
  }

  /**
   * Cancel a scheduled ride
   */
  async cancelRide(ride: RideSchedule): Promise<void> {
    const alert = await this.alertController.create({
      header: 'Cancel Ride',
      message: 'Are you sure you want to cancel this ride?',
      buttons: [
        {
          text: 'No',
          role: 'cancel'
        },
        {
          text: 'Yes',
          handler: () => {
            if (ride?.id) {
              this.rideService.cancelRide(ride.id).subscribe();
            }
          }
        }
      ]
    });

    await alert.present();
  }

  /**
   * Get child names for a ride
   */
  getChildrenNames(childIds: string[]): string {
    if (!childIds || !Array.isArray(childIds)) return '';
    
    return childIds
      .map(id => this.children.get(id)?.name || '')
      .filter(name => name)
      .join(', ');
  }

  /**
   * Format pickup time
   */
  formatPickupTime(date: Date | string | null): string {
    if (!date) return '';
    try {
      return new Date(date).toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit'
      });
    } catch (e) {
      console.error('Error formatting pickup time:', e);
      return '';
    }
  }

  /**
   * Get status color for Ionic components
   */
  getStatusColor(status: string): string {
    switch (status?.toLowerCase()) {
      case 'scheduled':
        return 'primary';
      case 'in_progress':
        return 'success';
      case 'completed':
        return 'success';
      case 'cancelled':
        return 'danger';
      default:
        return 'medium';
    }
  }

  /**
   * Check if a ride can be tracked
   */
  canTrackRide(ride: RideSchedule): boolean {
    return ride?.status ? ['scheduled', 'in_progress'].includes(ride.status) : false;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}