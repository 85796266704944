import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController, IonicModule } from '@ionic/angular';
import { Subscription, interval, firstValueFrom } from 'rxjs';
import { RideSchedulingService } from '../ride-scheduling.service';
import { EmergencyService } from '../../../core/services/emergency.service';
import { LocationService } from '../../../services/location.service';
import { RideSchedule, RideTracking } from '../../../shared/models/ride.model';
import { Child } from '../../../shared/models/child.model';
import { ChildProfileService } from '../../../features/profile/child.service';
import { EmergencyReport } from '../../../core/services/emergency.service';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { GoogleMapsModule } from '@angular/google-maps';
import { FormsModule } from '@angular/forms';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

@Component({
  selector: 'app-ride-tracking',
  templateUrl: './ride-tracking.component.html',
  styleUrls: ['./ride-tracking.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule,
    GoogleMapsModule,
    FormsModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class RideTrackingComponent implements OnInit, OnDestroy {
  @ViewChild('safetyCode') safetyCodeInput: ElementRef;

  ride: RideSchedule | null = null;
  tracking: RideTracking | null = null;
  children: Child[] = [];
  activeEmergency: EmergencyReport | null = null;
  isVerifyingPickup = false;
  isVerifyingDropoff = false;
  routePath: google.maps.LatLngLiteral[] = [];
  mapZoom = 15;
  mapCenter: google.maps.LatLngLiteral = { lat: 0, lng: 0 };

  private subscriptions: Subscription[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private alertController: AlertController,
    private rideService: RideSchedulingService,
    private emergencyService: EmergencyService,
    private locationService: LocationService,
    private childService: ChildProfileService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    const rideId = this.route.snapshot.paramMap.get('id');
    if (rideId) {
      // Load ride details and start tracking
      this.subscriptions.push(
        this.rideService.startTrackingRide(rideId).subscribe(tracking => {
          this.tracking = tracking;
          this.updateMapPosition();
          this.rideService.getCurrentRide().subscribe(ride => {
            if (ride) {
              this.ride = ride;
              this.loadChildrenDetails();
            }
          });
        })
      );

      // Set up periodic tracking updates
      this.subscriptions.push(
        interval(10000).subscribe(() => {
          this.rideService.startTrackingRide(rideId).subscribe(tracking => {
            this.tracking = tracking;
            this.updateMapPosition();
            this.updateRoutePath();
          });
        })
      );

      // Subscribe to active emergencies
      this.subscriptions.push(
        this.emergencyService.subscribeToActiveEmergencies()
          .subscribe(emergencies => {
            this.activeEmergency = emergencies[0] || null;
          })
      );
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  /**
   * Load children details for the ride
   */
  private loadChildrenDetails(): void {
    if (this.ride) {
      this.subscriptions.push(
        this.childService.getChildren()
          .subscribe(children => {
            this.children = children.filter(
              child => this.ride.childIds.includes(child.id)
            );
          })
      );
    }
  }

  /**
   * Update map position based on tracking data
   */
  private updateMapPosition(): void {
    if (this.tracking?.currentLocation) {
      this.mapCenter = {
        lat: this.tracking.currentLocation.lat,
        lng: this.tracking.currentLocation.lng
      };
    }
  }

  /**
   * Update the route path on the map
   */
  private updateRoutePath(): void {
    if (this.tracking?.currentLocation) {
      this.routePath.push({
        lat: this.tracking.currentLocation.lat,
        lng: this.tracking.currentLocation.lng
      });
    }
  }

  /**
   * Get safety check status icon
   */
  getSafetyCheckIcon(check: any): string {
    switch (check.type) {
      case 'photo_verification':
        return check.status === 'completed' ? 'checkmark-circle' : 'camera';
      case 'safety_code':
        return check.status === 'completed' ? 'checkmark-circle' : 'key';
      default:
        return check.status === 'completed' ? 'checkmark-circle' : 'alert-circle';
    }
  }

  /**
   * Start photo verification process
   */
  async startPhotoVerification(type: 'pickup' | 'dropoff'): Promise<void> {
    if (type === 'pickup') {
      this.isVerifyingPickup = true;
    } else {
      this.isVerifyingDropoff = true;
    }

    // TODO: Implement photo verification logic
  }

  /**
   * Verify safety code
   */
  async verifySafetyCode(code: string): Promise<void> {
    if (!this.ride || !code) return;

    try {
      const result = await firstValueFrom(this.rideService.verifySafetyCode(this.ride.id, code));
      if (result) {
        const toast = await this.alertController.create({
          header: 'Success',
          message: 'Safety code verified successfully',
          buttons: ['OK']
        });
        await toast.present();
      } else {
        throw new Error('Invalid safety code');
      }
    } catch (error) {
      const alert = await this.alertController.create({
        header: 'Error',
        message: 'Invalid safety code. Please try again.',
        buttons: ['OK']
      });
      await alert.present();
    }
  }

  /**
   * Get formatted status text
   */
  getStatusText(): string {
    if (!this.tracking) return 'Connecting...';

    switch (this.tracking.status) {
      case 'en_route_to_pickup':
        return 'Driver is on the way to pickup';
      case 'at_pickup':
        return 'Driver has arrived at pickup location';
      case 'en_route_to_dropoff':
        return 'En route to destination';
      case 'at_dropoff':
        return 'Arrived at destination';
      default:
        return 'Unknown status';
    }
  }

  /**
   * Get ETA text
   */
  getEtaText(): string {
    if (!this.tracking?.estimatedArrival) return 'Calculating...';
    
    const eta = new Date(this.tracking.estimatedArrival);
    return eta.toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit'
    });
  }

  /**
   * Check if a specific safety check is completed
   */
  isSafetyCheckCompleted(type: string): boolean {
    return this.ride?.safetyChecks.some(
      check => check.type === type && check.status === 'completed'
    ) ?? false;
  }

  /**
   * Open emergency dialog
   */
  async openEmergencyDialog(): Promise<void> {
    const alert = await this.alertController.create({
      header: 'Emergency Assistance',
      message: 'Do you need immediate assistance?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel'
        },
        {
          text: 'Report Emergency',
          role: 'destructive',
          handler: () => {
            this.reportEmergency();
          }
        }
      ]
    });
    await alert.present();
  }

  /**
   * Report an emergency
   */
  private async reportEmergency(): Promise<void> {
    if (!this.ride) return;

    try {
      const emergencyReport = await firstValueFrom(
        this.emergencyService.reportEmergency({
          rideId: this.ride.id,
          type: 'SAFETY',
          location: this.tracking?.currentLocation || null,
          description: `Emergency reported during ride ${this.ride.id}`
        })
      );

      this.activeEmergency = emergencyReport;
      
      const toast = await this.alertController.create({
        header: 'Emergency Reported',
        message: 'Our team has been notified and will contact you shortly.',
        buttons: ['OK']
      });
      await toast.present();
    } catch (error) {
      console.error('Failed to report emergency:', error);
      const alert = await this.alertController.create({
        header: 'Error',
        message: 'Failed to report emergency. Please try again or call emergency services directly.',
        buttons: ['OK']
      });
      await alert.present();
    }
  }

  /**
   * Get driver arrival time
   */
  getDriverArrivalTime(): string {
    if (!this.tracking?.estimatedArrival) return 'Unknown';
    
    const arrivalTime = new Date(this.tracking.estimatedArrival);
    return arrivalTime.toLocaleTimeString([], { 
      hour: '2-digit', 
      minute: '2-digit'
    });
  }

  /**
   * Calculate and format time remaining
   */
  getTimeRemaining(): string {
    if (!this.tracking?.estimatedArrival) return 'Calculating...';
    
    const now = new Date();
    const arrivalTime = new Date(this.tracking.estimatedArrival);
    const diffMs = arrivalTime.getTime() - now.getTime();
    
    if (diffMs <= 0) return 'Arriving now';
    
    const diffMins = Math.round(diffMs / 60000);
    return diffMins === 1 ? '1 minute' : `${diffMins} minutes`;
  }

  /**
   * Navigate back to ride details
   */
  goBack(): void {
    this.router.navigate(['/rides', this.ride?.id || 'active']);
  }

  /**
   * Contact driver
   */
  contactDriver(): void {
    // TODO: Implement driver contact functionality
  }
}