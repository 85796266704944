import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ErrorDetails } from './error-handler.service';

/**
 * Log levels for different types of log messages
 */
export enum LogLevel {
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
  DEBUG = 'debug'
}

/**
 * Service for logging events, errors, and analytics
 */
@Injectable({
  providedIn: 'root'
})
export class LoggingService {
  private readonly MAX_LOGS_IN_MEMORY = 100;
  private inMemoryLogs: Array<{
    level: LogLevel;
    message: string;
    data?: any;
    timestamp: Date;
  }> = [];
  
  constructor() {}

  /**
   * Log an informational message
   * @param message Log message
   * @param data Optional data to include
   */
  public logInfo(message: string, data?: any): void {
    this.log(LogLevel.INFO, message, data);
  }

  /**
   * Log a warning message
   * @param message Warning message
   * @param data Optional data to include
   */
  public logWarning(message: string, data?: any): void {
    this.log(LogLevel.WARNING, message, data);
  }

  /**
   * Log an error message
   * @param errorDetails Error details object or simple error message
   */
  public logError(errorDetails: ErrorDetails | string): void {
    if (typeof errorDetails === 'string') {
      this.log(LogLevel.ERROR, errorDetails);
    } else {
      this.log(
        LogLevel.ERROR, 
        errorDetails.message, 
        { code: errorDetails.code, severity: errorDetails.severity }
      );
      
      // In production, send to remote logging service
      if (environment.production) {
        // Implementation would connect to your chosen logging provider
        // Example: Sentry.captureException(errorDetails.originalError);
      }
    }
  }

  /**
   * Main logging method
   */
  private log(level: LogLevel, message: string, data?: any): void {
    const logEntry = {
      level,
      message,
      data,
      timestamp: new Date()
    };
    
    // Keep logs in memory for debugging
    this.inMemoryLogs.unshift(logEntry);
    
    // Trim logs if they exceed maximum
    if (this.inMemoryLogs.length > this.MAX_LOGS_IN_MEMORY) {
      this.inMemoryLogs = this.inMemoryLogs.slice(0, this.MAX_LOGS_IN_MEMORY);
    }
    
    // Console logging for development
    if (!environment.production) {
      this.logToConsole(level, message, data);
    }
  }

  /**
   * Log to console during development
   */
  private logToConsole(level: LogLevel, message: string, data?: any): void {
    const timestamp = new Date().toISOString();
    
    switch (level) {
      case LogLevel.ERROR:
        console.error(`[${timestamp}] ERROR: ${message}`, data || '');
        break;
      case LogLevel.WARNING:
        console.warn(`[${timestamp}] WARNING: ${message}`, data || '');
        break;
      case LogLevel.INFO:
        console.log(`[${timestamp}] INFO: ${message}`, data || '');
        break;
      case LogLevel.DEBUG:
        console.debug(`[${timestamp}] DEBUG: ${message}`, data || '');
        break;
    }
  }
} 