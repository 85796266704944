import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-error-display',
  templateUrl: './error-display.component.html',
  styleUrls: ['./error-display.component.scss']
})
export class ErrorDisplayComponent {
  @Input() errorTitle: string = 'Something went wrong';
  @Input() errorMessage: string = 'An unexpected error occurred. Please try again.';
  @Input() errorIcon: string = 'alert-circle-outline';
  @Input() showRetry: boolean = true;
  @Input() retryButtonText: string = 'Try Again';
  @Input() showReportButton: boolean = false;
  @Input() reportButtonText: string = 'Report Issue';

  @Output() retry = new EventEmitter<void>();
  @Output() report = new EventEmitter<void>();

  /**
   * Retry action emits event to parent component
   */
  onRetry(): void {
    this.retry.emit();
  }

  /**
   * Report action emits event to parent component
   */
  onReport(): void {
    this.report.emit();
  }
} 