import { NgModule, APP_INITIALIZER } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { IonicModule } from '@ionic/angular';
import { TranslationService } from './services/translation.service';

// Factory function to create TranslateHttpLoader
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

// Factory function to initialize the translation service
export function initializeTranslation(translationService: TranslationService) {
  return () => translationService.initialize();
}

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      },
      isolate: false,
      defaultLanguage: 'en'
    })
  ],
  exports: [
    TranslateModule
  ],
  providers: [
    TranslateService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeTranslation,
      deps: [TranslationService],
      multi: true
    }
  ]
})
export class TranslationModule { } 