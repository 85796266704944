import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { StorageService } from './storage.service';

export interface Language {
  code: string;
  name: string;
  nativeName: string; // Native name of the language
  flag: string; // Flag emoji or icon path
  active: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class TranslationService {
  // Languages supported by the application
  private _languages: Language[] = [
    { code: 'en', name: 'English', nativeName: 'English', flag: '🇺🇸', active: true },
    { code: 'es', name: 'Spanish', nativeName: 'Español', flag: '🇪🇸', active: true },
    { code: 'fr', name: 'French', nativeName: 'Français', flag: '🇫🇷', active: true }
  ];

  // Default language
  private _defaultLanguage = 'en';
  
  // Current language as BehaviorSubject for reactive UI updates
  private _currentLanguage = new BehaviorSubject<string>(this._defaultLanguage);
  
  // Public observable for components to subscribe to
  public currentLanguage$ = this._currentLanguage.asObservable();
  
  // Flag to prevent multiple simultaneous language changes
  private _isChangingLanguage = false;

  constructor(
    private translateService: TranslateService,
    private storageService: StorageService
  ) {}

  /**
   * Initialize the translation service
   * Should be called in app initialization
   */
  public async initialize(): Promise<void> {
    // Set available languages
    this.translateService.addLangs(this._languages.map(lang => lang.code));
    
    // Set fallback language
    this.translateService.setDefaultLang(this._defaultLanguage);
    
    // Try to load saved language preference
    const savedLanguage = await this.storageService.get('app_language');
    
    if (savedLanguage && this.isValidLanguage(savedLanguage)) {
      await this.setLanguage(savedLanguage);
    } else {
      // Try to detect browser language or use default
      const browserLang = this.translateService.getBrowserLang();
      const languageToUse = 
        browserLang && this.isValidLanguage(browserLang) 
          ? browserLang 
          : this._defaultLanguage;
      
      await this.setLanguage(languageToUse);
    }
  }

  /**
   * Change the application language
   * @param languageCode The language code to set
   */
  public async setLanguage(languageCode: string): Promise<boolean> {
    // Prevent multiple simultaneous language changes
    if (this._isChangingLanguage) {
      console.warn('Language change already in progress');
      return false;
    }
    
    // Don't change if it's already the current language
    if (languageCode === this.getCurrentLanguage()) {
      return true;
    }
    
    this._isChangingLanguage = true;
    
    try {
      if (!this.isValidLanguage(languageCode)) {
        console.warn(`Invalid language code: ${languageCode}. Using default.`);
        languageCode = this._defaultLanguage;
      }
      
      // Update the language in the translate service
      this.translateService.use(languageCode);
      
      // Save preference to storage
      await this.storageService.set('app_language', languageCode);
      
      // Update current language subject
      this._currentLanguage.next(languageCode);
      
      // Update document language for accessibility
      document.documentElement.lang = languageCode;
      
      console.log(`Language changed to: ${languageCode}`);
      return true;
    } catch (error) {
      console.error('Error changing language:', error);
      return false;
    } finally {
      // Reset flag after a short delay to prevent rapid changes
      setTimeout(() => {
        this._isChangingLanguage = false;
      }, 300);
    }
  }

  /**
   * Get the list of available languages
   */
  public getLanguages(): Language[] {
    return [...this._languages].filter(lang => lang.active);
  }

  /**
   * Get currently active language code
   */
  public getCurrentLanguage(): string {
    return this._currentLanguage.getValue();
  }

  /**
   * Get currently active language object
   */
  public getCurrentLanguageObject(): Language | undefined {
    const currentCode = this.getCurrentLanguage();
    return this._languages.find(lang => lang.code === currentCode);
  }

  /**
   * Get translation for a key
   * @param key Translation key
   * @param params Optional parameters
   */
  public translate(key: string, params: object = {}): string {
    if (!key) return '';
    
    try {
      return this.translateService.instant(key, params);
    } catch (error) {
      console.error(`Translation error for key: ${key}`, error);
      return key;
    }
  }

  /**
   * Get translation as observable (for reactive updates)
   * @param key Translation key
   * @param params Optional parameters
   */
  public get(key: string, params: object = {}): Observable<string> {
    return this.translateService.get(key, params);
  }

  /**
   * Check if key exists in translations
   * @param key Translation key
   */
  public hasTranslation(key: string): boolean {
    return this.translateService.instant(key) !== key;
  }

  /**
   * Validate if a language code is supported
   * @param code Language code to check
   */
  private isValidLanguage(code: string): boolean {
    return this._languages.some(lang => 
      lang.code === code && lang.active
    );
  }
} 