// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Styles for the profile component */
.profile-section {
  margin-top: 16px;
  margin-bottom: 16px;
}

.verification-status {
  margin-top: 24px;
}

.status-item {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.status-icon {
  margin-right: 8px;
}

.verified {
  color: green;
}

.pending {
  color: orange;
}

.not-verified {
  color: red;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInByb2ZpbGUuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUEscUNBQUE7QUFDQTtFQUNFLGdCQUFBO0VBQ0EsbUJBQUE7QUFDRjs7QUFFQTtFQUNFLGdCQUFBO0FBQ0Y7O0FBRUE7RUFDRSxhQUFBO0VBQ0EsbUJBQUE7RUFDQSxrQkFBQTtBQUNGOztBQUVBO0VBQ0UsaUJBQUE7QUFDRjs7QUFFQTtFQUNFLFlBQUE7QUFDRjs7QUFFQTtFQUNFLGFBQUE7QUFDRjs7QUFFQTtFQUNFLFVBQUE7QUFDRiIsImZpbGUiOiJwcm9maWxlLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLyogU3R5bGVzIGZvciB0aGUgcHJvZmlsZSBjb21wb25lbnQgKi9cbi5wcm9maWxlLXNlY3Rpb24ge1xuICBtYXJnaW4tdG9wOiAxNnB4O1xuICBtYXJnaW4tYm90dG9tOiAxNnB4O1xufVxuXG4udmVyaWZpY2F0aW9uLXN0YXR1cyB7XG4gIG1hcmdpbi10b3A6IDI0cHg7XG59XG5cbi5zdGF0dXMtaXRlbSB7XG4gIGRpc3BsYXk6IGZsZXg7XG4gIGFsaWduLWl0ZW1zOiBjZW50ZXI7XG4gIG1hcmdpbi1ib3R0b206IDhweDtcbn1cblxuLnN0YXR1cy1pY29uIHtcbiAgbWFyZ2luLXJpZ2h0OiA4cHg7XG59XG5cbi52ZXJpZmllZCB7XG4gIGNvbG9yOiBncmVlbjtcbn1cblxuLnBlbmRpbmcge1xuICBjb2xvcjogb3JhbmdlO1xufVxuXG4ubm90LXZlcmlmaWVkIHtcbiAgY29sb3I6IHJlZDtcbn0gIl19 */`, "",{"version":3,"sources":["webpack://./src/app/components/parent/profile/profile.component.scss"],"names":[],"mappings":"AAAA,qCAAA;AACA;EACE,gBAAA;EACA,mBAAA;AACF;;AAEA;EACE,gBAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,kBAAA;AACF;;AAEA;EACE,iBAAA;AACF;;AAEA;EACE,YAAA;AACF;;AAEA;EACE,aAAA;AACF;;AAEA;EACE,UAAA;AACF;AACA,4+BAA4+B","sourcesContent":["/* Styles for the profile component */\n.profile-section {\n  margin-top: 16px;\n  margin-bottom: 16px;\n}\n\n.verification-status {\n  margin-top: 24px;\n}\n\n.status-item {\n  display: flex;\n  align-items: center;\n  margin-bottom: 8px;\n}\n\n.status-icon {\n  margin-right: 8px;\n}\n\n.verified {\n  color: green;\n}\n\n.pending {\n  color: orange;\n}\n\n.not-verified {\n  color: red;\n} "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
