import { Injectable } from '@angular/core';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/compat/storage';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Observable, from, throwError, of } from 'rxjs';
import { catchError, finalize, map, switchMap, take } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { v4 as uuidv4 } from 'uuid';
import { environment } from '@environments/environment';
import { lastValueFrom } from 'rxjs';
import { castObservable } from '../utils/rxjs-compat';
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';

/**
 * Comprehensive service for all file upload operations.
 * 
 * This service handles all file uploads including:
 * - Firebase Storage uploads for general media (images, documents, etc.)
 * - API endpoint uploads for specific use cases (profile photos, child photos, etc.)
 * - Support for various file formats and sources
 */
@Injectable({
  providedIn: 'root'
})
export class FileUploadService {
  private uploadTask: AngularFireUploadTask | null = null;
  private basePath = 'uploads';
  private apiUrl = environment.apiUrl || 'api';

  constructor(
    private storage: AngularFireStorage,
    private afAuth: AngularFireAuth,
    private http: HttpClient
  ) { }

  //----------------------------------------------------------------------
  // FIREBASE STORAGE UPLOADS
  //----------------------------------------------------------------------

  /**
   * Upload a file to Firebase Storage
   * @param file The file to upload
   * @param path The storage path to upload to
   * @returns Observable of the download URL
   */
  uploadFile(file: File, path: string): Observable<string> {
    // Generate a unique filename
    const fileName = this.generateUniqueFileName(file);
    const filePath = `${path}/${fileName}`;
    
    // Create a reference to the storage location
    const uploadResult = this.storage.upload(filePath, file);
    
    // Store the task for progress tracking
    this.uploadTask = uploadResult.task as unknown as AngularFireUploadTask;
    
    // Get the download URL after upload completes
    return uploadResult.snapshotChanges().pipe(
      // Wait for the upload to complete, then get the download URL
      finalize(async () => {
        try {
          // Get the reference from the storage path
          const fileRef = this.storage.ref(filePath);
          // Now we can safely get the download URL
          return fileRef.getDownloadURL();
        } catch (error) {
          console.error('Error getting download URL:', error);
          throw error;
        }
      }),
      // Map the finalize result to the download URL
      switchMap(() => this.storage.ref(filePath).getDownloadURL()),
      catchError(error => {
        console.error('Error uploading file:', error);
        throw error;
      })
    );
  }
  
  /**
   * Upload a base64 image to Firebase Storage
   * @param base64Data The base64-encoded image data
   * @param path The storage path to upload to
   * @returns Observable of the download URL
   */
  uploadBase64Image(base64Data: string, path: string): Observable<string | null> {
    // Check if the data is a valid base64 image
    if (!base64Data.startsWith('data:image')) {
      console.warn('Invalid base64 image data');
      return of(null);
    }
    
    // Extract content type and base64 data
    const contentType = base64Data.split(';')[0].split(':')[1];
    const base64 = base64Data.replace(/^data:image\/[a-z]+;base64,/, '');
    
    // Generate a unique filename with timestamp to avoid cache issues
    const timestamp = new Date().getTime();
    const uuid = uuidv4();
    const fileName = `${uuid}-${timestamp}.${contentType.split('/')[1]}`;
    const filePath = `${path}/${fileName}`;
    
    // Create a reference to the storage location
    const storageRef = this.storage.ref(filePath);
    
    // Set custom metadata to help with CORS
    const metadata = {
      contentType,
      customMetadata: {
        'origin': window.location.origin,
        'uploadedFrom': 'angular-app',
        'timestamp': new Date().toISOString()
      }
    };
    
    try {
      // Upload the file
      this.uploadTask = storageRef.putString(base64, 'base64', metadata);
      
      // Get the download URL after upload completes
      return from(this.uploadTask.snapshotChanges()).pipe(
        switchMap(() => storageRef.getDownloadURL()),
        catchError(error => {
          console.error('Error uploading base64 image:', error);
          
          // Check if this is a CORS-related error
          if (this.isCorsError(error)) {
            console.warn('CORS issue detected. Please run "npm run fix:cors" to fix this issue.');
            
            return throwError(() => ({
              code: 'storage/cors-error',
              message: 'CORS policy prevented the upload. Please run "npm run fix:cors" to configure your Firebase Storage.',
              originalError: error
            }));
          }
          
          return throwError(() => error);
        })
      );
    } catch (error) {
      console.error('Error initiating upload:', error);
      return throwError(() => error);
    }
  }
  
  /**
   * Check if an error is related to CORS
   * @private
   */
  private isCorsError(error: any): boolean {
    if (!error) return false;
    
    // Check the error message for CORS-related terms
    const errorString = JSON.stringify(error).toLowerCase();
    return (
      errorString.includes('cors') ||
      errorString.includes('access-control-allow-origin') ||
      errorString.includes('cross-origin') ||
      (error.status === 0 && errorString.includes('httpclient'))
    );
  }
  
  /**
   * Upload multiple files to Firebase Storage
   * 
   * @param files Array of files to upload
   * @param path Storage path (folder)
   * @param metadata Optional file metadata
   * @returns Observable array of download URLs
   */
  uploadMultipleFiles(files: File[], path = 'uploads', metadata?: any): Observable<string[]> {
    return this.getCurrentUserId().pipe(
      switchMap(userId => {
        if (!userId) {
          return throwError(() => new Error('User not authenticated'));
        }
        
        if (!files || files.length === 0) {
          return throwError(() => new Error('No files to upload'));
        }
        
        // Create an array of upload observables
        const uploadObservables = files.map(file => 
          this.uploadFile(file, path)
        );
        
        // Combine all upload observables
        return from(Promise.all(uploadObservables.map(obs => 
          lastValueFrom(obs.pipe(take(1)))
        )));
      }),
      catchError(error => {
        console.error('Error in multiple file upload:', error);
        return throwError(() => error);
      })
    );
  }
  
  /**
   * Delete a file from Firebase Storage
   * 
   * @param downloadUrl Download URL of the file to delete
   * @returns Observable that resolves when deletion is complete
   */
  deleteFile(downloadUrl: string): Observable<void> {
    return from(this.afAuth.currentUser).pipe(
      switchMap(user => {
        if (!user) {
          return throwError(() => new Error('User not authenticated'));
        }
        
        try {
          // Extract the storage path from the download URL
          const storage = this.storage.storage;
          const storageRef = storage.refFromURL(downloadUrl);
          
          // Delete the file
          return from(storageRef.delete()).pipe(
            catchError(error => {
              console.error('Error deleting file:', error);
              return throwError(() => error);
            })
          );
        } catch (error) {
          console.error('Error parsing download URL:', error);
          return throwError(() => new Error('Invalid download URL'));
        }
      }),
      catchError(error => {
        console.error('Error deleting file:', error);
        return throwError(() => error);
      })
    );
  }

  //----------------------------------------------------------------------
  // API ENDPOINT UPLOADS
  //----------------------------------------------------------------------
  
  /**
   * Upload a file to a specific API endpoint
   * 
   * @param file File to upload
   * @param endpoint API endpoint path
   * @param additionalData Additional form data to include
   * @returns Promise with the server response
   */
  async uploadToApi(file: File, endpoint: string, additionalData?: Record<string, any>): Promise<any> {
    try {
      const formData = new FormData();
      formData.append('file', file);
      
      // Add any additional data
      if (additionalData) {
        Object.keys(additionalData).forEach(key => {
          formData.append(key, additionalData[key]);
        });
      }
      
      // Send to API endpoint
      return lastValueFrom(
        this.http.post<any>(`${this.apiUrl}/${endpoint}`, formData)
      );
    } catch (error) {
      console.error(`Error uploading to ${endpoint}:`, error);
      throw error;
    }
  }
  
  /**
   * Upload a child photo to the dedicated API endpoint
   * 
   * @param childId ID of the child
   * @param file File to upload
   * @returns Promise with URL of the uploaded file
   */
  async uploadChildPhoto(childId: string, file: File): Promise<string> {
    try {
      const response = await this.uploadToApi(file, 'upload/child-photo', { childId });
      return response.url;
    } catch (error) {
      console.error('Error uploading child photo:', error);
      throw error;
    }
  }
  
  /**
   * Upload a profile photo
   * 
   * Uses Firebase Storage for standard uploads, but can be configured to use
   * the API endpoint for special processing cases
   * 
   * @param file File or base64 string to upload
   * @param useApi Whether to use the API endpoint (defaults to false)
   * @returns Observable or Promise with URL of the uploaded file
   */
  uploadProfilePhoto(file: File | string, useApi = false): Observable<string> | Promise<string> {
    if (useApi) {
      // If it's a base64 string, convert to File first
      if (typeof file === 'string') {
        const blob = this.dataURItoBlob(file);
        if (!blob) {
          return Promise.reject(new Error('Invalid image data'));
        }
        const fileObj = new File([blob], 'profile.jpg', { type: 'image/jpeg' });
        return this.uploadToApi(fileObj, 'upload/profile-photo');
      }
      
      return this.uploadToApi(file, 'upload/profile-photo');
    } else {
      // Use Firebase Storage
      if (typeof file === 'string') {
        return this.uploadBase64Image(file, 'profile-images');
      } else {
        return this.uploadFile(file, 'profile-images');
      }
    }
  }

  //----------------------------------------------------------------------
  // UTILITY METHODS
  //----------------------------------------------------------------------
  
  /**
   * Generate a unique file name to prevent collisions
   */
  private generateUniqueFileName(file: File): string {
    const extension = file.name.split('.').pop();
    const uuid = uuidv4();
    const timestamp = Date.now();
    return `${uuid}-${timestamp}.${extension}`;
  }
  
  /**
   * Convert a data URI to a Blob
   */
  private dataURItoBlob(dataURI: string): Blob | null {
    try {
      // Extract base64 data
      const byteString = atob(dataURI.split(',')[1]);
      
      // Extract mime type
      const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
      
      // Convert to byte array
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
      
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      
      return new Blob([ab], { type: mimeString });
    } catch (error) {
      console.error('Error converting data URI to Blob:', error);
      return null;
    }
  }
  
  /**
   * Gets the current authenticated user's ID
   * @returns Observable with user ID or null
   */
  private getCurrentUserId(): Observable<string | null> {
    return castObservable<firebase.User | null>(from(this.afAuth.currentUser)).pipe(
      map(user => user ? user.uid : null),
      catchError(error => {
        console.error('Error getting current user ID:', error);
        return of(null);
      })
    );
  }
  
  /**
   * Get file extension from mime type
   */
  private getExtensionFromMimeType(mimeType: string): string {
    const map: Record<string, string> = {
      'image/jpeg': 'jpg',
      'image/jpg': 'jpg',
      'image/png': 'png',
      'image/gif': 'gif',
      'image/webp': 'webp',
      'application/pdf': 'pdf',
      'application/msword': 'doc',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
      'application/vnd.ms-excel': 'xls',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
      'text/csv': 'csv',
      'application/zip': 'zip',
      'video/mp4': 'mp4',
      'audio/mpeg': 'mp3'
    };
    
    return map[mimeType] || 'bin';
  }
  
  /**
   * Parse file size into human-readable format
   */
  formatFileSize(bytes: number): string {
    if (bytes === 0) return '0 Bytes';
    
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  }

  /**
   * Get the current upload progress
   * @returns Observable of the upload progress (0-100)
   */
  getUploadProgress(): Observable<number | null> {
    if (!this.uploadTask) {
      return of(null);
    }
    
    return this.uploadTask.percentageChanges();
  }
  
  /**
   * Gets the Firebase Storage bucket name from the environment configuration
   * Useful for providing instructions to users
   */
  getStorageBucket(): string {
    try {
      const app = firebase.app();
      const storageBucket = (app.options as any).storageBucket || '';
      return storageBucket;
    } catch (error) {
      console.error('Error getting storage bucket name:', error);
      return '';
    }
  }
  
  /**
   * Generates the recommended CORS configuration JSON for the storage bucket
   * 
   * @returns A formatted string with the CORS configuration JSON
   */
  getRecommendedCorsConfig(): string {
    const origins = [
      window.location.origin, // Current origin
      'http://localhost:8100', // Ionic dev server
      'http://localhost:4200', // Angular dev server
      'capacitor://localhost', // Capacitor native app
      'https://herethere-c5b49.web.app', // Firebase hosting
      'https://herethere-c5b49.firebaseapp.com' // Firebase hosting
    ];
    
    const corsConfig = [
      {
        origin: origins,
        method: ['GET', 'PUT', 'POST', 'DELETE', 'HEAD', 'OPTIONS'],
        maxAgeSeconds: 3600,
        responseHeader: [
          'Content-Type', 
          'Content-Length', 
          'Content-Encoding', 
          'Access-Control-Allow-Origin',
          'Access-Control-Allow-Methods',
          'Access-Control-Allow-Headers',
          'Content-Disposition',
          'Cache-Control'
        ]
      }
    ];
    
    return JSON.stringify(corsConfig, null, 2);
  }
  
  /**
   * Provides instructions for fixing CORS issues with Firebase Storage
   * 
   * @returns Object with instructions and configs needed to fix CORS
   */
  getCorsFixInstructions(): { 
    corsJson: string; 
    bucketName: string;
    gsutilCommand: string;
    rulesConfig: string;
  } {
    const bucketName = this.getStorageBucket();
    const corsJson = this.getRecommendedCorsConfig();
    
    return {
      corsJson,
      bucketName,
      gsutilCommand: `gsutil cors set cors.json gs://${bucketName}`,
      rulesConfig: `service firebase.storage {
  match /b/{bucket}/o {
    match /{allPaths=**} {
      allow read, write: if request.auth != null;
      // Allow localhost during development
      allow read, write: if request.origin == 'http://localhost:8100';
    }
  }
}`
    };
  }
}