import { createFeatureSelector, createSelector } from '@ngrx/store';
import { messageFeatureKey, MessageState } from './message.reducer';

export const selectMessageState = createFeatureSelector<MessageState>(messageFeatureKey);

// Select all messages (sorted by timestamp)
export const selectMessages = createSelector(
  selectMessageState,
  (state: MessageState) => {
    if (!state.messages) return [];
    
    // Return messages sorted by timestamp (most recent last for chat display)
    return [...state.messages].sort((a, b) => {
      const timeA = a.timestamp ? new Date(a.timestamp).getTime() : 0;
      const timeB = b.timestamp ? new Date(b.timestamp).getTime() : 0;
      return timeA - timeB;
    });
  }
);

// Select messages count for debugging
export const selectMessagesCount = createSelector(
  selectMessages,
  (messages) => messages ? messages.length : 0
);

// Select message loading state
export const selectMessagesLoading = createSelector(
  selectMessageState,
  (state: MessageState) => state.loading
);

// Select thread
export const selectThread = createSelector(
  selectMessageState,
  (state: MessageState) => state.thread
);

// Select thread loading state
export const selectThreadLoading = createSelector(
  selectMessageState,
  (state: MessageState) => state.threadLoading
);

// Select thread's other participant information
export const selectOtherParticipant = createSelector(
  selectMessageState,
  selectThread,
  (state: MessageState, thread) => {
    if (!thread || !thread.participants) {
      return null;
    }
    
    // Find the current userId in session (this would typically come from auth state)
    // For now we're just using the first participant as a placeholder
    const otherParticipantId = thread.participants.length > 1 ? 
      thread.participants[1] : thread.participants[0];
    
    return {
      id: otherParticipantId,
      name: thread.participantNames[otherParticipantId],
      avatar: thread.participantAvatars[otherParticipantId]
    };
  }
);

// Select error
export const selectMessageError = createSelector(
  selectMessageState,
  (state: MessageState) => state.error
);

// Select loading states combined
export const selectIsLoading = createSelector(
  selectMessagesLoading,
  selectThreadLoading,
  (messagesLoading, threadLoading) => messagesLoading || threadLoading
); 