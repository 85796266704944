import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { SafetyService } from '../../../services/safety.service';
import { Subscription } from 'rxjs';
import { LocationService } from '../../../services/location.service';

@Component({
  selector: 'app-location-tracker',
  templateUrl: './location-tracker.component.html',
  styleUrls: ['./location-tracker.component.scss'],
  standalone: true,
  imports: [CommonModule, IonicModule, FormsModule, TranslateModule]
})
export class LocationTrackerComponent implements OnInit, OnDestroy {
  @Input() rideId: string;
  @Input() isActive = false;
  @Output() locationUpdated = new EventEmitter<google.maps.LatLngLiteral>();
  @Output() trackingStarted = new EventEmitter<void>();
  @Output() trackingStopped = new EventEmitter<void>();

  private watchId: number | null = null;
  private subscription: Subscription = new Subscription();
  lastLocation: google.maps.LatLngLiteral | null = null;
  lastUpdated: Date | null = null;
  locationAccuracy: number = 0;
  locationError: string | null = null;
  trackingInterval: number = 10; // seconds
  batteryWarning: boolean = false;

  constructor(
    private safetyService: SafetyService,
    private locationService: LocationService
  ) {}

  ngOnInit(): void {
    // Subscribe to the tracking status from the safety service
    this.subscription.add(
      this.safetyService.getLocationTrackingActive().subscribe(isActive => {
        this.isActive = isActive;
        if (isActive && !this.watchId) {
          this.startTracking();
        } else if (!isActive && this.watchId) {
          this.stopTracking();
        }
      })
    );

    // Initialize tracking if it should be active
    if (this.isActive) {
      this.startTracking();
    }
  }

  /**
   * Toggle location tracking
   */
  toggleTracking(event: any): void {
    const isActive = event.detail.checked;
    this.safetyService.setLocationTrackingActive(isActive);
    
    if (isActive) {
      this.startTracking();
    } else {
      this.stopTracking();
    }
  }

  /**
   * Start location tracking
   */
  startTracking(): void {
    if (navigator.geolocation) {
      // Check battery level before starting continuous tracking
      if ('getBattery' in navigator) {
        (navigator as any).getBattery().then((battery: any) => {
          if (battery.level < 0.2 && !battery.charging) {
            this.batteryWarning = true;
          }
        });
      }

      this.watchId = navigator.geolocation.watchPosition(
        (position) => {
          this.locationError = null;
          const location = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          };
          this.lastLocation = location;
          this.lastUpdated = new Date();
          this.locationAccuracy = position.coords.accuracy;
          
          // Emit the location update
          this.locationUpdated.emit(location);
          
          // Send to safety service if we have a ride ID
          if (this.rideId) {
            this.safetyService.updateLocation(this.rideId, location).subscribe();
          }
        },
        (error) => {
          console.error('Location tracking error:', error);
          this.locationError = this.getLocationErrorMessage(error);
        },
        {
          enableHighAccuracy: true,
          timeout: 10000,
          maximumAge: this.trackingInterval * 1000
        }
      );
      
      this.isActive = true;
      this.safetyService.setLocationTrackingActive(true);
      this.trackingStarted.emit();
    }
  }

  /**
   * Stop location tracking
   */
  stopTracking(): void {
    if (this.watchId !== null) {
      navigator.geolocation.clearWatch(this.watchId);
      this.watchId = null;
      this.isActive = false;
      this.safetyService.setLocationTrackingActive(false);
      this.trackingStopped.emit();
      this.batteryWarning = false;
    }
  }

  /**
   * Get a user-friendly error message for geolocation errors
   */
  private getLocationErrorMessage(error: GeolocationPositionError): string {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        return 'Location access denied. Please enable location permissions in your browser settings.';
      case error.POSITION_UNAVAILABLE:
        return 'Location information is unavailable. Please check your device GPS.';
      case error.TIMEOUT:
        return 'Location request timed out. Please try again.';
      default:
        return 'Unknown location error occurred.';
    }
  }

  /**
   * Share current location with emergency contacts
   */
  shareLocation(): void {
    if (this.lastLocation && this.rideId) {
      // Create shareable location link
      const locationUrl = `https://maps.google.com/?q=${this.lastLocation?.lat},${this.lastLocation?.lng}`;
      
      // Get the address if possible, otherwise use coordinates
      if (this.locationService.getGeoCodedAddress) {
        this.locationService.getGeoCodedAddress(
          this.lastLocation.lat, 
          this.lastLocation.lng
        ).then(address => {
          // Format address object if it's not a string
          let formattedAddress = 'Unknown location';
          if (address) {
            if (typeof address === 'string') {
              formattedAddress = address;
            } else if (typeof address === 'object') {
              // Try to format the address object
              const parts = [];
              if (address.street) parts.push(address.street);
              if (address.building) parts.push(address.building);
              if (address.block) parts.push(address.block);
              if (address.country) parts.push(address.country);
              
              formattedAddress = parts.length > 0 ? parts.join(', ') : 'Unknown location';
            }
          }
          
          this.shareLocationWithAddress(locationUrl, formattedAddress);
        }).catch(() => {
          this.shareLocationWithAddress(locationUrl, 'Unknown location');
        });
      } else {
        this.shareLocationWithAddress(locationUrl, `${this.lastLocation.lat.toFixed(6)}, ${this.lastLocation.lng.toFixed(6)}`);
      }
    }
  }

  /**
   * Share location with the given address
   */
  private shareLocationWithAddress(locationUrl: string, address: string): void {
    // Share via the Web Share API if available
    if (navigator.share) {
      navigator.share({
        title: 'My Current Location',
        text: `My current location: ${address}`,
        url: locationUrl
      }).catch(err => console.error('Error sharing location:', err));
    } else {
      // Fallback to clipboard
      navigator.clipboard.writeText(locationUrl)
        .then(() => alert('Location link copied to clipboard'))
        .catch(err => console.error('Error copying to clipboard:', err));
    }
  }

  /**
   * Format a timestamp
   */
  formatTime(date: Date | null): string {
    return date ? date.toLocaleTimeString() : '';
  }

  /**
   * Get a human-readable accuracy description
   */
  getAccuracyDescription(): string {
    if (!this.locationAccuracy) return 'Unknown';
    if (this.locationAccuracy < 10) return 'Excellent';
    if (this.locationAccuracy < 50) return 'Good';
    if (this.locationAccuracy < 100) return 'Fair';
    return 'Poor';
  }

  /**
   * Set tracking interval
   */
  setTrackingInterval(seconds: number): void {
    this.trackingInterval = seconds;
    if (this.isActive) {
      // Restart tracking with new interval
      this.stopTracking();
      this.startTracking();
    }
  }

  ngOnDestroy(): void {
    this.stopTracking();
    this.subscription.unsubscribe();
  }
}