import { Injectable, OnDestroy, Optional } from '@angular/core';
import { SwPush } from '@angular/service-worker';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { AngularFirestore, DocumentReference } from '@angular/fire/compat/firestore';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { BehaviorSubject, Observable, Subject, Subscription, of } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { ToastController } from '@ionic/angular';
import { Router } from '@angular/router';
import { Notification, NotificationType, NotificationPriority } from '@app/models/notification.model';

@Injectable({
  providedIn: 'root'
})
export class NotificationService implements OnDestroy {
  private readonly VAPID_PUBLIC_KEY = 'YOUR_VAPID_PUBLIC_KEY';
  private notificationsSubject = new BehaviorSubject<Notification[]>([]);
  private unreadCountSubject = new BehaviorSubject<number>(0);
  private currentUserId: string | null = null;
  private subscriptions: Subscription[] = [];
  
  constructor(
    private toastController: ToastController,
    @Optional() private swPush: SwPush,
    private afMessaging: AngularFireMessaging,
    private firestore: AngularFirestore,
    private auth: AngularFireAuth,
    private router: Router
  ) {
    // Get current user and initialize subscriptions
    this.subscriptions.push(
      this.auth.authState.subscribe(user => {
        this.currentUserId = user ? user.uid : null;
        
        if (this.currentUserId) {
          this.loadUserNotifications();
          if (this.swPush) {
            this.initializePushNotifications();
          }
        } else {
          this.notificationsSubject.next([]);
          this.unreadCountSubject.next(0);
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  /**
   * Initialize push notification handling
   */
  private initializePushNotifications = async(): Promise<void> => {
    if (!this.swPush || !this.swPush.isEnabled) {
      console.warn('Push notifications not enabled or supported');
      return;
    }
    
    try {
      const sub = await this.swPush.requestSubscription({
        serverPublicKey: this.VAPID_PUBLIC_KEY
      });
      
      // Store the subscription in Firestore for the user
      if (this.currentUserId) {
        await this.firestore.doc(`users/${this.currentUserId}`).update({
          pushSubscription: sub
        });
      }
      
      // Listen for push messages
      this.subscriptions.push(
        this.swPush.messages.subscribe((message: any) => {
          this.handlePushMessage(message);
        })
      );

      // Listen for notification clicks
      this.subscriptions.push(
        this.swPush.notificationClicks.subscribe(({ action, notification }) => {
          this.handleNotificationClick(action, notification);
        })
      );
    } catch (err) {
      console.error('Could not subscribe to notifications', err);
    }
  }

  /**
   * Load user notifications from Firestore
   */
  private loadUserNotifications(): void {
    if (!this.currentUserId) return;
    
    this.subscriptions.push(
      this.firestore
        .collection<Notification>('notifications', ref => 
          ref.where('userId', '==', this.currentUserId)
            .orderBy('timestamp', 'desc')
            .limit(50)
        )
        .valueChanges({ idField: 'id' })
        .pipe(
          tap(notifications => {
            this.notificationsSubject.next(notifications);
            this.updateUnreadCount(notifications);
          }),
          catchError(err => {
            console.error('Error loading notifications', err);
            return of([]);
          })
        )
        .subscribe()
    );
  }
  
  /**
   * Update unread notifications count
   */
  private updateUnreadCount(notifications: Notification[]): void {
    const unreadCount = notifications.filter(notif => !notif.read).length;
    this.unreadCountSubject.next(unreadCount);
  }

  /**
   * Handle incoming push message
   */
  private handlePushMessage(message: any): void {
    if (!this.currentUserId) return;
    
    const notification: Notification = {
      userId: this.currentUserId,
      title: message.notification?.title || 'New Notification',
      message: message.notification?.body || '',
      timestamp: Date.now(),
      read: false,
      type: (message.data?.type as NotificationType) || 'system',
      priority: (message.data?.priority as NotificationPriority) || 'medium',
      rideId: message.data?.rideId,
      threadId: message.data?.threadId,
      actionUrl: message.data?.actionUrl,
      metadata: message.data
    };

    // Store notification in Firestore
    this.addNotificationToFirestore(notification);

    // Show toast for medium and high priority notifications
    if (notification.priority === 'high') {
      this.showAlert(notification.title, notification.message);
    } else if (notification.priority === 'medium') {
      this.showToast(notification.message, 'warning');
    }
  }
  
  /**
   * Add notification to Firestore
   */
  private async addNotificationToFirestore(notification: Notification): Promise<string | null> {
    if (!this.currentUserId) return null;
    
    try {
      const docRef = await this.firestore
        .collection<Notification>('notifications')
        .add(notification);
      
      return docRef.id;
    } catch (err) {
      console.error('Error adding notification to Firestore', err);
      return null;
    }
  }

  /**
   * Handle notification click
   */
  private handleNotificationClick(action: string, notification: NotificationOptions & {data?: any}): void {
    const data = notification.data || {};
    
    // Mark as read
    if (data.id) {
      this.markAsRead(data.id);
    }
    
    // Handle different actions
    switch (action) {
      case 'view_ride':
        if (data.rideId) {
          this.router.navigate(['/rides/track', data.rideId]);
        }
        break;
      case 'view_message':
        if (data.threadId) {
          this.router.navigate(['/chat', data.threadId]);
        }
        break;
      case 'view_payment':
        this.router.navigate(['/payments']);
        break;
      case 'emergency':
        if (data.rideId) {
          this.router.navigate(['/rides/emergency', data.rideId]);
        }
        break;
      default:
        // If no specific action but we have an actionUrl
        if (data.actionUrl) {
          this.router.navigateByUrl(data.actionUrl);
        } else if (data.type === 'message') {
          this.router.navigate(['/inbox'], { queryParams: { tab: 'messages' }});
        } else {
          this.router.navigate(['/inbox']);
        }
        break;
    }
  }

  /**
   * Create toast notification
   */
  showToast = async(message: string, color: string = 'primary', duration: number = 3000): Promise<void> => {
    const toast = await this.toastController.create({
      message,
      duration,
      position: 'bottom',
      color,
      buttons: [{
        text: 'Close',
        role: 'cancel'
      }]
    });

    await toast.present();
  }

  /**
   * Show alert dialog
   */
  async showAlert(title: string, message: string): Promise<void> {
    // Using toast with longer duration for high-priority instead of alert
    // as it's less intrusive but still prominent
    await this.showToast(message, 'danger', 5000);
  }

  /**
   * Get all notifications
   */
  getNotifications(): Observable<Notification[]> {
    return this.notificationsSubject.asObservable();
  }

  /**
   * Get unread notification count
   */
  getUnreadCount(): Observable<number> {
    return this.unreadCountSubject.asObservable();
  }

  /**
   * Mark notification as read
   */
  async markAsRead(notificationId: string): Promise<void> {
    if (!this.currentUserId) return;
    
    try {
      await this.firestore
        .collection('notifications')
        .doc(notificationId)
        .update({ read: true });
        
      // Update the local notifications array
      const currentNotifications = this.notificationsSubject.value;
      const updatedNotifications = currentNotifications.map(notification => 
        notification.id === notificationId 
          ? { ...notification, read: true } 
          : notification
      );
      
      this.notificationsSubject.next(updatedNotifications);
      this.updateUnreadCount(updatedNotifications);
    } catch (err) {
      console.error('Error marking notification as read', err);
    }
  }

  /**
   * Mark all notifications as read
   */
  async markAllAsRead(): Promise<void> {
    if (!this.currentUserId) return;
    
    try {
      // Get all unread notifications
      const batch = this.firestore.firestore.batch();
      const unreadNotificationsQuery = await this.firestore
        .collection<Notification>('notifications', ref => 
          ref.where('userId', '==', this.currentUserId)
            .where('read', '==', false)
        )
        .get()
        .toPromise();
      
      if (!unreadNotificationsQuery?.docs.length) return;
      
      // Update all to read
      unreadNotificationsQuery.docs.forEach(doc => {
        batch.update(doc.ref, { read: true });
      });
      
      await batch.commit();
      
      // Update local state
      const currentNotifications = this.notificationsSubject.value;
      const updatedNotifications = currentNotifications.map(notification => ({
        ...notification,
        read: true
      }));
      
      this.notificationsSubject.next(updatedNotifications);
      this.unreadCountSubject.next(0);
    } catch (err) {
      console.error('Error marking all notifications as read', err);
    }
  }

  /**
   * Delete a notification
   */
  async deleteNotification(notificationId: string): Promise<void> {
    try {
      await this.firestore
        .collection('notifications')
        .doc(notificationId)
        .delete();
      
      // Update local state
      const notifications = this.notificationsSubject.value;
      const updatedNotifications = notifications.filter(n => n.id !== notificationId);
      this.notificationsSubject.next(updatedNotifications);
      this.updateUnreadCount(updatedNotifications);
    } catch (err) {
      console.error('Error deleting notification', err);
    }
  }

  /**
   * Create a new notification
   */
  async createNotification(
    title: string, 
    message: string, 
    type: NotificationType = 'system',
    priority: NotificationPriority = 'medium',
    metadata?: Record<string, any>
  ): Promise<string | null> {
    if (!this.currentUserId) return null;
    
    const notification: Notification = {
      userId: this.currentUserId,
      title,
      message,
      timestamp: Date.now(),
      read: false,
      type,
      priority,
      actionUrl: metadata?.actionUrl,
      rideId: metadata?.rideId,
      threadId: metadata?.threadId,
      metadata
    };
    
    return this.addNotificationToFirestore(notification);
  }

  /**
   * Create a message notification
   */
  async createMessageNotification(
    threadId: string,
    senderName: string,
    message: string
  ): Promise<string | null> {
    return this.createNotification(
      `Message from ${senderName}`,
      message,
      'message',
      'medium',
      {
        threadId,
        actionUrl: `/chat/${threadId}`
      }
    );
  }

  /**
   * Create an emergency notification
   */
  async createEmergencyNotification(
    message: string,
    rideId: string
  ): Promise<string | null> {
    return this.createNotification(
      'Emergency Alert',
      message,
      'emergency',
      'high',
      {
        rideId,
        actionUrl: `/rides/emergency/${rideId}`
      }
    );
  }
  
  /**
   * Request notification permissions
   */
  async requestPermission(): Promise<NotificationPermission> {
    if (!('Notification' in window)) {
      console.warn('Notifications not supported in this browser');
      return 'denied';
    }

    return await Notification.requestPermission();
  }
}