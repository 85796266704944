import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, tap, catchError } from 'rxjs/operators';
import { Driver, DriverAvailability } from '../../shared/models/driver.model';

@Injectable({
  providedIn: 'root'
})
export class DriverService {
  private apiUrl = 'api/drivers';
  private availableDriversSubject = new BehaviorSubject<Driver[]>([]);

  constructor(private http: HttpClient) {
    this.loadAvailableDrivers();
  }

  /**
   * Get all available drivers
   */
  getAvailableDrivers(): Observable<Driver[]> {
    return this.availableDriversSubject.asObservable();
  }

  /**
   * Get driver by ID
   */
  getDriver(driverId: string): Observable<Driver> {
    return this.http.get<Driver>(`${this.apiUrl}/${driverId}`).pipe(
      catchError(error => {
        console.error('Error fetching driver:', error);
        return of(null);
      })
    );
  }

  /**
   * Get drivers available for a specific area and time
   */
  getDriversForArea(
    latitude: number, 
    longitude: number, 
    radius: number
  ): Observable<Driver[]> {
    return this.http.get<Driver[]>(`${this.apiUrl}/nearby`, {
      params: {
        lat: latitude.toString(),
        lng: longitude.toString(),
        radius: radius.toString()
      }
    }).pipe(
      catchError(error => {
        console.error('Error fetching nearby drivers:', error);
        return of([]);
      })
    );
  }

  /**
   * Get driver reviews
   */
  getDriverReviews(driverId: string, page = 1, limit = 10): Observable<any> {
    return this.http.get(`${this.apiUrl}/${driverId}/reviews`, {
      params: {
        page: page.toString(),
        limit: limit.toString()
      }
    }).pipe(
      catchError(error => {
        console.error('Error fetching driver reviews:', error);
        return of({
          reviews: [],
          total: 0,
          page: 1
        });
      })
    );
  }

  /**
   * Get driver availability for specific dates
   */
  getDriverAvailability(
    driverId: string, 
    startDate: Date, 
    endDate: Date
  ): Observable<DriverAvailability> {
    return this.http.get<DriverAvailability>(
      `${this.apiUrl}/${driverId}/availability`,
      {
        params: {
          start: startDate.toISOString(),
          end: endDate.toISOString()
        }
      }
    ).pipe(
      catchError(error => {
        console.error('Error fetching driver availability:', error);
        return of(null);
      })
    );
  }

  /**
   * Request a specific driver for a ride
   */
  requestDriver(driverId: string, rideId: string): Observable<boolean> {
    return this.http.post<{ success: boolean }>(
      `${this.apiUrl}/${driverId}/request`,
      { rideId }
    ).pipe(
      map(response => response.success),
      catchError(error => {
        console.error('Error requesting driver:', error);
        return of(false);
      })
    );
  }

  /**
   * Add a review for a driver
   */
  addDriverReview(
    driverId: string,
    rating: number,
    comment: string,
    tags: string[] = []
  ): Observable<boolean> {
    return this.http.post<{ success: boolean }>(
      `${this.apiUrl}/${driverId}/reviews`,
      { rating, comment, tags }
    ).pipe(
      map(response => response.success),
      catchError(error => {
        console.error('Error adding driver review:', error);
        return of(false);
      })
    );
  }

  /**
   * Get driver statistics
   */
  getDriverStats(driverId: string): Observable<any> {
    return this.http.get(`${this.apiUrl}/${driverId}/stats`).pipe(
      catchError(error => {
        console.error('Error fetching driver stats:', error);
        return of({
          totalRides: 0,
          completedRides: 0,
          averageRating: 0,
          totalReviews: 0
        });
      })
    );
  }

  /**
   * Get driver certifications and verification status
   */
  getDriverVerification(driverId: string): Observable<any> {
    return this.http.get(`${this.apiUrl}/${driverId}/verification`).pipe(
      catchError(error => {
        console.error('Error fetching driver verification:', error);
        return of({
          backgroundCheck: null,
          certifications: [],
          verification: null
        });
      })
    );
  }

  /**
   * Report an issue with a driver
   */
  reportDriver(
    driverId: string,
    reason: string,
    description: string
  ): Observable<boolean> {
    return this.http.post<{ success: boolean }>(
      `${this.apiUrl}/${driverId}/report`,
      { reason, description }
    ).pipe(
      map(response => response.success),
      catchError(error => {
        console.error('Error reporting driver:', error);
        return of(false);
      })
    );
  }

  /**
   * Load available drivers
   */
  private loadAvailableDrivers(): void {
    this.http.get<Driver[]>(`${this.apiUrl}/available`).subscribe(
      drivers => this.availableDriversSubject.next(drivers),
      error => {
        console.error('Error loading available drivers:', error);
        this.availableDriversSubject.next([]);
      }
    );
  }
}