/**
 * Application constants for development environment
 * Contains all text and static data used throughout the application
 */

// Chat data
export const CHAT_DATA = [{
  name: 'Enappd',
  image: 'assets/chat/herethere.png',
  description: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Enim laboriosam sunt nulla minima ratione, pariatur quaerat aut ex a ullam? Officia, explicabo optio. Dolores, ab exercitationem? Neque illo soluta sapiente!',
  count: '2',
  time: '11:32 PM',
  distance: '6km',
  type: 'PRO', 
  crown: 'assets/chat/crown.png'
}, {
  name: 'Jovenica Alba',
  image: 'assets/chat/chat1.jpg',
  description: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Enim laboriosam sunt nulla minima ratione, pariatur quaerat aut ex a ullam? Officia, explicabo optio. Dolores, ab exercitationem? Neque illo soluta sapiente!',
  count: '2',
  time: '12:17',
  distance: '6km',
  type: 'PRO',
  crown: 'assets/chat/crown.png',
}];

// Delete ride reasons
export const DELETE_RIDE = [{
  reason: 'I no longer need a ride.'
}, {
  reason: 'I found another ride.'
}, {
  reason: 'My plans changed.'
}, {
  reason: 'My car broke down.'
}, {
  reason: 'Other reason.'
}];

// Car data (minimal version for fallback)
export const CAR_DATA = [{
  name: 'Madza',
  car_no: '43A 235 70',
  icon: 'newspaper-outline',
}, {
  name: 'Toyota',
  car_no: '43A 125 70',
  icon: 'newspaper-outline',
}];

// Minimal car makes and models data for fallback
export const NEW_VECHILE_DATA = [
  {
    label: 'Make',
    placeholder: 'Select Vehicle',
    data: [
      {
        "name": "Toyota",
        "models": ["Camry", "Corolla", "RAV4", "Highlander", "Prius"]
      },
      {
        "name": "Honda",
        "models": ["Civic", "Accord", "CR-V", "Pilot", "Odyssey"]
      },
      {
        "name": "Ford",
        "models": ["F-150", "Escape", "Explorer", "Mustang", "Focus"]
      }
    ]
  },
  {
    label: 'Model',
    placeholder: 'Select Model'
  },
  {
    label: 'Year',
    placeholder: 'Select Year',
    data: Array.from({ length: 34 }, (_, i) => ({ name: (2023 - i).toString() }))
  },
  {
    label: 'Color',
    placeholder: 'Select Color',
    data: [
      { name: 'Black' },
      { name: 'White' },
      { name: 'Silver' },
      { name: 'Gray' },
      { name: 'Red' },
      { name: 'Blue' },
      { name: 'Brown' },
      { name: 'Green' }
    ]
  },
  {
    label: 'Capacity',
    placeholder: 'Select Capacity',
    data: [
      { name: '1' },
      { name: '2' },
      { name: '3' },
      { name: '4' },
      { name: '5' },
      { name: '6' },
      { name: '7' },
      { name: '8' }
    ]
  }
];

// Mark options
export const MARK_OPTIONS = {
  icon: {
    iconUrl: 'assets/icon/marker.png',
    iconSize: [35, 35],
    iconAnchor: [17.5, 35],
  }
};

// Edit ride reasons
export const EDIT_RIDE = [{
  reason: 'Change pickup location'
}, {
  reason: 'Change dropoff location'
}, {
  reason: 'Change date/time'
}, {
  reason: 'Change number of passengers'
}, {
  reason: 'Other'
}];

// Notification types
export const NOTIFICATIONS = [{
  title: 'Booking Confirmed',
  subTitle: 'Your booking has been confirmed',
  date: '10-11-2020',
  time: '10:30 AM',
  icon: 'checkmark-circle-outline',
  color: 'success'
}, {
  title: 'Booking Cancelled',
  subTitle: 'Your booking has been cancelled',
  date: '10-11-2020',
  time: '10:30 AM',
  icon: 'close-circle-outline',
  color: 'danger'
}];

// Document types
export const DOCUMENTS = [{
  name: 'Driving License',
  icon: 'car-outline',
  url: '/driving-license'
}, {
  name: 'ID Card',
  icon: 'card-outline',
  url: '/verify-id'
}];

// Footer chat options
export const FOOTER_CHAT = [{
  title: 'Chat',
  url: '/tabs/messages',
  icon: 'chatbubble-outline'
}, {
  title: 'Groups',
  url: '/tabs/messages',
  icon: 'people-outline'
}, {
  title: 'Calls',
  url: '/tabs/messages',
  icon: 'call-outline'
}];

// Profile data
export const PROFILE_DATA = {
  name: 'John Doe',
  email: 'john.doe@example.com',
  phone: '+1 234 567 890',
  image: 'assets/profile/profile.jpg'
};

// Profile details sections
export const PROFILE_DETAILS = [{
  title: 'Account',
  icon: 'person-outline',
  items: [{
    title: 'Edit Profile',
    url: '/edit-profile',
    icon: 'create-outline'
  }, {
    title: 'Change Password',
    url: '/change-password',
    icon: 'key-outline'
  }]
}, {
  title: 'Preferences',
  icon: 'options-outline',
  items: [{
    title: 'Notifications',
    url: '/notifications-settings',
    icon: 'notifications-outline'
  }, {
    title: 'Language',
    url: '/language',
    icon: 'language-outline'
  }]
}];

// Profile settings
export const PROFILE = {
  items: [{
    title: 'Edit Profile',
    url: '/edit-profile',
    icon: 'create-outline'
  }, {
    title: 'Payment Methods',
    url: '/payment-methods',
    icon: 'card-outline'
  }, {
    title: 'Notifications',
    url: '/notifications-settings',
    icon: 'notifications-outline'
  }, {
    title: 'Help & Support',
    url: '/support',
    icon: 'help-circle-outline'
  }, {
    title: 'Terms & Conditions',
    url: '/terms',
    icon: 'document-text-outline'
  }, {
    title: 'Privacy Policy',
    url: '/privacy',
    icon: 'shield-outline'
  }, {
    title: 'Logout',
    url: '/logout',
    icon: 'log-out-outline'
  }]
};

// Biography options
export const BIOGRAPHY = [{
  title: 'About Me',
  icon: 'person-outline',
  placeholder: 'Tell us about yourself'
}, {
  title: 'Interests',
  icon: 'heart-outline',
  placeholder: 'What do you like to do?'
}, {
  title: 'Education',
  icon: 'school-outline',
  placeholder: 'Where did you study?'
}, {
  title: 'Work',
  icon: 'briefcase-outline',
  placeholder: 'Where do you work?'
}];

// Preference options
export const PREFERENCE = [{
  title: 'Smoking',
  icon: 'flame-outline',
  options: ['Yes', 'No']
}, {
  title: 'Pets',
  icon: 'paw-outline',
  options: ['Yes', 'No']
}, {
  title: 'Music',
  icon: 'musical-notes-outline',
  options: ['Yes', 'No']
}, {
  title: 'Conversation',
  icon: 'chatbubble-outline',
  options: ['Yes', 'No']
}];

// Countries list (abbreviated)
export const COUNTRIES = [
  { name: 'United States', dial_code: '+1', code: 'US', flag: 'assets/flags/us.png' },
  { name: 'United Kingdom', dial_code: '+44', code: 'GB', flag: 'assets/flags/gb.png' },
  { name: 'Canada', dial_code: '+1', code: 'CA', flag: 'assets/flags/ca.png' },
  { name: 'Australia', dial_code: '+61', code: 'AU', flag: 'assets/flags/au.png' },
  { name: 'Germany', dial_code: '+49', code: 'DE', flag: 'assets/flags/de.png' },
  { name: 'France', dial_code: '+33', code: 'FR', flag: 'assets/flags/fr.png' },
  { name: 'Japan', dial_code: '+81', code: 'JP', flag: 'assets/flags/jp.png' },
  { name: 'China', dial_code: '+86', code: 'CN', flag: 'assets/flags/cn.png' },
  { name: 'India', dial_code: '+91', code: 'IN', flag: 'assets/flags/in.png' },
  { name: 'Brazil', dial_code: '+55', code: 'BR', flag: 'assets/flags/br.png' }
];

// Email verification messages
export const EMAIL_VERIFICATION = [{
  title: 'Email Verification',
  message: 'We have sent a verification link to your email. Please check your inbox and click on the link to verify your email address.',
  icon: 'mail-outline'
}, {
  title: 'Email Verified',
  message: 'Your email has been verified successfully. You can now login to your account.',
  icon: 'checkmark-circle-outline'
}];

// Payment cards
export const CARDS = [{
  number: '**** **** **** 1234',
  name: 'John Doe',
  expiry: '12/25',
  type: 'visa',
  icon: 'assets/cards/visa.png'
}, {
  number: '**** **** **** 5678',
  name: 'John Doe',
  expiry: '10/24',
  type: 'mastercard',
  icon: 'assets/cards/mastercard.png'
}];

// Tab routing
export const TAB_ROUTING = [
  {
    tab: 'home',
    iconName: 'home',
    label: 'Home'
  },
  {
    tab: 'find',
    iconName: 'search',
    label: 'Find Ride'
  },
  {
    tab: 'offer',
    iconName: 'add-circle',
    label: 'offer'
  },
  {
    tab: 'profile',
    iconName: 'person',
    label: 'Profile'
  }
]; 