import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Car } from '@app/state/models/car';
import { from, Observable, of, throwError } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { map, catchError, switchMap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import * as fromState from '@app/state';
import * as CarActions from '@app/state/car/car.actions';
import { castObservable } from '../utils/rxjs-compat';
import firebase from 'firebase/compat/app';

/**
 * Service for managing user vehicle data in Firebase
 * This service handles CRUD operations for users' cars/vehicles
 * 
 * Note: This is different from CarDataService which provides
 * reference data like makes, models, years, etc.
 */
@Injectable({
    providedIn: 'root'
})
export class CarService {
    car: Car;

    constructor(
        private firestore: AngularFirestore,
        public ngFireAuth: AngularFireAuth,
        private store: Store<fromState.State>,
    ) {}

    /**
     * Create a new car record in Firestore
     * @param car Car data to save
     * @returns Observable of the operation result
     */
    carCreate(car: any): Observable<any> {
        // Add current timestamp and ensure userId is set
        return this.getCurrentUserId().pipe(
            switchMap(userId => {
                if (!userId) {
                    return throwError(() => new Error('User must be authenticated to create a car'));
                }
                
                const carData = {
                    ...car,
                    userId: userId,
                    createdAt: new Date().getTime()
                };
                
                return from(this.firestore.collection('cars').add(carData));
            }),
            catchError(error => {
                console.error('Error creating car:', error);
                return throwError(() => error);
            })
        );
    }

    /**
     * Update an existing car record
     * @param car Car data with id to update
     * @returns Observable of the operation result
     */
    updateCar(car: any): Observable<any> {
        if (!car.id) {
            return throwError(() => new Error('Car ID is required for update'));
        }
        
        return from(
            this.firestore.collection('cars').doc(car.id).set({
                ...car,
                updatedAt: new Date().getTime()
            }, { merge: true })
        ).pipe(
            catchError(error => {
                console.error('Error updating car:', error);
                return throwError(() => error);
            })
        );
    }

    /**
     * Get a single car by ID
     * @param id Car ID to retrieve
     * @returns Observable with car data
     */
    getCar(id: string): Observable<any> {
        const snapshotObservable = this.firestore.doc(`cars/${id}`).snapshotChanges();
        return castObservable<any>(snapshotObservable)
            .pipe(
                map((doc) => {
                    const data = doc.payload.data() as any;
                    return { id: doc.payload.id, ...data };
                }),
                catchError(error => {
                    console.error('Error getting car:', error);
                    return of(null);
                })
            );
    }

    /**
     * Get all cars in the system (use with caution)
     * @returns Observable with array of all cars
     */
    getCarAll(): Observable<any[]> {
        const valueChangesObservable = this.firestore.collection('cars').valueChanges({ idField: 'id' });
        return castObservable<any[]>(valueChangesObservable)
            .pipe(
                catchError(error => {
                    console.error('Error getting all cars:', error);
                    return of([]);
                })
            );
    }

    /**
     * Get all cars for a specific user
     * @param userId User ID to get cars for
     * @returns Observable with array of user's cars
     */
    getUserCars(userId: string): Observable<any[]> {
        const valueChangesObservable = this.firestore.collection('cars', ref => 
            ref.where('userId', '==', userId)
        ).valueChanges({ idField: 'id' });
        
        return castObservable<any[]>(valueChangesObservable)
        .pipe(
            catchError(error => {
                console.error(`Error getting cars for user ${userId}:`, error);
                return of([]);
            })
        );
    }

    /**
     * Delete a car by ID
     * @param id Car ID to delete
     * @returns Observable of the operation result
     */
    deleteCar(id: string): Observable<void> {
        return from(this.firestore.collection("cars").doc(id).delete())
            .pipe(
                catchError(error => {
                    console.error('Error deleting car:', error);
                    return throwError(() => error);
                })
            );
    }
    
    /**
     * Gets the current authenticated user's ID
     * @returns Observable with user ID or null
     */
    private getCurrentUserId(): Observable<string | null> {
        return castObservable<firebase.User | null>(from(this.ngFireAuth.currentUser)).pipe(
            map(user => user ? user.uid : null)
        );
    }
} 