// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.star-rating {
  display: flex;
  align-items: center;
}
.star-rating mat-icon {
  margin-right: -4px;
}
.star-rating mat-icon.star-small {
  font-size: 16px;
  width: 16px;
  height: 16px;
}
.star-rating mat-icon.star-medium {
  font-size: 20px;
  width: 20px;
  height: 20px;
}
.star-rating mat-icon.star-large {
  font-size: 24px;
  width: 24px;
  height: 24px;
}
.star-rating mat-icon:last-child {
  margin-right: 0;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInN0YXItcmF0aW5nLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0UsYUFBQTtFQUNBLG1CQUFBO0FBQ0Y7QUFDRTtFQUNFLGtCQUFBO0FBQ0o7QUFDSTtFQUNFLGVBQUE7RUFDQSxXQUFBO0VBQ0EsWUFBQTtBQUNOO0FBRUk7RUFDRSxlQUFBO0VBQ0EsV0FBQTtFQUNBLFlBQUE7QUFBTjtBQUdJO0VBQ0UsZUFBQTtFQUNBLFdBQUE7RUFDQSxZQUFBO0FBRE47QUFJSTtFQUNFLGVBQUE7QUFGTiIsImZpbGUiOiJzdGFyLXJhdGluZy5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIi5zdGFyLXJhdGluZyB7XG4gIGRpc3BsYXk6IGZsZXg7XG4gIGFsaWduLWl0ZW1zOiBjZW50ZXI7XG4gIFxuICBtYXQtaWNvbiB7XG4gICAgbWFyZ2luLXJpZ2h0OiAtNHB4O1xuICAgIFxuICAgICYuc3Rhci1zbWFsbCB7XG4gICAgICBmb250LXNpemU6IDE2cHg7XG4gICAgICB3aWR0aDogMTZweDtcbiAgICAgIGhlaWdodDogMTZweDtcbiAgICB9XG4gICAgXG4gICAgJi5zdGFyLW1lZGl1bSB7XG4gICAgICBmb250LXNpemU6IDIwcHg7XG4gICAgICB3aWR0aDogMjBweDtcbiAgICAgIGhlaWdodDogMjBweDtcbiAgICB9XG4gICAgXG4gICAgJi5zdGFyLWxhcmdlIHtcbiAgICAgIGZvbnQtc2l6ZTogMjRweDtcbiAgICAgIHdpZHRoOiAyNHB4O1xuICAgICAgaGVpZ2h0OiAyNHB4O1xuICAgIH1cbiAgICBcbiAgICAmOmxhc3QtY2hpbGQge1xuICAgICAgbWFyZ2luLXJpZ2h0OiAwO1xuICAgIH1cbiAgfVxufSJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/shared/ui/star-rating/star-rating.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;AACF;AACE;EACE,kBAAA;AACJ;AACI;EACE,eAAA;EACA,WAAA;EACA,YAAA;AACN;AAEI;EACE,eAAA;EACA,WAAA;EACA,YAAA;AAAN;AAGI;EACE,eAAA;EACA,WAAA;EACA,YAAA;AADN;AAII;EACE,eAAA;AAFN;AACA,glCAAglC","sourcesContent":[".star-rating {\n  display: flex;\n  align-items: center;\n  \n  mat-icon {\n    margin-right: -4px;\n    \n    &.star-small {\n      font-size: 16px;\n      width: 16px;\n      height: 16px;\n    }\n    \n    &.star-medium {\n      font-size: 20px;\n      width: 20px;\n      height: 20px;\n    }\n    \n    &.star-large {\n      font-size: 24px;\n      width: 24px;\n      height: 24px;\n    }\n    \n    &:last-child {\n      margin-right: 0;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
