import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { FileUploadService } from './file-upload.service';

@Injectable({
  providedIn: 'root'
})
export class UploadService {
  private apiUrl = 'api/upload';

  constructor(
    private http: HttpClient,
    private fileUploadService: FileUploadService
  ) {}

  /**
   * Upload a child photo to the server
   * This is a specific method for child photos that posts to a dedicated endpoint
   * 
   * @param childId ID of the child
   * @param file File to upload
   * @returns Promise with URL of the uploaded file
   */
  async uploadChildPhoto(childId: string, file: File): Promise<string> {
    // For child photos, we use a dedicated API endpoint (not Firebase Storage)
    const formData = new FormData();
    formData.append('file', file);
    formData.append('childId', childId);
    
    try {
      const response = await lastValueFrom(
        this.http.post<{ url: string }>(`${this.apiUrl}/child-photo`, formData)
      );
      return response.url;
    } catch (error) {
      console.error('Error uploading child photo:', error);
      throw error;
    }
  }
} 