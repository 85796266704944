import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouteReuseStrategy } from '@angular/router';
import { IonicRouteStrategy } from '@ionic/angular';
import { PipesModule } from '../pipes/pipes.module';

// Authentication services
import { AuthenticationService } from '../services/auth.service';

// Feature services
import { SafetyService } from '../features/safety/safety.service';
import { NetworkService } from '../features/network/network.service';
import { RideService } from '../services/ride.service';
import { ChildService } from '../services/child.service';
import { ProfileService } from '../services/profile.service';
import { WebSocketService } from '../services/websocket.service';

// Interceptors
import { TokenInterceptor } from '../interceptors/token.interceptor';
import { ErrorInterceptor } from '../interceptors/error.interceptor';

/**
 * Core module that provides all services that should be instantiated once in the application
 * This includes authentication, error handling, API services, etc.
 */
@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    PipesModule
  ],
  providers: [
    // Core app services
    AuthenticationService,
    WebSocketService,
    ProfileService,
    
    // Feature services
    SafetyService,
    NetworkService,
    RideService,
    ChildService,
    
    // Global HTTP interceptors
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    
    // Ionic specific providers
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ]
})
export class CoreModule {
  /**
   * Prevent reimporting of the CoreModule
   */
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it only in AppModule');
    }
  }
}