import { createAction, props } from '@ngrx/store';

export const eventCreate = createAction(
  '[Event] Create Event',
  props<{ event: any }>()
);

export const eventCreateSuccess = createAction(
  '[Event API] Create Event Success',
  props<{ event: any }>()
);

export const eventCreateFailure = createAction(
  '[Event API] Create Event Failure',
  props<{ error: any }>()
);

export const eventGet = createAction(
  '[Event] Get Event',
  props<{ id: string }>()
);

export const eventGetSuccess = createAction(
  '[Event API] Get Event Success',
  props<{ event: any }>()
);

export const eventGetFailure = createAction(
  '[Event API] Get Event Failure',
  props<{ error: any }>()
);

export const eventGetAll = createAction(
  '[Event] Get All Events'
);

export const eventGetAllSuccess = createAction(
  '[Event API] Get All Events Success',
  props<{ events: any[] }>()
);

export const eventGetAllFailure = createAction(
  '[Event API] Get All Events Failure',
  props<{ error: any }>()
);

export const eventUpdate = createAction(
  '[Event] Update Event',
  props<{ event: any }>()
);

export const eventUpdateSuccess = createAction(
  '[Event API] Update Event Success',
  props<{ event: any }>()
);

export const eventUpdateFailure = createAction(
  '[Event API] Update Event Failure',
  props<{ error: any }>()
);

export const eventDelete = createAction(
  '[Event] Delete Event',
  props<{ id: string }>()
);

export const eventDeleteSuccess = createAction(
  '[Event API] Delete Event Success'
);

export const eventDeleteFailure = createAction(
  '[Event API] Delete Event Failure',
  props<{ error: any }>()
); 