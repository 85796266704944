import { createReducer, on } from '@ngrx/store';
import * as MessageActions from './message.actions';
import { ChatMessage, ChatThread } from '@app/models/chat.model';

export const messageFeatureKey = 'message';

export interface MessageState {
  messages: ChatMessage[];
  thread: ChatThread | null;
  loading: boolean;
  threadLoading: boolean;
  error: any;
  currentThreadId?: string;
}

export const initialState: MessageState = {
  messages: [],
  thread: null,
  loading: false,
  threadLoading: false,
  error: null
};

// Add a utility function to handle errors consistently
// This will be reused in all failure action handlers
const handleError = (state: MessageState, error: any) => ({
  ...state,
  error: {
    message: error.message || 'Unknown error',
    code: error.code || 'UNKNOWN',
    stack: error.stack || ''
  },
  loading: false,
  threadLoading: false
});

export const reducer = createReducer(
  initialState,
  
  // Load messages
  on(MessageActions.loadMessages, (state, { threadId }) => ({
    ...state,
    loading: true,
    error: null,
    // Only clear messages if loading a different thread
    messages: threadId !== state.thread?.id ? [] : state.messages,
    currentThreadId: threadId // Track the current thread ID to maintain message context
  })),
  
  on(MessageActions.loadMessagesSuccess, (state, { messages }) => {
    // Sort messages by timestamp to ensure they're in chronological order
    const sortedMessages = [...messages].sort((a, b) => a.timestamp - b.timestamp);
    
    return {
      ...state,
      messages: sortedMessages,
      loading: false
    };
  }),
  
  on(MessageActions.loadMessagesFailure, (state, { error }) => 
    handleError(state, error)
  ),
  
  // Load more messages (pagination)
  on(MessageActions.loadMoreMessages, state => ({
    ...state,
    loading: true,
    error: null
  })),
  
  on(MessageActions.loadMoreMessagesSuccess, (state, { messages }) => {
    // Create a map of existing messages for quick lookup
    const existingMessagesMap = new Map(state.messages.map(m => [m.id, m]));
    
    // Filter out duplicates and add new messages
    const newMessages = messages.filter(message => !existingMessagesMap.has(message.id));
    
    // Sort all messages by timestamp
    const allMessages = [...newMessages, ...state.messages].sort(
      (a, b) => a.timestamp - b.timestamp
    );
    
    return {
      ...state,
      messages: allMessages,
      loading: false
    };
  }),
  
  on(MessageActions.loadMoreMessagesFailure, (state, { error }) => 
    handleError(state, error)
  ),
  
  // Send message
  on(MessageActions.sendMessage, state => ({
    ...state,
    error: null
  })),
  
  on(MessageActions.sendMessageSuccess, (state, { message }) => {
    console.log('Reducer: handling sendMessageSuccess with message:', message);
    
    // Check if we already have this message to avoid duplicates
    if (state.messages.some(m => m.id === message.id)) {
      console.log('Reducer: message already exists in state, not adding duplicate');
      return state;
    }
    
    console.log('Reducer: adding new message to state');
    
    // Add the new message and ensure messages are sorted by timestamp
    const updatedMessages = [...state.messages, message].sort(
      (a, b) => a.timestamp - b.timestamp
    );
    
    return {
      ...state,
      messages: updatedMessages,
      error: null
    };
  }),
  
  on(MessageActions.sendMessageFailure, (state, { error }) => 
    handleError(state, error)
  ),
  
  // Load thread
  on(MessageActions.loadThread, state => ({
    ...state,
    threadLoading: true,
    error: null
  })),
  
  on(MessageActions.loadThreadSuccess, (state, { thread }) => ({
    ...state,
    thread,
    threadLoading: false
  })),
  
  on(MessageActions.loadThreadFailure, (state, { error }) => 
    handleError(state, error)
  ),
  
  // Delete message
  on(MessageActions.deleteMessage, state => ({
    ...state,
    error: null
  })),
  
  on(MessageActions.deleteMessageSuccess, (state, { messageId }) => ({
    ...state,
    messages: state.messages.filter(message => message.id !== messageId)
  })),
  
  on(MessageActions.deleteMessageFailure, (state, { error }) => 
    handleError(state, error)
  ),
  
  // Mark thread as read
  on(MessageActions.markThreadAsRead, state => ({
    ...state,
    error: null
  })),
  
  on(MessageActions.markThreadAsReadFailure, (state, { error }) => 
    handleError(state, error)
  ),
  
  // Clear message state
  on(MessageActions.clearMessageState, () => initialState)
); 