import { Actions, ofType, createEffect } from '@ngrx/effects';
import { catchError, exhaustMap, map, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { LoginService } from '@app/services/login.service';
import { of } from 'rxjs';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as UserActions from './user.actions';
import { UserService } from '@app/services/user.service';
import * as fromUser from './user.reducer';
import { AuthenticationService } from '@app/services/auth.service';


@Injectable()
export class UserEffects {
    updateUser$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserActions.userUpdate),
            map(action => action.user),
            exhaustMap((data: any) =>
                this.userService.updateUser(data).pipe(
                    map((user) => UserActions.userUpdateSuccess({ user })),
                    catchError(error => of(UserActions.userUpdateFailure({ error: this.handleError(error) })))
                )
            )
        )
    );

    loadUser$ = createEffect(() => 
        this.actions$.pipe(
            ofType(UserActions.userGet),
            switchMap(() => {
                // Use authState instead of user$
                return this.authService.authState.pipe(
                    switchMap(authUser => {
                        if (!authUser) {
                            return of(UserActions.userGetFailure({ error: 'User not authenticated' }));
                        }
                        return this.userService.getUserById(authUser.uid).pipe(
                            map(user => UserActions.userGetSuccess({ user })),
                            catchError(error => of(UserActions.userGetFailure({ error: this.handleError(error) })))
                        );
                    })
                );
            })
        )
    );

    getUserAll$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserActions.userGetAll),
            map(action => action),
            exhaustMap(() =>
                this.userService.getUserAll().pipe(
                    map((users: any) => UserActions.userGetAllSuccess({ users })),
                    catchError(error => of(UserActions.userGetAllFailure({ error: this.handleError(error) })))
                )
            )
        )
    );

    updateNotificationSettings$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserActions.updateNotificationSettings),
            map(action => ({ userId: action.userId, settings: action.settings })),
            exhaustMap((user) =>
                this.userService.updateUser(user).pipe(
                    map((user) => UserActions.updateNotificationSettingsSuccess({ user })),
                    catchError(error => {
                        // Make sure to serialize the error object
                        const errorMessage = typeof error === 'object' ? 
                            (error.message || 'Unknown error') : 
                            String(error);
                        
                        return of(UserActions.updateNotificationSettingsFailure({ 
                            error: errorMessage 
                        }));
                    })
                )
            )
        )
    );

    /**
     * Helper method to ensure all errors are serializable
     */
    private handleError(error: any): string {
        if (typeof error === 'object') {
            return error.message || JSON.stringify(error) || 'Unknown error';
        }
        return String(error);
    }

    constructor(
        private actions$: Actions,
        private userService: UserService,
        private authService: AuthenticationService
    ) { }
}
