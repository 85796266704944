import { Injectable } from '@angular/core';
import { Plugin } from '@capacitor/core';
import { StatusBar } from '@capacitor/status-bar';
import { 
  ActionSheetController, 
  AlertController, 
  LoadingController, 
  ModalController, 
  NavController, 
  ToastController 
} from '@ionic/angular';
import { environment } from '../../environments/environment';
import { Store, select } from '@ngrx/store';
import { UserClass } from '@app/state/user/user.model';
import { selectUser, selectAllUsers } from '@app/state/user/user.selectors';
import { selectAllProfiles } from '@app/state/profile/profile.selectors';
import * as UserActions from '@app/state/user/user.actions';
import * as CarActions from '@app/state/car/car.actions';
import { selectAllCars } from '@app/state/car/car.selectors';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { take } from 'rxjs/operators';

declare let google;

@Injectable({
  providedIn: 'root'
})
export class UtilService {
  private users = [];
  private user: any;
  private cars: any = [];
  private profiles: any = [];

  private loader: HTMLIonLoadingElement | null = null;

  constructor(
    private http: HttpClient,
    private store: Store,
    private actionSheetCtrl: ActionSheetController,
    private alertCtrl: AlertController,
    private loadingCtrl: LoadingController,
    private modalCtrl: ModalController,
    private navCtrl: NavController,
    private toastCtrl: ToastController
  ) {
    this.store.pipe(select(selectAllUsers))
      .subscribe(values => {
        if (values) {
          const newUser = new UserClass();
          const keys = Object.keys(newUser);
          values.forEach((value) => {
            keys.forEach((prop: any) => {
              newUser[prop] = value[prop] || null;
            });
            this.users.push({ ...newUser });
          });
        }
      });

    this.store.pipe(select(selectUser))
      .subscribe(value => {
        if (value) {
          const newUser = new UserClass();
          const keys = Object.keys(newUser);
          keys.forEach((prop: any) => {
            newUser[prop] = value[prop] || null;
          });
          this.user = { ...newUser };
        }
      });

    this.store.pipe(select(selectAllCars))
      .subscribe(values => {
        if (values) {
          this.cars = values;
        }
      });

    this.store.pipe(select(selectAllProfiles))
      .subscribe(values => {
        if (values) {
          this.profiles = values;
        }
      });
  }

  validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  goToNew(route) {
    this.navCtrl.navigateForward(route);
  }

  goBack() {
    this.navCtrl.back();
  }

  goBackTo(route) {
    this.navCtrl.navigateBack(route);
  }

  goForward(route) {
    this.navCtrl.navigateForward(route);
  }

  async createAlert(header, backdropDismiss, message, buttonOptions1, buttonOptions2?): Promise<HTMLIonAlertElement> {
    const buttons = buttonOptions2 ? [buttonOptions1, buttonOptions2] : [buttonOptions1];
    const alert = await this.alertCtrl.create({
      header,
      backdropDismiss,
      message,
      buttons
    });
    return alert;
  }

  async createLoader(message): Promise<HTMLIonLoadingElement> {
    const loader = await this.loadingCtrl.create({
      message,
      spinner: 'crescent'
    });
    return loader;
  }

  async createToast(message, showCloseButton = false, position = 'bottom' as 'top' | 'bottom' | 'middle', duration = 2000): Promise<HTMLIonToastElement> {
    const toast = await this.toastCtrl.create({
      message,
      duration,
      position,
      buttons: showCloseButton ? [
        {
          text: 'Close',
          role: 'cancel'
        }
      ] : []
    });
    return toast;
  }

  async createModal(component, componentProps?, cssClass?, showBackdrop?: true, backdropDismiss?: true): Promise<HTMLIonModalElement> {
    const modal = await this.modalCtrl.create({
      component,
      componentProps,
      cssClass,
      showBackdrop,
      backdropDismiss
    });
    return modal;
  }

  async createActionSheet(button1, button2?, button3?) {
    const buttons = [button1];
    if (button2) buttons.push(button2);
    if (button3) buttons.push(button3);
    const actionSheet = await this.actionSheetCtrl.create({ buttons });
    return actionSheet;
  }

  alignProfileToRide(rides: any) {
    if (!rides || !rides.length) {
      return [];
    }

    const profiles = this.profiles;
    const cars = this.cars;

    if (!profiles || !profiles.length) {
      return rides;
    }

    rides.forEach((ride) => {
      if (ride.driverId) {
        const profile = profiles.find(p => p.userId === ride.driverId);
        if (profile) {
          ride.profile = profile;
          if (ride.carId && cars && cars.length) {
            const car = cars.find(c => c.id === ride.carId);
            if (car) {
              ride.car = car;
            }
          }
        }
      }
    });

    return rides;
  }

  alginUserToProfile(profiles) {
    if (!profiles || !profiles.length) {
      return [];
    }

    const users = this.users;

    if (!users || !users.length) {
      return profiles;
    }

    profiles.forEach((profile) => {
      if (profile.userId) {
        const user = users.find(u => u.uid === profile.userId);
        if (user) {
          profile.user = user;
        }
      }
    });

    return profiles;
  }

  alignCarToProfile(profile) {
    if (!profile) {
      return null;
    }

    const cars = this.cars;

    if (!cars || !cars.length) {
      return profile;
    }

    if (profile.userId) {
      const car = cars.find(c => c.userId === profile.userId);
      if (car) {
        profile.car = car;
      }
    }

    return profile;
  }

  changeStatusBarColor() {
    StatusBar.setBackgroundColor({ color: '#3880ff' });
  }

  /**
   * Show a loading indicator
   * @param message Optional message to display
   */
  async showLoading(message: string = 'Please wait...'): Promise<void> {
    if (this.loader) {
      await this.hideLoading();
    }
    
    this.loader = await this.loadingCtrl.create({
      message,
      spinner: 'crescent'
    });
    
    await this.loader.present();
  }

  /**
   * Hide the currently displayed loading indicator
   */
  async hideLoading(): Promise<void> {
    if (this.loader) {
      await this.loader.dismiss();
      this.loader = null;
    }
  }

  shallowCopyObject(value, typeClass) {
    if (!value) return null;
    const newObj = new typeClass();
    const keys = Object.keys(newObj);
    keys.forEach((prop: any) => {
      newObj[prop] = value[prop] || null;
    });
    return { ...newObj };
  }

  changeTimeFormat(date?) {
    if (!date) {
      date = new Date();
    }
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'pm' : 'am';
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
    return `${formattedHours}:${formattedMinutes} ${ampm}`;
  }

  sortRides(rides: any) {
    if (!rides || !rides.length) {
      return [];
    }

    return rides.sort((a, b) => {
      return new Date(a.departureTime).getTime() - new Date(b.departureTime).getTime();
    });
  }

  calculateSeatsAvailable(ride, type) {
    if (!ride) {
      return 0;
    }

    if (type === 'offer') {
      if (!ride.bookedRides || !ride.bookedRides.length) {
        return ride.availableSeats || 0;
      }

      let bookedSeats = 0;
      ride.bookedRides.forEach(bookedRide => {
        bookedSeats += bookedRide.passengers || 1;
      });

      return (ride.availableSeats || 0) - bookedSeats;
    }

    return ride.passengers || 1;
  }

  formatTimeForDisplay(time: string): string {
    if (!time) return '';
    
    const date = new Date(time);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
    
    return `${formattedHours}:${formattedMinutes} ${ampm}`;
  }

  // Format distance to always display in miles
  formatDistance(distance: string | number): string {
    if (!distance) return '';
    
    // If it's a number, assume it's in kilometers
    if (typeof distance === 'number') {
      return Math.round(distance * 0.621371) + ' miles';
    }
    
    // If it's a string
    if (typeof distance === 'string') {
      // Remove any tilde characters
      distance = distance.replace(/~/g, '');
      
      // Check if it contains "km"
      if (distance.includes('km')) {
        const match = distance.match(/(\d+(?:\.\d+)?)\s*km/);
        if (match && match[1]) {
          const km = parseFloat(match[1]);
          return Math.round(km * 0.621371) + ' miles';
        }
      }
      
      // If already in miles or no unit specified
      return distance;
    }
    
    return '';
  }
}