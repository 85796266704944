/**
 * Notification interface for the HereThere app
 * This is the canonical model for notifications throughout the application
 */

export type NotificationType = 'ride' | 'payment' | 'system' | 'message' | 'emergency';
export type NotificationPriority = 'low' | 'medium' | 'high';

export interface Notification {
  id?: string;
  userId: string;
  title: string;
  message: string;
  timestamp: number;
  read: boolean;
  type: NotificationType;
  priority: NotificationPriority;
  actionUrl?: string;
  rideId?: string;
  threadId?: string;
  metadata?: Record<string, any>;
}

/**
 * Helper functions for notifications
 */
export class NotificationUtils {
  /**
   * Get an icon name for a notification type
   */
  static getIconForType(type: NotificationType): string {
    switch (type) {
      case 'ride':
        return 'car-outline';
      case 'payment':
        return 'wallet-outline';
      case 'message':
        return 'chatbubble-outline';
      case 'emergency':
        return 'warning-outline';
      case 'system':
      default:
        return 'information-circle-outline';
    }
  }

  /**
   * Get a color for a notification priority
   */
  static getColorForPriority(priority: NotificationPriority): string {
    switch (priority) {
      case 'high':
        return 'danger';
      case 'medium':
        return 'warning';
      case 'low':
      default:
        return 'medium';
    }
  }

  /**
   * Format a timestamp in a human-readable format
   */
  static formatTimestamp(timestamp: number): string {
    const now = Date.now();
    const diff = now - timestamp;
    
    // Less than a minute
    if (diff < 60 * 1000) {
      return 'Just now';
    }
    
    // Less than an hour
    if (diff < 60 * 60 * 1000) {
      const minutes = Math.floor(diff / (60 * 1000));
      return `${minutes}m ago`;
    }
    
    // Less than a day
    if (diff < 24 * 60 * 60 * 1000) {
      const hours = Math.floor(diff / (60 * 60 * 1000));
      return `${hours}h ago`;
    }
    
    // Less than a week
    if (diff < 7 * 24 * 60 * 60 * 1000) {
      const days = Math.floor(diff / (24 * 60 * 60 * 1000));
      return `${days}d ago`;
    }
    
    // Otherwise show date
    return new Date(timestamp).toLocaleDateString();
  }
} 