import { createAction, props } from '@ngrx/store';
import { LocationData } from '@app/models/ride.model';

// Location Actions
export const setPickupLocation = createAction(
  '[Journey] Set Pickup Location',
  props<{ location: LocationData | null }>()
);

export const setDropOffLocation = createAction(
  '[Journey] Set Drop Off Location',
  props<{ location: LocationData | null }>()
);

export const clearLocations = createAction(
  '[Journey] Clear Locations'
);

export const setRouteData = createAction(
  '[Journey] Set Route Data',
  props<{ data: any }>()
);

// Date and Journey Type Actions
export const setSelectedDate = createAction(
  '[Journey] Set Selected Date',
  props<{ date: string }>()
);

export const setSelectedReturnDate = createAction(
  '[Journey] Set Selected Return Date',
  props<{ date: string }>()
);

export const setJourneyType = createAction(
  '[Journey] Set Journey Type',
  props<{ journeyType: 'oneWay' | 'roundTrip' }>()
);

// Ride Preferences Actions
export const setPassengerCount = createAction(
  '[Journey] Set Passenger Count',
  props<{ count: number }>()
);

export const setSelectedLuggage = createAction(
  '[Journey] Set Selected Luggage',
  props<{ luggage: string }>()
);

export const setRideOptions = createAction(
  '[Journey] Set Ride Options',
  props<{ options: {pets: boolean, smoking: boolean, wheelchair: boolean} }>()
);

// UI State Actions
export const setLocationSelectionMode = createAction(
  '[Journey] Set Location Selection Mode',
  props<{ mode: string }>()
);

export const setIsEditingRide = createAction(
  '[Journey] Set Is Editing Ride',
  props<{ isEditing: boolean }>()
);

export const setCurrentTab = createAction(
  '[Journey] Set Current Tab',
  props<{ tab: string }>()
);

export const setSelectedRideDetails = createAction(
  '[Journey] Set Selected Ride Details',
  props<{ details: any }>()
);

export const setFindRideDetails = createAction(
  '[Journey] Set Find Ride Details',
  props<{ details: any }>()
);

export const setJourneyFlag = createAction(
  '[Journey] Set Journey Flag',
  props<{ isJourney: boolean }>()
);

export const setSelectedJourneyForEdit = createAction(
  '[Journey] Set Selected Journey For Edit',
  props<{ journey: any }>()
);

export const setSelectedLocation = createAction(
  '[Journey] Set Selected Location',
  props<{ location: any }>()
);

export const setOnwardRideDetails = createAction(
  '[Journey] Set Onward Ride Details',
  props<{ details: any }>()
);

export const setReturnRideDetails = createAction(
  '[Journey] Set Return Ride Details',
  props<{ details: any }>()
);

export const setStopovers = createAction(
  '[Journey] Set Stopovers',
  props<{ stopovers: any[] }>()
);

export const setRideDirection = createAction(
  '[Journey] Set Ride Direction',
  props<{ direction: string }>()
);

export const setRideType = createAction(
  '[Journey] Set Ride Type',
  props<{ rideType: string }>()
);

export const setTotalFare = createAction(
  '[Journey] Set Total Fare',
  props<{ fare: string }>()
);

export const setBookRideConfirmation = createAction(
  '[Journey] Set Book Ride Confirmation',
  props<{ confirmation: boolean }>()
);

export const setBookedRides = createAction(
  '[Journey] Set Booked Rides',
  props<{ rides: any[] }>()
);

export const setOfferedRides = createAction(
  '[Journey] Set Offered Rides',
  props<{ rides: any[] }>()
);

export const initializeRideDetails = createAction(
  '[Journey] Initialize Ride Details'
);

export const resetAll = createAction(
  '[Journey] Reset All'
);
