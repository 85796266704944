import { NgModule, NO_ERRORS_SCHEMA, ErrorHandler, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { GoogleMapsModule } from '@angular/google-maps';
import { IonicStorageModule } from '@ionic/storage-angular';
import { ThemeService } from './services/theme.service';
import { TranslationModule } from './translation.module';
import { OnboardingService } from './services/onboarding.service';

// Explicitly import common Angular modules to ensure they're available
import { CommonModule, Location } from '@angular/common';
import { RouterModule, RouteReuseStrategy } from '@angular/router';

// Import the service worker module
import { AppServiceWorkerModule } from './app-service-worker/app-service-worker.module';

// Firebase
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFirestoreModule, SETTINGS } from '@angular/fire/compat/firestore';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';

// NgRx - Ensure we explicitly import all needed symbols
import { StoreModule, ActionReducerMap, MetaReducer, createAction, props, createReducer, on, createFeatureSelector, createSelector } from '@ngrx/store';
import { EffectsModule, Actions, createEffect, ofType } from '@ngrx/effects';
import { StoreRouterConnectingModule, RouterState } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

// Core and Shared Modules
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { FeaturesModule } from './features/features.module';
import { AngularFireCompatModule } from './utils/angular-fire-compat.module';

// App Routing and Component
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// Feature Modules
import { TabsPageModule } from './pages/tabs/tabs.module';
import { PagesModule } from '@app/pages';

// Environment
import { environment } from '../environments/environment';

// Reducers and Effects
import { reducers, metaReducers, effects } from './state';
import * as fromCalendar from './state/calendar';

// Pipes Module
import { PipesModule } from './pipes/pipes.module';

// Add LocationStrategy imports
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { ErrorHandlerService } from './core/services/error-handler.service';
import { LoggingService } from './core/services/logging.service';
import { Calendar } from '@awesome-cordova-plugins/calendar/ngx';

@NgModule({
  declarations: [AppComponent],
  schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    // Angular Core
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    CommonModule, // Explicitly import CommonModule
    RouterModule, // Explicitly import RouterModule
    
    // Service Worker
    AppServiceWorkerModule,
    
    // Ionic
    IonicModule.forRoot(),
    IonicStorageModule.forRoot(),
    
    // Translations - Using our consolidated module
    TranslationModule,
    
    // Core and Shared
    CoreModule,
    SharedModule,
    FeaturesModule,
    AngularFireCompatModule,
    
    // Firebase
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule.enablePersistence({
      synchronizeTabs: true
    }),
    AngularFireStorageModule,
    
    // NgRx
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
        strictStateSerializability: true,
        strictActionSerializability: !environment.production,
      },
    }),
    StoreRouterConnectingModule.forRoot({
      routerState: RouterState.Minimal,
    }),
    StoreDevtoolsModule.instrument({
      name: 'HereThere',
      logOnly: environment.production,
      maxAge: 25
    }),
    EffectsModule.forRoot(effects),
    
    // Maps
    GoogleMapsModule,
    
    // App Modules
    AppRoutingModule,
    TabsPageModule,
    PagesModule,
    PipesModule,
  ],
  exports: [],
  providers: [
    {
      provide: SETTINGS,
      useValue: {
        cacheSizeBytes: 50 * 1024 * 1024, // 50MB cache size
        ignoreUndefinedProperties: true, // Avoid issues with undefined properties
        timestampsInSnapshots: true, // Consistent timestamp format
        merge: true // Enable merge option
      }
    },
    { provide: Window, useValue: window },
    { provide: LocationStrategy, useClass: PathLocationStrategy }, // Changed to path-based routing (browser routing)
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    ThemeService,
    LoggingService,
    OnboardingService,
    // Register the custom error handler
    {
      provide: ErrorHandler,
      useClass: ErrorHandlerService
    },
    Calendar,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}