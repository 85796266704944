// ----------------------------------
// Angular & Router
// ----------------------------------
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

// ----------------------------------
// Application & Services
// ----------------------------------
import { AuthGuardService as AuthGuard } from './services/auth-guard.service';
import { OnboardingGuard } from './guards/onboarding.guard';
import { TabsPage } from './pages/tabs/tabs.page';
import { inject } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { map } from 'rxjs/operators';
import { castObservable } from './utils/rxjs-compat';
import firebase from 'firebase/compat/app';

// ----------------------------------
// Application Routes
// ----------------------------------
/**
 * Defines all application routes including lazy-loaded modules
 * and guard checks.
 */

// Guard to redirect logged-in users to home page
const redirectLoggedInToHome = () => {
  const afAuth = inject(AngularFireAuth);
  const authState$ = castObservable<firebase.User | null>(afAuth.authState);
  return authState$.pipe(
    map(user => {
      if (user) {
        // User is logged in, redirect to home
        return ['/tabs/home'];
      } else {
        // User is not logged in, allow access to login page
        return true;
      }
    })
  );
};

export const routes: Routes = [
  // Redirect when no path is provided
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'tabs',
    component: TabsPage,
    canActivate: [AuthGuard, OnboardingGuard],
    children: [
      {
        path: 'home',
        children: [
          {
            path: '', loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule)
          }
        ]
      },
      {
        path: 'offer',
        children: [
          {
            path: '', loadChildren: () => import('./pages/pickup/pickup.module').then(m => m.PickupPageModule)
          }
        ]
      },
      {
        path: 'profile',
        children: [
          {
            path: '', loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfilePageModule)
          }
        ]
      },
      {
        path: 'find',
        children: [
          {
            path: '', loadChildren: () => import('./pages/find-ride/find-ride.module').then(m => m.FindRidePageModule)
          }
        ]
      },
      {
        path: 'messages',
        children: [
          {
            path: '', loadChildren: () => import('./pages/inbox/inbox.module').then(m => m.InboxPageModule)
          }
        ]
      },
      {
        path: '',
        redirectTo: '/tabs',
        pathMatch: 'full'
      }
    ]
  },
  // Public routes
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule),
    canActivate: [redirectLoggedInToHome]
  },
  {
    path: 'add-car',
    loadChildren: () => import('./pages/add-car/add-car.module').then(m => m.AddCarPageModule)
  },
  {
    path: 'add-new-vehicle',
    loadChildren: () => import('./pages/add-new-vehicle/add-new-vehicle.module').then(m => m.AddNewVehiclePageModule)
  },
  {
    path: 'add-return-description',
    loadChildren: () => import('./pages/add-return-description/add-return-description.module').then(m => m.AddReturnDescriptionPageModule)
  },
  {
    path: 'booking-confirmation',
    loadChildren: () => import('./pages/booking-confirmation/booking-confirmation.module').then(m => m.BookingConfirmationPageModule)
  },
  {
    path: 'book-instantly',
    loadChildren: () => import('./pages/book-instantly/book-instantly.module').then(m => m.BookInstantlyPageModule)
  },
  {
    path: 'calendar',
    loadChildren: () => import('./pages/calendar/calendar.module').then(m => m.CalendarPageModule),
    data: { flow: 'createRide' }
  },
  {
    path: 'check-booking',
    loadChildren: () => import('./pages/check-booking/check-booking.module').then(m => m.CheckBookingPageModule)
  },
  {
    path: 'coming-back',
    loadChildren: () => import('./pages/coming-back/coming-back.module').then(m => m.ComingBackPageModule)
  },
  {
    path: 'delete-ride',
    loadChildren: () => import('./pages/delete-ride/delete-ride.module').then(m => m.DeleteRidePageModule)
  },
  {
    path: 'direction',
    loadChildren: () => import('./pages/direction/direction.module').then(m => m.DirectionPageModule)
  },
  {
    path: 'driving-license',
    loadChildren: () => import('./pages/driving-license/driving-license.module').then(m => m.DrivingLicensePageModule)
  },
  {
    path: 'drop-off',
    loadChildren: () => import('./pages/drop-off/drop-off.module').then(m => m.DropOffPageModule),
    data: { flow: 'createRide' }
  },
  {
    path: 'edit-price',
    loadChildren: () => import('./pages/edit-price/edit-price.module').then(m => m.EditPricePageModule)
  },
  {
    path: 'edit-price-list',
    loadChildren: () => import('./pages/edit-price-list/edit-price-list.module').then(m => m.EditPriceListPageModule)
  },
  {
    path: 'edit-profile',
    loadChildren: () => import('./pages/edit-profile/edit-profile.module').then(m => m.EditProfilePageModule)
  },
  {
    path: 'edit-ride',
    loadChildren: () => import('./pages/edit-ride/edit-ride.module').then(m => m.EditRidePageModule)
  },
  {
    path: 'find-ride',
    loadChildren: () => import('./pages/find-ride/find-ride.module').then(m => m.FindRidePageModule)
  },
  {
    path: 'find-ride-search-result',
    loadChildren: () => import('./pages/find-ride-search-result/find-ride-search-result.module').then(m => m.FindRideSearchResultPageModule)
  },
  {
    path: 'forget-password',
    loadChildren: () => import('./pages/forget-password/forget-password.module').then(m => m.ForgetPasswordPageModule)
  },
  {
    path: 'journey',
    redirectTo: '/tabs/home',
    pathMatch: 'full'
  },
  {
    path: 'login-form',
    loadChildren: () => import('./pages/login-form/login-form.module').then(m => m.LoginFormPageModule),
    canActivate: [redirectLoggedInToHome]
  },
  {
    path: 'map',
    loadChildren: () => import('./pages/map/map.module').then(m => m.MapPageModule)
  },
  {
    path: 'middle-seat',
    loadChildren: () => import('./pages/middle-seat/middle-seat.module').then(m => m.MiddleSeatPageModule)
  },
  {
    path: 'offer-ride-confirmation',
    loadChildren: () => import('./pages/offer-ride-confirmation/offer-ride-confirmation.module').then(m => m.OfferRideConfirmationPageModule)
  },
  {
    path: 'passengers-option',
    loadChildren: () => import('./pages/passengers-option/passengers-option.module').then(m => m.PassengersOptionPageModule)
  },
  {
    path: 'phone-verification',
    loadChildren: () => import('./pages/phone-verification/phone-verification.module').then(m => m.PhoneVerificationPageModule)
  },
  {
    path: 'pickup',
    loadChildren: () => import('./pages/pickup/pickup.module').then(m => m.PickupPageModule)
  },
  {
    path: 'preferences',
    loadChildren: () => import('./pages/preferences/preferences.module').then(m => m.PreferencesPageModule)
  },
  {
    path: 'recommended-price',
    loadChildren: () => import('./pages/recommended-price/recommended-price.module').then(m => m.RecommendedPricePageModule)
  },
  {
    path: 'ride-details/:id',
    loadChildren: () => import('./pages/ride-details/ride-details.module').then(m => m.RideDetailsPageModule)
  },
  {
    path: 'ride-details',
    loadChildren: () => import('./pages/ride-details/ride-details.module').then(m => m.RideDetailsPageModule)
  },
  {
    path: 'role-form',
    loadChildren: () => import('./pages/role-form/role-form.module').then(m => m.RoleFormPageModule)
  },
  {
    path: 'signup',
    loadChildren: () => import('./pages/signup/signup.module').then(m => m.SignupPageModule),
    canActivate: [redirectLoggedInToHome]
  },
  {
    path: 'signup-with-email',
    loadChildren: () => import('./pages/signup-with-email/signup-with-email.module').then(m => m.SignupWithEmailPageModule),
    canActivate: [redirectLoggedInToHome]
  },
  {
    path: 'stop-over',
    loadChildren: () => import('./pages/stop-over/stop-over.module').then(m => m.StopOverPageModule)
  },
  {
    path: 'total-passengers',
    loadChildren: () => import('./pages/total-passengers/total-passengers.module').then(m => m.TotalPassengersPageModule)
  },
  {
    path: 'verify-email',
    loadChildren: () => import('./pages/verify-email/verify-email.module').then(m => m.VerifyEmailPageModule)
  },
  {
    path: 'verify-id',
    loadChildren: () => import('./pages/verify-id/verify-id.module').then(m => m.VerifyIdPageModule),
    data: { enhancedVerification: true } // Enable new verification features
  },
  {
    path: 'verify-phone',
    loadChildren: () => import('./pages/verify-phone/verify-phone.module').then(m => m.VerifyPhonePageModule)
  },
  {
    path: 'stop-over-location',
    loadChildren: () => import('./pages/stop-over-location/stop-over-location.module').then(m => m.StopOverLocationPageModule)
  },
  {
    path: 'notifications-settings',
    loadChildren: () => import('./pages/notifications-settings/notifications-settings.module').then(m => m.NotificationsSettingsPageModule)
  },
  {
    path: 'network',
    loadComponent: () => import('./features/network/network.component').then(m => m.NetworkComponent),
    data: {
      animation: 'NetworkPage',
      backPath: '/tabs/profile'
    }
  },
  {
    path: 'safety',
    loadComponent: () => import('./features/safety/safety.component').then(m => m.SafetyComponent),
    data: { 
      animation: 'SafetyPage',
      backPath: '/tabs/profile'
    }
  },
  {
    path: 'trusted-network',
    loadChildren: () => import('./pages/trusted-network/trusted-network.module').then(m => m.TrustedNetworkPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'trusted-group-details/:id',
    loadChildren: () => import('./pages/trusted-group-details/trusted-group-details.module').then(m => m.TrustedGroupDetailsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'safety-preferences',
    loadComponent: () => import('./pages/safety-preferences/safety-preferences.page').then(m => m.SafetyPreferencesPage)
  },
  {
    path: 'emergency-contacts',
    loadComponent: () => import('./pages/emergency-contacts/emergency-contacts.page').then(m => m.EmergencyContactsPage)
  },
  {
    path: 'verify-school',
    redirectTo: '/tabs/profile',
    pathMatch: 'full'
  },
  {
    path: 'background-check',
    redirectTo: '/tabs/profile',
    pathMatch: 'full'
  },
  {
    path: 'manage-roles',
    redirectTo: '/tabs/profile',
    pathMatch: 'full'
  },
  {
    path: 'notification-settings',
    redirectTo: '/notifications-settings',
    pathMatch: 'full'
  },
  {
    path: 'payment-methods',
    loadChildren: () => import('./pages/payment-methods/payment-methods.module').then(m => m.PaymentMethodsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'privacy-settings',
    loadChildren: () => import('./pages/privacy-settings/privacy-settings.module').then(m => m.PrivacySettingsPageModule)
  },
  {
    path: 'privacy-policy',
    loadChildren: () => import('./pages/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyPageModule)
  },
  {
    path: 'language-settings',
    loadChildren: () => import('./pages/language-settings/language-settings.module').then(m => m.LanguageSettingsPageModule)
  },
  {
    path: 'calendar-settings',
    loadChildren: () => import('./pages/calendar-settings/calendar-settings.module').then(m => m.CalendarSettingsPageModule)
  },
  {
    path: 'help-support',
    loadChildren: () => import('./pages/help-support/help-support.module').then(m => m.HelpSupportPageModule)
  },
  {
    path: 'theme-settings',
    loadChildren: () => import('./pages/theme-settings/theme-settings.module').then(m => m.ThemeSettingsPageModule)
  },
  {
    path: 'create-ride',
    loadChildren: () => import('./pages/create-ride/create-ride.module').then(m => m.CreateRidePageModule)
  },
  {
    path: 'language-selection',
    redirectTo: '/tabs/profile',
    pathMatch: 'full'
  },
  {
    path: 'chat/:id',
    loadChildren: () => import('./pages/chat/chat.module').then(m => m.ChatPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'member-profile/:memberId',
    loadChildren: () => import('./pages/member-profile/member-profile.module').then(m => m.MemberProfilePageModule),
    canActivate: [AuthGuard]
  },
  // Fallback route for unknown paths
  {
    path: '**',
    redirectTo: 'login'
  }
];

// ----------------------------------
// App Routing Module
// ----------------------------------
@NgModule({
  // Configure the router with all the routes
  imports: [
    RouterModule.forRoot(routes, { 
      preloadingStrategy: PreloadAllModules,
      useHash: true  // Use HashLocationStrategy for better server compatibility
    }),
  ],
  // Exporting the router makes it available throughout the app
  exports: [RouterModule]
})
export class AppRoutingModule {}
