import { createAction, props } from '@ngrx/store';
import { Profile } from '../models/profile.model';


// Profile
export const profileGet = createAction(
  '[Edit Profile] Get Profile',
  props<{ id: string }>()
);

export const profileGetSuccess = createAction(
  '[Edit Profile API] Get Profile Success',
  props<{ profile: Profile }>()
);

export const profileGetFailure = createAction(
  '[Edit Profile API] Get Profile Failure',
  props<{ error: string }>()
);

// Profile All
export const profileGetAll = createAction(
  '[Edit Profile] Get All Profiles'
);

export const profileGetAllSuccess = createAction(
  '[Edit Profile API] Get All Profiles Success',
  props<{ profiles: Profile[] }>()
);

export const profileGetAllFailure = createAction(
  '[Edit Profile API] Get All Profiles Failure',
  props<{ error: string }>()
);

// Profile Upsert
export const profileUpsert = createAction(
  '[Edit Profile] Upsert Profile',
  props<{ data: Profile }>()
);

export const profileUpsertSuccess = createAction(
  '[Edit Profile API] Upsert Profile Success'
);

export const profileUpsertFailure = createAction(
  '[Edit Profile API] Upsert Profile Failure',
  props<{ error: string }>()
);

// Edit profile actions
export const loadUserProfile = createAction(
  '[Edit Profile] Load User Profile',
  props<{ userId: string }>()
);

export const loadUserProfileSuccess = createAction(
  '[Edit Profile API] Load User Profile Success',
  props<{ profile: Profile }>()
);

export const loadUserProfileFailure = createAction(
  '[Edit Profile API] Load User Profile Failure',
  props<{ error: string }>()
);

export const updateUserProfile = createAction(
  '[Edit Profile] Update User Profile',
  props<{ userId: string; profile: Profile }>()
);

export const updateUserProfileSuccess = createAction(
  '[Edit Profile API] Update User Profile Success',
  props<{ profile: Profile }>()
);

export const updateUserProfileFailure = createAction(
  '[Edit Profile API] Update User Profile Failure',
  props<{ error: string }>()
);

export const uploadProfilePhoto = createAction(
  '[Edit Profile] Upload Profile Photo',
  props<{ userId: string; photo: File }>()
);

export const uploadProfilePhotoSuccess = createAction(
  '[Edit Profile API] Upload Profile Photo Success',
  props<{ photoUrl: string }>()
);

export const uploadProfilePhotoFailure = createAction(
  '[Edit Profile API] Upload Profile Photo Failure',
  props<{ error: string }>()
);

export const updateUserRoles = createAction(
  '[Edit Profile] Update User Roles',
  props<{ userId: string; roles: string[] }>()
);

export const updateUserRolesSuccess = createAction(
  '[Edit Profile API] Update User Roles Success',
  props<{ roles: string[] }>()
);

export const updateUserRolesFailure = createAction(
  '[Edit Profile API] Update User Roles Failure',
  props<{ error: string }>()
);
