import { Actions, ofType, createEffect } from '@ngrx/effects';
import { catchError, exhaustMap, map, take, tap } from 'rxjs/operators';
import { User } from '@app/state/models/user';
import * as LoginFormActions from './login-form.actions';
import * as UserActions from '@app/state/user/user.actions';
import * as RideActions from '@app/state/ride/ride.actions';
import * as CarActions from '@app/state/car/car.actions';
import * as ProfileActions from '@app/state/edit-profile/edit-profile.actions';

import { Injectable } from '@angular/core';
import { LoginService } from '@app/services/login.service';
import { of } from 'rxjs';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as fromRoot from '@app/state';


@Injectable()
export class LoginFormEffects {
  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoginFormActions.login),
      map((action: { credentials: any }) => {
        const credentials = action.credentials;
        // Add the missing username property
        return {
          email: credentials.email,
          password: credentials.password,
          username: credentials.email // Use email as username by default
        };
      }),
      exhaustMap((auth: {email: string, password: string, username: string}) =>
        this.loginService.SignIn(auth).pipe(
          map(user => LoginFormActions.loginSuccess({ user })),
          catchError(error => of(LoginFormActions.loginFailure({ error })))
        )
      )
    )
  );

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoginFormActions.logout),
      map(action => action),
      exhaustMap(() =>
        this.loginService.Logout().pipe(
          map(() => LoginFormActions.logoutSuccess()),
          catchError((error: any) => of(LoginFormActions.logoutFailure(error)))
        )
      )
    )
  );

  setLoggedInBoolean$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LoginFormActions.loginSuccess),
        tap((action: { user: any }) => {
          this.store.dispatch(UserActions.userUpsertSuccess({ user: action.user }));
          this.store.dispatch(UserActions.userGet({ id: action.user.uid }));
          this.store.dispatch(ProfileActions.loadUserProfile({ userId: action.user.uid }));
          this.store.dispatch(ProfileActions.loadUserProfile({ userId: action.user.uid }));
          this.store.dispatch(RideActions.rideGetAll());
          this.store.dispatch(CarActions.getAllCars());
          this.router.navigate(['/tabs/home']);
        }),
        take(1)
      ),
    { dispatch: false }
  );

  checkVerifiedEmail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LoginFormActions.loginSuccess),
        tap((user: any) => this.loginService.checkVerifiedEmail(user))
      ),
    { dispatch: false }
  );

  // loginRedirect$ = createEffect(
  //   () =>
  //     this.actions$.pipe(
  //       ofType(LoginFormPageActions.loginRedirect, LoginFormPageActions.logout),
  //       tap(authed => {
  //         this.router.navigate(['/login']);
  //       })
  //     ),
  //   { dispatch: false }
  // );


  constructor(
    private actions$: Actions,
    private loginService: LoginService,
    private router: Router,
    private store: Store<fromRoot.State>,
  ) { }
}
