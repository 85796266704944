import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HTTP_INTERCEPTORS, HttpErrorResponse } from '@angular/common/http';
import { Observable, from, throwError, BehaviorSubject } from 'rxjs';
import { switchMap, take, filter, catchError, finalize, retry } from 'rxjs/operators';
import { AuthService } from '../core/services/auth.service';
import { Router } from '@angular/router';
import { ErrorHandlerService } from '../core/services/error-handler.service';
import { NetworkService } from './network.service';
import { ToastService } from './toast.service';

/**
 * Intercepts HTTP requests to add authentication tokens and handle auth errors
 */
@Injectable({ providedIn: 'root' })
export class AuthTokenHttpInterceptor implements HttpInterceptor {
    private isRefreshing = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    private readonly MAX_RETRIES = 2;

    constructor(
        private authService: AuthService,
        private router: Router,
        private errorHandler: ErrorHandlerService,
        private networkService: NetworkService,
        private toastService: ToastService
    ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Skip authentication for public endpoints
        if (this.isPublicEndpoint(req.url)) {
            return next.handle(req);
        }

        // Get the token synchronously for better performance
        const token = this.authService.getToken();
        if (token) {
            req = this.addTokenToRequest(req, token);
        }
        
        return next.handle(req).pipe(
            // Auto-retry for network issues or server errors
            retry({
                count: this.MAX_RETRIES,
                delay: (error, retryCount) => {
                    // Only retry for network issues or 5xx server errors
                    const isNetworkError = this.networkService.isNetworkError(error);
                    const isServerError = error instanceof HttpErrorResponse && 
                                          error.status >= 500 && 
                                          error.status < 600;
                    
                    if ((isNetworkError || isServerError) && retryCount <= this.MAX_RETRIES) {
                        // Use exponential backoff delay
                        return this.networkService.getRetryDelayStream(retryCount);
                    }
                    
                    // Don't retry for other errors
                    return throwError(() => error);
                }
            }),
            catchError((error: HttpErrorResponse) => {
                if (error.status === 401) {
                    // Token expired or invalid - attempt to refresh
                    return this.handle401Error(req, next);
                } else if (error.status === 403) {
                    // For 403 errors (Forbidden), redirect to unauthorized page
                    this.router.navigate(['/unauthorized']);
                    
                    // Let error handler service process it
                    this.errorHandler.handleError(error);
                    return throwError(() => error);
                } else {
                    // For all other errors, let the error handler service process it
                    this.errorHandler.handleError(error);
                    return throwError(() => error);
                }
            })
        );
    }

    private addTokenToRequest(req: HttpRequest<any>, token: string): HttpRequest<any> {
        // Clone the request and add the token header along with security headers
        return req.clone({
            setHeaders: {
                Authorization: `Bearer ${token}`,
                // Add security headers
                'X-Content-Type-Options': 'nosniff',
                'X-Frame-Options': 'DENY',
                'X-XSS-Protection': '1; mode=block'
            }
        });
    }

    private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!this.isRefreshing) {
            this.isRefreshing = true;
            this.refreshTokenSubject.next(null);

            // Try to refresh the token
            return from(this.refreshToken()).pipe(
                switchMap(success => {
                    this.isRefreshing = false;
                    
                    if (success) {
                        // Get the new token
                        const newToken = this.authService.getToken();
                        this.refreshTokenSubject.next(newToken);
                        
                        // Retry the request with the new token
                        return next.handle(this.addTokenToRequest(request, newToken));
                    } else {
                        // Token refresh failed, redirect to login
                        this.handleSessionExpired();
                        return throwError(() => new Error('Session expired'));
                    }
                }),
                catchError(error => {
                    this.isRefreshing = false;
                    
                    // Handle the refresh token error
                    this.errorHandler.handleError(error);
                    this.handleSessionExpired();
                    
                    return throwError(() => error);
                }),
                finalize(() => {
                    this.isRefreshing = false;
                })
            );
        } else {
            // Wait for the token refresh to complete
            return this.refreshTokenSubject.pipe(
                filter(token => token != null),
                take(1),
                switchMap(token => {
                    return next.handle(this.addTokenToRequest(request, token));
                })
            );
        }
    }

    private async refreshToken(): Promise<boolean> {
        try {
            // Implement a token refresh logic that works with the current AuthService
            // We'll just request a new token
            return true; // Simplified for this example
        } catch (error) {
            this.errorHandler.handleError(error);
            return false;
        }
    }

    private handleSessionExpired(): void {
        // Logout the user
        this.authService.logout();
        
        // Navigate to login with return URL
        this.router.navigate(['/login'], { 
            queryParams: { 
                returnUrl: this.router.url, 
                reason: 'session_expired' 
            }
        });
        
        // Show a message
        this.toastService.showError('Your session has expired. Please sign in again.');
    }

    private isPublicEndpoint(url: string): boolean {
        // Define public endpoints that don't need authentication
        const publicUrls = [
            '/login',
            '/register',
            '/signup',
            '/forgot-password',
            '/reset-password',
            '/verify-email',
            '/assets/',
            '/firebase-messaging-config.json',
            '/api/public/'
        ];
        
        return publicUrls.some(publicUrl => url.includes(publicUrl));
    }
}

export const AuthTokenHttpInterceptorProvider = {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthTokenHttpInterceptor,
    multi: true
} 