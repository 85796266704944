import { NgModule } from '@angular/core';
import { pendingUntilEvent, PendingTasks } from './rxjs-compat';

/**
 * This module provides mock implementations of Angular Fire dependencies
 * that are not available in the current version of Angular.
 */
@NgModule({
  providers: [
    {
      provide: 'pendingUntilEvent',
      useValue: pendingUntilEvent
    },
    {
      provide: 'PendingTasks',
      useClass: PendingTasks
    }
  ]
})
export class AngularFireCompatModule { } 