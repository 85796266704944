import { Profile as ProfileInterface } from './profile.model';

export class ProfileClass implements ProfileInterface {
    id: string;
    uid: string;
    email: string;
    displayName?: string;
    firstName?: string;
    lastName?: string;
    phoneNumber?: string;
    photoURL?: string;
    birthDate?: string;
    gender?: string;
    address?: string | {
        city: string;
        state: string;
        country: string;
        zip: string;
    };
    bio?: string;
    role?: string;
    isVerified?: boolean;
    verifications?: {
        email?: boolean;
        phone?: boolean;
        government?: boolean;
        background?: boolean;
    };
    settings?: {
        notifications?: boolean;
        darkMode?: boolean;
        language?: string;
    };
    createdAt?: string;
    updatedAt?: string;
    activeStatus?: 'online' | 'offline' | 'away';
    lastActive?: string;
    trustedContacts?: string[];
    verificationStatus: {
        idVerified: boolean;
        phoneVerified: boolean;
        emailVerified: boolean;
        schoolVerified?: boolean;
    };
    schoolEmail?: string;
    schoolEmailVerified: boolean;
    backgroundCheckConsent: boolean;
    backgroundCheckStatus: 'not_started' | 'pending' | 'approved' | 'rejected';

    // Additional properties used in the application but not in the interface
    userId?: string;
    phone?: string;
    userPhone?: string;
    userEmail?: string;
    preferences?: {
        smoking?: boolean;
        pets?: boolean;
        music?: boolean;
        conversation?: boolean;
    };
    currentUserCar?: any;
    selectedCar?: any;
    rating?: number;
    reviews?: any[];

    // Privacy settings properties
    privacySettings?: Array<{
        id: string;
        title: string;
        description: string;
        enabled: boolean;
        icon: string;
        category: 'data' | 'sharing' | 'notifications' | 'location';
    }>;
    deletionRequested?: boolean;
    deletionRequestDate?: string;

    constructor() {
        this.id = '';
        this.uid = '';
        this.email = '';
        this.firstName = '';
        this.lastName = '';
        this.displayName = '';
        this.bio = '';
        this.address = '';
        this.verificationStatus = {
            idVerified: false,
            phoneVerified: false,
            emailVerified: false,
            schoolVerified: false
        };
        this.backgroundCheckStatus = 'not_started';
        this.backgroundCheckConsent = false;
        this.schoolEmailVerified = false;
        this.trustedContacts = [];
        this.preferences = {};
        this.privacySettings = [];
        this.deletionRequested = false;
        
        // For backward compatibility
        this.userId = '';
    }
}

// No need to redefine Profile interface here
// Use the one from profile.model.ts instead
export type ProfilePropsArray = Array<keyof ProfileInterface>;
