import { Injectable } from '@angular/core';
import { ChatThread, ChatMessage, Notification } from '@app/models/chat.model';
import firebase from 'firebase/compat/app';

@Injectable({
  providedIn: 'root'
})
export class MockDataService {
  // IDs for various virtual assistants/system accounts
  private readonly WELCOME_BOT_ID = 'welcome-bot';
  private readonly RIDE_ASSIST_ID = 'ride-assistant';
  private readonly SAFETY_BOT_ID = 'safety-bot';
  private readonly SUPPORT_ID = 'support-team';

  constructor() {}

  /**
   * Generates a welcome thread with introductory information
   * @param userId Current user's ID
   * @param userName Current user's name
   * @returns A ChatThread with welcome messages
   */
  generateWelcomeThread(userId: string, userName: string): ChatThread {
    const threadId = `welcome-${userId}`;
    const timestamp = Date.now();
    
    return {
      id: threadId,
      participants: [userId, this.WELCOME_BOT_ID],
      participantNames: {
        [userId]: userName,
        [this.WELCOME_BOT_ID]: 'Welcome Guide'
      },
      participantAvatars: {
        [userId]: '',
        [this.WELCOME_BOT_ID]: 'assets/icon/welcome-icon.svg'
      },
      lastMessage: {
        content: 'Welcome to HereThere! I\'m here to help you get started.',
        timestamp: timestamp,
        senderId: this.WELCOME_BOT_ID
      },
      updatedAt: timestamp,
      unreadCounts: {
        [userId]: 3 // Show 3 unread messages to draw attention
      },
      metadata: {
        online: true,
        rideInfo: {
          type: 'onboarding',
          distance: 'N/A'
        }
      }
    };
  }

  /**
   * Generates a safety information thread
   * @param userId Current user's ID
   * @param userName Current user's name
   * @returns A ChatThread with safety information
   */
  generateSafetyThread(userId: string, userName: string): ChatThread {
    const threadId = `safety-${userId}`;
    const timestamp = Date.now();
    
    return {
      id: threadId,
      participants: [userId, this.SAFETY_BOT_ID],
      participantNames: {
        [userId]: userName,
        [this.SAFETY_BOT_ID]: 'Safety Guide'
      },
      participantAvatars: {
        [userId]: '',
        [this.SAFETY_BOT_ID]: 'assets/icon/shield-icon.svg'
      },
      lastMessage: {
        content: 'Your safety is our priority. Let me show you our safety features.',
        timestamp: timestamp,
        senderId: this.SAFETY_BOT_ID
      },
      updatedAt: timestamp,
      unreadCounts: {
        [userId]: 3 // Show 3 unread messages to draw attention
      },
      metadata: {
        online: true,
        rideInfo: {
          type: 'safety',
          distance: 'N/A'
        }
      }
    };
  }

  /**
   * Generates a ride assistance thread
   * @param userId Current user's ID
   * @param userName Current user's name
   * @returns A ChatThread with ride assistance information
   */
  generateRideAssistThread(userId: string, userName: string): ChatThread {
    const threadId = `ride-assist-${userId}`;
    const timestamp = Date.now();
    
    return {
      id: threadId,
      participants: [userId, this.RIDE_ASSIST_ID],
      participantNames: {
        [userId]: userName,
        [this.RIDE_ASSIST_ID]: 'Ride Assistant'
      },
      participantAvatars: {
        [userId]: '',
        [this.RIDE_ASSIST_ID]: 'assets/icon/car-icon.svg'
      },
      lastMessage: {
        content: 'Need help finding or offering a ride? I can help you get started.',
        timestamp: timestamp,
        senderId: this.RIDE_ASSIST_ID
      },
      updatedAt: timestamp,
      unreadCounts: {
        [userId]: 4 // Show 4 unread messages to draw attention
      },
      metadata: {
        online: true,
        rideInfo: {
          type: 'help',
          distance: 'N/A'
        }
      }
    };
  }

  /**
   * Generates welcome messages for the welcome thread
   * @param userId Current user's ID
   * @returns An array of chat messages with welcome information
   */
  generateWelcomeMessages(userId: string): ChatMessage[] {
    // Use numeric timestamps instead of Date objects to avoid Firebase conversion issues
    const now = Date.now();
    const messages: ChatMessage[] = [];
    
    // First message - 2 minutes ago
    messages.push({
      senderId: this.WELCOME_BOT_ID,
      content: '👋 Welcome to HereThere! I\'m your personal guide to help you get started with our ridesharing community.',
      timestamp: now - 2 * 60 * 1000,
      read: { [userId]: false, [this.WELCOME_BOT_ID]: true },
      type: 'text'
    });
    
    // Second message - 1 minute ago
    messages.push({
      senderId: this.WELCOME_BOT_ID,
      content: 'HereThere connects you with trusted drivers and passengers in your community. Our app makes ridesharing safe, easy, and environmentally friendly!',
      timestamp: now - 1 * 60 * 1000,
      read: { [userId]: false, [this.WELCOME_BOT_ID]: true },
      type: 'text'
    });
    
    // Third message - just now
    messages.push({
      senderId: this.WELCOME_BOT_ID,
      content: '🚗 Here\'s what you can do with HereThere:\n\n• Find rides to your destination\n• Offer rides and share your journey\n• Connect with people you trust\n• Track journeys in real-time\n• Set up recurring rides for regular commutes\n\nCheck out your other message threads for specific guides on safety and finding/offering rides!',
      timestamp: now,
      read: { [userId]: false, [this.WELCOME_BOT_ID]: true },
      type: 'text'
    });
    
    return messages;
  }
  
  /**
   * Generates safety information messages
   * @param userId Current user's ID
   * @returns An array of chat messages with safety information
   */
  generateSafetyMessages(userId: string): ChatMessage[] {
    // Use numeric timestamps instead of Date objects to avoid Firebase conversion issues
    const now = Date.now();
    const messages: ChatMessage[] = [];
    
    // First message
    messages.push({
      senderId: this.SAFETY_BOT_ID,
      content: '🔒 Your safety is our #1 priority at HereThere! We\'ve built several key safety features to give you peace of mind.',
      timestamp: now - 3 * 60 * 1000,
      read: { [userId]: false, [this.SAFETY_BOT_ID]: true },
      type: 'text'
    });
    
    // Second message
    messages.push({
      senderId: this.SAFETY_BOT_ID,
      content: 'Our safety features include:\n\n✅ ID Verification - All drivers and riders must verify their identity\n📱 Real-time tracking - Share your journey with trusted contacts\n🆘 Emergency button - One-tap access to emergency assistance\n🔑 Safety codes - Unique codes to confirm the right driver before entering',
      timestamp: now - 2 * 60 * 1000,
      read: { [userId]: false, [this.SAFETY_BOT_ID]: true },
      type: 'text'
    });
    
    // Third message
    messages.push({
      senderId: this.SAFETY_BOT_ID,
      content: 'To set up your safety preferences, tap the "Profile" tab, then select "Safety & Preferences". There you can add trusted contacts, set up emergency information, and customize your safety settings. We recommend doing this right away!',
      timestamp: now,
      read: { [userId]: false, [this.SAFETY_BOT_ID]: true },
      type: 'text'
    });
    
    return messages;
  }
  
  /**
   * Generates ride assistance messages
   * @param userId Current user's ID
   * @returns An array of chat messages with ride assistance information
   */
  generateRideAssistMessages(userId: string): ChatMessage[] {
    // Use numeric timestamps instead of Date objects to avoid Firebase conversion issues
    const now = Date.now();
    const messages: ChatMessage[] = [];
    
    // First message
    messages.push({
      senderId: this.RIDE_ASSIST_ID,
      content: '🚘 Ready to start using HereThere for your journeys? Let me show you how to get going!',
      timestamp: now - 4 * 60 * 1000,
      read: { [userId]: false, [this.RIDE_ASSIST_ID]: true },
      type: 'text'
    });
    
    // Second message
    messages.push({
      senderId: this.RIDE_ASSIST_ID,
      content: '🔍 Finding a ride is easy:\n\n1. Tap "Find Ride" on the home screen\n2. Enter your pickup location and destination\n3. Choose your travel date and time\n4. Browse available rides and select one\n5. Confirm your booking and pay securely through the app',
      timestamp: now - 3 * 60 * 1000,
      read: { [userId]: false, [this.RIDE_ASSIST_ID]: true },
      type: 'text'
    });
    
    // Third message
    messages.push({
      senderId: this.RIDE_ASSIST_ID,
      content: '🚗 Want to offer a ride?\n\n1. Tap "Offer Ride" on the home screen\n2. Enter your route details and departure time\n3. Set your available seats and price per seat\n4. Add any preferences (pet-friendly, luggage space, etc.)\n5. Review and publish your ride offer',
      timestamp: now - 2 * 60 * 1000,
      read: { [userId]: false, [this.RIDE_ASSIST_ID]: true },
      type: 'text'
    });
    
    // Fourth message
    messages.push({
      senderId: this.RIDE_ASSIST_ID,
      content: '💡 Pro tips for a great experience:\n\n• Complete your profile with a photo to build trust\n• Add your frequent destinations to save time\n• Set up your payment methods in advance\n• Leave reviews after rides to help others\n• Use the messaging feature to coordinate with your driver/passengers\n\nNeed help at any time? Just reply to this message and our support team will assist you!',
      timestamp: now,
      read: { [userId]: false, [this.RIDE_ASSIST_ID]: true },
      type: 'text'
    });
    
    return messages;
  }
  
  /**
   * Generates initial welcome notifications
   * @param userId Current user's ID
   * @returns Array of welcome notifications
   */
  generateWelcomeNotifications(userId: string): Notification[] {
    // Use numeric timestamps instead of Date objects to avoid Firebase conversion issues
    const now = Date.now();
    const notifications: Notification[] = [];
    
    // Welcome notification
    notifications.push({
      userId: userId,
      title: 'Welcome to HereThere!',
      message: 'Start exploring our ridesharing community.',
      timestamp: now - 5 * 60 * 1000,
      read: false,
      type: 'system',
      actionUrl: '/tabs/home'
    });
    
    // Profile completion notification
    notifications.push({
      userId: userId,
      title: 'Complete Your Profile',
      message: 'Add your details to help others recognize you.',
      timestamp: now - 4 * 60 * 1000,
      read: false,
      type: 'system',
      actionUrl: '/tabs/profile',
      metadata: {
        priority: 'high'
      }
    });
    
    // Safety features notification
    notifications.push({
      userId: userId,
      title: 'Safety Features Available',
      message: 'Explore our safety settings for secure rides.',
      timestamp: now - 3 * 60 * 1000,
      read: false,
      type: 'system',
      actionUrl: '/safety-preferences'
    });
    
    // First ride notification
    notifications.push({
      userId: userId,
      title: 'Find Your First Ride',
      message: 'Start by searching for available rides in your area.',
      timestamp: now,
      read: false,
      type: 'ride',
      actionUrl: '/tabs/find'
    });
    
    // New messages notification
    notifications.push({
      userId: userId,
      title: 'New Welcome Messages',
      message: 'Check your inbox for getting started information.',
      timestamp: now - 1 * 60 * 1000,
      read: false,
      type: 'message',
      actionUrl: '/tabs/messages',
      metadata: {
        threadId: `welcome-${userId}`
      }
    });
    
    return notifications;
  }

  // Create the mock chat threads
  private createMockChatThreads(): ChatThread[] {
    const mockThreads: ChatThread[] = [];
    
    // Thread 1 - Driver to passenger chat
    const timestamp1 = Date.now();
    const thread1: ChatThread = {
      id: '1',
      participants: ['currentUser', 'user1'],
      participantNames: {
        'currentUser': 'You',
        'user1': 'John Smith'
      },
      participantAvatars: {
        'currentUser': 'assets/avatars/user.jpg',
        'user1': 'assets/avatars/user1.jpg'
      },
      lastMessage: {
        content: 'Yes, I\'ll be there soon.',
        timestamp: timestamp1,
        senderId: 'currentUser'
      },
      updatedAt: timestamp1,
      unreadCounts: {
        'currentUser': 0,
        'user1': 1
      },
      metadata: {
        online: true,
        rideInfo: {
          type: 'driver',
          distance: '5.2'
        }
      }
    };
    mockThreads.push(thread1);
    
    // Thread 2 - Passenger to driver chat
    const timestamp2 = Date.now() - 10 * 60 * 1000; // 10 mins ago
    const thread2: ChatThread = {
      id: '2',
      participants: ['currentUser', 'user2'],
      participantNames: {
        'currentUser': 'You',
        'user2': 'Sarah Johnson'
      },
      participantAvatars: {
        'currentUser': 'assets/avatars/user.jpg',
        'user2': 'assets/avatars/user2.jpg'
      },
      lastMessage: {
        content: 'I\'m at the pickup point',
        timestamp: timestamp2,
        senderId: 'user2'
      },
      updatedAt: timestamp2,
      unreadCounts: {
        'currentUser': 1,
        'user2': 0
      },
      metadata: {
        online: false,
        rideInfo: {
          type: 'passenger',
          distance: '3.1'
        }
      }
    };
    mockThreads.push(thread2);
    
    // Thread 3 - Another passenger conversation
    const timestamp3 = Date.now() - 3 * 60 * 60 * 1000; // 3 hours ago
    const thread3: ChatThread = {
      id: '3',
      participants: ['currentUser', 'user3'],
      participantNames: {
        'currentUser': 'You',
        'user3': 'Michael Brown'
      },
      participantAvatars: {
        'currentUser': 'assets/avatars/user.jpg',
        'user3': 'assets/avatars/user3.jpg'
      },
      lastMessage: {
        content: 'Thanks for the ride!',
        timestamp: timestamp3,
        senderId: 'user3'
      },
      updatedAt: timestamp3,
      unreadCounts: {
        'currentUser': 0,
        'user3': 0
      }
    };
    mockThreads.push(thread3);
    
    return mockThreads;
  }

  // Create mock messages for thread 1
  private createMockMessagesForThread1(): ChatMessage[] {
    const messages: ChatMessage[] = [];
    const now = Date.now();
    
    // Message 1
    messages.push({
      id: '1-1',
      senderId: 'user1',
      content: 'Hey, I\'m waiting at the mall entrance. Are you nearby?',
      timestamp: now - 2 * 60 * 1000, // 2 minutes ago
      read: {
        'currentUser': true,
        'user1': true
      },
      type: 'text'
    });
    
    // Message 2
    messages.push({
      id: '1-2',
      senderId: 'currentUser',
      content: 'I\'m about 5 minutes away. There\'s some traffic.',
      timestamp: now - 1 * 60 * 1000, // 1 minute ago
      read: {
        'currentUser': true,
        'user1': true
      },
      type: 'text'
    });
    
    // Message 3
    messages.push({
      id: '1-3',
      senderId: 'currentUser',
      content: 'Yes, I\'ll be there soon.',
      timestamp: now, // now
      read: {
        'currentUser': true,
        'user1': false
      },
      type: 'text'
    });
    
    return messages;
  }
  
  // Create mock messages for thread 2
  private createMockMessagesForThread2(): ChatMessage[] {
    const messages: ChatMessage[] = [];
    const now = Date.now();
    
    // Message 1
    messages.push({
      id: '2-1',
      senderId: 'currentUser',
      content: 'Hi Sarah, I\'ll be your driver today.',
      timestamp: now - 3 * 60 * 1000, // 3 minutes ago
      read: {
        'currentUser': true,
        'user2': true
      },
      type: 'text'
    });
    
    // Message 2
    messages.push({
      id: '2-2',
      senderId: 'user2',
      content: 'Great! I\'ll be ready in 5 minutes.',
      timestamp: now - 2 * 60 * 1000, // 2 minutes ago
      read: {
        'currentUser': true,
        'user2': true
      },
      type: 'text'
    });
    
    // Message 3
    messages.push({
      id: '2-3',
      senderId: 'user2',
      content: 'I\'m at the pickup point',
      timestamp: now, // now
      read: {
        'currentUser': false,
        'user2': true
      },
      type: 'text'
    });
    
    return messages;
  }
  
  // Create mock messages for thread 3
  private createMockMessagesForThread3(): ChatMessage[] {
    const messages: ChatMessage[] = [];
    const now = Date.now();
    
    // Message 1
    messages.push({
      id: '3-1',
      senderId: 'currentUser',
      content: 'I\'ve arrived at your location.',
      timestamp: now - 4 * 60 * 1000, // 4 minutes ago
      read: {
        'currentUser': true,
        'user3': true
      },
      type: 'text'
    });
    
    // Message 2
    messages.push({
      id: '3-2',
      senderId: 'user3',
      content: 'Coming down now!',
      timestamp: now - 3 * 60 * 1000, // 3 minutes ago
      read: {
        'currentUser': true,
        'user3': true
      },
      type: 'text'
    });
    
    // Message 3
    messages.push({
      id: '3-3',
      senderId: 'currentUser',
      content: 'We\'ve arrived at your destination.',
      timestamp: now - 2 * 60 * 1000, // 2 minutes ago
      read: {
        'currentUser': true,
        'user3': true
      },
      type: 'text'
    });
    
    // Message 4
    messages.push({
      id: '3-4',
      senderId: 'user3',
      content: 'Thanks for the ride!',
      timestamp: now, // now
      read: {
        'currentUser': true,
        'user3': true
      },
      type: 'text'
    });
    
    return messages;
  }

  // Create mock notifications
  private createMockNotifications(): Notification[] {
    const notifications: Notification[] = [];
    const now = Date.now();
    
    // Notification 1
    notifications.push({
      id: '1',
      userId: 'currentUser',
      title: 'New Ride Request',
      message: 'You have a new ride request from John Smith',
      timestamp: now - 5 * 60 * 1000, // 5 minutes ago
      read: false,
      type: 'ride',
      actionUrl: '/tabs/inbox'
    });
    
    // Notification 2
    notifications.push({
      id: '2',
      userId: 'currentUser',
      title: 'Payment Received',
      message: 'You received $15.50 for your ride with Sarah Johnson',
      timestamp: now - 4 * 60 * 1000, // 4 minutes ago
      read: true,
      type: 'payment',
      actionUrl: '/tabs/payments'
    });
    
    // Notification 3 (including metadata)
    notifications.push({
      id: '3',
      userId: 'currentUser',
      title: 'Ride Completed',
      message: 'Your ride with Michael Brown has been completed',
      timestamp: now - 3 * 60 * 1000, // 3 minutes ago
      read: true,
      type: 'ride',
      actionUrl: '/tabs/history',
      metadata: {
        rideId: '123',
        amount: 23.50,
        distance: '15.2'
      }
    });
    
    // Notification 4
    notifications.push({
      id: '4',
      userId: 'currentUser',
      title: 'System Maintenance',
      message: 'HereThere will be down for maintenance from 2-4 AM tomorrow',
      timestamp: now, // now
      read: false,
      type: 'system'
    });
    
    // Notification 5
    notifications.push({
      id: '5',
      userId: 'currentUser',
      title: 'New Message',
      message: 'You have a new message from John Smith',
      timestamp: now - 1 * 60 * 1000, // 1 minute ago
      read: false,
      type: 'message',
      actionUrl: '/tabs/chat/1'
    });
    
    return notifications;
  }
} 