import { createReducer, on } from '@ngrx/store';
import { LocationData } from '@app/models/ride.model';
import * as JourneyActions from './journey.actions';

export const journeyFeatureKey = 'journey';

export interface State {
  // Location streams
  pickupLocation: LocationData | null;
  dropOffLocation: LocationData | null;
  routeData: any;

  // Date and journey type
  selectedDate: string;
  selectedReturnDate: string;
  journeyType: 'oneWay' | 'roundTrip';

  // Ride preferences
  passengerCount: number;
  selectedLuggage: string;
  rideOptions: { pets: boolean; smoking: boolean; wheelchair: boolean };

  // View state for location selection
  locationSelectionMode: string;
  isEditingRide: boolean;

  // Current tab tracking
  currentTab: string;

  // Ride details
  selectedRideDetails: any;
  findRideDetails: any;

  // Legacy properties needed by existing components
  selectedJourneyForEdit: any;
  selectedLocation: any;
  onwardRideDetails: any;
  returnRideDetails: any;
  stopovers: any[];
  rideDirection: string;
  rideType: string;
  totalFare: string;
  _journey: boolean; // Journey flag
  
  // Additional properties needed by components
  bookRideConfirmation: boolean;
  bookedRides: any[];
  offeredRides: any[];
}

export const initialState: State = {
  // Location streams
  pickupLocation: null,
  dropOffLocation: null,
  routeData: null,

  // Date and journey type
  selectedDate: '',
  selectedReturnDate: '',
  journeyType: 'oneWay',

  // Ride preferences
  passengerCount: 1,
  selectedLuggage: 'medium',
  rideOptions: {
    pets: false,
    smoking: false,
    wheelchair: false
  },

  // View state for location selection
  locationSelectionMode: '',
  isEditingRide: false,

  // Current tab tracking
  currentTab: 'home',

  // Ride details
  selectedRideDetails: {},
  findRideDetails: {},

  // Legacy properties needed by existing components
  selectedJourneyForEdit: {},
  selectedLocation: null,
  onwardRideDetails: { instantBooking: true },
  returnRideDetails: { instantBooking: true },
  stopovers: [],
  rideDirection: 'onward',
  rideType: 'offer',
  totalFare: '',
  _journey: true, // Journey flag
  
  // Additional properties needed by components
  bookRideConfirmation: false,
  bookedRides: [],
  offeredRides: []
};

export const reducer = createReducer(
  initialState,

  // Location Actions
  on(JourneyActions.setPickupLocation, (state, { location }) => ({
    ...state,
    pickupLocation: location
  })),

  on(JourneyActions.setDropOffLocation, (state, { location }) => ({
    ...state,
    dropOffLocation: location
  })),

  on(JourneyActions.clearLocations, (state) => ({
    ...state,
    pickupLocation: null,
    dropOffLocation: null
  })),

  on(JourneyActions.setRouteData, (state, { data }) => ({
    ...state,
    routeData: data
  })),

  // Date and Journey Type Actions
  on(JourneyActions.setSelectedDate, (state, { date }) => ({
    ...state,
    selectedDate: date
  })),

  on(JourneyActions.setSelectedReturnDate, (state, { date }) => ({
    ...state,
    selectedReturnDate: date
  })),

  on(JourneyActions.setJourneyType, (state, { journeyType }) => ({
    ...state,
    journeyType
  })),

  // Ride Preferences Actions
  on(JourneyActions.setPassengerCount, (state, { count }) => ({
    ...state,
    passengerCount: count
  })),

  on(JourneyActions.setSelectedLuggage, (state, { luggage }) => ({
    ...state,
    selectedLuggage: luggage
  })),

  on(JourneyActions.setRideOptions, (state, { options }) => ({
    ...state,
    rideOptions: { 
      pets: options.pets,
      smoking: options.smoking,
      wheelchair: options.wheelchair
    }
  })),

  // UI State Actions
  on(JourneyActions.setLocationSelectionMode, (state, { mode }) => ({
    ...state,
    locationSelectionMode: mode
  })),

  on(JourneyActions.setIsEditingRide, (state, { isEditing }) => ({
    ...state,
    isEditingRide: isEditing
  })),

  on(JourneyActions.setCurrentTab, (state, { tab }) => ({
    ...state,
    currentTab: tab
  })),

  // Ride Details Actions
  on(JourneyActions.setSelectedRideDetails, (state, { details }) => ({
    ...state,
    selectedRideDetails: details
  })),

  on(JourneyActions.setFindRideDetails, (state, { details }) => ({
    ...state,
    findRideDetails: details
  })),

  on(JourneyActions.setJourneyFlag, (state, { isJourney }) => ({
    ...state,
    _journey: isJourney
  })),

  // Legacy Properties Actions
  on(JourneyActions.setSelectedJourneyForEdit, (state, { journey }) => ({
    ...state,
    selectedJourneyForEdit: journey
  })),

  on(JourneyActions.setSelectedLocation, (state, { location }) => ({
    ...state,
    selectedLocation: location
  })),

  on(JourneyActions.setOnwardRideDetails, (state, { details }) => ({
    ...state,
    onwardRideDetails: details
  })),

  on(JourneyActions.setReturnRideDetails, (state, { details }) => ({
    ...state,
    returnRideDetails: details
  })),

  on(JourneyActions.setStopovers, (state, { stopovers }) => ({
    ...state,
    stopovers
  })),

  on(JourneyActions.setRideDirection, (state, { direction }) => ({
    ...state,
    rideDirection: direction
  })),

  on(JourneyActions.setRideType, (state, { rideType }) => ({
    ...state,
    rideType
  })),

  on(JourneyActions.setTotalFare, (state, { fare }) => ({
    ...state,
    totalFare: fare
  })),

  on(JourneyActions.setBookRideConfirmation, (state, { confirmation }) => ({
    ...state,
    bookRideConfirmation: confirmation
  })),

  on(JourneyActions.setBookedRides, (state, { rides }) => ({
    ...state,
    bookedRides: rides
  })),

  on(JourneyActions.setOfferedRides, (state, { rides }) => ({
    ...state,
    offeredRides: rides
  })),

  on(JourneyActions.initializeRideDetails, (state) => ({
    ...state,
    onwardRideDetails: {
      instantBooking: true,
      middleSeat: false,
      passengerCount: 1
    },
    returnRideDetails: {
      instantBooking: true,
      middleSeat: false,
      passengerCount: 1
    }
  })),

  on(JourneyActions.resetAll, () => ({
    ...initialState
  }))
);
