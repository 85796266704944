import { Component, OnInit, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { GoogleMapsModule } from '@angular/google-maps';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { TranslateModule } from '@ngx-translate/core';

import { DriverService } from '../driver.service';
import { Driver } from '../../../shared/models/driver.model';
import { PageHeaderComponent } from '../../../shared/ui/page-header/page-header.component';
import { EmptyStateComponent } from '../../../shared/ui/empty-state/empty-state.component';
import { StarRatingComponent } from '../../../shared/ui/star-rating/star-rating.component';
import { DriverListSkeletonComponent } from './driver-list-skeleton/driver-list-skeleton.component';

@Component({
  selector: 'app-driver-list',
  templateUrl: './driver-list.component.html',
  styleUrls: ['./driver-list.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    IonicModule,
    GoogleMapsModule,
    PageHeaderComponent,
    EmptyStateComponent,
    StarRatingComponent,
    DriverListSkeletonComponent,
    TranslateModule
  ]
})
export class DriverListComponent implements OnInit, OnDestroy {
  drivers: Driver[] = [];
  filteredDrivers: Driver[] = [];
  isLoading = true;
  
  sortControl = new FormControl('rating');
  filterControl = new FormControl('all');
  
  private searchSubject = new Subject<string>();
  private subscriptions = new Subscription();

  readonly sortOptions = [
    { value: 'rating', label: 'Rating (High to Low)' },
    { value: 'rides', label: 'Most Rides' },
    { value: 'name', label: 'Name (A-Z)' }
  ];

  readonly filterOptions = [
    { value: 'all', label: 'All Drivers' },
    { value: 'available', label: 'Available Now' },
    { value: 'verified', label: 'Verified Only' }
  ];

  constructor(
    private router: Router,
    private driverService: DriverService
  ) {
    this.setupSearchSubscription();
  }

  ngOnInit(): void {
    // Load drivers
    this.subscriptions.add(
      this.driverService.getAvailableDrivers().subscribe(
        drivers => {
          this.drivers = drivers;
          this.applyFiltersAndSort();
          this.isLoading = false;
        }
      )
    );

    // Handle sort changes
    this.subscriptions.add(
      this.sortControl.valueChanges.subscribe(() => {
        this.applyFiltersAndSort();
      })
    );

    // Handle filter changes
    this.subscriptions.add(
      this.filterControl.valueChanges.subscribe(() => {
        this.applyFiltersAndSort();
      })
    );
  }

  /**
   * Setup search with debounce
   */
  private setupSearchSubscription(): void {
    this.subscriptions.add(
      this.searchSubject
        .pipe(
          debounceTime(300),
          distinctUntilChanged()
        )
        .subscribe(searchTerm => {
          this.applyFiltersAndSort(searchTerm);
        })
    );
  }

  /**
   * Handle search input
   */
  onSearch(event: any): void {
    this.searchSubject.next(event.target.value.toLowerCase());
  }

  /**
   * Apply filters and sorting
   */
  private applyFiltersAndSort(searchTerm: string = ''): void {
    let filtered = [...this.drivers];

    // Apply search filter
    if (searchTerm) {
      filtered = filtered.filter(driver => 
        `${driver.firstName} ${driver.lastName}`.toLowerCase().includes(searchTerm) ||
        driver.specializations.some(spec => spec.toLowerCase().includes(searchTerm))
      );
    }

    // Apply status filter
    switch (this.filterControl.value) {
      case 'available':
        filtered = filtered.filter(d => d.availability.immediatelyAvailable);
        break;
      case 'verified':
        filtered = filtered.filter(d => 
          d.verificationStatus === 'approved' && 
          d.backgroundCheckStatus === 'approved'
        );
        break;
    }

    // Apply sorting
    switch (this.sortControl.value) {
      case 'rating':
        filtered.sort((a, b) => b.averageRating - a.averageRating);
        break;
      case 'rides':
        filtered.sort((a, b) => b.completedRides - a.completedRides);
        break;
      case 'name':
        filtered.sort((a, b) => 
          `${a.firstName} ${a.lastName}`.localeCompare(`${b.firstName} ${b.lastName}`)
        );
        break;
    }

    this.filteredDrivers = filtered;
  }

  /**
   * View driver details
   */
  viewDriverProfile(driverId: string): void {
    this.router.navigate(['/drivers', driverId]);
  }

  /**
   * Get availability status text
   */
  getAvailabilityText(driver: Driver): string {
    if (driver.availability.immediatelyAvailable) {
      return 'Available Now';
    }
    return 'Not Available';
  }

  /**
   * Get availability status color
   */
  getAvailabilityColor(driver: Driver): string {
    return driver.availability.immediatelyAvailable ? 'success' : 'medium';
  }

  /**
   * Format completion rate
   */
  getCompletionRate(driver: Driver): string {
    if (!driver.totalRides) return '0%';
    const rate = (driver.completedRides / driver.totalRides) * 100;
    return `${Math.round(rate)}%`;
  }

  /**
   * Check if driver is highly rated
   */
  isHighlyRated(driver: Driver): boolean {
    return driver.averageRating >= 4.5;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}