import { Component, OnInit, OnDestroy, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { Subscription } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';

import { RideSchedulingService } from '../ride-scheduling.service';
import { RideSchedule } from '../../../shared/models/ride.model';
import { SharedModule } from '@app/shared/shared.module';
import { PageHeaderComponent } from '../../../shared/ui/page-header/page-header.component';

@Component({
  selector: 'app-ride-history',
  templateUrl: './ride-history.component.html',
  styleUrls: ['./ride-history.component.scss'],
  standalone: true,
  schemas: [NO_ERRORS_SCHEMA],
  imports: [
    CommonModule,
    IonicModule,
    RouterModule,
    FormsModule,
    SharedModule,
    TranslateModule,
    PageHeaderComponent
  ]
})
export class RideHistoryComponent implements OnInit, OnDestroy {
  rides: RideSchedule[] = [];
  filteredRides: RideSchedule[] = [];
  selectedFilter: 'all' | 'completed' | 'cancelled' = 'all';
  selectedMonth: Date | null = null;
  isLoading = true;

  private subscriptions = new Subscription();

  constructor(
    private router: Router,
    private rideService: RideSchedulingService
  ) {}

  ngOnInit(): void {
    this.loadRideHistory();
  }

  /**
   * Load ride history
   */
  private loadRideHistory(): void {
    // TODO: Implement pagination
    this.isLoading = true;
    this.subscriptions.add(
      this.rideService.getRideHistory().subscribe(
        rides => {
          this.rides = rides;
          this.applyFilters();
          this.isLoading = false;
        },
        error => {
          console.error('Error loading ride history:', error);
          this.isLoading = false;
        }
      )
    );
  }

  /**
   * Apply filters to rides
   */
  private applyFilters(): void {
    let filtered = [...this.rides];

    // Apply status filter
    if (this.selectedFilter !== 'all') {
      filtered = filtered.filter(ride => ride.status === this.selectedFilter);
    }

    // Apply month filter
    if (this.selectedMonth) {
      filtered = filtered.filter(ride => {
        const rideDate = new Date(ride.pickupTime);
        return (
          rideDate.getMonth() === this.selectedMonth.getMonth() &&
          rideDate.getFullYear() === this.selectedMonth.getFullYear()
        );
      });
    }

    // Sort by date, most recent first
    filtered.sort((a, b) => 
      new Date(b.pickupTime).getTime() - new Date(a.pickupTime).getTime()
    );

    this.filteredRides = filtered;
  }

  /**
   * Set status filter
   */
  setStatusFilter(filter: 'all' | 'completed' | 'cancelled'): void {
    this.selectedFilter = filter;
    this.applyFilters();
  }

  /**
   * Set month filter
   */
  setMonthFilter(date: Date | null): void {
    this.selectedMonth = date;
    this.applyFilters();
  }

  /**
   * Handle month filter change from ion-datetime
   */
  handleMonthFilterChange(event: any): void {
    const dateValue = event.detail.value;
    const date = dateValue ? new Date(dateValue) : null;
    this.setMonthFilter(date);
  }

  /**
   * Navigate to ride details
   */
  viewRideDetails(ride: RideSchedule): void {
    this.router.navigate(['/rides/details', ride.id]);
  }

  /**
   * Navigate to ride review
   */
  reviewRide(ride: RideSchedule): void {
    this.router.navigate(['/rides/review', ride.id]);
  }

  /**
   * Format date for display
   */
  formatDate(date: Date): string {
    return new Date(date).toLocaleDateString(undefined, {
      weekday: 'short',
      month: 'short',
      day: 'numeric'
    });
  }

  /**
   * Format time for display
   */
  formatTime(date: Date): string {
    return new Date(date).toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit'
    });
  }

  /**
   * Get color for ride status
   */
  getStatusColor(status: string): string {
    switch (status) {
      case 'completed':
        return 'success';
      case 'cancelled':
        return 'warn';
      default:
        return 'primary';
    }
  }

  /**
   * Check if a ride can be reviewed
   */
  canReviewRide(ride: RideSchedule): boolean {
    const isCompletedRecently = (
      ride.status === 'completed' &&
      Date.now() - new Date(ride.updated).getTime() < 7 * 24 * 60 * 60 * 1000 // 7 days
    );
    return isCompletedRecently;
  }

  /**
   * Get ride price with currency formatting
   */
  formatPrice(price: number): string {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(price);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}