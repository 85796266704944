import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, from } from 'rxjs';
import { switchMap, map, catchError, tap, withLatestFrom } from 'rxjs/operators';
import { MessagingService } from '@app/services/messaging.service';
import * as MessageActions from './message.actions';
import { Store } from '@ngrx/store';
import { selectThread } from './message.selectors';

@Injectable()
export class MessageEffects {
  constructor(
    private actions$: Actions,
    private messagingService: MessagingService,
    private store: Store
  ) {}

  // Load messages
  loadMessages$ = createEffect(() => this.actions$.pipe(
    ofType(MessageActions.loadMessages),
    tap(action => console.log('Loading messages for thread:', action.threadId)),
    switchMap(({ threadId, page, pageSize }) => 
      this.messagingService.getMessages(threadId, pageSize).pipe(
        tap(messages => console.log(`Loaded ${messages.length} messages for thread ${threadId}`)),
        map(messages => MessageActions.loadMessagesSuccess({ messages })),
        catchError(error => {
          console.error('Error loading messages:', error);
          const serializedError = {
            message: error.message || 'Unknown error',
            code: error.code || 'UNKNOWN',
            stack: error.stack || ''
          };
          return of(MessageActions.loadMessagesFailure({ error: serializedError }));
        })
      )
    )
  ));

  // Load more messages (pagination)
  loadMoreMessages$ = createEffect(() => this.actions$.pipe(
    ofType(MessageActions.loadMoreMessages),
    tap(action => console.log('Loading more messages for thread:', action.threadId, 'page:', action.page)),
    switchMap(({ threadId, page, pageSize }) => 
      this.messagingService.getMessages(threadId, page * pageSize).pipe(
        tap(messages => console.log(`Loaded ${messages.length} more messages for thread ${threadId}`)),
        map(messages => MessageActions.loadMoreMessagesSuccess({ messages })),
        catchError(error => {
          console.error('Error loading more messages:', error);
          const serializedError = {
            message: error.message || 'Unknown error',
            code: error.code || 'UNKNOWN',
            stack: error.stack || ''
          };
          return of(MessageActions.loadMoreMessagesFailure({ error: serializedError }));
        })
      )
    )
  ));

  // Load thread
  loadThread$ = createEffect(() => this.actions$.pipe(
    ofType(MessageActions.loadThread),
    switchMap(({ threadId }) => 
      this.messagingService.getChatThread(threadId).pipe(
        map(thread => MessageActions.loadThreadSuccess({ thread })),
        catchError(error => {
          const serializedError = {
            message: error.message || 'Unknown error',
            code: error.code || 'UNKNOWN',
            stack: error.stack || ''
          };
          return of(MessageActions.loadThreadFailure({ error: serializedError }));
        })
      )
    )
  ));

  // Send message
  sendMessage$ = createEffect(() => this.actions$.pipe(
    ofType(MessageActions.sendMessage),
    tap(action => console.log('Sending message:', { 
      threadId: action.threadId, 
      content: action.content,
      type: action.messageType,
      metadata: action.metadata || {}
    })),
    switchMap(({ threadId, content, messageType, metadata }) => 
      from(this.messagingService.sendMessage(threadId, content, messageType, metadata || {})).pipe(
        switchMap(messageId => 
          this.messagingService.getMessageById(threadId, messageId).pipe(
            tap(message => console.log('Message sent successfully, ID:', messageId)),
            map(message => MessageActions.sendMessageSuccess({ message }))
          )
        ),
        catchError(error => {
          console.error('Error sending message:', error);
          const serializedError = {
            message: error.message || 'Unknown error',
            code: error.code || 'UNKNOWN',
            stack: error.stack || ''
          };
          return of(MessageActions.sendMessageFailure({ error: serializedError }));
        })
      )
    )
  ));

  // Delete message
  deleteMessage$ = createEffect(() => this.actions$.pipe(
    ofType(MessageActions.deleteMessage),
    switchMap(({ threadId, messageId }) => 
      from(this.messagingService.deleteMessage(threadId, messageId)).pipe(
        map(() => MessageActions.deleteMessageSuccess({ messageId })),
        catchError(error => {
          const serializedError = {
            message: error.message || 'Unknown error',
            code: error.code || 'UNKNOWN',
            stack: error.stack || ''
          };
          return of(MessageActions.deleteMessageFailure({ error: serializedError }));
        })
      )
    )
  ));

  // Mark thread as read
  markThreadAsRead$ = createEffect(() => this.actions$.pipe(
    ofType(MessageActions.markThreadAsRead),
    switchMap(({ threadId }) => 
      from(this.messagingService.markThreadAsRead(threadId)).pipe(
        map(() => MessageActions.markThreadAsReadSuccess()),
        catchError(error => {
          const serializedError = {
            message: error.message || 'Unknown error',
            code: error.code || 'UNKNOWN',
            stack: error.stack || ''
          };
          return of(MessageActions.markThreadAsReadFailure({ error: serializedError }));
        })
      )
    )
  ));

  // Mark thread as read when loading messages
  markAsReadOnLoad$ = createEffect(() => this.actions$.pipe(
    ofType(MessageActions.loadMessagesSuccess),
    withLatestFrom(this.store.select(selectThread)),
    tap(([_, thread]) => {
      if (thread?.id) {
        this.store.dispatch(MessageActions.markThreadAsRead({ threadId: thread.id }));
      }
    })
  ), { dispatch: false });
} 