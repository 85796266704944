import { createReducer, on } from '@ngrx/store';
import { Event } from '@app/state/models';
import * as CalendarActions from './calendar.actions';

export const calendarFeatureKey = 'calendar';

export interface State {
    event: Event | null;
    events: Event[] | null;
    error: any;
    pending: boolean;
}

export const initialState: State = {
    event: null,
    events: null,
    error: null,
    pending: false,
};

export const reducer = createReducer(
    initialState,
    on(CalendarActions.eventCreate, (state) => ({
        ...state,
        error: null,
        pending: true,
    })),
    on(CalendarActions.eventUpdate, (state) => ({
        ...state,
        error: null,
        pending: true,
    })),
    on(CalendarActions.eventUpdateSuccess, (state, { event }) => ({
        ...state,
        event,
        error: null,
        pending: false,
    })),
    on(CalendarActions.eventUpdateFailure, (state, { error }) => ({
        ...state,
        error,
        pending: false,
    })),
    on(CalendarActions.eventCreateSuccess, (state, { event }) => ({
        ...state,
        event,
        error: null,
        pending: false,
    })),
    on(CalendarActions.eventCreateFailure, (state, { error }) => ({
        ...state,
        error,
        pending: false,
    })),
    on(CalendarActions.eventGet, (state) => ({
        ...state,
        error: null,
        pending: true,
    })),
    on(CalendarActions.eventGetSuccess, (state, { event }) => ({
        ...state,
        event,
        error: null,
        pending: false,
    })),
    on(CalendarActions.eventGetFailure, (state, { error }) => ({
        ...state,
        error,
        pending: false,
    })),
    on(CalendarActions.eventGetAll, (state) => ({
        ...state,
        error: null,
        pending: true,
    })),
    on(CalendarActions.eventGetAllSuccess, (state, { events }) => ({
        ...state,
        events,
        error: null,
        pending: false,
    })),
    on(CalendarActions.eventGetAllFailure, (state, { error }) => ({
        ...state,
        error,
        pending: false,
    })),
    on(CalendarActions.eventDelete, (state) => ({
        ...state,
        error: null,
        pending: true,
    })),
    on(CalendarActions.eventDeleteSuccess, (state) => ({
        ...state,
        event: null,
        error: null,
        pending: false,
    })),
    on(CalendarActions.eventDeleteFailure, (state, { error }) => ({
        ...state,
        error,
        pending: false,
    }))
);

// Selectors
export const getError = (state: State) => state.error;
export const getPending = (state: State) => state.pending;
export const getEvent = (state: State) => state.event;
export const getAllEvents = (state: State) => state.events;