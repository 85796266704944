import { Component, OnInit, OnDestroy } from '@angular/core';
import { SafetyService } from './safety.service';
import { Subscription } from 'rxjs';
import { CommonModule } from '@angular/common';
import { IonicModule, AlertController } from '@ionic/angular';
import { ReactiveFormsModule, FormBuilder, FormGroup, Validators, FormsModule } from '@angular/forms';
import { SafetyAlert } from '@app/state/models/safety.model';
import { Router } from '@angular/router';
import { EmergencyButtonComponent } from './emergency-button/emergency-button.component';
import { LocationTrackerComponent } from './location-tracker/location-tracker.component';
import { VerificationPhotoComponent } from './verification-photo/verification-photo.component';
import { TranslateModule } from '@ngx-translate/core';
import { LanguageService } from '../../services/language.service';

@Component({
  selector: 'app-safety',
  templateUrl: './safety.component.html',
  styleUrls: ['./safety.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    IonicModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule,
    EmergencyButtonComponent,
    LocationTrackerComponent,
    VerificationPhotoComponent
  ]
})
export class SafetyComponent implements OnInit, OnDestroy {
  private alertSubscription: Subscription;
  private locationSubscription: Subscription;
  
  currentRideId: string;
  locationTracking: boolean = false;
  safetyCodeForm: FormGroup;
  safetyCode: string | null = null;
  
  alerts: SafetyAlert[] = [{
    type: 'info',
    title: 'Safety System Active',
    message: 'Safety monitoring is enabled',
    timestamp: new Date()
  }];

  constructor(
    private safetyService: SafetyService, 
    private alertController: AlertController, 
    private router: Router,
    private fb: FormBuilder
  ) {
    this.safetyCodeForm = this.fb.group({
      code: ['', [Validators.required, Validators.minLength(4)]]
    });
  }

  ngOnInit() {
    this.alertSubscription = this.safetyService.getAlerts()
      .subscribe(alerts => {
        if (alerts && alerts.length > 0) {
          this.alerts = [...alerts];
        }
      });
      
    this.locationSubscription = this.safetyService.isLocationTrackingActive()
      .subscribe(isActive => {
        this.locationTracking = isActive;
      });
  }

  /**
   * Trigger emergency assistance with location
   */
  async triggerEmergency() {
    const alert = await this.alertController.create({
      header: 'Emergency Assistance',
      message: 'Do you need immediate assistance?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel'
        },
        {
          text: 'Yes, Send Help',
          role: 'destructive',
          handler: () => {
            this.sendEmergencyAlert();
          }
        }
      ]
    });
    
    await alert.present();
  }
  
  /**
   * Send emergency alert with location data
   */
  private sendEmergencyAlert() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(async (position) => {
        const location = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };
        
        try {
          await this.safetyService.triggerEmergency(
            this.currentRideId,
            location
          ).toPromise();
          
          this.showConfirmationAlert('Emergency services have been notified. Stay where you are.');
        } catch (error) {
          console.error('Error triggering emergency:', error);
          this.showConfirmationAlert('Unable to contact emergency services. Please call 911 directly.');
        }
      });
    }
  }

  /**
   * Upload child verification photo
   */
  async uploadVerificationPhoto(file: File) {
    if (file) {
      try {
        await this.safetyService.uploadVerificationPhoto(
          this.currentRideId,
          file
        ).toPromise();
        
        this.showConfirmationAlert('Verification photo uploaded successfully');
      } catch (error) {
        console.error('Error uploading verification photo:', error);
        this.showConfirmationAlert('Failed to upload verification photo. Please try again.');
      }
    }
  }

  /**
   * Generate a child safety code
   */
  async generateSafetyCode(childId: string) {
    try {
      this.safetyService.generateChildSafetyCode(childId)
        .subscribe(code => {
          this.safetyCode = code;
          this.showConfirmationAlert(`Safety code generated: ${code}`);
        });
    } catch (error) {
      console.error('Error generating safety code:', error);
    }
  }
  
  /**
   * Verify a child safety code
   */
  verifySafetyCode() {
    if (this.safetyCodeForm.valid) {
      const code = this.safetyCodeForm.get('code')?.value;
      
      this.safetyService.verifyChildSafetyCode(code, this.currentRideId)
        .subscribe(isValid => {
          if (isValid) {
            this.showConfirmationAlert('Safety code verified successfully');
          } else {
            this.showConfirmationAlert('Invalid safety code. Please try again.');
          }
        });
    }
  }

  /**
   * Handle location updates from the tracker component
   */
  onLocationUpdated(location: google.maps.LatLngLiteral) {
    // The location tracker component already emits the location to the service
    // We can add additional handling here if needed
    console.log('Location updated:', location);
  }
  
  /**
   * Handle tracking started event
   */
  onTrackingStarted() {
    this.locationTracking = true;
    this.alerts.unshift({
      type: 'info',
      title: 'Location Tracking',
      message: 'Location tracking has been activated',
      timestamp: new Date()
    });
  }
  
  /**
   * Handle tracking stopped event
   */
  onTrackingStopped() {
    this.locationTracking = false;
    this.alerts.unshift({
      type: 'info',
      title: 'Location Tracking',
      message: 'Location tracking has been deactivated',
      timestamp: new Date()
    });
  }

  /**
   * Show emergency contact information
   */
  async showEmergencyContacts() {
    const alert = await this.alertController.create({
      header: 'Emergency Contacts',
      message: 'Your emergency contacts will be notified in case of emergency',
      buttons: ['OK']
    });
    
    await alert.present();
  }
  
  /**
   * Show confirmation alert with message
   */
  private async showConfirmationAlert(message: string) {
    const alert = await this.alertController.create({
      header: 'Alert',
      message: message,
      buttons: ['OK']
    });
    
    await alert.present();
  }

  ngOnDestroy() {
    this.alertSubscription?.unsubscribe();
    this.locationSubscription?.unsubscribe();
  }
}