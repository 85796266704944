import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, mergeMap, catchError, tap, switchMap } from 'rxjs/operators';
import { CalendarIntegrationService } from '@app/services/calendar-integration.service';
import { RideService } from '@app/services/ride.service';
import { GeocodingValidationService } from '@app/services/geocoding-validation.service';
import { ToastController } from '@ionic/angular';
import * as CalendarActions from './calendar.state';

@Injectable()
export class CalendarEffects {
    eventCreate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CalendarActions.eventCreate),
            map(action => action.event),
            mergeMap((event) =>
                this.calendarService.eventCreate(event).pipe(
                    map(() => CalendarActions.eventCreateSuccess({ event })),
                    catchError((error) => of(CalendarActions.eventCreateFailure({ error })))
                )
            )
        )
    );

    getCalendar$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CalendarActions.eventGet),
            map(action => action.id),
            mergeMap((id) =>
                this.calendarService.getEvents('primary', new Date().toISOString().split('T')[0], 
                  new Date(Date.now() + 30 * 24 * 60 * 60 * 1000).toISOString().split('T')[0]).pipe(
                    map((events) => {
                        const event = events.find(e => e.id === id);
                        if (!event) {
                            throw new Error('Event not found');
                        }
                        return CalendarActions.eventGetSuccess({ event });
                    }),
                    catchError((error) => of(CalendarActions.eventGetFailure({ error })))
                )
            )
        )
    );

    getCalendarAll$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CalendarActions.eventGetAll),
            mergeMap(() =>
                this.calendarService.getEvents('primary', new Date().toISOString().split('T')[0], 
                  new Date(Date.now() + 30 * 24 * 60 * 60 * 1000).toISOString().split('T')[0]).pipe(
                    map((events) => CalendarActions.eventGetAllSuccess({ events })),
                    catchError((error) => of(CalendarActions.eventGetAllFailure({ error })))
                )
            )
        )
    );

    // Optional commented navigation effect
    // eventSuccess$ = createEffect(
    //     () =>
    //         this.actions$.pipe(
    //             ofType(CalendarActions.eventUpdateSuccess),
    //             tap(() => this.router.navigate(['/tabs/event']))
    //         ),
    //     { dispatch: false }
    // );

    deleteCalendar$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CalendarActions.eventDelete),
            map(action => action.id),
            mergeMap((id) =>
                this.calendarService.deleteEvent(id).pipe(
                    map(() => CalendarActions.eventDeleteSuccess()),
                    catchError((error) => of(CalendarActions.eventDeleteFailure({ error })))
                )
            )
        )
    );

    loadEvents$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CalendarActions.loadEvents),
            mergeMap(({ calendarId, startDate, endDate }) =>
                this.calendarService.getEvents(calendarId, startDate, endDate).pipe(
                    map(events => CalendarActions.loadEventsSuccess({ events })),
                    catchError(error => of(CalendarActions.loadEventsFailure({ error: error.message })))
                )
            )
        )
    );

    importEvent$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CalendarActions.importEvent),
            switchMap(({ event }) => {
                // Handle both string and object location types
                const locationAddress = typeof event.location === 'string' 
                    ? event.location 
                    : event.location?.address || '';

                if (!locationAddress) {
                    return of(CalendarActions.importEventFailure({ 
                        error: 'Event location is required for import' 
                    }));
                }

                return this.geocodingValidation.validateLocation(locationAddress).pipe(
                    switchMap(validation => {
                        if (!validation.isValid) {
                            return of(CalendarActions.importEventFailure({ 
                                error: validation.error || 'Invalid location' 
                            }));
                        }

                        return this.rideService.createRideFromCalendarEvent({
                            ...event,
                            location: validation.location
                        }).pipe(
                            map(() => CalendarActions.importEventSuccess({ eventId: event.id })),
                            catchError(error => of(CalendarActions.importEventFailure({ 
                                error: error.message 
                            })))
                        );
                    }),
                    catchError(error => of(CalendarActions.importEventFailure({ 
                        error: `Location validation failed: ${error.message}` 
                    })))
                );
            })
        )
    );

    importSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CalendarActions.importEventSuccess),
            tap(async () => {
                const toast = await this.toastController.create({
                    message: 'Event successfully imported as a ride',
                    duration: 2000,
                    position: 'bottom',
                    color: 'success'
                });
                toast.present();
            })
        ),
        { dispatch: false }
    );

    importFailure$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CalendarActions.importEventFailure),
            tap(async ({ error }) => {
                const toast = await this.toastController.create({
                    message: `Failed to import event: ${error}`,
                    duration: 3000,
                    position: 'bottom',
                    color: 'danger'
                });
                toast.present();
            })
        ),
        { dispatch: false }
    );

    constructor(
        private actions$: Actions,
        private calendarService: CalendarIntegrationService,
        private rideService: RideService,
        private geocodingValidation: GeocodingValidationService,
        private toastController: ToastController
    ) {}
}