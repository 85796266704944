import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, tap, catchError } from 'rxjs/operators';

export interface TrustedParent {
  id: string;
  name: string;
  email: string;
  profileImage?: string;
  verificationStatus: 'verified' | 'pending' | 'unverified';
  schoolAffiliations?: string[];
  connectionDate: Date;
  childrenCount?: number;
}

export interface ParentConnectionRequest {
  id: string;
  requesterId: string;
  requesterName: string;
  requesterEmail: string;
  requesterImage?: string;
  requestDate: Date;
  status: 'pending' | 'accepted' | 'rejected';
  schoolAffiliation?: string;
}

@Injectable({
  providedIn: 'root'
})
export class NetworkService {
  private apiUrl = 'api/network';
  private trustedParentsSubject = new BehaviorSubject<TrustedParent[]>([]);
  private connectionRequestsSubject = new BehaviorSubject<ParentConnectionRequest[]>([]);

  constructor(private http: HttpClient) {
    // Initialize with data
    this.loadTrustedParents();
    this.loadConnectionRequests();
  }

  /**
   * Get trusted parents list
   */
  getTrustedParents(): Observable<TrustedParent[]> {
    return this.trustedParentsSubject.asObservable();
  }

  /**
   * Get connection requests
   */
  getConnectionRequests(): Observable<ParentConnectionRequest[]> {
    return this.connectionRequestsSubject.asObservable();
  }

  /**
   * Send connection request to another parent
   */
  sendConnectionRequest(parentEmail: string): Observable<boolean> {
    return this.http.post<boolean>(`${this.apiUrl}/connect`, { parentEmail })
      .pipe(
        tap(() => this.loadConnectionRequests()),
        catchError(error => {
          console.error('Error sending connection request:', error);
          return of(false);
        })
      );
  }

  /**
   * Accept a connection request
   */
  acceptConnectionRequest(requestId: string): Observable<boolean> {
    return this.http.post<boolean>(`${this.apiUrl}/accept`, { requestId })
      .pipe(
        tap(() => {
          this.loadConnectionRequests();
          this.loadTrustedParents();
        }),
        catchError(error => {
          console.error('Error accepting connection request:', error);
          return of(false);
        })
      );
  }

  /**
   * Reject a connection request
   */
  rejectConnectionRequest(requestId: string): Observable<boolean> {
    return this.http.post<boolean>(`${this.apiUrl}/reject`, { requestId })
      .pipe(
        tap(() => this.loadConnectionRequests()),
        catchError(error => {
          console.error('Error rejecting connection request:', error);
          return of(false);
        })
      );
  }

  /**
   * Remove a trusted parent connection
   */
  removeConnection(parentId: string): Observable<boolean> {
    return this.http.delete<boolean>(`${this.apiUrl}/connection/${parentId}`)
      .pipe(
        tap(() => this.loadTrustedParents()),
        catchError(error => {
          console.error('Error removing connection:', error);
          return of(false);
        })
      );
  }

  /**
   * Search for parents by email domain or name
   */
  searchParents(query: string): Observable<TrustedParent[]> {
    return this.http.get<TrustedParent[]>(`${this.apiUrl}/search`, { params: { query } });
  }

  /**
   * Verify school affiliation through email domain
   */
  verifySchoolDomain(domain: string): Observable<boolean> {
    return this.http.post<boolean>(`${this.apiUrl}/verify-school`, { domain });
  }

  /**
   * Load trusted parents from API
   */
  private loadTrustedParents(): void {
    this.http.get<TrustedParent[]>(`${this.apiUrl}/trusted-parents`)
      .subscribe(
        parents => this.trustedParentsSubject.next(parents),
        error => console.error('Error loading trusted parents:', error)
      );
  }

  /**
   * Load connection requests from API
   */
  private loadConnectionRequests(): void {
    this.http.get<ParentConnectionRequest[]>(`${this.apiUrl}/connection-requests`)
      .subscribe(
        requests => this.connectionRequestsSubject.next(requests),
        error => console.error('Error loading connection requests:', error)
      );
  }
}