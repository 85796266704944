import { createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import * as TrustedNetworkActions from './trusted-network.actions';
import { TrustedGroup, TrustedMember } from '../models/trusted-network.model';

export const trustedNetworkFeatureKey = 'trustedNetwork';

// Define the entity adapters
export const groupsAdapter: EntityAdapter<TrustedGroup> = createEntityAdapter<TrustedGroup>();
export const membersAdapter: EntityAdapter<TrustedMember> = createEntityAdapter<TrustedMember>({
  selectId: (member: TrustedMember) => member.userId
});

// Define the state interface
export interface State {
  groups: EntityState<TrustedGroup>;
  members: EntityState<TrustedMember>;
  selectedGroupId: string | null;
  loading: boolean;
  loaded: boolean;
  error: string | null;
  networkConnected: boolean;
}

// Initial state
export const initialState: State = {
  groups: groupsAdapter.getInitialState(),
  members: membersAdapter.getInitialState(),
  selectedGroupId: null,
  loading: false,
  loaded: false,
  error: null,
  networkConnected: navigator.onLine,
};

// Create reducer with all the action handlers
export const reducer = createReducer(
  initialState,
  
  // Load groups
  on(TrustedNetworkActions.loadGroups, (state) => ({
    ...state,
    loading: true,
    error: null
  })),
  
  on(TrustedNetworkActions.loadGroupsSuccess, (state, { groups }) => ({
    ...state,
    groups: groupsAdapter.setAll(groups, state.groups),
    loading: false,
    loaded: true,
    error: null
  })),
  
  on(TrustedNetworkActions.loadGroupsFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error: typeof error === 'string' ? error : 'Failed to load groups'
  })),
  
  // Load single group
  on(TrustedNetworkActions.loadGroup, (state, { groupId }) => ({
    ...state,
    selectedGroupId: groupId,
    loading: true,
    error: null
  })),
  
  on(TrustedNetworkActions.loadGroupSuccess, (state, { group }) => ({
    ...state,
    groups: groupsAdapter.upsertOne(group, state.groups),
    loading: false,
    error: null
  })),
  
  on(TrustedNetworkActions.loadGroupFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error: typeof error === 'string' ? error : 'Failed to load group'
  })),
  
  // Create group
  on(TrustedNetworkActions.createGroup, (state) => ({
    ...state,
    loading: true,
    error: null
  })),
  
  on(TrustedNetworkActions.createGroupSuccess, (state, { group }) => ({
    ...state,
    groups: groupsAdapter.addOne(group, state.groups),
    loading: false,
    error: null
  })),
  
  on(TrustedNetworkActions.createGroupFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error: typeof error === 'string' ? error : 'Failed to create group'
  })),
  
  // Update group
  on(TrustedNetworkActions.updateGroup, (state) => ({
    ...state,
    loading: true,
    error: null
  })),
  
  on(TrustedNetworkActions.updateGroupSuccess, (state, { group }) => ({
    ...state,
    groups: groupsAdapter.updateOne({ id: group.id, changes: group }, state.groups),
    loading: false,
    error: null
  })),
  
  on(TrustedNetworkActions.updateGroupFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error: typeof error === 'string' ? error : 'Failed to update group'
  })),
  
  // Delete group
  on(TrustedNetworkActions.deleteGroup, (state) => ({
    ...state,
    loading: true,
    error: null
  })),
  
  on(TrustedNetworkActions.deleteGroupSuccess, (state, { groupId }) => ({
    ...state,
    groups: groupsAdapter.removeOne(groupId, state.groups),
    loading: false,
    error: null
  })),
  
  on(TrustedNetworkActions.deleteGroupFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error: typeof error === 'string' ? error : 'Failed to delete group'
  })),
  
  // Load members
  on(TrustedNetworkActions.loadMembers, (state) => ({
    ...state,
    loading: true,
    error: null
  })),
  
  on(TrustedNetworkActions.loadMembersSuccess, (state, { members }) => ({
    ...state,
    members: membersAdapter.setAll(members, state.members),
    loading: false,
    error: null
  })),
  
  on(TrustedNetworkActions.loadMembersFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error: typeof error === 'string' ? error : 'Failed to load members'
  })),
  
  // Create invite and Join group don't modify state directly
  on(TrustedNetworkActions.createInvite, (state) => ({
    ...state,
    loading: true,
    error: null
  })),
  
  on(TrustedNetworkActions.createInviteSuccess, (state) => ({
    ...state,
    loading: false,
    error: null
  })),
  
  on(TrustedNetworkActions.createInviteFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error: typeof error === 'string' ? error : 'Failed to create invite'
  })),
  
  on(TrustedNetworkActions.joinGroup, (state) => ({
    ...state,
    loading: true,
    error: null
  })),
  
  on(TrustedNetworkActions.joinGroupSuccess, (state) => ({
    ...state,
    loading: false,
    error: null
  })),
  
  on(TrustedNetworkActions.joinGroupFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error: typeof error === 'string' ? error : 'Failed to join group'
  })),
  
  // Set loading and error states
  on(TrustedNetworkActions.setLoading, (state, { loading }) => ({
    ...state,
    loading
  })),
  
  on(TrustedNetworkActions.setError, (state, { error }) => ({
    ...state,
    error
  })),
  
  // Reset state
  on(TrustedNetworkActions.resetTrustedNetworkState, () => initialState)
);

// Export the entity adapter selectors
export const {
  selectIds: selectGroupIds,
  selectEntities: selectGroupEntities,
  selectAll: selectAllGroups,
  selectTotal: selectTotalGroups
} = groupsAdapter.getSelectors();

export const {
  selectIds: selectMemberIds,
  selectEntities: selectMemberEntities,
  selectAll: selectAllMembers,
  selectTotal: selectTotalMembers
} = membersAdapter.getSelectors(); 