import { createReducer, on } from '@ngrx/store';
import * as LayoutActions from './layout.actions';

export const layoutFeatureKey = 'layout';

export interface State {
  showSidenav: boolean;
}

const initialState: State = {
  showSidenav: false,
};

export const reducer = createReducer(
  initialState,
  // Even though the `state` is unused, it helps infer the return type
  on(LayoutActions.closeSidenav, state => ({ showSidenav: false })),
  on(LayoutActions.openSidenav, state => ({ showSidenav: true })),
);

// Renamed to avoid conflicts with the selectors file
export const getShowSidenav = (state: State) => state.showSidenav; 