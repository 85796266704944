import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of, from } from 'rxjs';
import { catchError, exhaustMap, map, tap, take, switchMap, mergeMap } from 'rxjs/operators';
import * as EditProfileActions from './edit-profile.actions';
import * as ProfileActions from '../profile/profile.actions';
import { Profile } from '../models/profile.model';
import { ProfileService } from '../../services/profile.service';
import { Store } from '@ngrx/store';
import * as fromState from '../index';

@Injectable()
export class EditProfileEffects {

  upsertProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EditProfileActions.profileUpsert),
      map(action => action.data),
      exhaustMap((profile: Profile) => {
        console.log('Upserting profile in EditProfileEffects:', profile);
        return this.profileService.upsertProfile(profile).pipe(
          mergeMap(success => {
            if (success) {
              console.log('Profile upsert succeeded in EditProfileEffects');
              
              // Return an array of actions to dispatch - both for edit-profile and profile state slices
              return [
                EditProfileActions.profileUpsertSuccess(),
                // Also update the profile state slice
                ProfileActions.profileUpsertSuccess()
              ];
            } else {
              console.log('Profile upsert failed in EditProfileEffects');
              return [
                EditProfileActions.profileUpsertFailure({ error: 'Failed to update profile' }),
                ProfileActions.profileUpsertFailure({ error: 'Failed to update profile' })
              ];
            }
          }),
          catchError(error => {
            console.error('Error upserting profile in EditProfileEffects:', error);
            return of(EditProfileActions.profileUpsertFailure({ error: error.message }));
          })
        );
      })
    )
  );

  getProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EditProfileActions.profileGet),
      map(action => action.id),
      exhaustMap((userId: string) => {
        // First try to get from BehaviorSubject which is faster
        return this.profileService.getProfile$().pipe(
          take(1),
          switchMap((profile: Profile | null) => {
            // If profile exists and matches userId, return it
            if (profile && (profile.id === userId || profile.uid === userId)) {
              console.log('Got profile from subject in EditProfileEffects:', profile);
              
              // Dispatch an action to update the profile state slice as well
              this.dispatchProfileAction(ProfileActions.profileGetSuccess({ profile: [profile] }));
              
              return of(EditProfileActions.profileGetSuccess({ profile }));
            }
            
            // Otherwise fetch from Firestore
            return this.profileService.getProfileById(userId).then(fetchedProfile => {
              if (fetchedProfile) {
                console.log('Got profile from Firestore in EditProfileEffects:', fetchedProfile);
                
                // Dispatch an action to update the profile state slice as well
                this.dispatchProfileAction(ProfileActions.profileGetSuccess({ profile: [fetchedProfile] }));
                
                return EditProfileActions.profileGetSuccess({ profile: fetchedProfile });
              } else {
                console.log('Profile not found in EditProfileEffects');
                return EditProfileActions.profileGetFailure({ error: 'Profile not found' });
              }
            }).catch(error => {
              console.error('Error getting profile in EditProfileEffects:', error);
              return EditProfileActions.profileGetFailure({ error: error.message });
            });
          }),
          catchError(error => {
            console.error('Error in getProfile$ effect in EditProfileEffects:', error);
            return of(EditProfileActions.profileGetFailure({ error: error.message }));
          })
        );
      })
    )
  );

  getProfileAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EditProfileActions.profileGetAll),
      exhaustMap(() => {
        // Get all profiles from Firestore
        return this.profileService.getAllProfiles$().pipe(
          map(profiles => {
            console.log('Got all profiles in EditProfileEffects:', profiles);
            return EditProfileActions.profileGetAllSuccess({ profiles });
          }),
          catchError(error => {
            console.error('Error getting all profiles in EditProfileEffects:', error);
            return of(EditProfileActions.profileGetAllFailure({ error: error.message }));
          })
        );
      })
    )
  );

  // Navigate after profile update is successful
  profileSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(EditProfileActions.profileUpsertSuccess),
        tap(() => {
          console.log('Profile upsert success in EditProfileEffects - navigating to profile page');
          this.router.navigate(['/profile']);
        })
      ),
    { dispatch: false }
  );
  
  // Helper method to dispatch profile actions
  private dispatchProfileAction(action: any): void {
    // Use setTimeout to avoid ExpressionChangedAfterItHasBeenCheckedError
    setTimeout(() => {
      this.store.dispatch(action);
    });
  }

  constructor(
    private actions$: Actions,
    private profileService: ProfileService,
    private router: Router,
    private store: Store<fromState.State>
  ) {}
}
