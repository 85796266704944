// User model
export interface User {
  uid: string;
  email: string;
  displayName?: string;
  photoURL?: string;
  phoneNumber?: string;
  emailVerified: boolean;
  isAnonymous?: boolean;
  settings?: any;
  [key: string]: any;
}

export class UserClass implements User {
  uid: string;
  email: string;
  displayName?: string;
  photoURL?: string;
  phoneNumber?: string;
  emailVerified: boolean;
  isAnonymous?: boolean;
  settings?: any;
  [key: string]: any;

  constructor() {
    this.uid = '';
    this.email = '';
    this.displayName = '';
    this.photoURL = '';
    this.phoneNumber = '';
    this.emailVerified = false;
    this.isAnonymous = false;
    this.settings = {};
  }
} 