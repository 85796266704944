import { Injectable, ErrorHandler } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { FirebaseError } from 'firebase/app';
import { ToastController } from '@ionic/angular';

export enum ErrorSeverity {
  CRITICAL = 'critical',
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info'
}

export interface ErrorDetails {
  message: string;
  originalError?: any;
  code?: string;
  severity: ErrorSeverity;
  actionable: boolean;
  timestamp: Date;
}

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService implements ErrorHandler {
  // Firebase Auth error codes mapped to user-friendly messages
  private readonly firebaseAuthErrorMap: Record<string, string> = {
    'auth/email-already-in-use': 'This email is already in use. Try logging in instead.',
    'auth/invalid-email': 'The email address is not valid.',
    'auth/user-disabled': 'Your account has been disabled. Please contact support.',
    'auth/user-not-found': 'No account found with this email. Please sign up.',
    'auth/wrong-password': 'Incorrect password. Please try again or reset your password.',
    'auth/too-many-requests': 'Too many unsuccessful login attempts. Please try again later.',
    'auth/weak-password': 'Password is too weak. Please use a stronger password.',
    'auth/requires-recent-login': 'For security, please log in again before making this change.'
  };

  constructor(
    private router: Router,
    private toastController: ToastController
  ) {}

  /**
   * Required implementation of Angular's ErrorHandler
   */
  handleError(error: any): void {
    console.error('Error caught by error handler:', error);

    // Process different error types
    let errorDetails: ErrorDetails;
    
    if (error instanceof HttpErrorResponse) {
      errorDetails = this.handleHttpError(error);
    } else if (this.isFirebaseError(error)) {
      errorDetails = this.handleFirebaseError(error);
    } else {
      errorDetails = this.handleGenericError(error);
    }

    // Display toast for user feedback
    this.displayErrorToast(errorDetails);
  }

  /**
   * Handle HTTP errors
   */
  private handleHttpError(error: HttpErrorResponse): ErrorDetails {
    let message: string;
    let severity: ErrorSeverity = ErrorSeverity.ERROR;
    let actionable = false;

    switch (error.status) {
      case 0:
        message = 'Unable to connect to the server. Please check your internet connection.';
        actionable = true;
        break;
      case 401:
        message = 'Your session has expired. Please log in again.';
        actionable = true;
        this.router.navigate(['/login']);
        break;
      case 403:
        message = 'You do not have permission to perform this action.';
        break;
      case 404:
        message = 'The requested resource was not found.';
        break;
      case 500:
      case 502:
      case 503:
      case 504:
        message = 'A server error occurred. Please try again later.';
        severity = ErrorSeverity.CRITICAL;
        break;
      default:
        message = 'An error occurred while processing your request.';
    }

    return {
      message,
      originalError: error,
      code: `${error.status}`,
      severity,
      actionable,
      timestamp: new Date()
    };
  }

  /**
   * Handle Firebase errors
   */
  private handleFirebaseError(error: FirebaseError): ErrorDetails {
    const code = error.code || 'unknown';
    let message: string;
    let severity = ErrorSeverity.ERROR;
    let actionable = true;

    // Handle auth errors
    if (code.startsWith('auth/')) {
      message = this.firebaseAuthErrorMap[code] || 'An authentication error occurred.';
      
      // Handle auth errors that require redirection to login
      if (['auth/user-token-expired', 'auth/user-disabled', 'auth/user-not-found'].includes(code)) {
        this.router.navigate(['/login']);
      }
    } 
    // Handle other Firebase errors
    else {
      message = error.message || 'An error occurred with Firebase.';
    }

    return {
      message,
      originalError: error,
      code,
      severity,
      actionable,
      timestamp: new Date()
    };
  }

  /**
   * Handle generic JavaScript errors
   */
  private handleGenericError(error: any): ErrorDetails {
    const message = error.message || 'An unexpected error occurred.';
    
    let severity = ErrorSeverity.ERROR;
    if (error instanceof TypeError || error instanceof ReferenceError) {
      severity = ErrorSeverity.CRITICAL;
    }

    return {
      message,
      originalError: error,
      severity,
      actionable: false,
      timestamp: new Date()
    };
  }

  /**
   * Check if an error is a Firebase error
   */
  private isFirebaseError(error: any): boolean {
    return (
      error &&
      typeof error === 'object' &&
      'code' in error &&
      typeof error.code === 'string' &&
      (error.code.startsWith('auth/') || error.code.includes('/'))
    );
  }

  /**
   * Display error toast
   */
  private async displayErrorToast(errorDetails: ErrorDetails): Promise<void> {
    // Don't show toast for non-actionable info-level errors
    if (!errorDetails.actionable && errorDetails.severity === ErrorSeverity.INFO) {
      return;
    }
    
    // Determine toast color based on severity
    let color = 'primary';
    let duration = 3000;
    
    switch (errorDetails.severity) {
      case ErrorSeverity.CRITICAL:
      case ErrorSeverity.ERROR:
        color = 'danger';
        duration = 5000;
        break;
      case ErrorSeverity.WARNING:
        color = 'warning';
        duration = 4000;
        break;
    }
    
    const toast = await this.toastController.create({
      message: errorDetails.message,
      duration: duration,
      position: 'bottom',
      color: color,
      buttons: [
        {
          side: 'end',
          icon: 'close',
          role: 'cancel'
        }
      ]
    });
    
    await toast.present();
  }

  /**
   * Helper method to wrap async functions with consistent error handling
   */
  public async wrapAsync<T>(fn: () => Promise<T>, errorMessage?: string): Promise<T | null> {
    try {
      return await fn();
    } catch (error) {
      // Create a custom error with the provided message if available
      if (errorMessage) {
        console.error(errorMessage, error);
      }
      
      // Process the error
      this.handleError(error);
      return null;
    }
  }
} 