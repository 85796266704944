import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Storage } from '@ionic/storage-angular';

/**
 * StorageService provides a unified interface for browser storage operations.
 * 
 * Features:
 * - Local Storage and Session Storage operations
 * - Storage change notifications via observables
 * - Type-safe storage with serialization/deserialization
 * - Storage quota management
 * - Expirable storage items (TTL)
 */
@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private _storage: Storage | null = null;

  // Storage change notifications
  private storageSubject = new BehaviorSubject<{ key: string, value: any }>({ key: '', value: null });
  
  // Public stream of storage changes
  public storageChange$ = this.storageSubject.asObservable();
  
  // Backwards compatibility with old storage service
  private _dataSubject = new BehaviorSubject<Map<string, any>>(new Map());
  public data$ = this._dataSubject.asObservable();
  
  // Specialized subject for trusted users
  private _trustedUsersSubject = new BehaviorSubject<string[]>([]);
  public trustedUsers$ = this._trustedUsersSubject.asObservable();

  constructor(private storage: Storage) {
    this.init();
    // Initialize trustedUsers if available
    this.loadTrustedUsers();
  }

  async init(): Promise<void> {
    // If storage is already initialized, return
    if (this._storage !== null) {
      return;
    }
    
    // Initialize storage
    this._storage = await this.storage.create();
  }

  /**
   * Set a value in storage
   * @param key Storage key
   * @param value Value to store
   * @param useSession Whether to use session storage instead of local storage
   * @param ttl Time to live in milliseconds (optional)
   */
  async set(key: string, value: any, useSession = false, ttl?: number): Promise<void> {
    await this.init();
    
    try {
      // Wrap value with metadata if TTL is provided
      const valueToStore = ttl 
        ? { 
            value,
            expiry: Date.now() + ttl
          }
        : value;
          
      // Serialize the value
      const serialized = JSON.stringify(valueToStore);
      
      // Store in the appropriate storage
      const storage = useSession ? sessionStorage : localStorage;
      await this._storage?.set(key, serialized);
      
      // Notify subscribers
      this.storageSubject.next({ key, value });
    } catch (error) {
      console.error('Error storing data:', error);
      throw error;
    }
  }

  /**
   * Get a value from storage
   * @param key Storage key
   * @param useSession Whether to use session storage instead of local storage
   * @returns The stored value or null if not found
   */
  async get(key: string, useSession = false, parseJson: boolean = false): Promise<any> {
    await this.init();
    
    const value = await this._storage?.get(key);
    
    if (parseJson && value) {
      try {
        return JSON.parse(value);
      } catch (error) {
        console.error('Error parsing JSON from storage:', error);
        return null;
      }
    }
    
    return value;
  }

  /**
   * Remove an item from storage
   * @param key Storage key
   * @param useSession Whether to use session storage instead of local storage
   */
  async remove(key: string, useSession = false): Promise<void> {
    await this.init();
    
    try {
      // Remove from the appropriate storage
      const storage = useSession ? sessionStorage : localStorage;
      await this._storage?.remove(key);
      
      // Notify subscribers
      this.storageSubject.next({ key, value: null });
    } catch (error) {
      console.error('Error removing data:', error);
    }
  }

  /**
   * Clear all items from storage
   * @param useSession Whether to clear session storage instead of local storage
   */
  async clear(useSession = false): Promise<void> {
    await this.init();
    
    try {
      // Clear the appropriate storage
      const storage = useSession ? sessionStorage : localStorage;
      await this._storage?.clear();
      
      // Notify subscribers with a special null key to indicate complete clear
      this.storageSubject.next({ key: null as any, value: null });
    } catch (error) {
      console.error('Error clearing storage:', error);
    }
  }

  /**
   * Get all keys in storage
   * @param useSession Whether to use session storage instead of local storage
   * @returns Array of keys
   */
  async keys(useSession = false): Promise<string[]> {
    await this.init();
    
    try {
      // Get keys from the appropriate storage
      const storage = useSession ? sessionStorage : localStorage;
      return this._storage?.keys() || [];
    } catch (error) {
      console.error('Error getting storage keys:', error);
      return [];
    }
  }

  /**
   * Check if a key exists in storage
   * @param key Storage key
   * @param useSession Whether to use session storage instead of local storage
   * @returns True if the key exists and is not expired
   */
  async has(key: string, useSession = false): Promise<boolean> {
    await this.init();
    
    try {
      // Check if exists in the appropriate storage
      const value = await this.get(key, useSession);
      return value !== null;
    } catch (error) {
      console.error('Error checking for key:', error);
      return false;
    }
  }

  /**
   * Set multiple values at once
   * @param items Object with key-value pairs to store
   * @param useSession Whether to use session storage instead of local storage
   * @param ttl Time to live in milliseconds (optional, applies to all items)
   */
  async setMultiple(items: Record<string, any>, useSession = false, ttl?: number): Promise<void> {
    await this.init();
    
    Object.entries(items).forEach(([key, value]) => {
      this.set(key, value, useSession, ttl);
    });
  }

  /**
   * Get multiple values at once
   * @param keys Array of keys to retrieve
   * @param useSession Whether to use session storage instead of local storage
   * @returns Object with key-value pairs
   */
  async getMultiple(keys: string[], useSession = false): Promise<Record<string, any>> {
    await this.init();
    
    return keys.reduce(async (result, key) => {
      result[key] = await this.get(key, useSession);
      return result;
    }, {} as Record<string, any>);
  }

  /**
   * Remove multiple items at once
   * @param keys Array of keys to remove
   * @param useSession Whether to use session storage instead of local storage
   */
  async removeMultiple(keys: string[], useSession = false): Promise<void> {
    await this.init();
    
    keys.forEach(async key => {
      await this.remove(key, useSession);
    });
  }

  /**
   * Get the current storage usage in bytes
   * @param useSession Whether to check session storage instead of local storage
   * @returns Current storage usage in bytes
   */
  async getStorageUsage(useSession = false): Promise<number> {
    await this.init();
    
    try {
      // Get from the appropriate storage
      const storage = useSession ? sessionStorage : localStorage;
      
      let totalSize = 0;
      const keys = await this.keys(useSession);
      for (const key of keys) {
        const value = await this.get(key, useSession);
        if (value) {
          totalSize += key.length + value.length;
        }
      }
      
      return totalSize * 2; // Approximate size in bytes (2 bytes per character for UTF-16)
    } catch (error) {
      console.error('Error calculating storage usage:', error);
      return 0;
    }
  }

  /**
   * Get all storage content as an object
   * @param useSession Whether to use session storage instead of local storage
   * @returns Object with all storage content
   */
  async getAll(useSession = false): Promise<Record<string, any>> {
    await this.init();
    
    try {
      // Get all keys
      const allKeys = await this.keys(useSession);
      
      // Build result object
      return allKeys.reduce(async (result, key) => {
        result[key] = await this.get(key, useSession);
        return result;
      }, {} as Record<string, any>);
    } catch (error) {
      console.error('Error getting all storage items:', error);
      return {};
    }
  }

  /**
   * Watch a specific key for changes
   * @param key Storage key to watch
   * @returns Observable that emits when the specific key changes
   */
  watch(key: string): Observable<any> {
    return new Observable(observer => {
      // Initial value
      this.get(key).then(value => {
        observer.next(value);
      });
      
      // Subscribe to storage changes
      const subscription = this.storageChange$.subscribe(change => {
        if (change.key === key) {
          observer.next(change.value);
        }
      });
      
      // Clean up subscription
      return () => subscription.unsubscribe();
    });
  }

  /**
   * Set trusted users array
   * @param userIds Array of user IDs
   */
  async setTrustedUsers(userIds: string[]): Promise<void> {
    await this.set('trustedUsers', userIds);
    this._trustedUsersSubject.next(userIds);
  }

  /**
   * Add a user to trusted users
   * @param userId User ID to add
   */
  async addTrustedUser(userId: string): Promise<void> {
    const currentUsers = await this.get('trustedUsers') as string[] || [];
    if (!currentUsers.includes(userId)) {
      const updatedUsers = [...currentUsers, userId];
      await this.setTrustedUsers(updatedUsers);
    }
  }

  /**
   * Remove a user from trusted users
   * @param userId User ID to remove
   */
  async removeTrustedUser(userId: string): Promise<void> {
    const currentUsers = await this.get('trustedUsers') as string[] || [];
    const updatedUsers = currentUsers.filter(id => id !== userId);
    await this.setTrustedUsers(updatedUsers);
  }

  /**
   * Load trusted users from storage
   */
  private async loadTrustedUsers(): Promise<void> {
    try {
      const users = await this.get('trustedUsers') as string[] || [];
      this._trustedUsersSubject.next(users);
    } catch (error) {
      console.error('Error loading trusted users:', error);
      this._trustedUsersSubject.next([]);
    }
  }
} 