import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject, Subject, of } from 'rxjs';
import { WebSocketService } from '../../services/websocket.service';
import { SafetyAlert } from '@app/state/models/safety.model';
import { catchError, map } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/compat/firestore';

/**
 * Safety service that handles all safety-related functionality
 * including emergency protocols, verification, and location tracking
 */
@Injectable({
  providedIn: 'root'
})
export class SafetyService {
  private apiUrl = 'api/safety';
  private alertSubject = new Subject<SafetyAlert>();
  private alertsSubject = new BehaviorSubject<SafetyAlert[]>([]);
  private childSafetyCodeSubject = new BehaviorSubject<string | null>(null);
  private locationTrackingActive = new BehaviorSubject<boolean>(false);

  constructor(
    private http: HttpClient,
    private wsService: WebSocketService,
    private firestore: AngularFirestore
  ) {
    this.initializeWebSocket();
  }

  private initializeWebSocket() {
    this.wsService.connect('safety').subscribe(
      (message: any) => {
        if (message.type === 'safety_alert') {
          this.alertSubject.next(message.data);
          
          const currentAlerts = this.alertsSubject.getValue();
          this.alertsSubject.next([message.data, ...currentAlerts]);
        }
      }
    );
  }

  /**
   * Get user safety preferences from Firestore
   */
  async getUserSafetyPreferences(userId: string): Promise<any> {
    try {
      const docRef = await this.firestore.collection('users').doc(userId)
        .collection('settings').doc('safetyPreferences').get().toPromise();
      
      if (docRef && docRef.exists) {
        return docRef.data();
      } else {
        // Return default preferences if not set
        return {
          locationTracking: true,
          photoVerification: true,
          emergencySharing: true,
          safetyCode: true,
          shareRideDetails: true,
          sendRideNotifications: true,
          automaticEmergencyDetection: false
        };
      }
    } catch (error) {
      console.error('Error getting safety preferences:', error);
      // Return default preferences on error
      return {
        locationTracking: true,
        photoVerification: true,
        emergencySharing: true,
        safetyCode: true,
        shareRideDetails: true,
        sendRideNotifications: true,
        automaticEmergencyDetection: false
      };
    }
  }

  /**
   * Update user safety preferences in Firestore
   */
  async updateSafetyPreferences(userId: string, preferences: any): Promise<boolean> {
    try {
      await this.firestore.collection('users').doc(userId)
        .collection('settings').doc('safetyPreferences').set(preferences, { merge: true });
      
      // Update local state for real-time access
      if (preferences.locationTracking !== undefined) {
        this.setLocationTrackingActive(preferences.locationTracking);
      }
      
      console.log('Safety preferences updated for user', userId);
      return true;
    } catch (error) {
      console.error('Error updating safety preferences:', error);
      return false;
    }
  }

  /**
   * Get emergency contacts for a user from Firestore
   */
  async getEmergencyContacts(userId: string): Promise<any[]> {
    try {
      const snapshot = await this.firestore.collection('users').doc(userId)
        .collection('emergencyContacts').get().toPromise();
      
      if (snapshot && !snapshot.empty) {
        return snapshot.docs.map(doc => {
          return { id: doc.id, ...doc.data() };
        });
      } else {
        return [];
      }
    } catch (error) {
      console.error('Error getting emergency contacts:', error);
      return [];
    }
  }

  /**
   * Add an emergency contact
   */
  async addEmergencyContact(userId: string, contact: any): Promise<string | null> {
    try {
      const docRef = await this.firestore.collection('users').doc(userId)
        .collection('emergencyContacts').add(contact);
      return docRef.id;
    } catch (error) {
      console.error('Error adding emergency contact:', error);
      return null;
    }
  }

  /**
   * Update an emergency contact
   */
  async updateEmergencyContact(userId: string, contactId: string, contact: any): Promise<boolean> {
    try {
      await this.firestore.collection('users').doc(userId)
        .collection('emergencyContacts').doc(contactId).update(contact);
      return true;
    } catch (error) {
      console.error('Error updating emergency contact:', error);
      return false;
    }
  }

  /**
   * Delete an emergency contact
   */
  async deleteEmergencyContact(userId: string, contactId: string): Promise<boolean> {
    try {
      await this.firestore.collection('users').doc(userId)
        .collection('emergencyContacts').doc(contactId).delete();
      return true;
    } catch (error) {
      console.error('Error deleting emergency contact:', error);
      return false;
    }
  }

  /**
   * Trigger emergency protocol with location information
   */
  triggerEmergency(userId: string, location: { lat: number; lng: number }): Observable<boolean> {
    return this.http.post<{ success: boolean }>(`${this.apiUrl}/emergency`, { userId, location })
      .pipe(
        map(response => response.success),
        catchError(error => {
          console.error('Error triggering emergency:', error);
          return of(false);
        })
      );
  }

  /**
   * Get safety alerts for a user
   */
  getAlerts(): Observable<SafetyAlert[]> {
    return this.alertsSubject.asObservable();
  }

  /**
   * Get latest safety alert
   */
  getLatestAlert(): Observable<SafetyAlert> {
    return this.alertSubject.asObservable();
  }

  /**
   * Set child safety code
   */
  setChildSafetyCode(code: string): void {
    this.childSafetyCodeSubject.next(code);
  }

  /**
   * Get child safety code
   */
  getChildSafetyCode(): Observable<string | null> {
    return this.childSafetyCodeSubject.asObservable();
  }

  /**
   * Set location tracking status
   */
  setLocationTrackingActive(active: boolean): void {
    this.locationTrackingActive.next(active);
  }

  /**
   * Get location tracking status
   */
  getLocationTrackingActive(): Observable<boolean> {
    return this.locationTrackingActive.asObservable();
  }

  /**
   * @deprecated Use getLocationTrackingActive instead
   * Kept for backwards compatibility
   */
  isLocationTrackingActive(): Observable<boolean> {
    return this.getLocationTrackingActive();
  }

  /**
   * Upload verification photo for child pickup/dropoff
   */
  uploadVerificationPhoto(rideId: string, photo: File): Observable<SafetyAlert> {
    // Mock implementation for demo purposes
    console.log('Uploading verification photo for ride', rideId);
    const mockResponse: SafetyAlert = {
      type: 'info',
      title: 'Verification Photo Uploaded',
      message: 'Your verification photo has been uploaded successfully',
      timestamp: new Date()
    };
    
    // Add to the alerts array
    const currentAlerts = this.alertsSubject.getValue();
    this.alertsSubject.next([mockResponse, ...currentAlerts]);
    
    return of(mockResponse);
  }

  /**
   * Generate a unique safety code for child verification
   */
  generateChildSafetyCode(childId: string): Observable<string> {
    // Mock implementation for demo purposes
    const code = Math.floor(1000 + Math.random() * 9000).toString(); // 4-digit code
    this.setChildSafetyCode(code);
    return of(code);
  }

  /**
   * Verify child safety code
   */
  verifyChildSafetyCode(code: string, rideId: string): Observable<boolean> {
    // Mock implementation for demo purposes
    return this.getChildSafetyCode().pipe(
      map(storedCode => storedCode === code)
    );
  }
}
