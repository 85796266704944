import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IonicModule, ToastController } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { RideSchedulingService } from '../ride-scheduling.service';
import { RideSchedule, RideReview } from '../../../shared/models/ride.model';
import { PageHeaderComponent } from '../../../shared/ui/page-header/page-header.component';

@Component({
  selector: 'app-ride-review',
  templateUrl: './ride-review.component.html',
  styleUrls: ['./ride-review.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    IonicModule,
    TranslateModule,
    PageHeaderComponent
  ]
})
export class RideReviewComponent implements OnInit {
  ride: RideSchedule | null = null;
  reviewForm: FormGroup;
  selectedRating = 0;
  isSubmitting = false;
  maxCommentLength = 500;

  readonly categoryOptions = [
    { label: 'Safe Driving', value: 'safe_driving' },
    { label: 'Punctual', value: 'punctual' },
    { label: 'Clean Vehicle', value: 'clean_vehicle' },
    { label: 'Professional', value: 'professional' },
    { label: 'Good Communication', value: 'good_communication' },
    { label: 'Child Friendly', value: 'child_friendly' }
  ];

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private toastController: ToastController,
    private rideService: RideSchedulingService
  ) {
    this.initForm();
  }

  ngOnInit(): void {
    const rideId = this.route.snapshot.paramMap.get('id');
    if (rideId) {
      this.rideService.getCurrentRide().subscribe(ride => {
        if (ride && ride.id === rideId) {
          this.ride = ride;
        }
      });
    }
  }

  /**
   * Initialize the review form
   */
  private initForm(): void {
    this.reviewForm = this.fb.group({
      rating: [null, [Validators.required, Validators.min(1), Validators.max(5)]],
      categories: [[]],
      comment: ['', Validators.maxLength(500)]
    });
  }

  /**
   * Set rating value
   */
  setRating(rating: number): void {
    this.selectedRating = rating;
    this.reviewForm.patchValue({ rating });
  }

  /**
   * Toggle category selection
   */
  toggleCategory(category: string): void {
    const categories = this.reviewForm.get('categories').value;
    const index = categories.indexOf(category);
    
    if (index === -1) {
      categories.push(category);
    } else {
      categories.splice(index, 1);
    }
    
    this.reviewForm.patchValue({ categories });
  }

  /**
   * Check if a category is selected
   */
  isCategorySelected(category: string): boolean {
    return this.reviewForm.get('categories').value.includes(category);
  }

  /**
   * Submit review
   */
  async submitReview(): Promise<void> {
    if (this.reviewForm.invalid || !this.ride) {
      return;
    }

    const formValue = this.reviewForm.value;
    const review: RideReview = {
      id: `review-${Date.now()}`,
      rideId: this.ride.id,
      reviewerId: 'current',
      rating: formValue.rating,
      comment: formValue.comment,
      categories: formValue.categories,
      created: new Date()
    };

    this.isSubmitting = true;
    try {
      // TODO: Implement review submission in service
      // await this.rideService.submitReview(review);
      
      const toast = await this.toastController.create({
        message: 'Review submitted successfully',
        duration: 3000,
        position: 'bottom',
        color: 'success',
        buttons: [
          {
            text: 'Close',
            role: 'cancel'
          }
        ]
      });
      await toast.present();
      
      this.router.navigate(['/rides/history']);
    } catch (error) {
      console.error('Error submitting review:', error);
      const errorToast = await this.toastController.create({
        message: 'Failed to submit review',
        duration: 3000,
        position: 'bottom',
        color: 'danger',
        buttons: [
          {
            text: 'Close',
            role: 'cancel'
          }
        ]
      });
      await errorToast.present();
    } finally {
      this.isSubmitting = false;
    }
  }

  /**
   * Get star array for template
   */
  getStarArray(): number[] {
    return Array(5).fill(0).map((_, i) => i + 1);
  }

  /**
   * Get remaining characters count for comment
   */
  getRemainingChars(): number {
    const maxLength = 500;
    const currentLength = this.reviewForm.get('comment').value?.length || 0;
    return maxLength - currentLength;
  }
}