import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State, journeyFeatureKey } from './journey.reducer';
import { combineLatest } from 'rxjs';

export const selectJourneyState = createFeatureSelector<State>(journeyFeatureKey);

// Location selectors
export const selectPickupLocation = createSelector(
  selectJourneyState,
  (state: State) => state.pickupLocation
);

export const selectDropOffLocation = createSelector(
  selectJourneyState,
  (state: State) => state.dropOffLocation
);

export const selectRouteData = createSelector(
  selectJourneyState,
  (state: State) => state.routeData
);

// Date and journey type selectors
export const selectSelectedDate = createSelector(
  selectJourneyState,
  (state: State) => state.selectedDate
);

export const selectSelectedReturnDate = createSelector(
  selectJourneyState,
  (state: State) => state.selectedReturnDate
);

export const selectJourneyType = createSelector(
  selectJourneyState,
  (state: State) => state.journeyType
);

// Ride preferences selectors
export const selectPassengerCount = createSelector(
  selectJourneyState,
  (state: State) => state.passengerCount
);

export const selectSelectedLuggage = createSelector(
  selectJourneyState,
  (state: State) => state.selectedLuggage
);

export const selectRideOptions = createSelector(
  selectJourneyState,
  (state: State) => state.rideOptions as {pets: boolean, smoking: boolean, wheelchair: boolean}
);

// UI state selectors
export const selectLocationSelectionMode = createSelector(
  selectJourneyState,
  (state: State) => state.locationSelectionMode
);

export const selectIsEditingRide = createSelector(
  selectJourneyState,
  (state: State) => state.isEditingRide
);

export const selectCurrentTab = createSelector(
  selectJourneyState,
  (state: State) => state.currentTab
);

// Ride details selectors
export const selectSelectedRideDetails = createSelector(
  selectJourneyState,
  (state: State) => state.selectedRideDetails
);

export const selectFindRideDetails = createSelector(
  selectJourneyState,
  (state: State) => state.findRideDetails
);

// Legacy properties selectors
export const selectJourneyFlag = createSelector(
  selectJourneyState,
  (state: State) => state._journey
);

export const selectSelectedJourneyForEdit = createSelector(
  selectJourneyState,
  (state: State) => state.selectedJourneyForEdit
);

export const selectSelectedLocation = createSelector(
  selectJourneyState,
  (state: State) => state.selectedLocation
);

export const selectOnwardRideDetails = createSelector(
  selectJourneyState,
  (state: State) => state.onwardRideDetails
);

export const selectReturnRideDetails = createSelector(
  selectJourneyState,
  (state: State) => state.returnRideDetails
);

export const selectStopovers = createSelector(
  selectJourneyState,
  (state: State) => state.stopovers
);

export const selectRideDirection = createSelector(
  selectJourneyState,
  (state: State) => state.rideDirection
);

export const selectRideType = createSelector(
  selectJourneyState,
  (state: State) => state.rideType
);

export const selectTotalFare = createSelector(
  selectJourneyState,
  (state: State) => state.totalFare
);

export const selectBookRideConfirmation = createSelector(
  selectJourneyState,
  (state: State) => state.bookRideConfirmation
);

export const selectBookedRides = createSelector(
  selectJourneyState,
  (state: State) => state.bookedRides
);

export const selectOfferedRides = createSelector(
  selectJourneyState,
  (state: State) => state.offeredRides
);

// Derived selectors
export const selectHasValidLocations = createSelector(
  selectPickupLocation,
  selectDropOffLocation,
  (pickup, dropoff) => Boolean(
    pickup?.lat && pickup?.lng && dropoff?.lat && dropoff?.lng
  )
);
