import { createFeatureSelector, createSelector } from '@ngrx/store';

// Define the profile state structure
export interface ProfileState {
  profiles: any[];
  loading: boolean;
  error: any;
}

// Feature selector with proper typing
export const getProfileState = createFeatureSelector<ProfileState>('profile');

// Selectors for backward compatibility
export const selectAllProfiles = createSelector(
  getProfileState,
  state => state?.profiles || []
); 