import { createAction, props } from '@ngrx/store';
import { TrustedGroup, TrustedMember, TrustedInvite } from '../models/trusted-network.model';

export const trustedNetworkFeatureKey = 'trustedNetwork';

// Load groups actions
export const loadGroups = createAction(
  '[Trusted Network] Load Groups'
);

export const loadGroupsSuccess = createAction(
  '[Trusted Network] Load Groups Success',
  props<{ groups: TrustedGroup[] }>()
);

export const loadGroupsFailure = createAction(
  '[Trusted Network] Load Groups Failure',
  props<{ error: any }>()
);

// Load single group actions
export const loadGroup = createAction(
  '[Trusted Network] Load Group',
  props<{ groupId: string }>()
);

export const loadGroupSuccess = createAction(
  '[Trusted Network] Load Group Success',
  props<{ group: TrustedGroup }>()
);

export const loadGroupFailure = createAction(
  '[Trusted Network] Load Group Failure',
  props<{ error: any }>()
);

// Create group actions
export const createGroup = createAction(
  '[Trusted Network] Create Group',
  props<{ group: Partial<TrustedGroup> }>()
);

export const createGroupSuccess = createAction(
  '[Trusted Network] Create Group Success',
  props<{ group: TrustedGroup }>()
);

export const createGroupFailure = createAction(
  '[Trusted Network] Create Group Failure',
  props<{ error: any }>()
);

// Update group actions
export const updateGroup = createAction(
  '[Trusted Network] Update Group',
  props<{ groupId: string, changes: Partial<TrustedGroup> }>()
);

export const updateGroupSuccess = createAction(
  '[Trusted Network] Update Group Success',
  props<{ group: TrustedGroup }>()
);

export const updateGroupFailure = createAction(
  '[Trusted Network] Update Group Failure',
  props<{ error: any }>()
);

// Delete group actions
export const deleteGroup = createAction(
  '[Trusted Network] Delete Group',
  props<{ groupId: string }>()
);

export const deleteGroupSuccess = createAction(
  '[Trusted Network] Delete Group Success',
  props<{ groupId: string }>()
);

export const deleteGroupFailure = createAction(
  '[Trusted Network] Delete Group Failure',
  props<{ error: any }>()
);

// Load members actions
export const loadMembers = createAction(
  '[Trusted Network] Load Members'
);

export const loadMembersSuccess = createAction(
  '[Trusted Network] Load Members Success',
  props<{ members: TrustedMember[] }>()
);

export const loadMembersFailure = createAction(
  '[Trusted Network] Load Members Failure',
  props<{ error: any }>()
);

// Create invite actions
export const createInvite = createAction(
  '[Trusted Network] Create Invite',
  props<{ groupId: string, email?: string, phone?: string }>()
);

export const createInviteSuccess = createAction(
  '[Trusted Network] Create Invite Success',
  props<{ invite: TrustedInvite }>()
);

export const createInviteFailure = createAction(
  '[Trusted Network] Create Invite Failure',
  props<{ error: any }>()
);

// Join group actions
export const joinGroup = createAction(
  '[Trusted Network] Join Group',
  props<{ inviteCode: string }>()
);

export const joinGroupSuccess = createAction(
  '[Trusted Network] Join Group Success',
  props<{ groupId: string }>()
);

export const joinGroupFailure = createAction(
  '[Trusted Network] Join Group Failure',
  props<{ error: any }>()
);

// Set loading and error states
export const setLoading = createAction(
  '[Trusted Network] Set Loading',
  props<{ loading: boolean }>()
);

export const setError = createAction(
  '[Trusted Network] Set Error',
  props<{ error: string | null }>()
);

// Reset state actions
export const resetTrustedNetworkState = createAction(
  '[Trusted Network] Reset Trusted Network State'
); 