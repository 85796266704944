import { createAction, props } from '@ngrx/store';

// Create/Update Ride Actions (kept from before)
export const createRide = createAction(
  '[Ride] Create Ride',
  props<{ ride: any }>()
);

export const createRideSuccess = createAction(
  '[Ride] Create Ride Success',
  props<{ ride: any }>()
);

export const createRideFailure = createAction(
  '[Ride] Create Ride Failure',
  props<{ error: any }>()
);

// Get All Rides Actions (kept from before)
export const rideGetAll = createAction(
  '[Ride] Get All'
);

export const rideGetAllSuccess = createAction(
  '[Ride] Get All Success',
  props<{ rides: any[] }>()
);

export const rideGetAllFailure = createAction(
  '[Ride] Get All Failure',
  props<{ error: any }>()
);

// Get One Ride Actions (kept from before)
export const rideGet = createAction(
  '[Ride] Get One',
  props<{ id: string }>()
);

export const rideGetSuccess = createAction(
  '[Ride] Get One Success',
  props<{ ride: any }>()
);

export const rideGetFailure = createAction(
  '[Ride] Get One Failure',
  props<{ error: any }>()
);

// Update Ride Actions (kept from before)
export const updateRide = createAction(
  '[Ride] Update',
  props<{ ride: any }>()
);

export const updateRideSuccess = createAction(
  '[Ride] Update Success',
  props<{ ride: any }>()
);

export const updateRideFailure = createAction(
  '[Ride] Update Failure',
  props<{ error: any }>()
);

// Delete Ride Actions (kept from before)
export const deleteRide = createAction(
  '[Ride] Delete',
  props<{ id: string }>()
);

export const deleteRideSuccess = createAction(
  '[Ride] Delete Success',
  props<{ id: string }>()
);

export const deleteRideFailure = createAction(
  '[Ride] Delete Failure',
  props<{ error: any }>()
);

// Select Ride Actions (kept from before)
export const selectRide = createAction(
  '[Ride] Select',
  props<{ ride: any }>()
);

// Cancel Ride Actions (kept from before)
export const cancelRide = createAction(
  '[Ride] Cancel Ride',
  props<{ ride: any }>()
);

export const cancelRideSuccess = createAction(
  '[Ride] Cancel Ride Success',
  props<{ ride: any }>()
);

export const cancelRideFailure = createAction(
  '[Ride] Cancel Ride Failure',
  props<{ error: any }>()
);

// Filter Rides Actions (kept from before)
export const getAllRidesFilterSuccess = createAction(
  '[Ride] Get All Rides Filter Success',
  props<{ 
    bookedRides: any[],
    offeredRides: any[],
    rideHistory: any[]
  }>()
);

// Clear Selected Ride (kept from before)
export const clearSelectedRide = createAction(
  '[Ride] Clear Selected Ride'
);

// MISSING ACTIONS - Adding these to fix your errors

// Legacy loadRides actions (for backwards compatibility)
export const loadRides = createAction(
  '[Ride] Load Rides'
);

export const loadRidesSuccess = createAction(
  '[Ride] Load Rides Success',
  props<{ rides: any[] }>()
);

export const loadRidesFailure = createAction(
  '[Ride] Load Rides Failure',
  props<{ error: any }>()
);

// Legacy rideUpsert actions (for backwards compatibility)
export const rideUpsert = createAction(
  '[Ride] Upsert',
  props<{ ride: any }>()
);

export const rideUpsertSuccess = createAction(
  '[Ride] Upsert Success',
  props<{ ride: any }>()
);

export const rideUpsertFailure = createAction(
  '[Ride] Upsert Failure',
  props<{ error: any }>()
);

// Book ride actions
export const bookRide = createAction(
  '[Ride] Book Ride',
  props<{ ride: any }>()
);

export const bookRideSuccess = createAction(
  '[Ride] Book Ride Success',
  props<{ ride: any }>()
);

export const bookRideFailure = createAction(
  '[Ride] Book Ride Failure',
  props<{ error: any }>()
);

// Filter actions
export const getAllRidesFilter = createAction(
  '[Ride] Get All Rides Filter',
  props<{ userId: string }>()
);

export const getAllRidesFilterFailure = createAction(
  '[Ride] Get All Rides Filter Failure',
  props<{ error: any }>()
);

// Search actions
export const searchRide = createAction(
  '[Ride] Search',
  props<{ searchParams: any }>()
);

export const searchRideSuccess = createAction(
  '[Ride] Search Success',
  props<{ rides: any[] }>()
);

export const searchRideFailure = createAction(
  '[Ride] Search Failure',
  props<{ error: any }>()
);

// Filter search results actions
export const filterSearchResultsSuccess = createAction(
  '[Ride] Filter Search Results Success',
  props<{ rides: any[] }>()
);

export const filterSearchResultsFailure = createAction(
  '[Ride] Filter Search Results Failure',
  props<{ error: any }>()
);

// Load Offered Rides actions
export const loadOfferedRides = createAction('[Ride] Load Offered Rides');
export const loadOfferedRidesSuccess = createAction(
  '[Ride] Load Offered Rides Success',
  props<{ rides: any[] }>()
);
export const loadOfferedRidesFailure = createAction(
  '[Ride] Load Offered Rides Failure',
  props<{ error: any }>()
);

// Select Ride Success/Failure
export const selectRideSuccess = createAction(
  '[Ride] Select Success',
  props<{ ride: any }>()
);
export const selectRideFailure = createAction(
  '[Ride] Select Failure',
  props<{ error: any }>()
);

// Search Rides (plural form)
export const searchRides = createAction(
  '[Ride] Search Rides',
  props<{ criteria: any }>()
);
export const searchRidesSuccess = createAction(
  '[Ride] Search Rides Success',
  props<{ results: any[] }>()
);
export const searchRidesFailure = createAction(
  '[Ride] Search Rides Failure',
  props<{ error: any }>()
);

// Cancel Booking
export const cancelBooking = createAction(
  '[Ride] Cancel Booking',
  props<{ rideId: string }>()
);
export const cancelBookingSuccess = createAction(
  '[Ride] Cancel Booking Success',
  props<{ rideId: string }>()
);
export const cancelBookingFailure = createAction(
  '[Ride] Cancel Booking Failure',
  props<{ error: any }>()
);

// Filter Rides
export const filterRides = createAction(
  '[Ride] Filter Rides',
  props<{ filters: any }>()
);
export const filterRidesSuccess = createAction(
  '[Ride] Filter Rides Success',
  props<{ filteredData: any }>()
);
export const filterRidesFailure = createAction(
  '[Ride] Filter Rides Failure',
  props<{ error: any }>()
);

// Clear error and reset state
export const clearRideError = createAction('[Ride] Clear Error');
export const resetRideState = createAction('[Ride] Reset State');

// Add this action
export const clearRideHistory = createAction('[Ride] Clear Ride History');

// Pagination actions
export const setPage = createAction(
  '[Ride] Set Page',
  props<{ page: number }>()
);

export const setPageSize = createAction(
  '[Ride] Set Page Size',
  props<{ pageSize: number }>()
);

export const updatePagination = createAction(
  '[Ride] Update Pagination',
  props<{ 
    currentPage?: number; 
    pageSize?: number; 
    totalItems?: number; 
    totalPages?: number; 
  }>()
);
