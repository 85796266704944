import * as LoginFormActions from './login-form.actions';
import { createReducer, on } from '@ngrx/store';

export const loginFormPageFeatureKey = 'loginFormPage';

export interface State {
  error: string | null;
  pending: boolean;
  isLoggedIn: boolean;
}

export const initialState: State = {
  error: null,
  pending: false,
  isLoggedIn: false,
};

export const reducer = createReducer(
  initialState,
  on(LoginFormActions.login, state => ({
    ...state,
    error: null,
    pending: true,
    isLoggedIn: false,
  })),

  on(LoginFormActions.loginSuccess, (state) => ({
    ...state,
    error: null,
    pending: false,
    isLoggedIn: true,
  })),

  on(LoginFormActions.loginFailure, (state, { error }) => ({
    ...state,
    error,
    pending: false,
    isLoggedIn: false,
  }))
);


export const getError = (state: State) => state.error;
export const getPending = (state: State) => state.pending;
export const getIsLoggedIn = (state: State) => state.isLoggedIn;

