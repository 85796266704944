import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, finalize, of, timer, fromEvent, merge, Subject, switchMap } from 'rxjs';
import { tap, catchError, map, mapTo, startWith, distinctUntilChanged, share } from 'rxjs/operators';
import { TrustConnection, ParentUser } from '@app/state/models/network.model';
import { Contact } from '@app/state/models/contact.model';

export interface NetworkContact {
  id: string;
  name: string;
  photo?: string;
  trusted: boolean;
  lastActive?: Date;
  status: 'pending' | 'active' | 'blocked';
}

@Injectable({
  providedIn: 'root'
})
export class NetworkService {
  private apiUrl = 'api/network';
  private contactsSubject = new BehaviorSubject<Contact[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  private online = navigator.onLine;
  private onlineStatusChange$ = new Subject<boolean>();

  readonly contacts$ = this.contactsSubject.asObservable();
  readonly loading$ = this.loadingSubject.asObservable();

  constructor(private http: HttpClient) {
    // Listen for online/offline events
    fromEvent(window, 'online').subscribe(() => {
      this.online = true;
      this.onlineStatusChange$.next(true);
    });

    fromEvent(window, 'offline').subscribe(() => {
      this.online = false;
      this.onlineStatusChange$.next(false);
    });
  }

  /**
   * Check if device is currently online
   */
  isOnline(): boolean {
    return this.online;
  }

  /**
   * Get network status observable
   */
  getNetworkStatus(): Observable<boolean> {
    return this.onlineStatusChange$.asObservable();
  }

  /**
   * Get a delay stream for retry operations with exponential backoff
   * @param retryCount The current retry count
   */
  getRetryDelayStream(retryCount: number): Observable<number> {
    // Calculate delay with exponential backoff, max 30 seconds
    const delayMs = Math.min(1000 * Math.pow(2, retryCount), 30000);
    
    // If we're offline, wait for connection before retrying
    if (!this.isOnline()) {
      return this.waitForConnection().pipe(
        switchMap(() => timer(1000))
      );
    }
    
    return timer(delayMs);
  }

  /**
   * Wait for connection to be restored
   */
  private waitForConnection(): Observable<boolean> {
    // If already online, return immediately
    if (this.isOnline()) {
      return of(true);
    }
    
    // Otherwise wait for online event
    return this.onlineStatusChange$.pipe(
      map(online => online)
    );
  }

  /**
   * Check if an error is related to network connectivity
   */
  isNetworkError(error: any): boolean {
    return (
      error.status === 0 ||
      error.name === 'NetworkError' ||
      (error.message && (
        error.message.includes('NetworkError') ||
        error.message.includes('Network Error') ||
        error.message.includes('Failed to fetch') ||
        error.message.includes('Network request failed')
      ))
    );
  }

  searchParents(query: string): Observable<ParentUser[]> {
    return this.http.get<ParentUser[]>(`${this.apiUrl}/search`, {
      params: { query }
    });
  }

  sendTrustRequest(targetUserId: string): Observable<TrustConnection> {
    return this.http.post<TrustConnection>(`${this.apiUrl}/connections`, {
      targetUserId
    });
  }

  respondToTrustRequest(connectionId: string, accept: boolean): Observable<TrustConnection> {
    return this.http.patch<TrustConnection>(
      `${this.apiUrl}/connections/${connectionId}`,
      { status: accept ? 'accepted' : 'rejected' }
    );
  }

  getConnections(): Observable<TrustConnection[]> {
    return this.http.get<TrustConnection[]>(`${this.apiUrl}/connections`);
  }

  loadContacts(): Observable<Contact[]> {
    this.loadingSubject.next(true);
    return this.http.get<Contact[]>(`${this.apiUrl}/contacts`).pipe(
      tap(contacts => {
        this.contactsSubject.next(contacts);
      }),
      finalize(() => this.loadingSubject.next(false)),
      catchError(error => {
        throw error;
      })
    );
  }

  addContact(contact: Partial<NetworkContact>): Observable<NetworkContact> {
    return this.http.post<NetworkContact>(`${this.apiUrl}/contacts`, contact).pipe(
      tap(newContact => {
        const currentContacts = this.contactsSubject.value;
        this.contactsSubject.next([...currentContacts, newContact]);
      })
    );
  }

  updateTrustedStatus(contactId: string, trusted: boolean): Observable<NetworkContact> {
    return this.http.patch<NetworkContact>(`${this.apiUrl}/contacts/${contactId}`, { trusted }).pipe(
      tap(updatedContact => {
        const currentContacts = this.contactsSubject.value;
        const index = currentContacts.findIndex(c => c.id === contactId);
        if (index !== -1) {
          currentContacts[index] = updatedContact;
          this.contactsSubject.next([...currentContacts]);
        }
      })
    );
  }
}