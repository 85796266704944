import { Component, OnInit, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';
import { NotificationService } from '@app/core/services/notification.service';
import { Notification, NotificationUtils } from '@app/models/notification.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
  standalone: true,
  imports: [CommonModule, IonicModule, RouterModule]
})
export class NotificationsComponent implements OnInit, OnDestroy {
  notifications: Notification[] = [];
  unreadCount: number = 0;
  private subscriptions: Subscription[] = [];

  constructor(private notificationService: NotificationService) {}

  ngOnInit() {
    // Subscribe to notifications
    this.subscriptions.push(
      this.notificationService.getNotifications().subscribe(notifications => {
        // Show only 5 most recent notifications in dropdown
        this.notifications = notifications
          .slice(0, 5)
          .sort((a, b) => b.timestamp - a.timestamp);
      })
    );

    // Subscribe to unread count
    this.subscriptions.push(
      this.notificationService.getUnreadCount().subscribe(count => {
        this.unreadCount = count;
      })
    );
  }

  ngOnDestroy() {
    // Unsubscribe to prevent memory leaks
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  markAsRead(notification: Notification) {
    if (notification.id) {
      this.notificationService.markAsRead(notification.id);
    }
  }

  getNotificationIcon(type: string): string {
    return NotificationUtils.getIconForType(type as any);
  }
  
  formatTimestamp(timestamp: number): string {
    return NotificationUtils.formatTimestamp(timestamp);
  }
  
  getColorForPriority(priority: string): string {
    return NotificationUtils.getColorForPriority(priority as any);
  }
} 