import { createReducer, on } from '@ngrx/store';
import { 
  userUpsert,
  userUpsertSuccess,
  userUpsertFailure,
  userUpdate,
  userUpdateSuccess,
  userUpdateFailure,
  userGet,
  userGetSuccess,
  userGetFailure,
  userGetAll,
  userGetAllSuccess,
  userGetAllFailure,
  userSignUp,
  updateNotificationSettings,
  updateNotificationSettingsSuccess,
  updateNotificationSettingsFailure
} from './user.actions';
import { User } from '@app/state/models/user';

export const userPageFeatureKey = 'userPage';

export interface State {
    user: any;
    users: any[];
    error: string | null;
    pending: boolean;
    settings: any;
}

export const initialState: State = {
    user: null,
    users: null,
    error: null,
    pending: false,
    settings: { email: false, sms: false, push: false},
};

export const userReducer = createReducer(
    initialState,
    on(userUpsert, (state) => ({
        ...state,
        error: null,
        pending: true,
    })),

    on(userUpsertSuccess, (state, { user }) => ({
        ...state,
        user,
        error: null,
        pending: false,
    })),

    on(userUpsertFailure, (state, { error }) => ({
        ...state,
        error,
        pending: false,
    })),

    on(userUpdate, (state) => ({
        ...state,
        error: null,
        pending: true,
    })),

    on(userUpdateSuccess, (state, { user }) => ({
        ...state,
        user,
        error: null,
        pending: false,
    })),

    on(userUpdateFailure, (state, { error }) => ({
        ...state,
        error,
        pending: false,
    })),

    on(userGetSuccess, (state, { user }) => {
        if (!user) {
            return { ...state };
        }

        return {
            ...state,
            user: user,
            error: null,
            pending: false
        };
    }),

    on(userGetFailure, (state, { error }) => ({
        ...state,
        error,
        pending: false,
    })),

    on(userGetAllSuccess, (state, { users }) => ({
        ...state,
        users: users,
        error: null,
        pending: false,
    })),

    on(userGetAllFailure, (state, { error }) => ({
        ...state,
        error,
        pending: false,
    })),

    on(userSignUp, (state, { data }) => ({
        ...state,
        user: { email: data.email, emailVerified: false },
        pending: false,
    })),

    on(updateNotificationSettings, (state) => ({
        ...state,
        error: null,
        pending: true,
    })),

    on(updateNotificationSettingsSuccess, (state, { user }) => {
        // Get settings from the user response or keep current settings
        const updatedSettings = user && user.settings ? user.settings : state.settings;

        // Update user with new settings if user exists
        const updatedUser = state.user ? {
            ...state.user,
            settings: updatedSettings
        } : null;

        return {
            ...state,
            settings: updatedSettings,
            user: updatedUser,
            error: null,
            pending: false
        };
    }),

    on(updateNotificationSettingsFailure, (state, { error }) => ({
        ...state,
        error,
        pending: false,
    }))
);

export const getError = (state: State) => state.error;
export const getPending = (state: State) => state.pending;
export const getUser = (state: State) => state.user;
export const getAllUsers = (state: State) => state.users;
export const getSettings = (state: State) => state.settings;

export { userReducer as reducer };