import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { PipesModule } from '../pipes/pipes.module';

// Admin features
import { EmergencyMonitorComponent } from './admin/emergency-monitor/emergency-monitor.component';

// Drivers features
import { DriverListComponent } from './drivers/driver-list/driver-list.component';
import { DriverProfileComponent } from './drivers/driver-profile/driver-profile.component';
import { DriverListSkeletonComponent } from './drivers/driver-list/driver-list-skeleton/driver-list-skeleton.component';

// Network features
import { NetworkComponent } from './network/network.component';

// Profile features
import { ChildListComponent } from './profile/child-list/child-list.component';
import { ChildFormComponent } from './profile/child-form/child-form.component';

// Rides features
import { ActiveRidesComponent } from './rides/active-rides/active-rides.component';
import { RideHistoryComponent } from './rides/ride-history/ride-history.component';
import { RideReviewComponent } from './rides/ride-review/ride-review.component';
import { ScheduleRideComponent } from './rides/schedule-ride/schedule-ride.component';
import { RideTrackingComponent } from './rides/ride-tracking/ride-tracking.component';

// Safety features
import { SafetyComponent } from './safety/safety.component';
import { EmergencyButtonComponent } from './safety/emergency-button/emergency-button.component';
import { LocationTrackerComponent } from './safety/location-tracker/location-tracker.component';
import { VerificationPhotoComponent } from './safety/verification-photo/verification-photo.component';

// Verification features
import { VerificationProcessComponent } from './verification/verification-process/verification-process.component';

@NgModule({
  declarations: [
    // No declarations as all components are now standalone
  ],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    RouterModule,
    SharedModule,
    PipesModule,
    
    // Admin features (standalone)
    EmergencyMonitorComponent,
    
    // Drivers features (standalone)
    DriverListComponent,
    DriverProfileComponent,
    DriverListSkeletonComponent,
    
    // Network features (standalone)
    NetworkComponent,
    
    // Profile features (standalone)
    ChildListComponent,
    ChildFormComponent,
    
    // Rides features (standalone)
    ActiveRidesComponent,
    RideHistoryComponent,
    RideReviewComponent,
    ScheduleRideComponent,
    RideTrackingComponent,
    
    // Safety features (standalone)
    SafetyComponent,
    EmergencyButtonComponent,
    LocationTrackerComponent,
    VerificationPhotoComponent,
    
    // Verification features (standalone)
    VerificationProcessComponent
  ],
  exports: [
    // Admin features
    EmergencyMonitorComponent,
    
    // Drivers features
    DriverListComponent,
    DriverProfileComponent,
    DriverListSkeletonComponent,
    
    // Network features
    NetworkComponent,
    
    // Profile features
    ChildListComponent,
    ChildFormComponent,
    
    // Rides features
    ActiveRidesComponent,
    RideHistoryComponent,
    RideReviewComponent,
    ScheduleRideComponent,
    RideTrackingComponent,
    
    // Safety features
    SafetyComponent,
    EmergencyButtonComponent,
    LocationTrackerComponent,
    VerificationPhotoComponent,
    
    // Verification features
    VerificationProcessComponent
  ]
})
export class FeaturesModule { } 