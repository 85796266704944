import { FormGroup, FormControl } from '@angular/forms';
import { UserService } from '@app/services/user.service';
import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { RoleFormService } from '@app/services/role-form.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import * as fromState from '@app/state';
import { selectUser } from '@app/state/user/user.selectors';
import * as EditProfileActions from '@app/state/edit-profile/edit-profile.actions';
import * as UserActions from '@app/state/user/user.actions';
import * as LoginFormActions from '@app/state/login/login-form.actions';

@Component({
  selector: 'app-role-form',
  templateUrl: './role-form.page.html',
  styleUrls: ['./role-form.page.scss']
})
export class RoleFormPage implements OnInit {
  user: any;
  role = null;
  form = new FormGroup({
    role: new FormControl(''),
  });
  title$: Observable<string>;
  user$: Observable<{}>;

  constructor(
    private modalController: ModalController,
    private userService: UserService,
    private userForm: RoleFormService,
    private store: Store<fromState.State>,
  ) { }

  ngOnInit() {
    this.store.pipe(select(selectUser))
      .subscribe(value => {
        if (value) {
          this.user = value;
        }
      });

    this.title$ = this.userForm.title$;
    this.user$ = this.userForm.user$.pipe(
      tap(user => {
        if (user) {
          this.form.patchValue(user);
        } else {
          this.form.reset({});
        }
      })
    );
  }

  onChange(event) {
    this.role = event.detail.value;
  }

  async dismiss() {
    await this.modalController.dismiss('Cancel');
  }

  async save() {
    const roleUser = { displayName: 'smarkle', email: this.user.email, role: this.role, password: 'Doggone', id: this.user.uid };
    await this.modalController.dismiss({ ...roleUser });
  }

}
