import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from './storage.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  // Default language
  private defaultLanguage = 'en';
  
  // Available languages
  private languages = [
    { code: 'en', name: 'English' },
    { code: 'es', name: 'Español' },
    { code: 'fr', name: 'Français' }
  ];
  
  // Current language subject
  private currentLanguage = new BehaviorSubject<string>(this.defaultLanguage);

  constructor(
    private translateService: TranslateService,
    private storageService: StorageService
  ) {
    this.initLanguage();
  }

  /**
   * Initialize the language service
   */
  private async initLanguage() {
    // Set available languages
    this.translateService.addLangs(this.languages.map(lang => lang.code));
    
    // Set default language
    this.translateService.setDefaultLang(this.defaultLanguage);
    
    // Get saved language or use browser language
    const savedLang = await this.storageService.get('language');
    if (savedLang) {
      this.setLanguage(savedLang);
    } else {
      // Detect browser language or use default
      const browserLang = this.translateService.getBrowserLang();
      const useLang = browserLang && this.languages.some(lang => lang.code === browserLang)
        ? browserLang
        : this.defaultLanguage;
      
      this.setLanguage(useLang);
    }
  }

  /**
   * Set the application language
   * @param language Language code to set
   */
  async setLanguage(language: string) {
    // Check if language is supported
    if (!this.languages.some(lang => lang.code === language)) {
      language = this.defaultLanguage;
    }
    
    // Set language
    this.translateService.use(language);
    
    // Save to storage
    await this.storageService.set('language', language);
    
    // Update current language subject
    this.currentLanguage.next(language);
  }

  /**
   * Get the current language code
   * @returns Current language code
   */
  getCurrentLanguage(): string {
    return this.currentLanguage.getValue();
  }
  
  /**
   * Get an observable of the current language
   * @returns Observable of the current language code
   */
  getCurrentLanguageObservable(): Observable<string> {
    return this.currentLanguage.asObservable();
  }

  /**
   * Get all available languages
   * @returns List of available languages
   */
  getLanguages() {
    return this.languages;
  }

  /**
   * Translate a key
   * @param key Translation key
   * @param params Optional parameters for the translation
   * @returns Translated string
   */
  translate(key: string, params: any = null): string {
    if (!key) return '';
    
    try {
      // If translation is not loaded yet, return the key
      if (!this.translateService.instant(key, params)) {
        return key;
      }
      
      return this.translateService.instant(key, params);
    } catch (error) {
      console.error('Translation error:', error);
      return key;
    }
  }

  /**
   * Check if a key exists in the translations
   * @param key Translation key
   * @returns Boolean indicating if key exists
   */
  hasTranslation(key: string): boolean {
    return this.translateService.instant(key) !== key;
  }
} 