import {
  ActionReducerMap,
  MetaReducer,
  createFeatureSelector,
  ActionReducer,
  Action,
  INIT
} from '@ngrx/store';
import { environment } from '@environments/environment';
import * as fromRouter from '@ngrx/router-store';

// Import feature reducers directly (avoiding index.ts files)
import * as fromCalendar from './calendar/calendar.reducer';
import * as fromCar from './car/car.reducer';
import * as fromUser from './user/user.reducer';
import * as fromLoginForm from './login/login-form.reducer';
import * as fromRide from './ride/ride.reducer';
import * as fromEditProfile from './edit-profile/edit-profile.reducer';
import * as fromLayout from './layout/layout.reducer';
import * as fromEvent from './event/event.reducer';
import * as fromProfile from './profile/profile.reducer';
import * as fromJourney from './journey/journey.reducer';
import * as fromMessage from './message/message.reducer';
import * as fromTrustedNetwork from './trusted-network/trusted-network.reducer';

// Define the shape of the entire application state
export interface State {
  [fromCalendar.calendarFeatureKey]: fromCalendar.State;
  [fromCar.carFeatureKey]: fromCar.State;
  [fromUser.userPageFeatureKey]: fromUser.State;
  [fromLoginForm.loginFormPageFeatureKey]: fromLoginForm.State;
  [fromRide.ridePageFeatureKey]: fromRide.RideState;
  [fromEditProfile.editProfilePageFeatureKey]: fromEditProfile.State;
  [fromLayout.layoutFeatureKey]: fromLayout.State;
  [fromEvent.eventFeatureKey]: fromEvent.State;
  [fromProfile.profileFeatureKey]: fromProfile.State;
  [fromJourney.journeyFeatureKey]: fromJourney.State;
  [fromMessage.messageFeatureKey]: fromMessage.MessageState;
  [fromTrustedNetwork.trustedNetworkFeatureKey]: fromTrustedNetwork.State;
  router: fromRouter.RouterReducerState<any>;
}

// Export all feature effects
import { CalendarEffects } from './calendar/calendar.effects';
import { RouterEffects } from './router/router.effects';
import { CarEffects } from './car/car.effects';
import { UserEffects } from './user/user.effects';
import { LoginFormEffects } from './login/login-form.effects';
import { RideEffects } from './ride/ride.effects';
import { EditProfileEffects } from './edit-profile/edit-profile.effects';
import { EventEffects } from './event/event.effects';
import { ProfileEffects } from './profile/profile.effects';
import { MessageEffects } from './message/message.effects';
import { TrustedNetworkEffects } from './trusted-network/trusted-network.effects';

// Export effects array for use in AppModule
export const effects = [
  RouterEffects, 
  CalendarEffects, 
  CarEffects, 
  UserEffects, 
  LoginFormEffects, 
  RideEffects, 
  EditProfileEffects, 
  EventEffects, 
  ProfileEffects,
  MessageEffects,
  TrustedNetworkEffects
];

// Define all reducers
export const reducers: ActionReducerMap<State> = {
  [fromCalendar.calendarFeatureKey]: fromCalendar.reducer,
  [fromCar.carFeatureKey]: fromCar.reducer,
  [fromUser.userPageFeatureKey]: fromUser.reducer,
  [fromLoginForm.loginFormPageFeatureKey]: fromLoginForm.reducer,
  [fromRide.ridePageFeatureKey]: fromRide.reducer,
  [fromEditProfile.editProfilePageFeatureKey]: fromEditProfile.reducer,
  [fromLayout.layoutFeatureKey]: fromLayout.reducer,
  [fromEvent.eventFeatureKey]: fromEvent.reducer,
  [fromProfile.profileFeatureKey]: fromProfile.reducer,
  [fromJourney.journeyFeatureKey]: fromJourney.reducer,
  [fromMessage.messageFeatureKey]: fromMessage.reducer,
  [fromTrustedNetwork.trustedNetworkFeatureKey]: fromTrustedNetwork.reducer,
  router: fromRouter.routerReducer
};

// Console logger
export function logger(reducer: ActionReducer<State>): ActionReducer<State> {
  return (state: State | undefined, action: Action): State => {
    const result = reducer(state, action);
    console.groupCollapsed(action.type);
    console.log('prev state', state);
    console.log('action', action);
    console.log('next state', result);
    console.groupEnd();

    return result;
  };
}

// Meta reducers
export const metaReducers: MetaReducer<State>[] = !environment.production ? [logger] : [];

// Export feature state selectors
export const getCalendarState = createFeatureSelector<fromCalendar.State>(
  fromCalendar.calendarFeatureKey
);

export const getCarState = createFeatureSelector<fromCar.State>(
  fromCar.carFeatureKey
);

export const getUserState = createFeatureSelector<fromUser.State>(
  fromUser.userPageFeatureKey
);

export const getLoginFormState = createFeatureSelector<fromLoginForm.State>(
  fromLoginForm.loginFormPageFeatureKey
);

export const getRideState = createFeatureSelector<fromRide.RideState>(
  fromRide.ridePageFeatureKey
);

export const getEditProfileState = createFeatureSelector<fromEditProfile.State>(
  fromEditProfile.editProfilePageFeatureKey
);

export const getLayoutState = createFeatureSelector<fromLayout.State>(
  fromLayout.layoutFeatureKey
);

export const getEventState = createFeatureSelector<fromEvent.State>(
  fromEvent.eventFeatureKey
);

export const getProfileState = createFeatureSelector<fromProfile.State>(
  fromProfile.profileFeatureKey
);

export const getJourneyState = createFeatureSelector<fromJourney.State>(
  fromJourney.journeyFeatureKey
);

export const getTrustedNetworkState = createFeatureSelector<fromTrustedNetwork.State>(
  fromTrustedNetwork.trustedNetworkFeatureKey
);

// Export models directly from available model files
export * from './models/user';
export * from './models/ride';
export * from './models/profile';
export * from './models/notification';
export * from './models/car';
export * from './models/trusted-network.model';
