import * as EditProfileActions from './edit-profile.actions';
import { createReducer, on } from '@ngrx/store';
import { Profile } from '@app/state/models/profile.model';

export const editProfilePageFeatureKey = 'editProfilePage';

export interface State {
  profiles: Profile[];
  profile: Profile;
  error: string | null;
  pending: boolean;
}

export const initialState: State = {
  profiles: [],
  profile: {} as Profile,
  error: null,
  pending: false,
};

export const reducer = createReducer(
  initialState,

  on(EditProfileActions.profileUpsert, (state, { data }) => {
    console.log('Profile upsert action received:', data);
    return {
      ...state,
      profile: { ...state.profile, ...data },
      error: null,
      pending: true,
    };
  }),

  on(EditProfileActions.profileUpsertSuccess, (state) => {
    console.log('Profile upsert success');
    return {
      ...state,
      error: null,
      pending: false,
    };
  }),

  on(EditProfileActions.profileUpsertFailure, (state, { error }) => {
    console.error('Profile upsert failure:', error);
    return {
      ...state,
      error,
      pending: false,
    };
  }),

  on(EditProfileActions.profileGet, (state) => {
    console.log('Profile get action received');
    return {
      ...state,
      pending: true,
    };
  }),

  on(EditProfileActions.profileGetSuccess, (state, { profile }) => {
    console.log('Profile get success:', profile);
    return {
      ...state,
      profile,
      error: null,
      pending: false,
    };
  }),

  on(EditProfileActions.profileGetFailure, (state, { error }) => {
    console.error('Profile get failure:', error);
    return {
      ...state,
      error,
      pending: false,
    };
  }),

  on(EditProfileActions.profileGetAllSuccess, (state, { profiles }) => {
    console.log('Profile get all success:', profiles?.length);
    return {
      ...state,
      profiles: profiles || [],
      error: null,
      pending: false,
    };
  }),

  on(EditProfileActions.profileGetAllFailure, (state, { error }) => {
    console.error('Profile get all failure:', error);
    return {
      ...state,
      error,
      pending: false,
    };
  })
  
);

// Rename these selector functions to avoid conflicts with the selectors in edit-profile.selectors.ts
export const selectError = (state: State) => state.error;
export const selectPending = (state: State) => state.pending;
export const selectProfile = (state: State) => state.profile;
export const selectProfileAll = (state: State) => state.profiles;


