// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Role form styles */
:host {
  display: block;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInJvbGUtZm9ybS5wYWdlLnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUEscUJBQUE7QUFDQTtFQUNFLGNBQUE7QUFDRiIsImZpbGUiOiJyb2xlLWZvcm0ucGFnZS5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLyogUm9sZSBmb3JtIHN0eWxlcyAqL1xuOmhvc3Qge1xuICBkaXNwbGF5OiBibG9jaztcbn0iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/pages/role-form/role-form.page.scss"],"names":[],"mappings":"AAAA,qBAAA;AACA;EACE,cAAA;AACF;AACA,wUAAwU","sourcesContent":["/* Role form styles */\n:host {\n  display: block;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
