import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, IonicModule } from '@ionic/angular';
import { Observable, Subscription, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { PageHeaderComponent } from '../../../shared/ui/page-header/page-header.component';

import { EmergencyService, EmergencyReport } from '../../../core/services/emergency.service';

@Component({
  selector: 'app-emergency-monitor',
  templateUrl: './emergency-monitor.component.html',
  styleUrls: ['./emergency-monitor.component.scss'],
  standalone: true,
  imports: [
    CommonModule, 
    IonicModule, 
    TranslateModule,
    PageHeaderComponent
  ]
})
export class EmergencyMonitorComponent implements OnInit, OnDestroy {
  emergencyReports$: Observable<EmergencyReport[]>;
  
  // Pagination state
  currentPage = 0;
  pageSize = 10;
  totalItems = 0;
  
  // Sorting state
  currentSort: { active: string; direction: string } = { active: 'timestamp', direction: 'desc' };
  
  // Filter state
  filterValue = '';
  filterSubject = new BehaviorSubject<string>('');
  
  displayedColumns: string[] = ['timestamp', 'userFullName', 'type', 'status', 'location', 'actions'];
  
  // Make Math available to the template
  Math = Math;
  
  private subscriptions = new Subscription();

  constructor(
    private emergencyService: EmergencyService,
    private router: Router,
    private alertController: AlertController
  ) { }

  ngOnInit(): void {
    this.loadEmergencyReports();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  loadEmergencyReports(): void {
    this.emergencyReports$ = this.emergencyService.getEmergencyReports(
      this.currentPage, 
      this.pageSize, 
      this.currentSort.active, 
      this.currentSort.direction,
      this.filterSubject.value
    ).pipe(
      map(response => {
        this.totalItems = response.total;
        return response.items;
      })
    );
  }

  sortData(event: any): void {
    const { field, direction } = event.detail;
    this.currentSort = { active: field, direction: direction };
    this.loadEmergencyReports();
  }

  onPageChange(event: any): void {
    this.currentPage = event.detail.value;
    this.loadEmergencyReports();
  }

  onPageSizeChange(event: any): void {
    this.pageSize = event.detail.value;
    this.currentPage = 0; // Reset to first page when changing page size
    this.loadEmergencyReports();
  }

  filterReports(event: any): void {
    this.filterValue = event.detail.value;
    this.filterSubject.next(this.filterValue);
    this.currentPage = 0;
    this.loadEmergencyReports();
  }

  getEmergencyTypeIcon(type: string): string {
    switch (type) {
      case 'Medical':
        return 'medkit';
      case 'Safety':
        return 'shield';
      case 'Vehicle':
        return 'car';
      case 'Other':
        return 'alert-circle';
      default:
        return 'warning';
    }
  }

  getStatusColor(status: string): string {
    if (status === 'pending') return 'warning';
    if (status === 'acknowledged') return 'primary';
    return 'success';
  }

  getNextSortDirection(): string {
    return this.currentSort.direction === 'asc' ? 'desc' : 'asc';
  }

  getSortIconName(): string {
    return this.currentSort.direction === 'asc' ? 'arrow-up' : 'arrow-down';
  }

  getSortData(field: string): any {
    return {detail: {field: field, direction: this.getNextSortDirection()}};
  }

  async viewEmergencyDetails(report: EmergencyReport): Promise<void> {
    this.router.navigate(['/admin/emergency', report.id]);
  }

  async resolveEmergency(report: EmergencyReport): Promise<void> {
    const alert = await this.alertController.create({
      header: 'Resolve Emergency',
      message: 'Are you sure you want to mark this emergency as resolved?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel'
        },
        {
          text: 'Confirm',
          handler: () => {
            const sub = this.emergencyService.resolveEmergency(report.id).subscribe(
              () => {
                this.loadEmergencyReports();
              },
              error => {
                console.error('Error resolving emergency:', error);
              }
            );
            
            this.subscriptions.add(sub);
          }
        }
      ]
    });
    
    await alert.present();
  }

  async escalateEmergency(report: EmergencyReport): Promise<void> {
    const alert = await this.alertController.create({
      header: 'Escalate Emergency',
      message: 'This will notify local emergency services. Continue?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel'
        },
        {
          text: 'Escalate',
          cssClass: 'alert-button-danger',
          handler: () => {
            const sub = this.emergencyService.escalateEmergency(report.id).subscribe(
              () => {
                this.loadEmergencyReports();
              },
              error => {
                console.error('Error escalating emergency:', error);
              }
            );
            
            this.subscriptions.add(sub);
          }
        }
      ]
    });
    
    await alert.present();
  }

  getPendingCount(): number {
    let count = 0;
    this.emergencyReports$.pipe(
      map(reports => reports.filter(e => e.status === 'pending').length)
    ).subscribe(length => count = length);
    return count;
  }
  
  getAcknowledgedCount(): number {
    let count = 0;
    this.emergencyReports$.pipe(
      map(reports => reports.filter(e => e.status === 'acknowledged').length)
    ).subscribe(length => count = length);
    return count;
  }
  
  getResolvedCount(): number {
    let count = 0;
    this.emergencyReports$.pipe(
      map(reports => reports.filter(e => e.status === 'resolved').length)
    ).subscribe(length => count = length);
    return count;
  }
  
  getReportsByStatus(status: string): EmergencyReport[] {
    let filteredReports: EmergencyReport[] = [];
    this.emergencyReports$.pipe(
      map(reports => reports.filter(e => e.status === status))
    ).subscribe(reports => filteredReports = reports);
    return filteredReports;
  }
}