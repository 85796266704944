import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';

@Component({
  selector: 'app-driver-list-skeleton',
  templateUrl: './driver-list-skeleton.component.html',
  styleUrls: ['./driver-list-skeleton.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    IonicModule
  ]
})
export class DriverListSkeletonComponent {
  @Input() count = 3;
  items: number[];

  ngOnInit() {
    this.items = Array(this.count).fill(0);
  }
}