import { createReducer, on } from '@ngrx/store';
import * as CarActions from './car.actions';
import { Car } from '@app/state/models/car';

export const carFeatureKey = 'car';

export interface State {
    car: any;
    cars: any[];
    error: any;
    pending: boolean;
}

export const initialState: State = {
    car: null,
    cars: null,
    error: null,
    pending: false,
};

export const reducer = createReducer(
    initialState,
    on(CarActions.updateCar, (state) => ({
        ...state,
        error: null,
        pending: true,
    })),

    on(CarActions.updateCarSuccess, (state, { car }) => ({
        ...state,
        car,
        error: null,
        pending: false,
    })),

    on(CarActions.updateCarFailure, (state, { error }) => ({
        ...state,
        error,
        pending: false,
    })),

    on(CarActions.createCarSuccess, (state) => ({
        ...state,
        error: null,
        pending: false,
    })),

    on(CarActions.createCarFailure, (state, { error }) => ({
        ...state,
        error,
        pending: false,
    })),

    on(CarActions.getCarSuccess, (state, { car }) => ({
        ...state,
        car,
        error: null,
        pending: false,
    })),

    on(CarActions.getCarFailure, (state, { error }) => ({
        ...state,
        error,
        pending: false,
    })),

    on(CarActions.getAllCarsSuccess, (state, { cars }) => ({
        ...state,
        cars,
        error: null,
        pending: false,
    })),

    on(CarActions.getAllCarsFailure, (state, { error }) => ({
        ...state,
        error,
        pending: false,
    })),

    on(CarActions.deleteCarSuccess, (state) => ({
        ...state,
        error: null,
        pending: false,
    })),

    on(CarActions.deleteCarFailure, (state, { error }) => ({
        ...state,
        error,
        pending: false,
    }))
); 