import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';

@Component({
  selector: 'app-loading-indicator',
  templateUrl: './loading-indicator.component.html',
  styleUrls: ['./loading-indicator.component.scss'],
  standalone: true,
  imports: [CommonModule, IonicModule]
})
export class LoadingIndicatorComponent {
  @Input() message: string = 'Loading...';
  @Input() color: string = 'primary';
}