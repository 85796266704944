import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-emergency-button',
  templateUrl: './emergency-button.component.html',
  styleUrls: ['./emergency-button.component.scss'],
  standalone: true,
  imports: [CommonModule, IonicModule, TranslateModule]
})
export class EmergencyButtonComponent implements OnInit {
  @Input() rideActive = false;
  @Input() disabled = false;
  @Output() emergencyTriggered = new EventEmitter<void>();

  ngOnInit(): void {
    // Additional initialization logic if needed
  }

  triggerEmergency(): void {
    if (!this.disabled) {
      this.emergencyTriggered.emit();
    }
  }
}