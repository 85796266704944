import { Injectable, NgZone } from '@angular/core';
import { Platform } from '@ionic/angular';
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions';
import { Geolocation } from '@capacitor/geolocation';
import { LocationAccuracy } from '@awesome-cordova-plugins/location-accuracy';
import { UtilService } from '@app/services/util.service';
import { NotificationService } from '@app/services/notification.service';
import { environment } from '../../environments/environment';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { UserService } from '@app/services/user.service';
import { Store, select } from '@ngrx/store';
import * as fromState from '@app/state';
import { selectUser } from '@app/state/user/user.selectors';
import { map, take } from 'rxjs/operators';
import { Observable, BehaviorSubject } from 'rxjs';
import { } from '@angular/google-maps';
import { Loader } from '@googlemaps/js-api-loader';
import { castObservable } from '../utils/rxjs-compat';

declare var google;

interface Location {
  center: google.maps.LatLngLiteral;
  accuracy: number;
  timestamp: number;
}

@Injectable({
  providedIn: 'root'
})
export class LocationService {
  location: Location;
  dropLocation: Location = {
    center: { lat: 0, lng: 0 },
    accuracy: 0,
    timestamp: 0
  };
  private _pickupLocation = new BehaviorSubject<Location>({
    center: {
      lat: 0,
      lng: 0
    },
    accuracy: 0,
    timestamp: 0
  });
  
  private _currentLocation = new BehaviorSubject<Location | null>(null);

  get pickupLocation(): Location {
    return this._pickupLocation.value;
  }

  setPickupLocation(location: google.maps.LatLngLiteral) {
    this._pickupLocation.next({
      center: location,
      accuracy: 0,
      timestamp: Date.now()
    });
  }
  currentAddress = { value: '' };
  pickupAddress = { value: '' };
  dropAddress = { value: '' };
  locationTime: any;
  locatedCountry = environment.country;
  canUseGPS = false;
  private user;
  isTracking = false;
  watch: any;
  locations: Observable<any>;
  locationsCollection: AngularFirestoreCollection<any>;
  currentPosition: any = { id: ' ' };
  currentRide: any = {};
  private loader;
  private myPosition: any;
  private myRidePosition: any;
  private NEAR_DISTANCE = 2;

  // Flag to track API loading status
  private _mapsApiLoaded = false;
  private _loadingApi = false;
  private _apiLoadPromise: Promise<void> | null = null;

  constructor(
    private platform: Platform,
    public util: UtilService,
    public zone: NgZone,
    private firestore: AngularFirestore,
    private store: Store<fromState.State>,
    private notificationService: NotificationService,
  ) {
    this.location = {
      center: {
        lat: 0,
        lng: 0,
      },
      accuracy: 0,
      timestamp: 0
    };
    this.myPosition = {
      center: {
        lat: 0,
        lng: 0,
      },
      accuracy: 0,
      timestamp: 0
    };
    this.myRidePosition = {
      center: {
        lat: 0,
        lng: 0,
      },
      accuracy: 0,
      timestamp: 0
    };
    this.dropLocation = {
      center: {
        lat: 0,
        lng: 0,
      },
      accuracy: 0,
      timestamp: 0
    };
    this.locationTime = Date.now();
    if (!this.platform.is('cordova')) {
      this.canUseGPS = true;
    }

    this.store.pipe(select(selectUser))
      .subscribe(value => {
        if (value) {
          this.user = value;
        }
      });

    this.loader = new Loader({
      apiKey: environment.googleMapsApiKey,
      version: 'weekly',
      libraries: ['places', 'geometry']
    });

    this.getMyPosition()
  }

  /**
   * Safely loads the Google Maps API and ensures it's only loaded once
   * with proper error handling and recovery
   * @returns Promise that resolves when API is loaded
   */
  async loadGoogleMapsApi(): Promise<void> {
    // Return immediately if already loaded
    if (this._mapsApiLoaded && typeof google !== 'undefined' && google.maps) {
      return Promise.resolve();
    }

    // Return existing promise if already loading
    if (this._loadingApi && this._apiLoadPromise) {
      return this._apiLoadPromise;
    }

    // Reset loading state if previous attempt failed
    if (!this._mapsApiLoaded && this._loadingApi) {
      this._loadingApi = false;
      this._apiLoadPromise = null;
    }

    // Start loading
    this._loadingApi = true;
    
    // Create and store the loading promise
    this._apiLoadPromise = new Promise<void>((resolve, reject) => {
      try {
        console.log('Loading Google Maps API...');
        
        // Set a global timeout for the entire loading process
        const globalTimeoutId = setTimeout(() => {
          console.error('Google Maps API loading timed out after 15 seconds');
          this._mapsApiLoaded = false;
          this._loadingApi = false;
          reject(new Error('Google Maps API loading timed out'));
        }, 15000);
        
        // Use the Loader to load the API
        this.loader.load().then(() => {
          // Clear the global timeout since we got a response
          clearTimeout(globalTimeoutId);
          
          // Check if Google Maps is fully loaded
          if (typeof google === 'undefined' || !google.maps) {
            console.error('Google Maps API failed to load properly');
            this._mapsApiLoaded = false;
            this._loadingApi = false;
            reject(new Error('Google Maps API not available after loading'));
          } else {
            // Additional check to ensure Places API is available
            if (!google.maps.places) {
              console.warn('Google Maps loaded but Places API is not available');
              // Still mark as loaded but log warning
              this._mapsApiLoaded = true;
              this._loadingApi = false;
              resolve();
            } else {
              console.log('Google Maps API loaded successfully with Places API');
              this._mapsApiLoaded = true;
              this._loadingApi = false;
              resolve();
            }
          }
        }).catch(error => {
          // Clear the global timeout since we got an error
          clearTimeout(globalTimeoutId);
          
          console.error('Error loading Google Maps API:', error);
          this._mapsApiLoaded = false;
          this._loadingApi = false;
          reject(error);
        });
      } catch (error) {
        console.error('Exception during Google Maps API loading:', error);
        this._mapsApiLoaded = false;
        this._loadingApi = false;
        reject(error);
      }
    });
    
    return this._apiLoadPromise;
  }

  resetCurrentLocation() {
    this.location.center.lat = 0;
    this.location.center.lng = 0;
    this.currentAddress.value = '';
  }
  resetAddresses() {
    this.location = {
      center: {
        lat: 0,
        lng: 0,
      },
      accuracy: 0,
      timestamp: 0
    };
    this.currentAddress.value = '';
    this.setPickupLocation({
      lat: 0,
      lng: 0,
    });
    this.pickupAddress.value = '';
    this.dropLocation = {
      center: {
        lat: 0,
        lng: 0,
      },
      accuracy: 0,
      timestamp: 0
    };
    this.dropAddress.value = '';
  }

  // Check if application having GPS access permission
  checkGPSPermission() {
    if (this.platform.is('cordova')) {
      AndroidPermissions.checkPermission(AndroidPermissions.PERMISSION.ACCESS_FINE_LOCATION).then(
        result => {
          if (result.hasPermission) {
            // If having permission show 'Turn On GPS' dialogue
            this.askToTurnOnGPS();
          } else {

            // If not having permission ask for permission
            this.requestGPSPermission();
          }
        },
        err => {
          alert(err);
        }
      );
    }

  }

  requestGPSPermission() {
    LocationAccuracy.canRequest().then((canRequest: boolean) => {
      if (canRequest) {
      } else {
        // Show 'GPS Permission Request' dialogue
        AndroidPermissions.requestPermission(AndroidPermissions.PERMISSION.ACCESS_FINE_LOCATION)
          .then(
            (result) => {
              if (result.hasPermission) {
                // call method to turn on GPS
                this.askToTurnOnGPS();
              } else {
                alert('Error requesting location permissions ');
              }
            },
            error => {
              // Show alert if user click on 'No Thanks'
              alert('requestPermission Error requesting location permissions ' + error);
            }
          );
      }
    });
  }

  askToTurnOnGPS() {
    LocationAccuracy.request(LocationAccuracy.REQUEST_PRIORITY_HIGH_ACCURACY).then(
      (result) => {
        // When GPS Turned ON call method to get Accurate location coordinates
        // Following is the default method called after permission and turning on GPS
        // You can call any default method here
        this.canUseGPS = true;
      },
      error => alert('Error requesting location permissions ' + JSON.stringify(error))
    );
  }

  // Method to get device accurate coordinates using device GPS
  getCurrentLocationCoordinates() {
    Geolocation.getCurrentPosition().then((resp) => {
      this.location.center.lat = resp.coords.latitude;
      this.location.center.lng = resp.coords.longitude;
      this.location.accuracy = resp.coords.accuracy;
      this.location.timestamp = resp.timestamp;
    }).catch((error) => {
      alert('Error getting location' + error);
    });
  }

  getMyPosition() {
    Geolocation.getCurrentPosition().then((resp) => {
      this.myPosition.center.lat = resp.coords.latitude;
      this.myPosition.center.lng = resp.coords.longitude;
      this.myPosition.accuracy = resp.coords.accuracy;
      this.myPosition.timestamp = resp.timestamp;
    }).catch((error) => {
      alert('Error getting myPosition' + error);
    });
  }


  // Method to get device accurate address using device Lat/Lng
  async getGeoCodedAddress(lat: number, lng: number) {
    let block, street, building, country;

    if (navigator.geolocation) {
      const geocoder = await new google.maps.Geocoder();
      const latlng = await new google.maps.LatLng(lat, lng);
      const request = { latLng: latlng };

      await new Promise((resolve, reject) => {

        geocoder.geocode(request, (results, status) => {
          let localAdd1 = '';
          let localAdd2 = '';
          if (status === google.maps.GeocoderStatus.OK) {
            const result = results[0];
            const rsltAdrComponent = result.address_components;
            if (result !== null) {
              if (rsltAdrComponent[0] !== null) {
                block = rsltAdrComponent[0].long_name;
                street = rsltAdrComponent[2].short_name;
                building = rsltAdrComponent[1].short_name;
              }
              // Find out country of geolocation
              // eslint-disable-next-line @typescript-eslint/no-unused-vars

              for (const item of rsltAdrComponent) {
                if (item.types && item.types.includes('country')) {
                  country = item.short_name;
                }
                if (item.types && item.types.includes('administrative_area_level_1')) {
                  localAdd1 = item.short_name;
                }
                if (item.types && item.types.includes('locality')) {
                  localAdd2 = item.short_name;
                }
              }
              // this.userProvider.getUserData().location = localAdd1 + ', ' + localAdd2;
              resolve(true);

            } else {
              alert('No address available!');
            }
          }
          this.currentAddress.value = (localAdd1 + ' ' + localAdd2 + ' ' + building + ' ' + street + ' ' + block).trim();
        });
      });
    }
    return { block, street, building, country };

  }

  // Method to get device's accurate coordinate and current address
  // It is a combination of above two methods
  async getCurrentLocationAndAddress() {
    const loader = await this.util.createLoader('Getting your location...');
    return await new Promise((resolve, reject) => {
      loader.present();
      Geolocation.getCurrentPosition().then((resp: { coords: { latitude: number; longitude: number; }; }) => {
        this.zone.run(async () => {
          this.location.center.lat = resp.coords.latitude;
          this.location.center.lng = resp.coords.longitude;
          await this.getGeoCodedAddress(resp.coords.latitude, resp.coords.longitude);
          loader.dismiss();
          resolve(true);
        });
        loader.dismiss();
      }).catch((error) => {
        console.log(error);
        loader.dismiss();
      }).finally(() => {
        loader.dismiss();
      });
    });
  }


  // Method to get device accurate Lat/Lng using device Address
  async getLatLan(address: string) {
    const geocoder = new google.maps.Geocoder();
    // tslint:disable-next-line: max-line-length
    return await new Promise((resolve, reject) => {
      geocoder.geocode({ address }, (results: { geometry: { location: any; }; }[], status: any) => {
        if (status === google.maps.GeocoderStatus.OK) {
          this.location.center.lat = results[0].geometry.location.lat();
          this.location.center.lng = results[0].geometry.location.lng();
          resolve(true);
        } else {
          resolve(false);
        }
      });
    });
  }

  async getCurrentLocationAndAddressForType(type: 'pickup' | 'drop'): Promise<Location | null> {
    try {
      const position = await Geolocation.getCurrentPosition();
      
      const location: Location = {
        center: {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        },
        accuracy: position.coords.accuracy,
        timestamp: position.timestamp
      };
      
      if (type === 'pickup') {
        this._pickupLocation.next(location);
        this.pickupAddress.value = await this.getAddressFromLatLng(location.center);
      } else {
        this.dropLocation = location;
        this.dropAddress.value = await this.getAddressFromLatLng(location.center);
      }
      
      return location;
    } catch (error) {
      console.error('Error getting current location:', error);
      return null;
    }
  }

  async getGeoCodedAddressForType(lat: number, lng: number, type) {
    return await new Promise(async (resolve, reject) => {
      await this.getGeoCodedAddress(lat, lng);
      if (type === 'pickup') {
        this.pickupAddress.value = this.currentAddress.value;
      } else if (type === 'drop') {
        this.dropAddress.value = this.currentAddress.value;
      }
      resolve(true);
    });
  }

  getCurrentLocationCoordinatesForType(type: 'pickup' | 'drop') {
    Geolocation.getCurrentPosition().then((resp) => {
      if (type === 'pickup') {
        this.setPickupLocation({
          lat: resp.coords.latitude,
          lng: resp.coords.longitude,
        });
      }
      if (type === 'drop') {
        this.dropLocation.center.lat = resp.coords.latitude;
        this.dropLocation.center.lng = resp.coords.longitude;
        this.dropLocation.accuracy = resp.coords.accuracy;
        this.dropLocation.timestamp = resp.timestamp;
      }
    }).catch((error) => {
      console.error('Error getting location:', error);
    });
  }

  public async getAddressFromLatLng(location: google.maps.LatLngLiteral): Promise<string> {
    const geocoder = new google.maps.Geocoder();
    try {
      const response = await geocoder.geocode({ location });
      if (response.results[0]) {
        return response.results[0].formatted_address;
      }
      return '';
    } catch (error) {
      console.error('Error getting address:', error);
      return '';
    }
  }

  private async getLatLngFromAddress(address: string): Promise<google.maps.LatLngLiteral | null> {
    const geocoder = new google.maps.Geocoder();
    try {
      const response = await geocoder.geocode({ address });
      if (response.results[0]?.geometry?.location) {
        const location = response.results[0].geometry.location;
        return {
          lat: location.lat(),
          lng: location.lng()
        };
      }
      return null;
    } catch (error) {
      console.error('Error getting location:', error);
      return null;
    }
  }

  async getLatLanForType(address: string, type: 'pickup' | 'drop'): Promise<boolean> {
    // Check for empty address
    if (!address || address.trim() === '') {
      console.warn('Empty address provided to getLatLanForType');
      return false;
    }
    
    try {
      // Ensure the Google Maps API is loaded
      await this.loadGoogleMapsApi();
      
      const location = await this.getLatLngFromAddress(address);
      if (location) {
        const locationData: Location = {
          center: location,
          accuracy: 0,
          timestamp: Date.now()
        };

        if (type === 'pickup') {
          this._pickupLocation.next(locationData);
          this.pickupAddress.value = await this.getAddressFromLatLng(location);
        } else {
          this.dropLocation = locationData;
          this.dropAddress.value = await this.getAddressFromLatLng(location);
        }
        return true;
      } else {
        console.warn(`Could not get coordinates for address: ${address}`);
        return false;
      }
    } catch (error) {
      console.error(`Error in getLatLanForType for ${type}:`, error);
      return false;
    }
  }

  private getCurrentPosition(): Promise<GeolocationPosition> {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject, {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0
      });
    });
  }

  /**
   * Gets autocomplete suggestions for a location search query
   * @param input The search query
   * @returns Promise with array of autocomplete results
   */
  async getAutoCompleteResults(input: string): Promise<any[]> {
    // Don't search if input is empty or too short
    if (!input || input.trim().length < 3) {
      return [];
    }

    try {
      // Ensure Google Maps API is loaded
      await this.loadGoogleMapsApi();
      
      // Check if Google and google.maps.places is available
      if (!window['google'] || !window['google'].maps || !window['google'].maps.places) {
        console.error('Google Maps Places API not available');
        return [`Error: Google Maps API not available. Please try again.`];
      }

      // Create a new session token for each autocomplete request
      const sessionToken = new google.maps.places.AutocompleteSessionToken();
      
      // Set up autocomplete service with proper error handling
      const autocompleteService = new google.maps.places.AutocompleteService();
      if (!autocompleteService) {
        console.error('Failed to create AutocompleteService');
        return [`Error: Place service unavailable. Please try again.`];
      }

      // Fixed: Use 'geocode' instead of mixing incompatible types
      // According to Google Places API docs, 'geocode' includes addresses, cities, and regions
      // which covers our needs without causing type mixing errors
      const request = {
        input: input,
        sessionToken: sessionToken,
        types: ['geocode'], // This includes addresses, cities, and regions
        componentRestrictions: { country: this.locatedCountry || 'us' }
      };

      // Use promise-based approach for better control
      return new Promise((resolve) => {
        autocompleteService.getPlacePredictions(
          request,
          (predictions, status) => {
            this.zone.run(() => {
              if (status === google.maps.places.PlacesServiceStatus.OK && predictions && predictions.length > 0) {
                // Map the predictions to just the description for simplicity
                const results = predictions.map(prediction => prediction.description);
                resolve(results);
              } else {
                // Handle various error conditions
                if (status === google.maps.places.PlacesServiceStatus.ZERO_RESULTS) {
                  resolve([`No results found for "${input}". Try a different search term.`]);
                } else if (status === google.maps.places.PlacesServiceStatus.OVER_QUERY_LIMIT) {
                  resolve([`Google Maps API query limit reached. Please try again later.`]);
                } else {
                  console.warn('PlacesService error:', status);
                  resolve([`Error: ${status || 'Unknown error'}. Please try again.`]);
                }
              }
            });
          }
        );
      });
    } catch (error) {
      console.error('Error getting autocomplete results:', error);
      return [`Error: ${error.message || 'Unknown error'}. Please try again.`];
    }
  }

  // Geolocation tracking /////////////////////////////

  // watchUserLocation() {
  //   let locationRef = this.firestore.collection(
  //     `locations/${this.user.uid}/track`,
  //     ref => ref.orderBy('timestamp')
  //   );

  //   return locationRef.snapshotChanges().pipe(
  //     map(actions =>
  //       actions.map((a: any) => {
  //         const data = a.payload.doc.data();
  //         const id = a.payload.doc.id;
  //         return { id, ...data };
  //       })
  //     )
  //   );
  // }

  getLocation(ride) {
    // Check if ride has a locationId
    if (ride.locationId) {
      return this.firestore.doc(`locations/${ride.locationId}`).valueChanges();
    } else {
      // Get locations by rideId
      const snapshotObservable = this.firestore.collection('locations', ref => 
        ref.where('rideId', '==', ride.id)
           .orderBy('timestamp', 'desc')
           .limit(1)
      ).snapshotChanges();
      
      return castObservable<any[]>(snapshotObservable).pipe(
        map(actions =>
          actions.map((a: any) => {
            const data = a.payload.doc.data();
            const id = a.payload.doc.id;
            return { id, ...data };
          })
        )
      );
    }
  }

  // Use Capacitor to track our geolocation
  async startTracking() {
    this.isTracking = true;
    this.watch = Geolocation.watchPosition(
      {
        enableHighAccuracy: true
      }, 
      (position) => {
        if (position) {
          this.zone.run(() => {
            // Update only if we have a valid location and a current ride
            if (position.coords && this.currentRide && this.currentRide.id) {
              this.upsertLocation(
                position.coords.latitude,
                position.coords.longitude,
                position.timestamp
              );
            }
          });
        }
      }
    );
  }

  // Unsubscribe from the geolocation watch using the initial ID
  stopTracking() {
    if (this.watch) {
      Geolocation.clearWatch({ id: this.watch });
      this.isTracking = false;
    }
  }

  // Save a new location to Firebase and center the map
  upsertLocation(lat, lng, timestamp) {
    // Get current user
    this.locationsCollection = this.firestore.collection('locations');
    if (this.currentPosition.id !== ' ') {
      const docObservable = this.firestore.doc(`locations/${this.currentPosition.id}`).get();
      castObservable<any>(docObservable)
        .pipe(take(1))
        .subscribe(doc => {
          if (doc.exists) {
            doc.ref.update({
              lat,
              lng,
              timestamp
            });
          }
        });
    } else {
      this.locationsCollection.add({
        rideId: this.currentRide.id,
        lat,
        lng,
        timestamp
      }).then(doc => {
        this.currentPosition.id = doc.id;
      });
    }
  }

  // Delete a location from Firebase
  deleteLocation(pos) {
    this.locationsCollection.doc(pos.id).delete();
  }

  isRideApproaching(currentLocation) {
    if (!this.currentRide.status) {
      if (this.isNear(currentLocation, this.currentRide.pickupLocation)) {
        this.currentRide.direction = 'onward';
        this.currentRide.status = 'started-onward';
      } else if (this.isNear(currentLocation, this.currentRide.dropLocation)) {
        this.currentRide.direction = 'return';
        this.currentRide.status = 'started-return';
      }
      this.currentRide.nearLocation = { address: this.currentRide.pickupAddress }; // Set the first location
      this.sendRideNotification(this.currentRide);
      return;
    }

    // Assuming 'stopovers' is an array of locations in the ride.stopovers
    if (this.currentRide.stopovers.length != 0) {
      for (let i = 0; i < this.currentRide.stopovers.length; i++) {
        let location = { center: { lat: this.currentRide.stopovers[i].latitude, lng: this.currentRide.stopovers[i].longitude } };

        // Check if the ride is approaching the location
        if (this.isNear(currentLocation, location)) {
          // If the ride's status is already 'approaching' and the near location is the same, return
          if (this.currentRide.status === 'approaching' && this.currentRide.nearLocation === this.currentRide.stopovers[i]) {
            return;
          }

          this.currentRide.status = 'approaching';
          this.currentRide.nearLocation = this.currentRide.stopovers[i]; // Set the next location

          // Send a notification
          this.sendRideNotification(this.currentRide);
          break;
        }
      }
    }

    // If the ride is near the destination, set the status to 'arrived'
    if (this.currentRide.direction === 'onward' && this.isNear(currentLocation, this.currentRide.dropLocation)) {
      if (this.currentRide.status === 'arrived-onward') {
        return;
      }

      this.currentRide.status = 'arrived-onward';
      this.currentRide.nearLocation = { address: this.currentRide.dropLocation };
      this.stopTracking();

      // Send a notification
      this.sendRideNotification(this.currentRide);

    } else if (this.currentRide.direction === 'return' && this.isNear(currentLocation, this.currentRide.pickupLocation)) {

        if (this.currentRide.status === 'arrived-return') {
          return;
        }

        this.currentRide.status = 'arrived-return';
        this.currentRide.nearLocation = { address: this.currentRide.dropLocation };
        this.stopTracking();

        // Send a notification
        this.sendRideNotification(this.currentRide);
    }
  }
  // Check if two locations are near each other
  isNear(location1, location2) {
    let distance = this.distance(location1, location2);
    return distance < this.NEAR_DISTANCE; // Assuming NEAR_DISTANCE is the distance to consider two locations near each other
  }

  /**
* Calculates the distance, in kilometers, between two locations, via the
* Haversine formula. Note that this is approximate due to the fact that
* the Earth's radius varies between 6356.752 km and 6378.137 km.
*
* @param {Object} location1 The first location given as .latitude and .longitude
* @param {Object} location2 The second location given as .latitude and .longitude
* @return {number} The distance, in kilometers, between the inputted locations.
*/
  distance(location1, location2) {

    function degreesToRadians(degrees) { return (degrees * Math.PI) / 180; }
    const radius = 6371; // Earth's radius in kilometers
    const latDelta = degreesToRadians(location2.center.lat - location1.center.lat);
    const lonDelta = degreesToRadians(location2.center.lng - location1.center.lng);

    const a = (Math.sin(latDelta / 2) * Math.sin(latDelta / 2)) +
      (Math.cos(degreesToRadians(location1.center.lat)) * Math.cos(degreesToRadians(location2.center.lat)) *
        Math.sin(lonDelta / 2) * Math.sin(lonDelta / 2));

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return radius * c;
  }

  sendRideNotification(ride) {
    let message = {
      tokens: [
        "fRqEQE_4HuPfcbb3ZeHJGj:APA91bHp1vqOqDc24c7jJKzesR_GYp0TQpxDQDuH84DUcGSBEyWr_i5ydKTo2oVx5L3fcUrQ2wqpf3Hs4NETj1Jjjm-p8UUvKa1MGy-S-pw89vBG44ANq8TS70ttJSId2P-0Laegvfrn", // gmail account
        "e4jntdlnvf6uedhyurjwni:APA91bGt6pGZib6XYLr11LlABKw3AEQ5WgYdrUKK4C_QdQWHmGcUP_qJySncnwQ9I-x6brhk8Wld1XGT2zOjENu5NxRlvx1LJf3SB3OI7sSxKxF0yRjQTOv9926utZY1HZXJb7c6S9xi",
        "dbsnjlpphkreu3vc6h-w2p:APA91bFgqfQTZA2qwFWS2PXXGRe2Dn7LAgngc7uvqMv2mS6j1R4OTllqE5BNLFrfKPb3skvcojCzhT_2LS0fka3wgQj2woDGbCOALJUEBFvV4Pash5geubZf1aD1IDfcqk61wKh4T7Zd"
      ],
      title: '',
      content: '',
      data: { type: '' }
    }

    switch (ride.status) {
      case 'started-onward':
        message.title = 'Ride Started';
        message.content = `Your ride has started from ${ride.pickupAddress}`;
        message.data.type = 'ride-started';
        break;
      case 'started-return':
        message.title = 'Ride Started';
        message.content = `Your ride is returning from ${ride.dropAddress}`;
        message.data.type = 'ride-started';
        break;
      case 'approaching':
        message.title = 'Ride Approaching';
        message.content = `Your ride is approaching ${ride.nearLocation.address}`; // assuming 'nearLocation' is the next location in the route
        message.data.type = 'ride-approaching';
        break;
      case 'arrived-onward':
        message.title = 'Ride Arrived';
        message.content = `Your ride has arrived at ${ride.dropAddress}`;
        message.data.type = 'ride-arrived';
        break;
      case 'arrived-return':
        message.title = 'Ride Arrived';
        message.content = `Your ride has returned to ${ride.pickupAddress}`;
        message.data.type = 'ride-arrived';
        break;
      default:
        return;
    }

    this.notificationService.messageCreate(message);
  }

  async getCurrentLocation(): Promise<Location> {
    try {
      const position = await Geolocation.getCurrentPosition();
      const location: Location = {
        center: {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        },
        accuracy: position.coords.accuracy,
        timestamp: position.timestamp
      };
      this._currentLocation.next(location);
      return location;
    } catch (error) {
      console.error('Error getting current location', error);
      throw error;
    }
  }

  async requestLocationPermission(): Promise<boolean> {
    try {
      if (this.platform.is('android')) {
        const hasPermission = await AndroidPermissions.checkPermission(
          AndroidPermissions.PERMISSION.ACCESS_FINE_LOCATION
        );
        
        if (!hasPermission.hasPermission) {
          const permissionRequest = await AndroidPermissions.requestPermission(
            AndroidPermissions.PERMISSION.ACCESS_FINE_LOCATION
          );
          
          if (!permissionRequest.hasPermission) {
            return false;
          }
        }
        
        const canRequest = await LocationAccuracy.canRequest();
        
        if (canRequest) {
          await LocationAccuracy.request(LocationAccuracy.REQUEST_PRIORITY_HIGH_ACCURACY);
        }
      }
      
      return true;
    } catch (error) {
      console.error('Error requesting location permission', error);
      return false;
    }
  }

  async getAddressFromCoordinates(latitude: number, longitude: number): Promise<string> {
    try {
      // Implementation would use Google Maps Geocoding API
      return 'Sample Address';
    } catch (error) {
      console.error('Error getting address from coordinates', error);
      throw error;
    }
  }

}
