import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';

/**
 * Reusable avatar component that displays either an image or a fallback icon
 */
@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  standalone: true,
  imports: [CommonModule, IonicModule]
})
export class AvatarComponent implements OnInit {
  @Input() imageUrl: string = '';
  @Input() initials: string = '';
  @Input() size: 'small' | 'medium' | 'large' = 'medium';
  @Input() color: string = 'primary';
  @Input() badge: string = '';
  @Input() verified: boolean = false;
  
  hasImage: boolean = false;
  src: string = '';
  
  ngOnInit() {
    this.hasImage = !!this.imageUrl;
    this.src = this.imageUrl;
  }
}