import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule, AlertController } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-emergency-dialog',
  templateUrl: './emergency-dialog.component.html',
  styleUrls: ['./emergency-dialog.component.scss'],
  standalone: true,
  imports: [CommonModule, IonicModule, FormsModule, TranslateModule]
})
export class EmergencyDialogComponent implements OnInit {
  @Input() message: string = '';
  @Input() emergency: any;
  @Input() data: any = { currentLocation: null };
  
  emergencyDescription: string = '';
  emergencyOptions = [
    {
      title: 'Medical Emergency',
      description: 'Need medical assistance',
      icon: 'medkit',
      action: 'medical'
    },
    {
      title: 'Safety Threat',
      description: 'I feel unsafe',
      icon: 'shield',
      action: 'safety'
    },
    {
      title: 'Vehicle Issue',
      description: 'Car breakdown or accident',
      icon: 'car',
      action: 'vehicle'
    }
  ];

  constructor(private alertController: AlertController) {}

  ngOnInit() {
    // Initialize any additional logic if needed
  }

  cancel() {
    // Close the dialog
    console.log('Dialog canceled');
  }
  
  selectEmergencyOption(action: string) {
    console.log('Selected emergency option:', action);
  }
  
  callEmergencyServices() {
    window.location.href = 'tel:911';
  }

  async showEmergencyAlert() {
    const alert = await this.alertController.create({
      header: 'Emergency Contact',
      message: this.message || 'Are you sure you want to contact emergency services?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel'
        },
        {
          text: 'Call Emergency',
          role: 'confirm',
          handler: () => {
            this.contactEmergency();
          }
        }
      ],
      cssClass: 'emergency-alert'
    });

    await alert.present();
  }

  private contactEmergency() {
    // Emergency contact logic here
    window.location.href = 'tel:911';
  }
}