// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Styles for the children component */
.emergency-info {
  margin-top: 16px;
  margin-bottom: 16px;
}

.emergency-field {
  margin-bottom: 12px;
}

.approved-pickup {
  margin-top: 24px;
}

.add-person-button {
  margin-top: 8px;
}

.person-item {
  margin-bottom: 8px;
  padding: 8px;
  border-radius: 4px;
  background-color: #f5f5f5;
}

.person-form {
  margin-top: 16px;
}

.person-field {
  margin-bottom: 8px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImNoaWxkcmVuLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBLHNDQUFBO0FBQ0E7RUFDRSxnQkFBQTtFQUNBLG1CQUFBO0FBQ0Y7O0FBRUE7RUFDRSxtQkFBQTtBQUNGOztBQUVBO0VBQ0UsZ0JBQUE7QUFDRjs7QUFFQTtFQUNFLGVBQUE7QUFDRjs7QUFFQTtFQUNFLGtCQUFBO0VBQ0EsWUFBQTtFQUNBLGtCQUFBO0VBQ0EseUJBQUE7QUFDRjs7QUFFQTtFQUNFLGdCQUFBO0FBQ0Y7O0FBRUE7RUFDRSxrQkFBQTtBQUNGIiwiZmlsZSI6ImNoaWxkcmVuLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLyogU3R5bGVzIGZvciB0aGUgY2hpbGRyZW4gY29tcG9uZW50ICovXG4uZW1lcmdlbmN5LWluZm8ge1xuICBtYXJnaW4tdG9wOiAxNnB4O1xuICBtYXJnaW4tYm90dG9tOiAxNnB4O1xufVxuXG4uZW1lcmdlbmN5LWZpZWxkIHtcbiAgbWFyZ2luLWJvdHRvbTogMTJweDtcbn1cblxuLmFwcHJvdmVkLXBpY2t1cCB7XG4gIG1hcmdpbi10b3A6IDI0cHg7XG59XG5cbi5hZGQtcGVyc29uLWJ1dHRvbiB7XG4gIG1hcmdpbi10b3A6IDhweDtcbn1cblxuLnBlcnNvbi1pdGVtIHtcbiAgbWFyZ2luLWJvdHRvbTogOHB4O1xuICBwYWRkaW5nOiA4cHg7XG4gIGJvcmRlci1yYWRpdXM6IDRweDtcbiAgYmFja2dyb3VuZC1jb2xvcjogI2Y1ZjVmNTtcbn1cblxuLnBlcnNvbi1mb3JtIHtcbiAgbWFyZ2luLXRvcDogMTZweDtcbn1cblxuLnBlcnNvbi1maWVsZCB7XG4gIG1hcmdpbi1ib3R0b206IDhweDtcbn0gIl19 */`, "",{"version":3,"sources":["webpack://./src/app/components/parent/children/children.component.scss"],"names":[],"mappings":"AAAA,sCAAA;AACA;EACE,gBAAA;EACA,mBAAA;AACF;;AAEA;EACE,mBAAA;AACF;;AAEA;EACE,gBAAA;AACF;;AAEA;EACE,eAAA;AACF;;AAEA;EACE,kBAAA;EACA,YAAA;EACA,kBAAA;EACA,yBAAA;AACF;;AAEA;EACE,gBAAA;AACF;;AAEA;EACE,kBAAA;AACF;AACA,4kCAA4kC","sourcesContent":["/* Styles for the children component */\n.emergency-info {\n  margin-top: 16px;\n  margin-bottom: 16px;\n}\n\n.emergency-field {\n  margin-bottom: 12px;\n}\n\n.approved-pickup {\n  margin-top: 24px;\n}\n\n.add-person-button {\n  margin-top: 8px;\n}\n\n.person-item {\n  margin-bottom: 8px;\n  padding: 8px;\n  border-radius: 4px;\n  background-color: #f5f5f5;\n}\n\n.person-form {\n  margin-top: 16px;\n}\n\n.person-field {\n  margin-bottom: 8px;\n} "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
