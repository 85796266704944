import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, from } from 'rxjs';
import { switchMap, map, catchError, withLatestFrom, concatMap, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { ToastController } from '@ionic/angular';

import * as TrustedNetworkActions from './trusted-network.actions';
import { TrustedNetworkService } from '../../services/trusted-network.service';
import { AuthenticationService } from '../../services/auth.service';
import { State } from '../';

@Injectable()
export class TrustedNetworkEffects {
  // Load groups effect
  loadGroups$ = createEffect(() => this.actions$.pipe(
    ofType(TrustedNetworkActions.loadGroups),
    switchMap(() => from(this.authService.getCurrentUserId()).pipe(
      switchMap(userId => {
        if (!userId) {
          return of(TrustedNetworkActions.loadGroupsFailure({ error: 'User not authenticated' }));
        }
        
        return from(this.trustedNetworkService.getGroups(userId)).pipe(
          map(groups => TrustedNetworkActions.loadGroupsSuccess({ groups })),
          catchError(error => of(TrustedNetworkActions.loadGroupsFailure({ error })))
        );
      }),
      catchError(error => of(TrustedNetworkActions.loadGroupsFailure({ error })))
    ))
  ));

  // Load single group effect
  loadGroup$ = createEffect(() => this.actions$.pipe(
    ofType(TrustedNetworkActions.loadGroup),
    switchMap(({ groupId }) => this.trustedNetworkService.getGroup(groupId).pipe(
      map(group => TrustedNetworkActions.loadGroupSuccess({ group })),
      catchError(error => of(TrustedNetworkActions.loadGroupFailure({ error })))
    ))
  ));

  // Create group effect
  createGroup$ = createEffect(() => this.actions$.pipe(
    ofType(TrustedNetworkActions.createGroup),
    switchMap(({ group }) => from(this.trustedNetworkService.createGroup(group)).pipe(
      switchMap(groupId => from(this.trustedNetworkService.getGroupAsync(groupId)).pipe(
        map(createdGroup => {
          if (createdGroup) {
            return TrustedNetworkActions.createGroupSuccess({ group: createdGroup });
          }
          return TrustedNetworkActions.createGroupFailure({ 
            error: 'Failed to retrieve created group' 
          });
        }),
        catchError(error => of(TrustedNetworkActions.createGroupFailure({ error })))
      )),
      catchError(error => of(TrustedNetworkActions.createGroupFailure({ error })))
    ))
  ));

  // Navigate to group details after group creation success
  navigateToGroupDetails$ = createEffect(() => this.actions$.pipe(
    ofType(TrustedNetworkActions.createGroupSuccess),
    tap(({ group }) => {
      this.router.navigate(['/trusted-group-details', group.id]);
    })
  ), { dispatch: false });

  // Update group effect
  updateGroup$ = createEffect(() => this.actions$.pipe(
    ofType(TrustedNetworkActions.updateGroup),
    switchMap(({ groupId, changes }) => from(this.trustedNetworkService.updateGroup(groupId, changes)).pipe(
      switchMap(() => from(this.trustedNetworkService.getGroupAsync(groupId)).pipe(
        map(updatedGroup => {
          if (updatedGroup) {
            return TrustedNetworkActions.updateGroupSuccess({ group: updatedGroup });
          }
          return TrustedNetworkActions.updateGroupFailure({ 
            error: 'Failed to retrieve updated group' 
          });
        }),
        catchError(error => of(TrustedNetworkActions.updateGroupFailure({ error })))
      )),
      catchError(error => of(TrustedNetworkActions.updateGroupFailure({ error })))
    ))
  ));

  // Delete group effect
  deleteGroup$ = createEffect(() => this.actions$.pipe(
    ofType(TrustedNetworkActions.deleteGroup),
    switchMap(({ groupId }) => from(this.trustedNetworkService.deleteGroup(groupId)).pipe(
      map(() => TrustedNetworkActions.deleteGroupSuccess({ groupId })),
      catchError(error => of(TrustedNetworkActions.deleteGroupFailure({ error })))
    ))
  ));

  // Load members effect
  loadMembers$ = createEffect(() => this.actions$.pipe(
    ofType(TrustedNetworkActions.loadMembers),
    switchMap(() => this.trustedNetworkService.getUsersInMyGroups().pipe(
      map(members => TrustedNetworkActions.loadMembersSuccess({ members })),
      catchError(error => of(TrustedNetworkActions.loadMembersFailure({ error })))
    ))
  ));

  // Create invite effect
  createInvite$ = createEffect(() => this.actions$.pipe(
    ofType(TrustedNetworkActions.createInvite),
    switchMap(({ groupId, email, phone }) => from(this.trustedNetworkService.createInvite(groupId, email, phone)).pipe(
      map(invite => TrustedNetworkActions.createInviteSuccess({ invite })),
      catchError(error => of(TrustedNetworkActions.createInviteFailure({ error })))
    ))
  ));

  // Join group effect
  joinGroup$ = createEffect(() => this.actions$.pipe(
    ofType(TrustedNetworkActions.joinGroup),
    switchMap(({ inviteCode }) => from(this.trustedNetworkService.joinGroupWithCode(inviteCode)).pipe(
      map(groupId => TrustedNetworkActions.joinGroupSuccess({ groupId })),
      catchError(error => of(TrustedNetworkActions.joinGroupFailure({ error })))
    ))
  ));

  // Navigate to group details after joining a group
  navigateAfterJoin$ = createEffect(() => this.actions$.pipe(
    ofType(TrustedNetworkActions.joinGroupSuccess),
    tap(({ groupId }) => {
      this.router.navigate(['/trusted-group-details', groupId]);
    })
  ), { dispatch: false });

  // Show toast notifications for success and failure
  showSuccessToast$ = createEffect(() => this.actions$.pipe(
    ofType(
      TrustedNetworkActions.createGroupSuccess,
      TrustedNetworkActions.updateGroupSuccess,
      TrustedNetworkActions.deleteGroupSuccess,
      TrustedNetworkActions.joinGroupSuccess,
      TrustedNetworkActions.createInviteSuccess
    ),
    tap(async (action) => {
      let message = 'Operation completed successfully';
      
      if (action.type === TrustedNetworkActions.createGroupSuccess.type) {
        message = 'Group created successfully';
      } else if (action.type === TrustedNetworkActions.updateGroupSuccess.type) {
        message = 'Group updated successfully';
      } else if (action.type === TrustedNetworkActions.deleteGroupSuccess.type) {
        message = 'Group deleted successfully';
      } else if (action.type === TrustedNetworkActions.joinGroupSuccess.type) {
        message = 'Successfully joined the group';
      } else if (action.type === TrustedNetworkActions.createInviteSuccess.type) {
        message = 'Invitation sent successfully';
      }
      
      const toast = await this.toastController.create({
        message,
        duration: 2000,
        color: 'success',
        position: 'bottom'
      });
      
      await toast.present();
    })
  ), { dispatch: false });

  showErrorToast$ = createEffect(() => this.actions$.pipe(
    ofType(
      TrustedNetworkActions.createGroupFailure,
      TrustedNetworkActions.updateGroupFailure,
      TrustedNetworkActions.deleteGroupFailure,
      TrustedNetworkActions.joinGroupFailure,
      TrustedNetworkActions.createInviteFailure,
      TrustedNetworkActions.loadGroupsFailure,
      TrustedNetworkActions.loadMembersFailure
    ),
    tap(async (action) => {
      const error = action['error'];
      let message = 'An error occurred';
      
      if (typeof error === 'string') {
        message = error;
      } else if (error && error.message) {
        message = error.message;
      }
      
      const toast = await this.toastController.create({
        message,
        duration: 3000,
        color: 'danger',
        position: 'bottom'
      });
      
      await toast.present();
    })
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private trustedNetworkService: TrustedNetworkService,
    private authService: AuthenticationService,
    private store: Store<State>,
    private router: Router,
    private toastController: ToastController
  ) {}
} 