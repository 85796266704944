/**
 * Date Helper Utilities
 * 
 * A collection of utility functions for safely handling dates
 * to prevent common errors like invalid date to toISOString() conversions
 */

/**
 * Safely converts a date object or string to ISO string format
 * Returns undefined if the date is invalid
 * 
 * @param date - Date object or string to convert
 * @returns ISO formatted date string or undefined if invalid
 */
export function safeToISOString(date: Date | string | null | undefined): string | undefined {
  if (!date) return undefined;
  
  try {
    // If it's a string, try to convert to Date first
    if (typeof date === 'string') {
      const parsedDate = new Date(date);
      if (isNaN(parsedDate.getTime())) {
        console.warn('Invalid date string could not be parsed:', date);
        return undefined;
      }
      return parsedDate.toISOString();
    }
    
    // Handle Date object directly
    if (date instanceof Date) {
      if (isNaN(date.getTime())) {
        console.warn('Invalid Date object detected');
        return undefined;
      }
      return date.toISOString();
    }
    
    // Unexpected type
    console.warn('Unexpected date type:', typeof date);
    return undefined;
  } catch (error) {
    console.warn('Error converting to ISO string:', error);
    return undefined;
  }
}

/**
 * Safely extracts the date portion (YYYY-MM-DD) from a date
 * Returns undefined if the date is invalid
 * 
 * @param date - Date object or string to extract from
 * @returns YYYY-MM-DD date string or undefined if invalid
 */
export function safeDateOnly(date: Date | string | null | undefined): string | undefined {
  const isoString = safeToISOString(date);
  if (!isoString) return undefined;
  
  return isoString.split('T')[0];
}

/**
 * Safely compares two dates for equality based on the date portion only (ignoring time)
 * Returns false if either date is invalid
 * 
 * @param date1 - First date to compare
 * @param date2 - Second date to compare
 * @returns true if the dates are the same day, false otherwise or if either is invalid
 */
export function sameDateOnly(date1: Date | string | null | undefined, date2: Date | string | null | undefined): boolean {
  const date1Str = safeDateOnly(date1);
  const date2Str = safeDateOnly(date2);
  
  if (!date1Str || !date2Str) return false;
  
  return date1Str === date2Str;
}

/**
 * Gets a timestamp (ms since epoch) from a date for comparing/sorting
 * Returns undefined if the date is invalid
 * 
 * @param date - Date to get timestamp from
 * @returns timestamp in milliseconds or undefined if invalid
 */
export function safeTimestamp(date: Date | string | null | undefined): number | undefined {
  if (!date) return undefined;
  
  try {
    // If it's a string, try to convert to Date first
    if (typeof date === 'string') {
      const parsedDate = new Date(date);
      if (isNaN(parsedDate.getTime())) {
        return undefined;
      }
      return parsedDate.getTime();
    }
    
    // Handle Date object directly
    if (date instanceof Date) {
      const timestamp = date.getTime();
      if (isNaN(timestamp)) {
        return undefined;
      }
      return timestamp;
    }
    
    return undefined;
  } catch (error) {
    console.warn('Error getting timestamp:', error);
    return undefined;
  }
}

/**
 * Creates a new date safely, allowing for error handling
 * Returns undefined if the date is invalid
 * 
 * @param input - Date string, timestamp or Date object
 * @returns A valid Date object or undefined
 */
export function createSafeDate(input: Date | string | number | null | undefined): Date | undefined {
  if (input === null || input === undefined) return undefined;
  
  try {
    let date: Date;
    
    if (input instanceof Date) {
      date = input;
    } else if (typeof input === 'number') {
      date = new Date(input);
    } else if (typeof input === 'string') {
      date = new Date(input);
    } else {
      console.warn('Unsupported date input type:', typeof input);
      return undefined;
    }
    
    // Validate date
    if (isNaN(date.getTime())) {
      console.warn('Invalid date created from input:', input);
      return undefined;
    }
    
    return date;
  } catch (error) {
    console.warn('Error creating date:', error);
    return undefined;
  }
} 