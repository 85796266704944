import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { GoogleMapsModule } from '@angular/google-maps';
import { TranslateModule } from '@ngx-translate/core';

import { RideSchedulingService } from '../ride-scheduling.service';
import { ChildProfileService } from '../../profile/child.service';
import { Child } from '../../../shared/models/child.model';
import { PageHeaderComponent } from '../../../shared/ui/page-header/page-header.component';

@Component({
  selector: 'app-schedule-ride',
  templateUrl: './schedule-ride.component.html',
  styleUrls: ['./schedule-ride.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    IonicModule,
    GoogleMapsModule,
    TranslateModule,
    PageHeaderComponent
  ]
})
export class ScheduleRideComponent implements OnInit {
  currentStep = 0;
  isLoading = false;
  children: Child[] = [];
  today = new Date().toISOString();

  childrenForm: FormGroup;
  rideForm: FormGroup;
  recurringForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private rideService: RideSchedulingService,
    private childService: ChildProfileService
  ) {
    this.initializeForms();
  }

  ngOnInit(): void {
    this.loadChildren();
    this.checkPreselectedDriver();
  }

  private initializeForms(): void {
    this.childrenForm = this.fb.group({
      selectedChildren: [[], [Validators.required, Validators.minLength(1)]]
    });

    this.rideForm = this.fb.group({
      pickupAddress: ['', Validators.required],
      pickupLocation: [null, Validators.required],
      pickupNotes: [''],
      dropoffAddress: ['', Validators.required],
      dropoffLocation: [null, Validators.required],
      dropoffNotes: [''],
      pickupDate: ['', Validators.required],
      pickupTime: ['', Validators.required],
      notes: ['']
    });

    this.recurringForm = this.fb.group({
      isRecurring: [false],
      frequency: ['daily'],
      daysOfWeek: [[]],
      startDate: [''],
      endDate: ['']
    });

    // Add conditional validators for recurring options
    this.recurringForm.get('isRecurring').valueChanges.subscribe(isRecurring => {
      const controls = ['frequency', 'startDate', 'endDate'];
      controls.forEach(control => {
        const validators = isRecurring ? [Validators.required] : [];
        this.recurringForm.get(control).setValidators(validators);
        this.recurringForm.get(control).updateValueAndValidity();
      });
    });
  }

  private loadChildren(): void {
    this.childService.getChildren().subscribe(children => {
      this.children = children;
    });
  }

  private checkPreselectedDriver(): void {
    const driverId = this.route.snapshot.queryParamMap.get('driverId');
    if (driverId) {
      // Store driver ID for later use when submitting the ride request
      this.rideForm.addControl('driverId', this.fb.control(driverId));
    }
  }

  onStepChange(event: any): void {
    const step = parseInt(event.detail.value, 10);
    if (step > this.currentStep && !this.canProceedToStep(step)) {
      return;
    }
    this.currentStep = step;
  }

  onChildSelect(event: any, childId: string): void {
    const selectedChildren = [...this.childrenForm.get('selectedChildren').value];
    if (event.detail.checked) {
      selectedChildren.push(childId);
    } else {
      const index = selectedChildren.indexOf(childId);
      if (index > -1) {
        selectedChildren.splice(index, 1);
      }
    }
    this.childrenForm.patchValue({ selectedChildren });
  }

  onLocationSelected(place: google.maps.places.PlaceResult, type: 'pickup' | 'dropoff'): void {
    if (!place.geometry?.location) return;

    const location = {
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng(),
      address: place.formatted_address
    };

    if (type === 'pickup') {
      this.rideForm.patchValue({
        pickupAddress: location.address,
        pickupLocation: { lat: location.lat, lng: location.lng }
      });
    } else {
      this.rideForm.patchValue({
        dropoffAddress: location.address,
        dropoffLocation: { lat: location.lat, lng: location.lng }
      });
    }
  }

  canProceedToStep(step: number): boolean {
    switch (step) {
      case 1:
        return this.childrenForm.valid;
      case 2:
        return this.rideForm.get('pickupAddress').value && 
               this.rideForm.get('dropoffAddress').value;
      default:
        return true;
    }
  }

  nextStep(): void {
    if (this.canProceedToStep(this.currentStep + 1)) {
      this.currentStep++;
    }
  }

  previousStep(): void {
    if (this.currentStep > 0) {
      this.currentStep--;
    }
  }

  getSelectedChildrenNames(): string {
    return this.childrenForm.value.selectedChildren
      .map(id => this.children.find(c => c.id === id)?.name)
      .filter(Boolean)
      .join(', ');
  }

  async submitRideRequest(): Promise<void> {
    if (this.rideForm.invalid || this.childrenForm.invalid) return;

    this.isLoading = true;

    const rideRequest = {
      ...this.rideForm.value,
      childIds: this.childrenForm.value.selectedChildren,
      recurring: this.recurringForm.value.isRecurring ? {
        frequency: this.recurringForm.value.frequency,
        daysOfWeek: this.recurringForm.value.daysOfWeek,
        startDate: this.recurringForm.value.startDate,
        endDate: this.recurringForm.value.endDate
      } : null
    };

    try {
      await this.rideService.scheduleRide(rideRequest).toPromise();
      this.router.navigate(['/rides/active']);
    } catch (error) {
      console.error('Error scheduling ride:', error);
    } finally {
      this.isLoading = false;
    }
  }
}