import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, from, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Profile } from '../state/models/profile.model';
import { StorageService } from './storage.service';
import { FirestoreService } from './firestore.service';
import { AuthenticationService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  private readonly PROFILES_COLLECTION = 'profiles';
  private currentProfileSubject = new BehaviorSubject<Profile | null>(null);
  currentProfile$ = this.currentProfileSubject.asObservable();

  constructor(
    private storageService: StorageService,
    private firestoreService: FirestoreService,
    private authService: AuthenticationService
  ) {
    this.authService.authState.subscribe(user => {
      if (user) {
        this.getProfileById(user.uid).then(profile => {
          this.currentProfileSubject.next(profile);
        }).catch(error => {
          console.error('Error loading profile:', error);
        });
      } else {
        this.currentProfileSubject.next(null);
      }
    });
  }

  /**
   * Get profile by ID from Firestore or local storage
   */
  async getProfileById(userId: string): Promise<Profile | null> {
    try {
      if (!userId) {
        console.error('getProfileById called with empty userId');
        return null;
      }

      const docRef = `${this.PROFILES_COLLECTION}/${userId}`;
      const profileDoc = await this.firestoreService.doc(docRef).get().toPromise();

      if (profileDoc && profileDoc.exists) {
        const profile = profileDoc.data() as Profile;

        // Ensure verification status is properly initialized
        if (!profile.verificationStatus) {
          profile.verificationStatus = {
            idVerified: false,
            phoneVerified: false,
            emailVerified: false
          };
        }

        // Cache in local storage for offline access
        await this.storageService.set(`profile_${userId}`, profile);
        return profile;
      }

      // Try to get from local storage if not in Firestore
      const cachedProfile = await this.storageService.get(`profile_${userId}`);
      return cachedProfile || null;

    } catch (error) {
      console.error('Error retrieving profile:', error);
      return null;
    }
  }

  /**
   * Update profile in Firestore
   */
  async updateProfile(userId: string, profileData: Partial<Profile>): Promise<boolean> {
    try {
      if (!userId) {
        console.error('updateProfile called with empty userId');
        return false;
      }

      const now = new Date().toISOString();
      const sanitizedData: Partial<Profile> = { updatedAt: now };

      // Copy all non-undefined values from profileData
      Object.keys(profileData).forEach(key => {
        if (profileData[key as keyof typeof profileData] !== undefined) {
          // Use type assertion to avoid type errors
          (sanitizedData as any)[key] = profileData[key as keyof typeof profileData];
        }
      });

      // Get existing profile or create new one
      const existingProfile = await this.getProfileById(userId);
      if (!existingProfile) {
        sanitizedData.id = userId;
        sanitizedData.uid = userId;
        sanitizedData.createdAt = now;
        sanitizedData.verificationStatus = {
          idVerified: false,
          phoneVerified: false,
          emailVerified: false
        };
        sanitizedData.backgroundCheckStatus = 'not_started';
        sanitizedData.backgroundCheckConsent = false;
        sanitizedData.schoolEmailVerified = false;
      }

      await this.firestoreService.doc(`${this.PROFILES_COLLECTION}/${userId}`).set(sanitizedData, { merge: true });
      
      // Update local cache
      const updatedProfile = await this.getProfileById(userId);
      if (updatedProfile) {
        this.currentProfileSubject.next(updatedProfile);
      }

      return true;
    } catch (error) {
      console.error('Error updating profile:', error);
      return false;
    }
  }

  /**
   * Update verification status for a specific verification type
   */
  async updateVerificationStatus(
    userId: string,
    verificationType: keyof Profile['verificationStatus'],
    isVerified: boolean
  ): Promise<boolean> {
    try {
      const profile = await this.getProfileById(userId);
      if (!profile) return false;

      const verificationStatus = {
        ...profile.verificationStatus,
        [verificationType]: isVerified
      };

      return this.updateProfile(userId, { verificationStatus });
    } catch (error) {
      console.error('Error updating verification status:', error);
      return false;
    }
  }

  /**
   * Verify school email
   */
  verifySchoolEmail(userId: string, email: string): Observable<any> {
    return from(this.updateProfile(userId, {
      schoolEmail: email,
      schoolEmailVerified: false,
      verificationStatus: {
        ...this.currentProfileSubject.value?.verificationStatus
      }
    })).pipe(
      map(success => ({
        success,
        message: success ? 'Verification email sent' : 'Failed to send verification email'
      })),
      catchError(error => {
        console.error('Error verifying school email:', error);
        return of({
          success: false,
          message: 'Error verifying school email'
        });
      })
    );
  }

  /**
   * Update background check consent
   */
  updateBackgroundCheckConsent(userId: string, consent: boolean): Observable<any> {
    return from(this.updateProfile(userId, {
      backgroundCheckConsent: consent,
      backgroundCheckStatus: consent ? 'pending' : 'not_started'
    })).pipe(
      map(success => ({
        success,
        message: success ? 'Background check consent updated' : 'Failed to update consent'
      })),
      catchError(error => {
        console.error('Error updating background check consent:', error);
        return of({
          success: false,
          message: 'Error updating background check consent'
        });
      })
    );
  }

  /**
   * Get current profile as an Observable
   * Used by effects to get the current profile
   */
  getProfile$(): Observable<Profile | null> {
    return this.currentProfile$;
  }

  /**
   * Get all profiles from Firestore
   * Used by effects to get all profiles
   */
  getAllProfiles$(): Observable<Profile[]> {
    // Use the doc method to get a reference to the collection
    return from(
      this.firestoreService.doc(this.PROFILES_COLLECTION).get().toPromise()
        .then(snapshot => {
          if (snapshot && snapshot.exists) {
            const data = snapshot.data();
            return Object.values(data || {}) as Profile[];
          }
          return [];
        })
    ).pipe(
      catchError(error => {
        console.error('Error getting all profiles:', error);
        return of([]);
      })
    );
  }

  /**
   * Upsert (update or insert) a profile
   * Used by effects to update a profile
   */
  upsertProfile(profile: Profile): Observable<boolean> {
    return from(this.updateProfile(profile.id || profile.uid, profile));
  }

  /**
   * Refresh the current profile
   * Used by components to refresh the current profile
   */
  refreshCurrentProfile(userId: string): Promise<Profile | null> {
    return this.getProfileById(userId).then(profile => {
      if (profile) {
        this.currentProfileSubject.next(profile);
      }
      return profile;
    });
  }
}