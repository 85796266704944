import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { StorageService } from '@app/services/storage.service';
import * as JourneyActions from './journey.actions';

@Injectable()
export class JourneyEffects {
  private readonly STORAGE_KEY = 'journey_state';

  // Persist the journey state to storage
  persistJourneyState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        JourneyActions.setPickupLocation,
        JourneyActions.setDropOffLocation,
        JourneyActions.setRouteData,
        JourneyActions.setSelectedDate,
        JourneyActions.setSelectedReturnDate,
        JourneyActions.setJourneyType,
        JourneyActions.setPassengerCount,
        JourneyActions.setSelectedLuggage,
        JourneyActions.setRideOptions
      ),
      tap(() => {
        // This is a placeholder for persistence logic
        // If actual persistence is needed, implement it here
        console.log('Journey state change detected - persistence logic could be implemented here');
      })
    ),
    { dispatch: false } // No follow-up actions
  );

  // Reset journey storage on resetAll
  resetJourneyStorage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(JourneyActions.resetAll),
      tap(() => {
        console.log('Resetting journey state storage');
        // Remove from storage if persistence is implemented
        // this.storage.remove(this.STORAGE_KEY);
      })
    ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private storage: StorageService
  ) {}
}
