import { Injectable, OnDestroy } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import { Observable, BehaviorSubject, of, throwError, Subscription } from 'rxjs';
import { map, switchMap, take, tap, shareReplay, catchError } from 'rxjs/operators';
import { NotificationService } from '@app/core/services/notification.service';
import { ChatMessage, ChatThread } from '@app/models/chat.model';

@Injectable({
  providedIn: 'root'
})
export class MessagingService implements OnDestroy {
  private currentUserId: string | null = null;
  private unreadCountSubject = new BehaviorSubject<number>(0);
  private subscriptions: Subscription[] = [];
  
  constructor(
    private firestore: AngularFirestore,
    private auth: AngularFireAuth,
    private notificationService: NotificationService,
    private router: Router
  ) {
    // Get current user ID and initialize listeners
    this.subscriptions.push(
      this.auth.authState.subscribe(user => {
        this.currentUserId = user ? user.uid : null;
        if (this.currentUserId) {
          this.updateTotalUnreadCount();
          this.listenForNewMessages();
        } else {
          this.unreadCountSubject.next(0);
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  // Get all chat threads for the current user
  getChatThreads(): Observable<ChatThread[]> {
    return this.auth.authState.pipe(
      switchMap(user => {
        if (!user) return of([]);

        return this.firestore
          .collection<ChatThread>('chatThreads', ref => 
            ref.where('participants', 'array-contains', user.uid)
               .orderBy('updatedAt', 'desc')
          )
          .valueChanges({ idField: 'id' })
          .pipe(
            map(threads => {
              // Convert Firestore timestamps to Date objects if needed
              return threads.map(thread => ({
                ...thread,
                updatedAt: this.convertTimestamp(thread.updatedAt),
                lastMessage: thread.lastMessage ? {
                  ...thread.lastMessage,
                  timestamp: this.convertTimestamp(thread.lastMessage.timestamp)
                } : undefined
              }));
            })
          );
      }),
      shareReplay(1)
    );
  }

  // Get messages for a specific thread
  getMessages(threadId: string, limit: number = 20): Observable<ChatMessage[]> {
    if (!threadId) {
      return of([]);
    }
    
    return this.firestore
      .collection<ChatThread>('chatThreads')
      .doc(threadId)
      .collection<ChatMessage>('messages', ref => 
        ref.orderBy('timestamp', 'desc')
          .limit(limit)
      )
      .valueChanges({ idField: 'id' })
      .pipe(
        map(messages => {
          // Convert timestamps and sort by oldest first (for display)
          return messages
            .map(msg => ({
              ...msg,
              timestamp: this.convertTimestamp(msg.timestamp)
            }))
            .sort((a, b) => a.timestamp - b.timestamp);
        }),
        tap(() => {
          if (this.currentUserId) {
            this.markThreadAsRead(threadId);
          }
        }),
        catchError(error => {
          console.error(`Error fetching messages for thread ${threadId}:`, error);
          return of([]);
        })
      );
  }

  // Send a new message
  async sendMessage(threadId: string, content: string, type: 'text' | 'image' | 'location' = 'text', metadata?: any): Promise<string> {
    if (!this.currentUserId) {
      throw new Error('User not authenticated');
    }

    try {
      const threadRef = this.firestore.collection<ChatThread>('chatThreads').doc(threadId);
      const threadSnapshot = await threadRef.get().toPromise();
      
      if (!threadSnapshot?.exists) {
        throw new Error('Chat thread not found');
      }
      
      const thread = threadSnapshot.data() as ChatThread;
      
      // Update unread counts for all participants except sender
      const unreadCounts = { ...thread.unreadCounts };
      
      // Set read status for all participants
      const readStatus: {[key: string]: boolean} = {};
      
      thread.participants.forEach(userId => {
        if (userId !== this.currentUserId) {
          unreadCounts[userId] = (unreadCounts[userId] || 0) + 1;
          readStatus[userId] = false;
          
          // Create notification for other participants
          this.createMessageNotification(thread, userId, content);
        } else {
          readStatus[userId] = true;
        }
      });

      // Create the message
      const timestamp = Date.now();
      const message: ChatMessage = {
        senderId: this.currentUserId,
        content,
        timestamp,
        read: readStatus,
        type,
        metadata: metadata || {}
      };

      // Update the thread with last message info
      await threadRef.update({
        lastMessage: {
          content,
          timestamp,
          senderId: this.currentUserId
        },
        updatedAt: timestamp,
        unreadCounts
      });

      // Add the message to the thread's messages collection
      const messageRef = await threadRef.collection<ChatMessage>('messages').add(message);
      return messageRef.id;
    } catch (error) {
      console.error('Error sending message:', error);
      throw new Error(`Failed to send message: ${error.message || 'Unknown error'}`);
    }
  }

  // Create a new chat thread
  async createChatThread(otherUserId: string, initialMessage: string): Promise<string> {
    if (!this.currentUserId) throw new Error('User not authenticated');
    
    try {
      // Get user profiles for names and avatars
      const currentUserDoc = await this.firestore
        .collection('users')
        .doc(this.currentUserId)
        .get()
        .pipe(take(1))
        .toPromise();
        
      const otherUserDoc = await this.firestore
        .collection('users')
        .doc(otherUserId)
        .get()
        .pipe(take(1))
        .toPromise();

      if (!currentUserDoc?.exists || !otherUserDoc?.exists) {
        throw new Error('User profile not found');
      }

      const currentUserData = currentUserDoc.data() as any;
      const otherUserData = otherUserDoc.data() as any;

      // Check if thread already exists
      const existingThreadQuery = await this.firestore
        .collection<ChatThread>('chatThreads', ref => 
          ref.where('participants', 'array-contains', this.currentUserId)
        )
        .get()
        .pipe(take(1))
        .toPromise();
        
      const existingThread = existingThreadQuery.docs.find(doc => {
        const data = doc.data();
        return data.participants.includes(otherUserId);
      });
      
      if (existingThread) {
        return existingThread.id;
      }

      // Create new thread
      const timestamp = Date.now();
      const participantNames: {[key: string]: string} = {};
      participantNames[this.currentUserId] = currentUserData.displayName || 'User';
      participantNames[otherUserId] = otherUserData.displayName || 'User';
      
      const participantAvatars: {[key: string]: string} = {};
      participantAvatars[this.currentUserId] = currentUserData.photoURL || '';
      participantAvatars[otherUserId] = otherUserData.photoURL || '';
      
      const unreadCounts: {[key: string]: number} = {};
      unreadCounts[this.currentUserId] = 0;
      unreadCounts[otherUserId] = 1; // Initial message is unread for recipient
      
      // Create the thread document
      const thread: ChatThread = {
        participants: [this.currentUserId, otherUserId],
        participantNames,
        participantAvatars,
        unreadCounts,
        updatedAt: timestamp,
        metadata: {
          online: false
        }
      };
      
      // Add thread to Firestore
      const threadRef = await this.firestore.collection<ChatThread>('chatThreads').add(thread);
      
      // Add initial message
      if (initialMessage) {
        const readStatus: {[key: string]: boolean} = {};
        readStatus[this.currentUserId] = true;
        readStatus[otherUserId] = false;
        
        const message: ChatMessage = {
          senderId: this.currentUserId,
          content: initialMessage,
          timestamp,
          read: readStatus,
          type: 'text'
        };
        
        // Add message to thread's messages collection
        await threadRef.collection('messages').add(message);
        
        // Update thread with last message info
        await threadRef.update({
          lastMessage: {
            content: initialMessage,
            timestamp,
            senderId: this.currentUserId
          }
        });
        
        // Create notification for the recipient
        this.createMessageNotification(thread, otherUserId, initialMessage);
      }
      
      return threadRef.id;
    } catch (error) {
      console.error('Error creating chat thread:', error);
      throw new Error(`Failed to create chat thread: ${error.message || 'Unknown error'}`);
    }
  }

  // Mark a thread as read
  async markThreadAsRead(threadId: string): Promise<void> {
    if (!this.currentUserId) return;
    
    try {
      const threadRef = this.firestore.collection('chatThreads').doc(threadId);
      const threadDoc = await threadRef.get().toPromise();
      
      if (!threadDoc?.exists) return;
      
      const thread = threadDoc.data() as ChatThread;
      
      // If there are no unread messages for this user, no need to update
      if (!thread.unreadCounts?.[this.currentUserId]) return;
      
      // Update thread unread count for current user
      await threadRef.update({
        [`unreadCounts.${this.currentUserId}`]: 0
      });
      
      // Mark all messages as read for current user
      const batch = this.firestore.firestore.batch();
      const unreadMessages = await threadRef
        .collection<ChatMessage>('messages')
        .ref
        .where(`read.${this.currentUserId}`, '==', false)
        .get();
      
      unreadMessages.docs.forEach(doc => {
        batch.update(doc.ref, {
          [`read.${this.currentUserId}`]: true
        });
      });
      
      await batch.commit();
      
      // Update total unread count
      this.updateTotalUnreadCount();
    } catch (error) {
      console.error('Error marking thread as read:', error);
    }
  }

  // Delete a message
  async deleteMessage(threadId: string, messageId: string): Promise<void> {
    if (!this.currentUserId) throw new Error('User not authenticated');
    
    try {
      const messageRef = this.firestore
        .collection('chatThreads')
        .doc(threadId)
        .collection('messages')
        .doc(messageId);
      
      const messageDoc = await messageRef.get().toPromise();
      
      if (!messageDoc?.exists) throw new Error('Message not found');
      
      const message = messageDoc.data() as ChatMessage;
      
      // Check if user is the sender
      if (message.senderId !== this.currentUserId) {
        throw new Error('You can only delete your own messages');
      }
      
      // Delete the message
      await messageRef.delete();
      
      // Get the last message in the thread after deletion
      const messages = await this.firestore
        .collection('chatThreads')
        .doc(threadId)
        .collection<ChatMessage>('messages', ref => 
          ref.orderBy('timestamp', 'desc')
          .limit(1)
        )
        .get()
        .toPromise();
      
      // Update thread with new last message if the deleted message was the last one
      if (messages.docs.length > 0) {
        const lastMessage = messages.docs[0].data();
        
        // Update thread document with new last message
        await this.firestore
          .collection('chatThreads')
          .doc(threadId)
          .update({
            lastMessage: {
              content: lastMessage.content,
              timestamp: lastMessage.timestamp,
              senderId: lastMessage.senderId
            }
          });
      } else {
        // No messages left in thread, clear lastMessage
        await this.firestore
          .collection('chatThreads')
          .doc(threadId)
          .update({
            lastMessage: null
          });
      }
    } catch (error) {
      console.error('Error deleting message:', error);
      throw new Error(`Failed to delete message: ${error.message || 'Unknown error'}`);
    }
  }

  // Get total unread message count
  getTotalUnreadCount(): Observable<number> {
    return this.unreadCountSubject.asObservable();
  }

  // Listen for new messages and create notifications
  private listenForNewMessages(): void {
    if (!this.currentUserId) return;
    
    this.subscriptions.push(
      this.getChatThreads().subscribe(threads => {
        // Update total unread count
        let totalUnread = 0;
        
        threads.forEach(thread => {
          // Add the unread count for this user
          totalUnread += thread.unreadCounts?.[this.currentUserId] || 0;
        });
        
        this.unreadCountSubject.next(totalUnread);
      })
    );
  }

  // Create message notification
  private async createMessageNotification(thread: ChatThread, recipientId: string, content: string): Promise<void> {
    if (recipientId === this.currentUserId) return;
    
    const senderName = thread.participantNames[this.currentUserId] || 'User';
    
    // Use notification service to create a message notification
    await this.notificationService.createMessageNotification(
      thread.id as string,
      senderName,
      content
    );
  }

  // Update the total unread count
  private async updateTotalUnreadCount(): Promise<void> {
    if (!this.currentUserId) return;
    
    try {
      const threadsQuerySnapshot = await this.firestore
        .collection<ChatThread>('chatThreads', ref => 
          ref.where('participants', 'array-contains', this.currentUserId)
        )
        .get()
        .toPromise();
      
      let totalUnread = 0;
      
      threadsQuerySnapshot?.docs.forEach(doc => {
        const thread = doc.data() as ChatThread;
        totalUnread += thread.unreadCounts?.[this.currentUserId] || 0;
      });
      
      this.unreadCountSubject.next(totalUnread);
    } catch (error) {
      console.error('Error updating total unread count:', error);
    }
  }

  // Convert Firestore timestamp to milliseconds
  private convertTimestamp(timestamp: any): number {
    if (!timestamp) return Date.now();
    
    if (timestamp.toMillis) {
      return timestamp.toMillis();
    } else if (timestamp.seconds) {
      return timestamp.seconds * 1000;
    }
    
    return timestamp;
  }

  // Get a specific chat thread
  getChatThread(threadId: string): Observable<ChatThread> {
    return this.firestore
      .collection<ChatThread>('chatThreads')
      .doc(threadId)
      .valueChanges()
      .pipe(
        map(thread => {
          if (!thread) throw new Error('Thread not found');
          
          return {
            ...thread,
            id: threadId,
            updatedAt: this.convertTimestamp(thread.updatedAt),
            lastMessage: thread.lastMessage ? {
              ...thread.lastMessage,
              timestamp: this.convertTimestamp(thread.lastMessage.timestamp)
            } : undefined
          };
        })
      );
  }

  // Get specific message by ID
  getMessageById(threadId: string, messageId: string): Observable<ChatMessage> {
    return this.firestore
      .collection('chatThreads')
      .doc(threadId)
      .collection<ChatMessage>('messages')
      .doc(messageId)
      .valueChanges()
      .pipe(
        map(message => {
          if (!message) throw new Error('Message not found');
          
          return {
            ...message,
            id: messageId,
            timestamp: this.convertTimestamp(message.timestamp)
          };
        })
      );
  }
} 