import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, tap, catchError } from 'rxjs/operators';
import { 
  DriverVerification, 
  VerificationDocument, 
  BackgroundCheckResult,
  DrivingRecord,
  VehicleInspection,
  DocumentType
} from '../../shared/models/driver-verification.model';

@Injectable({
  providedIn: 'root'
})
export class VerificationService {
  private apiUrl = 'api/verification';
  private verificationSubject = new BehaviorSubject<DriverVerification | null>(null);

  constructor(private http: HttpClient) {}

  /**
   * Get verification status for current driver
   */
  getCurrentVerification(): Observable<DriverVerification | null> {
    return this.http.get<DriverVerification>(`${this.apiUrl}/current`).pipe(
      tap(verification => this.verificationSubject.next(verification)),
      catchError(error => {
        console.error('Error fetching verification:', error);
        return of(null);
      })
    );
  }

  /**
   * Start new verification process
   */
  startVerification(): Observable<DriverVerification> {
    return this.http.post<DriverVerification>(`${this.apiUrl}/start`, {}).pipe(
      tap(verification => this.verificationSubject.next(verification)),
      catchError(error => {
        console.error('Error starting verification:', error);
        return of({
          id: 'temp-' + Date.now(),
          driverId: 'current',
          status: 'pending',
          submissionDate: new Date(),
          documents: []
        } as DriverVerification);
      })
    );
  }

  /**
   * Upload verification document
   */
  uploadDocument(documentType: DocumentType, file: File): Observable<VerificationDocument> {
    const formData = new FormData();
    formData.append('document', file);
    formData.append('type', documentType);

    return this.http.post<VerificationDocument>(`${this.apiUrl}/documents`, formData).pipe(
      tap(document => {
        const current = this.verificationSubject.value;
        if (current) {
          this.verificationSubject.next({
            ...current,
            documents: [...current.documents, document]
          });
        }
      }),
      catchError(error => {
        console.error('Error uploading document:', error);
        return of({
          id: 'temp-' + Date.now(),
          type: documentType,
          url: URL.createObjectURL(file),
          uploadDate: new Date(),
          status: 'pending'
        } as VerificationDocument);
      })
    );
  }

  /**
   * Submit verification for review
   */
  submitVerification(): Observable<DriverVerification> {
    return this.http.post<DriverVerification>(`${this.apiUrl}/submit`, {}).pipe(
      tap(verification => this.verificationSubject.next(verification)),
      catchError(error => {
        console.error('Error submitting verification:', error);
        return of(null);
      })
    );
  }

  /**
   * Get background check status
   */
  getBackgroundCheck(): Observable<BackgroundCheckResult | null> {
    return this.http.get<BackgroundCheckResult>(`${this.apiUrl}/background-check`).pipe(
      catchError(error => {
        console.error('Error fetching background check:', error);
        return of(null);
      })
    );
  }

  /**
   * Get driving record
   */
  getDrivingRecord(): Observable<DrivingRecord | null> {
    return this.http.get<DrivingRecord>(`${this.apiUrl}/driving-record`).pipe(
      catchError(error => {
        console.error('Error fetching driving record:', error);
        return of(null);
      })
    );
  }

  /**
   * Submit vehicle inspection
   */
  submitVehicleInspection(inspection: Partial<VehicleInspection>): Observable<VehicleInspection> {
    return this.http.post<VehicleInspection>(`${this.apiUrl}/vehicle-inspection`, inspection).pipe(
      tap(result => {
        const current = this.verificationSubject.value;
        if (current) {
          this.verificationSubject.next({
            ...current,
            vehicleInspection: result
          });
        }
      }),
      catchError(error => {
        console.error('Error submitting vehicle inspection:', error);
        return of(null);
      })
    );
  }

  /**
   * Get consent form URL
   */
  getConsentFormUrl(): Observable<string> {
    return this.http.get<{url: string}>(`${this.apiUrl}/consent-form`).pipe(
      map(response => response.url),
      catchError(error => {
        console.error('Error fetching consent form:', error);
        return of(null);
      })
    );
  }
}