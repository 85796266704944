import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromUser from './user.reducer';

// Feature selector
export const getUserState = createFeatureSelector<fromUser.State>(
  fromUser.userPageFeatureKey
);

// Derived selectors
export const getUserData = createSelector(
  getUserState,
  state => state.user
);

export const getUserLoading = createSelector(
  getUserState,
  state => state.pending
);

export const getUserError = createSelector(
  getUserState,
  state => state.error
);

export const getUserSettings = createSelector(
  getUserState,
  state => state.settings
);

export const isAuthenticated = createSelector(
  getUserState,
  state => !!state.user
);

// Backward compatibility selectors
export const selectUser = getUserData;
export const selectAllUsers = createSelector(
  getUserState,
  state => state.users || []
); 