import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { first, switchMap, tap } from 'rxjs/operators';
import { MockDataService } from './mock-data.service';
import { from, of } from 'rxjs';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class OnboardingService {
  private currentUserId: string | null = null;
  private currentUserName: string = 'User';
  private welcomeCreated: boolean = false;

  constructor(
    private firestore: AngularFirestore,
    private auth: AngularFireAuth,
    private mockDataService: MockDataService,
    private router: Router
  ) {
    // Initialize user data and automatically create welcome threads
    this.auth.authState.subscribe(user => {
      if (user) {
        this.currentUserId = user.uid;
        // Get user profile to fetch name
        this.firestore
          .collection('users')
          .doc(user.uid)
          .get()
          .pipe(first())
          .subscribe(profile => {
            if (profile.exists) {
              const userData = profile.data() as any;
              this.currentUserName = userData.displayName || 'User';
              
              // Check if this is a new user who needs welcome threads
              this.checkAndCreateWelcomeThreads();
            }
          });
      } else {
        this.currentUserId = null;
        this.welcomeCreated = false;
      }
    });
  }

  /**
   * Checks if user has welcome threads and creates them if needed
   */
  private checkAndCreateWelcomeThreads(): void {
    if (this.welcomeCreated || !this.currentUserId) {
      console.log('Welcome content already created or user not authenticated');
      return;
    }

    console.log('Checking for existing welcome threads...');
    
    try {
      // Check for existing welcome threads
      this.firestore
        .collection('chatThreads', ref => 
          ref.where('participants', 'array-contains', this.currentUserId)
          .limit(10) // Don't filter by metadata type in case the structure changed
        )
        .get()
        .pipe(first())
        .subscribe(snapshot => {
          if (snapshot.empty) {
            console.log('No existing threads found, creating welcome content...');
            // No threads found, create them
            this.createWelcomeThreads().then(created => {
              if (created) {
                this.welcomeCreated = true;
                console.log('Created default welcome messages for user');
                
                // Navigate to messages tab after a short delay
                setTimeout(() => {
                  this.router.navigate(['/tabs/messages']);
                }, 1500);
              } else {
                console.error('Failed to create welcome content');
              }
            }).catch(error => {
              console.error('Error in welcome thread creation:', error);
            });
          } else {
            // Check if we have the welcome thread specifically
            const hasWelcomeThread = snapshot.docs.some(doc => {
              const data = doc.data() as any;
              return data?.metadata?.rideInfo?.type === 'onboarding';
            });
            
            if (hasWelcomeThread) {
              console.log('User already has welcome threads');
              this.welcomeCreated = true;
            } else {
              console.log('User has threads but no welcome thread, creating welcome content...');
              this.createWelcomeThreads().then(created => {
                if (created) {
                  this.welcomeCreated = true;
                  console.log('Created default welcome messages for user');
                }
              }).catch(error => {
                console.error('Error in welcome thread creation:', error);
              });
            }
          }
        }, error => {
          console.error('Error checking for existing threads:', error);
        });
    } catch (error) {
      console.error('Exception checking for welcome threads:', error);
    }
  }

  /**
   * Creates welcome threads and messages for a new user
   * @returns Promise resolving to true if threads were created
   */
  async createWelcomeThreads(): Promise<boolean> {
    if (!this.currentUserId) {
      console.error('Cannot create welcome threads: User not authenticated');
      return false;
    }

    try {
      console.log('Generating welcome threads for user:', this.currentUserId);
      
      // Generate welcome threads and prepare them for Firestore
      const welcomeThread = this.mockDataService.generateWelcomeThread(this.currentUserId, this.currentUserName);
      const safetyThread = this.mockDataService.generateSafetyThread(this.currentUserId, this.currentUserName);
      const rideAssistThread = this.mockDataService.generateRideAssistThread(this.currentUserId, this.currentUserName);
      
      const preparedWelcomeThread = this.prepareThreadForFirestore(welcomeThread);
      const preparedSafetyThread = this.prepareThreadForFirestore(safetyThread);
      const preparedRideAssistThread = this.prepareThreadForFirestore(rideAssistThread);
      
      console.log('Welcome threads prepared, saving to Firestore...');
      
      try {
        // First attempt: Use batched writes for best performance
        const threadBatch = this.firestore.firestore.batch();
        
        const welcomeRef = this.firestore.collection('chatThreads').doc(welcomeThread.id).ref;
        const safetyRef = this.firestore.collection('chatThreads').doc(safetyThread.id).ref;
        const rideAssistRef = this.firestore.collection('chatThreads').doc(rideAssistThread.id).ref;
        
        threadBatch.set(welcomeRef, preparedWelcomeThread);
        threadBatch.set(safetyRef, preparedSafetyThread);
        threadBatch.set(rideAssistRef, preparedRideAssistThread);
        
        await threadBatch.commit();
      } catch (batchError) {
        console.warn('Batch write failed, falling back to individual writes:', batchError);
        
        // Fallback: Use individual writes
        await this.firestore.collection('chatThreads').doc(welcomeThread.id).set(preparedWelcomeThread);
        await this.firestore.collection('chatThreads').doc(safetyThread.id).set(preparedSafetyThread);
        await this.firestore.collection('chatThreads').doc(rideAssistThread.id).set(preparedRideAssistThread);
      }
      
      console.log('Threads created successfully');
      
      // Generate welcome messages
      const welcomeMessages = this.mockDataService.generateWelcomeMessages(this.currentUserId);
      const safetyMessages = this.mockDataService.generateSafetyMessages(this.currentUserId);
      const rideAssistMessages = this.mockDataService.generateRideAssistMessages(this.currentUserId);
      
      console.log('Messages generated, preparing batch operations...');
      
      try {
        // Try batched writes first for messages
        const messageBatch = this.firestore.firestore.batch();
        
        welcomeMessages.forEach(message => {
          const msgRef = this.firestore.collection('chatThreads').doc(welcomeThread.id)
            .collection('messages').doc().ref;
          messageBatch.set(msgRef, this.prepareMessageForFirestore(message));
        });
        
        safetyMessages.forEach(message => {
          const msgRef = this.firestore.collection('chatThreads').doc(safetyThread.id)
            .collection('messages').doc().ref;
          messageBatch.set(msgRef, this.prepareMessageForFirestore(message));
        });
        
        rideAssistMessages.forEach(message => {
          const msgRef = this.firestore.collection('chatThreads').doc(rideAssistThread.id)
            .collection('messages').doc().ref;
          messageBatch.set(msgRef, this.prepareMessageForFirestore(message));
        });
        
        await messageBatch.commit();
      } catch (messageBatchError) {
        console.warn('Message batch write failed, falling back to sequential writes:', messageBatchError);
        
        // Fallback to individual message writes
        const addMessage = async (message: any, threadId: string) => {
          const preparedMessage = this.prepareMessageForFirestore(message);
          await this.firestore.collection('chatThreads').doc(threadId)
            .collection('messages').add(preparedMessage);
        };
        
        // Add welcome messages
        for (const message of welcomeMessages) {
          await addMessage(message, welcomeThread.id);
        }
        
        // Add safety messages
        for (const message of safetyMessages) {
          await addMessage(message, safetyThread.id);
        }
        
        // Add ride assist messages
        for (const message of rideAssistMessages) {
          await addMessage(message, rideAssistThread.id);
        }
      }
      
      console.log('Messages created successfully');
      
      // Create welcome notifications
      try {
        console.log('Generating notifications...');
        const notifications = this.mockDataService.generateWelcomeNotifications(this.currentUserId);
        
        try {
          // Try batch writes for notifications
          const notificationBatch = this.firestore.firestore.batch();
          
          notifications.forEach(notification => {
            const notRef = this.firestore.collection('notifications').doc().ref;
            notificationBatch.set(notRef, this.prepareNotificationForFirestore(notification));
          });
          
          await notificationBatch.commit();
        } catch (notifBatchError) {
          console.warn('Notification batch write failed, falling back to individual writes:', notifBatchError);
          
          // Fallback to individual notification writes
          for (const notification of notifications) {
            const preparedNotification = this.prepareNotificationForFirestore(notification);
            await this.firestore.collection('notifications').add(preparedNotification);
          }
        }
        
        console.log('Notifications created successfully');
      } catch (notifError) {
        // Don't fail the entire process if notifications fail
        console.warn('Error creating notifications:', notifError);
      }
      
      console.log('Welcome threads, messages, and notifications created successfully');
      return true;
    } catch (error) {
      console.error('Error creating welcome content:', error);
      return false;
    }
  }
  
  /**
   * Converts Date objects to Firestore Timestamps for thread objects
   */
  private prepareThreadForFirestore(thread: any): any {
    const preparedThread = { ...thread };
    
    if (preparedThread.updatedAt instanceof Date) {
      // Ensure we safely create a Timestamp
      preparedThread.updatedAt = this.convertToTimestamp(preparedThread.updatedAt);
    }
    
    if (preparedThread.lastMessage?.timestamp instanceof Date) {
      // Ensure we safely create a Timestamp
      preparedThread.lastMessage.timestamp = this.convertToTimestamp(preparedThread.lastMessage.timestamp);
    }
    
    return preparedThread;
  }
  
  /**
   * Converts Date objects to Firestore Timestamps for message objects
   */
  private prepareMessageForFirestore(message: any): any {
    const preparedMessage = { ...message };
    
    if (preparedMessage.timestamp instanceof Date) {
      // Ensure we safely create a Timestamp
      preparedMessage.timestamp = this.convertToTimestamp(preparedMessage.timestamp);
    }
    
    return preparedMessage;
  }
  
  /**
   * Converts Date objects to Firestore Timestamps for notification objects
   */
  private prepareNotificationForFirestore(notification: any): any {
    const preparedNotification = { ...notification };
    
    if (preparedNotification.timestamp instanceof Date) {
      // Ensure we safely create a Timestamp
      preparedNotification.timestamp = this.convertToTimestamp(preparedNotification.timestamp);
    }
    
    return preparedNotification;
  }

  /**
   * Helper method to safely convert Date to Firestore Timestamp
   */
  private convertToTimestamp(date: Date): any {
    try {
      // Try to import firebase/firestore dynamically if needed
      if (!firebase.firestore) {
        // If firestore is not available on the firebase object,
        // fall back to using the date directly
        console.log('Firestore not available on firebase object, using date directly');
        return date;
      }
      
      // Use Firestore Timestamp if available
      return firebase.firestore.Timestamp.fromDate(date);
    } catch (error) {
      console.warn('Error converting to Timestamp, using date directly:', error);
      // Fall back to using the date directly
      return date;
    }
  }
} 