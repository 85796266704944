/**
 * Constants index file
 * Dynamically selects between development and production constants
 */

import { environment } from '../../environments/environment';
import * as devConstants from './constants';
import * as prodConstants from './constants.prod';

// Select constants based on environment
const constants = environment.production ? prodConstants : devConstants;

// Export all constants
export const CHAT_DATA = constants.CHAT_DATA;
export const DELETE_RIDE = constants.DELETE_RIDE;
export const CAR_DATA = constants.CAR_DATA;
export const NEW_VECHILE_DATA = constants.NEW_VECHILE_DATA;
export const MARK_OPTIONS = constants.MARK_OPTIONS;
export const EDIT_RIDE = constants.EDIT_RIDE;
export const NOTIFICATIONS = constants.NOTIFICATIONS;
export const DOCUMENTS = constants.DOCUMENTS;
export const FOOTER_CHAT = constants.FOOTER_CHAT;
export const PROFILE_DATA = constants.PROFILE_DATA;
export const PROFILE_DETAILS = constants.PROFILE_DETAILS;
export const PROFILE = constants.PROFILE;
export const BIOGRAPHY = constants.BIOGRAPHY;
export const PREFERENCE = constants.PREFERENCE;
export const COUNTRIES = constants.COUNTRIES;
export const EMAIL_VERIFICATION = constants.EMAIL_VERIFICATION;
export const CARDS = constants.CARDS;
export const TAB_ROUTING = constants.TAB_ROUTING; 