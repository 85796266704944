import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';

@Component({
  selector: 'app-star-rating',
  templateUrl: './star-rating.component.html',
  styleUrls: ['./star-rating.component.scss'],
  standalone: true,
  imports: [CommonModule, IonicModule]
})
export class StarRatingComponent {
  @Input() rating: number = 0;
  @Input() maxStars: number = 5;
  @Input() color: string = 'warning';
  @Input() readonly: boolean = false;
  @Output() ratingChange = new EventEmitter<number>();

  get stars(): number[] {
    return Array(this.maxStars).fill(0).map((_, i) => i + 1);
  }

  rate(value: number): void {
    if (!this.readonly) {
      this.rating = value;
      this.ratingChange.emit(value);
    }
  }

  isStarFilled(star: number): boolean {
    return star <= this.rating;
  }
  
  getStarClass(): string {
    return this.readonly ? 'readonly-star' : 'interactive-star';
  }
  
  getStarIconName(index: number): string {
    const position = index + 1;
    
    if (position <= this.rating) {
      return 'star'; // Filled star
    } else if (position - 0.5 <= this.rating) {
      return 'star-half'; // Half star
    } else {
      return 'star-outline'; // Empty star
    }
  }
}