// ----------------------------------
// Angular & Ionic
// ----------------------------------
import { Component, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Router } from '@angular/router';

// ----------------------------------
// Capacitor Plugins
// ----------------------------------
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar, Style } from '@capacitor/status-bar';
import { Capacitor } from '@capacitor/core';
import { Plugins } from '@capacitor/core';
import { defineCustomElements } from '@ionic/pwa-elements/loader';

// ----------------------------------
// Environment & Services
// ----------------------------------
import { environment } from '../environments/environment';
import { UtilService } from '@app/services/util.service';
import { LanguageService } from '@app/services/language.service';
import { OnboardingService } from '@app/services/onboarding.service';
import { AuthenticationService } from '@app/services/auth.service';
import { Subscription } from 'rxjs';

// Define the Calendar plugin
declare global {
  interface Window {
    CapacitorCalendar: any;
  }
}

// ----------------------------------
// Root Application Component
// ----------------------------------
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  /**
   * A simple list of pages that appear in the side menu
   */
  public appPages = [
    {
      title: 'Home',
      url: '/tabs/home',
      icon: 'home'
    },
    {
      title: 'List',
      url: '/list',
      icon: 'list'
    }
  ];

  private authSubscription: Subscription;

  /**
   * Inject dependencies and run initialization
   */
  constructor(
    private platform: Platform,
    private util: UtilService,
    private languageService: LanguageService,
    private router: Router,
    private onboardingService: OnboardingService,
    private authService: AuthenticationService
  ) {
    this.initializeApp();
  }

  ngOnInit() {
    // Initialize PWA elements for native-like features
    defineCustomElements(window);
    
    // Handle default route redirection based on auth state
    if (window.location.pathname === '/' || window.location.pathname === '') {
      this.authSubscription = this.authService.authState.subscribe(user => {
        if (user) {
          // User is authenticated, redirect to home
          console.log('User is authenticated, redirecting to home');
          this.router.navigate(['/tabs/home']);
        } else {
          // User is not authenticated, redirect to login
          console.log('User is not authenticated, redirecting to login');
          this.router.navigate(['/login']);
        }
        
        // Unsubscribe after navigation to avoid memory leaks
        if (this.authSubscription) {
          this.authSubscription.unsubscribe();
        }
      });
    }
    
    // Language service is initialized automatically in its constructor
    // OnboardingService is initialized automatically to create welcome messages
  }

  /**
   * Initialize Capacitor plugins and check for API key configuration
   */
  async initializeApp() {
    await this.platform.ready();
    
    // Only use StatusBar on native platforms
    if (Capacitor.isNativePlatform()) {
      try {
        await StatusBar.setStyle({ style: Style.Light });
      } catch (err) {
        console.warn('StatusBar plugin not available', err);
      }
    }

    // Initialize Google Maps with retry logic
    try {
      await this.loadGoogleMaps();
    } catch (error) {
      console.error('Failed to load Google Maps:', error);
    }

    SplashScreen.hide();

    // Register the Calendar plugin if available
    if (typeof window !== 'undefined' && window.CapacitorCalendar) {
      console.log('Calendar plugin registered');
    } else {
      console.log('Calendar plugin not available');
      
      // Create a mock implementation for web testing
      if (typeof window !== 'undefined') {
        window.CapacitorCalendar = {
          hasPermission: () => Promise.resolve({ granted: false }),
          requestPermission: () => Promise.resolve({ granted: false }),
          getCalendars: () => Promise.resolve({ calendars: [] }),
          getEvents: () => Promise.resolve({ events: [] }),
          createEvent: () => Promise.resolve({ eventId: 'mock-event-id' })
        };
      }
    }
  }

  private loadGoogleMaps(): Promise<void> {
    const API_KEY = environment.googleMapsApiKey;
    
    return new Promise((resolve, reject) => {
      if (typeof google === 'undefined' || typeof google.maps === 'undefined') {
        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&libraries=places`;
        script.async = true;
        script.defer = true;
        script.onerror = () => reject(new Error('Failed to load Google Maps'));
        script.onload = () => resolve();
        document.head.appendChild(script);
      } else {
        resolve();
      }
    });
  }

  /**
   * Display an alert if the Maps API key is not correctly configured
   */
  async showAPIKeyAlert() {
    const cancelAlert = await this.util.createAlert(
      'Wait!',
      false,
      'You have not entered your Maps API key in environment. Make sure you enter the API key in both debug and prod environment and index.html. Read more in the documentation or README.md of source code.',
      {
        text: 'Ok',
        role: 'cancel',
        cssClass: 'secondary',
        handler: async () => {
          // Additional logic can be placed here if needed
        }
      }
    );
    await cancelAlert.present();
  }
}