import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { ErrorHandlerService } from './error-handler.service';

export interface User {
  id: string;
  email: string;
  displayName: string;
  photoURL?: string;
  emailVerified: boolean;
  phoneNumber?: string;
  role: 'parent' | 'driver' | 'admin';
  createdAt: Date;
  lastLoginAt: Date;
}

export interface AuthState {
  user: User | null;
  loading: boolean;
  error: string | null;
  isAuthenticated: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private currentUserSubject = new BehaviorSubject<User | null>(null);
  private authStateSubject = new BehaviorSubject<AuthState>({
    user: null,
    loading: false,
    error: null,
    isAuthenticated: false
  });

  constructor(
    private http: HttpClient,
    private router: Router,
    private errorHandler: ErrorHandlerService
  ) {
    this.loadUserFromStorage();
  }

  get currentUser(): User | null {
    return this.currentUserSubject.value;
  }

  get authState(): Observable<AuthState> {
    return this.authStateSubject.asObservable();
  }

  get isAuthenticated(): boolean {
    return !!this.currentUserSubject.value;
  }

  login(email: string, password: string): Observable<User> {
    this.setAuthState({ loading: true, error: null });

    return this.http.post<{ user: User, token: string }>(`${environment.apiUrl}/auth/login`, { email, password })
      .pipe(
        tap(response => {
          this.handleAuthSuccess(response.user, response.token);
        }),
        map(response => response.user),
        catchError(error => {
          this.handleAuthError(error, 'Failed to login');
          return of(null);
        })
      );
  }

  logout(): void {
    localStorage.removeItem('auth_token');
    localStorage.removeItem('user');
    this.currentUserSubject.next(null);
    this.setAuthState({ user: null, isAuthenticated: false });
    this.router.navigate(['/login']);
  }

  register(userData: Partial<User>, password: string): Observable<User> {
    this.setAuthState({ loading: true, error: null });

    return this.http.post<{ user: User, token: string }>(`${environment.apiUrl}/auth/register`, { ...userData, password })
      .pipe(
        tap(response => {
          this.handleAuthSuccess(response.user, response.token);
        }),
        map(response => response.user),
        catchError(error => {
          this.handleAuthError(error, 'Failed to register');
          return of(null);
        })
      );
  }

  updateUserProfile(updates: Partial<User>): Observable<User> {
    const currentUser = this.currentUserSubject.value;
    if (!currentUser) return of(null);

    return this.http.patch<User>(`${environment.apiUrl}/users/${currentUser.id}`, updates)
      .pipe(
        tap(user => {
          this.currentUserSubject.next(user);
          this.setAuthState({ user });
          localStorage.setItem('user', JSON.stringify(user));
        }),
        catchError(error => {
          this.handleAuthError(error, 'Failed to update profile');
          return of(null);
        })
      );
  }

  getToken(): string {
    return localStorage.getItem('auth_token') || '';
  }

  requestPasswordReset(email: string): Observable<boolean> {
    return this.http.post<{ success: boolean }>(`${environment.apiUrl}/auth/request-password-reset`, { email })
      .pipe(
        map(response => response.success),
        catchError(error => {
          this.handleAuthError(error, 'Failed to request password reset');
          return of(false);
        })
      );
  }

  resetPassword(token: string, newPassword: string): Observable<boolean> {
    return this.http.post<{ success: boolean }>(`${environment.apiUrl}/auth/reset-password`, { token, password: newPassword })
      .pipe(
        map(response => response.success),
        catchError(error => {
          this.handleAuthError(error, 'Failed to reset password');
          return of(false);
        })
      );
  }

  private handleAuthSuccess(user: User, token: string): void {
    localStorage.setItem('auth_token', token);
    localStorage.setItem('user', JSON.stringify(user));
    this.currentUserSubject.next(user);
    this.setAuthState({ user, isAuthenticated: true, loading: false });
  }

  private loadUserFromStorage(): void {
    try {
      const token = localStorage.getItem('auth_token');
      const userData = localStorage.getItem('user');
      
      if (token && userData) {
        const user = JSON.parse(userData) as User;
        this.currentUserSubject.next(user);
        this.setAuthState({ user, isAuthenticated: true });
      }
    } catch (error) {
      this.errorHandler.handleError(error);
      this.logout();
    }
  }

  private setAuthState(updates: Partial<AuthState>): void {
    this.authStateSubject.next({
      ...this.authStateSubject.value,
      ...updates
    });
  }

  private handleAuthError(error: any, fallbackMessage: string): void {
    let errorMessage = fallbackMessage;
    
    if (error instanceof HttpErrorResponse) {
      errorMessage = error.error?.message || fallbackMessage;
    }
    
    this.setAuthState({ loading: false, error: errorMessage });
    
    this.errorHandler.handleError(error);
  }
} 