import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { ChildService } from '../../../services/child.service';
import { Child } from '../../../shared/models/child.model';
import { UploadService } from '../../../services/upload.service';
import { ApprovedPickup } from '../../../shared/models/approved-pickup.model';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-children',
  templateUrl: './children.component.html',
  styleUrls: ['./children.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    IonicModule,
    ReactiveFormsModule,
    TranslateModule
  ]
})
export class ChildrenComponent implements OnInit {
  children: Child[] = [];
  emergencyForm: FormGroup;
  pickupForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private childService: ChildService,
    private uploadService: UploadService
  ) {
    this.initializeForms();
  }

  ngOnInit(): void {
    this.loadChildren();
  }

  private initializeForms(): void {
    this.emergencyForm = this.fb.group({
      medicalConditions: [''],
      allergies: [''],
      medications: [''],
      specialInstructions: ['']
    });

    this.pickupForm = this.fb.group({
      name: [''],
      relationship: ['']
    });
  }

  private loadChildren(): void {
    this.childService.getChildren().subscribe(children => {
      this.children = children;
    });
  }

  async onPhotoUpload(event: any, childId: string): Promise<void> {
    const file = event.target.files[0];
    if (file) {
      try {
        const photoUrl = await this.uploadService.uploadChildPhoto(childId, file);
        this.childService.updateChildPhoto(childId, photoUrl).subscribe(() => {
          // Update local state
          const child = this.children.find(c => c.id === childId);
          if (child) {
            child.photoUrl = photoUrl;
          }
        });
      } catch (error) {
        console.error('Error uploading photo:', error);
      }
    }
  }

  updateEmergencyInfo(childId: string): void {
    const emergencyInfo = this.emergencyForm.value;
    this.childService.updateEmergencyInfo(childId, emergencyInfo).subscribe();
  }

  addApprovedPickup(childId: string): void {
    if (this.pickupForm.valid) {
      const pickup: ApprovedPickup = {
        id: Date.now().toString(), // Temporary ID
        ...this.pickupForm.value
      };

      this.childService.addApprovedPickup(childId, pickup).subscribe(() => {
        this.pickupForm.reset();
        this.loadChildren(); // Refresh list
      });
    }
  }

  removeApprovedPickup(childId: string, pickupId: string): void {
    this.childService.removeApprovedPickup(childId, pickupId).subscribe(() => {
      this.loadChildren(); // Refresh list
    });
  }
}