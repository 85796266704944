import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, from } from 'rxjs';
import { catchError, exhaustMap, map, switchMap, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';

import * as RideActions from './ride.actions';

import { RideService } from '@app/services/ride.service';
import { Ride } from '../models/ride';

// Define the RideSearchModel interface if not already imported
interface RideSearchModel {
  pickupAddress?: string;
  dropAddress?: string;
  departureTime?: string;
  seatsNeeded?: number;
  getAllRides?: boolean;
  [key: string]: any;
}

@Injectable()
export class RideEffects {
  constructor(
    private actions$: Actions,
    private rideService: RideService,
    private store: Store,
    private router: Router
  ) {}

  /**
   * Sanitizes ride data for serialization in NgRx actions.
   * Converts Date objects to ISO strings and removes functions.
   */
  private sanitizeRideForAction(ride: any): any {
    if (!ride) return null;
    
    const sanitized = { ...ride };
    
    // Convert Date objects to ISO strings
    if (sanitized.updatedAt instanceof Date) {
      sanitized.updatedAt = sanitized.updatedAt.toISOString();
    }
    
    if (sanitized.createdAt instanceof Date) {
      sanitized.createdAt = sanitized.createdAt.toISOString();
    }
    
    // Handle nested Date objects
    if (sanitized.timestamp instanceof Date) {
      sanitized.timestamp = sanitized.timestamp.toISOString();
    }
    
    // Handle specific nested objects that might contain Date objects
    ['pickupLocation', 'dropLocation', 'dropoffLocation'].forEach(key => {
      if (sanitized[key] && sanitized[key].timestamp instanceof Date) {
        sanitized[key] = {
          ...sanitized[key],
          timestamp: sanitized[key].timestamp.toISOString()
        };
      }
    });
    
    return sanitized;
  }

  // Effect for createRide (previously rideUpsert)
  createRide$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RideActions.createRide),
      switchMap(({ ride }) => {
        // Sanitize the ride data before creating
        const sanitizedRide = this.sanitizeRideForAction(ride);
        return from(this.rideService.createRide(sanitizedRide)).pipe(
          map(newRideId => {
            const newRide = { ...sanitizedRide, id: newRideId };
            // Sanitize the response data too
            const sanitizedNewRide = this.sanitizeRideForAction(newRide);
            return RideActions.createRideSuccess({ ride: sanitizedNewRide });
          }),
          catchError(error => of(RideActions.createRideFailure({ error })))
        );
      })
    )
  );

  // Effect for deleteRide
  deleteRide$ = createEffect(() => this.actions$.pipe(
    ofType(RideActions.deleteRide),
    map((action: { id: string }) => action.id),
    switchMap((id: string) => {
      return this.rideService.deleteRide({ id }).pipe(
        map(() => RideActions.deleteRideSuccess({ id })),
        catchError((error) => of(RideActions.deleteRideFailure({ error })))
      );
    })
  ));

  // Effect for getting a single ride
  loadRide$ = createEffect(() => this.actions$.pipe(
    ofType(RideActions.rideGet),
    map((action: { id: string }) => action.id),
    switchMap((id: string) => {
      return this.rideService.getRide(id).pipe(
        map((ride) => RideActions.rideGetSuccess({ ride })),
        catchError((error) => of(RideActions.rideGetFailure({ error })))
      );
    })
  ));

  // Effect for updating ride (including booking which previously used bookRide)
  updateRide$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RideActions.updateRide),
      switchMap(({ ride }) => {
        // Sanitize the ride data before updating
        const sanitizedRide = this.sanitizeRideForAction(ride);
        return from(this.rideService.updateRide(sanitizedRide)).pipe(
          map(() => {
            // Sanitize the response data too
            return RideActions.updateRideSuccess({ ride: sanitizedRide });
          }),
          catchError(error => of(RideActions.updateRideFailure({ error })))
        );
      })
    )
  );

  // Effect for cancelling a ride
  cancelRide$ = createEffect(() => this.actions$.pipe(
    ofType(RideActions.cancelRide),
    map((action: { ride: Ride }) => action.ride),
    switchMap((ride: Ride) => {
      return from(this.rideService.cancelRide(ride.id)).pipe(
        map(() => RideActions.cancelRideSuccess({ ride })),
        catchError((error) => of(RideActions.cancelRideFailure({ error })))
      );
    })
  ));

  // Effect for getting all rides
  loadRides$ = createEffect(() => this.actions$.pipe(
    ofType(RideActions.rideGetAll),
    switchMap(() => {
      return this.rideService.rideGetAll().pipe(
        map((rides) => RideActions.rideGetAllSuccess({ rides: rides || [] })),
        catchError((error) => of(RideActions.rideGetAllFailure({ error })))
      );
    })
  ));

  // Effect for get ride success - navigate to ride details
  getRideSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RideActions.rideGetSuccess),
        tap(({ ride }) => {
          // Optional: Navigate to ride details or perform other actions
          // this.router.navigate(['/ride-details']);
        })
      ),
    { dispatch: false }
  );

  // Update the effect to handle getAllRides flag
  searchRidesQuery$ = createEffect(() => 
    this.actions$.pipe(
      ofType(RideActions.searchRide),
      tap(action => console.log(`[RideEffects] Searching rides with params:`, action.searchParams)),
      map(action => action.searchParams),
      switchMap(params => {
        // Convert the query into a proper search model
        const searchData: RideSearchModel = {
          ...params,
          // Set getAllRides=true by default if not explicitly set to false
          // This ensures we return more results rather than none
          getAllRides: params.getAllRides !== false
        };

        // Fix: If this is a date-based search, make sure we're not overly restricting results
        if (searchData.departureTime) {
          try {
            const departureDate = new Date(searchData.departureTime);
            const currentDate = new Date();
            
            // If the date is invalid, today, or is using current timestamp as default,
            // ensure getAllRides is true to return more results
            if (
              isNaN(departureDate.getTime()) ||
              departureDate.toDateString() === currentDate.toDateString() || 
              Math.abs(departureDate.getTime() - currentDate.getTime()) < 60000 // Within a minute
            ) {
              console.log(`[RideEffects] Setting getAllRides=true because departureTime is today or invalid:`, searchData.departureTime);
              searchData.getAllRides = true;
            }
            
            console.log(`[RideEffects] Departure time parsed:`, {
              original: searchData.departureTime,
              parsed: departureDate.toString(),
              valid: !isNaN(departureDate.getTime()),
              getAllRides: searchData.getAllRides
            });
          } catch (err) {
            console.error(`[RideEffects] Error parsing departure time:`, err);
            // If we can't parse the date, set getAllRides to true as a fallback
            searchData.getAllRides = true;
          }
        }

        return this.rideService.searchRides(searchData).pipe(
          tap(results => console.log(`[RideEffects] Search returned ${results.length} results`)),
          map(rides => {
            // Sanitize rides for serialization (remove any circular references)
            const sanitizedRides = rides.map(ride => ({
              ...ride
            }));
            return RideActions.searchRideSuccess({ rides: sanitizedRides });
          }),
          catchError(error => {
            console.error('[RideEffects] Search rides error:', error);
            return of(RideActions.searchRideFailure({ error }));
          })
        );
      })
    )
  );

  // Modify the selectRide effect to sanitize the ride data
  selectRide$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RideActions.selectRide),
      map(action => {
        // Sanitize the ride data before passing it to the success action
        const sanitizedRide = this.sanitizeRideForAction(action.ride);
        return RideActions.selectRideSuccess({ ride: sanitizedRide });
      }),
      catchError(error => of(RideActions.selectRideFailure({ error })))
    )
  );

  // Add an effect for filtering rides
  filterRides$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RideActions.filterRides),
      switchMap(({ filters }) => {
        console.log('Starting ride filtering with filters:', filters);
        return this.rideService.filterRides(filters).pipe(
          map(filteredData => {
            // Process the filtered data to sanitize it
            const sanitizedData = {
              bookedRides: filteredData.bookedRides.map(ride => this.sanitizeRideForAction(ride)),
              offeredRides: filteredData.offeredRides.map(ride => this.sanitizeRideForAction(ride)),
              rideHistory: filteredData.rideHistory.map(ride => this.sanitizeRideForAction(ride))
            };
            
            console.log('Filter results processed:', {
              bookedRides: sanitizedData.bookedRides.length,
              offeredRides: sanitizedData.offeredRides.length,
              rideHistory: sanitizedData.rideHistory.length
            });
            
            return RideActions.filterRidesSuccess({ filteredData: sanitizedData });
          }),
          catchError(error => {
            console.error('Error filtering rides:', error);
            return of(RideActions.filterRidesFailure({ error }));
          })
        );
      })
    )
  );

  // Add other effects as needed
}
