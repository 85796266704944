import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export interface RideStatus {
  isActive: boolean;
  role: 'driver' | 'rider' | null;
}

@Injectable({
  providedIn: 'root'
})
export class RideStatusService {
  private rideStatusSubject = new BehaviorSubject<RideStatus>({
    isActive: false,
    role: null
  });

  constructor() { }

  /**
   * Get the current ride status as an observable
   */
  public getRideStatus(): Observable<RideStatus> {
    return this.rideStatusSubject.asObservable();
  }

  /**
   * Update the current ride status
   * @param status The new ride status
   */
  public updateRideStatus(status: RideStatus): void {
    this.rideStatusSubject.next(status);
  }

  /**
   * Start a ride with specified role
   * @param role The user's role in the ride (driver or rider)
   */
  public startRide(role: 'driver' | 'rider'): void {
    this.rideStatusSubject.next({
      isActive: true,
      role: role
    });
  }

  /**
   * End the current ride
   */
  public endRide(): void {
    this.rideStatusSubject.next({
      isActive: false,
      role: null
    });
  }
}