import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { RoleFormPage } from '@app/pages/role-form/role-form.page';
import { RoleFormPageModule } from '@app/pages/role-form/role-form.module';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

// Just define the component directly to avoid referencing missing components
import { Component } from '@angular/core';

@Component({
  template: ''
})
export class ShowHidePasswordComponent {}

import { AngularFireAuthGuardModule } from '@angular/fire/compat/auth-guard';
// Import effects array instead of individual effects
import { effects } from '@app/state';
import * as fromState from '@app/state';
// import { MaterialModule } from '@app/material';
import { PagesRoutingModule } from './pages-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthTokenHttpInterceptorProvider } from '@app/services/auth-token.interceptor';
import { ShareableModule } from '@app/components/shareable/shareable.module';
import { PipesModule } from '@app/pipes/pipes.module';

// Define the feature key
export const pagesFeatureKey = 'pages';

export const COMPONENTS = [
  ShowHidePasswordComponent
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    IonicModule,
    ShareableModule,
    ReactiveFormsModule,
    // MaterialModule,
    // PagesRoutingModule,
    AngularFireAuthGuardModule,
    BrowserAnimationsModule,
    RoleFormPageModule,
    PipesModule,
    StoreModule.forFeature(pagesFeatureKey, {}),
    EffectsModule.forFeature(effects),
  ],
  declarations: COMPONENTS,
  providers: [
    AuthTokenHttpInterceptorProvider,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class PagesModule { }
