import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Observable, from, of } from 'rxjs';
import { switchMap, take, tap } from 'rxjs/operators';
import { OnboardingService } from '../services/onboarding.service';

@Injectable({
  providedIn: 'root'
})
export class OnboardingGuard implements CanActivate {
  
  constructor(
    private auth: AngularFireAuth,
    private onboardingService: OnboardingService
  ) {}
  
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.auth.authState.pipe(
      take(1),
      switchMap(user => {
        if (!user) {
          return of(true); // Allow navigation if not logged in
        }
        
        // User is logged in, check if this is their first visit to a protected page
        // and create onboarding content if needed
        return from(this.onboardingService.createWelcomeThreads())
          .pipe(
            tap(created => {
              if (created) {
                console.log('Created welcome content for new user');
              }
            }),
            switchMap(() => of(true)) // Always allow navigation
          );
      })
    );
  }
} 