// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  
  // Firebase configuration
  firebaseConfig: {
    apiKey: "AIzaSyCBZzMY_tdXvHSmLBu7_37DFZAuzkIpDa0",
    authDomain: "herethere-c5b49.firebaseapp.com",
    projectId: "herethere-c5b49",
    storageBucket: "herethere-c5b49.firebasestorage.app",
    messagingSenderId: "85815072592",
    appId: "1:85815072592:web:da3a3e2627d2589278fe55",
    measurementId: "G-MLKWDEZP9Q",
    vapidKey: 'BOPF-nyBxghFrLL-VfIS7963iJX9CoT2aT9ItVZelxyJcT4eQXid2awJlZ_YqiwFPXFScF7Z-8Dtg12fNt8en6Y',
  },
  
  // API keys and endpoints
  apiUrl: 'https://api.example.com',
  GOOGLE_MAPS_API_KEY: 'AIzaSyCxGkNCXnf5paajRdFmmzE-jCtK86PUHkU',
  googleMapsApiKey: 'AIzaSyCxGkNCXnf5paajRdFmmzE-jCtK86PUHkU', // Added for consistency
  FUNCTIONS_BASE_URL_LOCAL: 'http://127.0.0.1:5001/herethere-c5b49/us-central1',
  FUNCTIONS_BASE_URL: 'https://us-central1-herethere-c5b49.cloudfunctions.net',
  
  // Car API configuration
  CAR_API: {
    API_NINJA_URL: 'https://api.api-ninjas.com/v1',
    API_NINJA_KEY: 'YOUR_API_KEY', // Replace with your API key
    AUTO_API_URL: 'https://thedmsgroup.github.io/autoapi',
    USE_AUTO_API: true // Set to false to use API Ninja instead
  },
  
  // Sample car data for fallback (minimal version)
  NEW_VECHILE_DATA: [
    {
      label: 'Make',
      placeholder: 'Select Vehicle',
      data: [
        {
          "name": "Toyota",
          "models": ["Camry", "Corolla", "RAV4", "Highlander", "Prius"]
        },
        {
          "name": "Honda",
          "models": ["Civic", "Accord", "CR-V", "Pilot", "Odyssey"]
        },
        {
          "name": "Ford",
          "models": ["F-150", "Escape", "Explorer", "Mustang", "Focus"]
        }
      ]
    },
    {
      label: 'Model',
      placeholder: 'Select Model'
    },
    {
      label: 'Year',
      placeholder: 'Select Year',
      data: [2023, 2022, 2021, 2020, 2019, 2018, 2017, 2016, 2015]
    },
    {
      label: 'Color',
      placeholder: 'Select Color',
      data: ['Black', 'White', 'Silver', 'Gray', 'Red', 'Blue', 'Brown', 'Green']
    }
  ],
  
  // Regional settings
  country: 'US',
  defaultLanguage: 'en',
  supportedLanguages: ['en', 'es', 'fr'],
  
  // App settings
  appName: 'HereThere',
  appVersion: '1.0.0',
  
  // Feature flags
  features: {
    enableTrustedNetwork: true,
    enableParentApproval: true,
    enablePayments: true,
    enableChat: true,
    enableNotifications: true
  },
  
  // Analytics settings
  analytics: {
    enabled: true,
    trackingId: 'UA-XXXXXXXXX-X'
  },
  
  // Logging settings
  logging: {
    level: 'debug', // 'debug', 'info', 'warn', 'error'
    enableRemoteLogging: false
  },
  
  // Add properties for services we created
  persistLogs: false,
  remoteLogging: false,
  useRemoteConfig: false,
  
  // Storage configuration
  storage: {
    prefix: 'herethere_',
    ttl: {
      default: 24 * 60 * 60 * 1000, // 24 hours
      user: 7 * 24 * 60 * 60 * 1000, // 7 days
      preferences: 365 * 24 * 60 * 60 * 1000 // 1 year
    }
  },
  
  // Stripe configuration
  stripe: {
    publishableKey: 'pk_test_XXXXXXXXXXXXXXXXXXXXXXXX'
  },
  
  // WebSocket configuration
  wsUrl: 'wss://example.com/ws'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
