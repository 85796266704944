import { createAction, props } from '@ngrx/store';
import { User } from '@app/state/models/user';

// Login form actions
export const login = createAction(
  '[Login Form] Login',
  props<{ credentials: { email: string; password: string } }>()
);

export const loginSuccess = createAction(
  '[Login API] Login Success',
  props<{ user: any }>()
);

export const loginFailure = createAction(
  '[Login API] Login Failure',
  props<{ error: any }>()
);

export const logout = createAction('[Login Form] Logout');

export const logoutSuccess = createAction(
  '[Login API] Logout Success'
);

export const logoutFailure = createAction(
  '[Login API] Logout Failure',
  props<{ error: any }>()
);

export const resetPassword = createAction(
  '[Login Form] Reset Password',
  props<{ email: string }>()
);

export const resetPasswordSuccess = createAction(
  '[Login API] Reset Password Success'
);

export const resetPasswordFailure = createAction(
  '[Login API] Reset Password Failure',
  props<{ error: any }>()
);

export const loginRedirect = createAction(
  '[Auth] Login Redirect'
);
