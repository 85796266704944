import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { tap, map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { NotificationService } from './notification.service';

export interface EmergencyReport {
  id: string;
  rideId: string;
  type: 'MEDICAL' | 'SAFETY' | 'VEHICLE' | 'OTHER';
  description?: string;
  location?: {
    lat: number;
    lng: number;
    address?: string;
  };
  timestamp: Date;
  status: 'pending' | 'acknowledged' | 'resolved';
  responseDetails?: {
    responderId?: string;
    responderType?: 'emergency_services' | 'support_team';
    eta?: Date;
    notes?: string;
  };
}

@Injectable({
  providedIn: 'root'
})
export class EmergencyService {
  private readonly API_URL = `${environment.apiUrl}/emergencies`;
  private activeEmergencies = new Subject<EmergencyReport[]>();

  constructor(
    private http: HttpClient,
    private notificationService: NotificationService
  ) {}

  /**
   * Report a new emergency
   */
  reportEmergency(report: Partial<EmergencyReport>): Observable<EmergencyReport> {
    const emergency: Partial<EmergencyReport> = {
      ...report,
      id: `emergency-${Date.now()}`,
      status: 'pending',
      timestamp: new Date()
    };

    return this.http.post<EmergencyReport>(`${this.API_URL}/report`, emergency)
      .pipe(
        tap(response => {
          // Send high-priority notification
          this.notificationService.createEmergencyNotification(
            'Emergency services have been notified and are responding.',
            response.rideId
          );

          // Update active emergencies list
          this.updateActiveEmergencies(response.rideId);
        })
      );
  }

  /**
   * Get active emergencies for a ride
   */
  getActiveEmergencies(rideId: string): Observable<EmergencyReport[]> {
    return this.http.get<EmergencyReport[]>(
      `${this.API_URL}/active/${rideId}`
    ).pipe(
      tap(emergencies => this.activeEmergencies.next(emergencies))
    );
  }

  /**
   * Get emergency report by ID
   */
  getEmergencyById(emergencyId: string): Observable<EmergencyReport> {
    return this.http.get<EmergencyReport>(
      `${this.API_URL}/${emergencyId}`
    );
  }

  /**
   * Update emergency status
   */
  updateEmergencyStatus(
    emergencyId: string,
    status: EmergencyReport['status'],
    details?: EmergencyReport['responseDetails']
  ): Observable<EmergencyReport> {
    return this.http.patch<EmergencyReport>(
      `${this.API_URL}/${emergencyId}/status`,
      { status, responseDetails: details }
    ).pipe(
      tap(emergency => {
        // Send notification about status update
        this.notificationService.createEmergencyNotification(
          `Emergency status updated to ${status}`,
          emergency.rideId
        );
      })
    );
  }

  /**
   * Cancel emergency report
   */
  cancelEmergency(emergencyId: string, reason: string): Observable<void> {
    return this.http.post<void>(
      `${this.API_URL}/${emergencyId}/cancel`,
      { reason }
    );
  }

  /**
   * Get emergency response ETA
   */
  getResponseEta(emergencyId: string): Observable<Date> {
    return this.http.get<{ eta: string }>(
      `${this.API_URL}/${emergencyId}/eta`
    ).pipe(
      map(response => new Date(response.eta))
    );
  }

  /**
   * Subscribe to active emergencies updates
   */
  subscribeToActiveEmergencies(): Observable<EmergencyReport[]> {
    return this.activeEmergencies.asObservable();
  }

  /**
   * Update active emergencies list
   */
  private updateActiveEmergencies(rideId: string): void {
    this.getActiveEmergencies(rideId).subscribe();
  }

  /**
   * Contact emergency services directly
   */
  contactEmergencyServices(location: { lat: number; lng: number }): void {
    // Implementation will vary by region and requirements
    // This is a placeholder for the actual implementation
    console.log('Contacting emergency services at location:', location);
  }

  /**
   * Get emergency reports with pagination and sorting
   */
  getEmergencyReports(
    page: number = 0,
    size: number = 10,
    sortBy: string = 'timestamp',
    sortDirection: string = 'desc',
    filter: string = ''
  ): Observable<{ items: EmergencyReport[]; total: number }> {
    return this.http.get<{ items: EmergencyReport[]; total: number }>(
      `${this.API_URL}?page=${page}&size=${size}&sort=${sortBy},${sortDirection}&filter=${filter}`
    );
  }

  /**
   * Resolve an emergency
   */
  resolveEmergency(emergencyId: string): Observable<EmergencyReport> {
    return this.updateEmergencyStatus(emergencyId, 'resolved', {
      notes: 'Emergency resolved by admin'
    });
  }

  /**
   * Escalate an emergency to higher priority
   */
  escalateEmergency(emergencyId: string): Observable<EmergencyReport> {
    return this.http.post<EmergencyReport>(
      `${this.API_URL}/${emergencyId}/escalate`,
      { timestamp: new Date() }
    );
  }
}