import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

/**
 * Cast an Observable to a specific type to fix RxJS compatibility issues
 * between different versions of RxJS used by Angular and Angular Fire.
 * 
 * This function creates a new Observable that subscribes to the source
 * and re-emits the values, effectively bridging between different RxJS versions.
 * 
 * @param source The source Observable to cast
 * @returns A new Observable with the correct type
 */
export function castObservable<T>(source: any): Observable<T> {
  return new Observable<T>(observer => {
    const subscription = source.subscribe({
      next: (value: any) => observer.next(value as T),
      error: (err: any) => observer.error(err),
      complete: () => observer.complete()
    });
    
    return () => {
      subscription.unsubscribe();
    };
  });
}

/**
 * Mock implementation of pendingUntilEvent to fix Angular Fire compatibility issues
 * This is needed because Angular Fire is looking for this function but it's not available
 * in the current version of Angular.
 * 
 * @param source The source Observable
 * @param eventName The event name to wait for
 * @returns The same Observable
 */
export function pendingUntilEvent<T>(source: Observable<T>, eventName: string): Observable<T> {
  // Just return the source Observable since we're not actually implementing the functionality
  return source;
}

/**
 * Mock implementation of PendingTasks to fix Angular Fire compatibility issues
 */
export class PendingTasks {
  add(task: any): void {
    // No-op implementation
  }
  
  remove(task: any): void {
    // No-op implementation
  }
  
  get hasPendingTasks(): boolean {
    return false;
  }
} 