import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule, ModalController } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule, FormsModule } from '@angular/forms';

@Component({
  selector: 'app-add-contact-modal',
  standalone: true,
  imports: [
    CommonModule, 
    IonicModule, 
    ReactiveFormsModule,
    FormsModule
  ],
  styles: [`
    :host {
      display: block;
      --background: var(--ion-background-color);
    }

    ion-content {
      --padding-top: var(--spacing-4);
      --padding-bottom: var(--spacing-4);
      --background: var(--ion-background-color);
    }
    
    ion-list {
      background: transparent;
    }

    ion-input {
      --background: var(--ion-background-color-step-50);
      --border-radius: var(--border-radius-md);
      --padding-start: var(--spacing-4);
      --padding-end: var(--spacing-4);
      margin-bottom: var(--spacing-4);
    }

    ion-item {
      --background: transparent;
      --padding-start: 0;
      --inner-padding-end: 0;
      margin-bottom: var(--spacing-4);
    }

    ion-toggle {
      --background: var(--ion-background-color-step-50);
    }

    .submit-button {
      margin-top: var(--spacing-6);
    }

    form {
      display: flex;
      flex-direction: column;
      gap: var(--spacing-4);
    }

    .error-text {
      color: var(--ion-color-danger);
      font-size: 0.875rem;
      margin-top: var(--spacing-1);
    }
  `],
  template: `
    <ion-header class="ion-no-border">
      <ion-toolbar>
        <ion-title role="heading" aria-level="1">Add Contact</ion-title>
        <ion-buttons slot="end">
          <ion-button (click)="dismiss()" aria-label="Close modal">
            <ion-icon name="close-outline" slot="icon-only"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content class="ion-padding">
      <form [formGroup]="contactForm" (ngSubmit)="onSubmit()" role="form">
        <ion-list lines="none">
          <ion-input
            class="ion-margin-bottom"
            fill="solid"
            label="Name"
            labelPlacement="stacked"
            formControlName="name" 
            type="text" 
            placeholder="Enter contact name"
            [clearInput]="true"
            required
            [attr.aria-invalid]="contactForm.get('name')?.invalid && contactForm.get('name')?.touched"
            aria-describedby="name-error"
          ></ion-input>
          <div *ngIf="contactForm.get('name')?.invalid && contactForm.get('name')?.touched" 
               class="error-text"
               id="name-error"
               role="alert">
            Name is required
          </div>

          <ion-input
            class="ion-margin-bottom"
            fill="solid"
            label="Phone"
            labelPlacement="stacked"
            formControlName="phone"
            type="tel"
            placeholder="Enter phone number"
            [clearInput]="true"
            required
            [attr.aria-invalid]="contactForm.get('phone')?.invalid && contactForm.get('phone')?.touched"
            aria-describedby="phone-error"
          ></ion-input>
          <div *ngIf="contactForm.get('phone')?.invalid && contactForm.get('phone')?.touched"
               class="error-text"
               id="phone-error"
               role="alert">
            Valid phone number is required
          </div>

          <ion-item lines="none" class="ion-margin-vertical">
            <ion-toggle 
              formControlName="trusted" 
              justify="space-between"
              labelPlacement="start"
              aria-label="Mark as trusted contact">
              Trusted Contact
            </ion-toggle>
          </ion-item>
        </ion-list>

        <ion-button 
          expand="block" 
          type="submit" 
          [disabled]="!contactForm.valid"
          class="submit-button">
          <ion-icon name="person-add-outline" slot="start" aria-hidden="true"></ion-icon>
          Add Contact
        </ion-button>
      </form>
    </ion-content>
  `
})
export class AddContactModalComponent {
  contactForm: FormGroup;

  constructor(
    private modalCtrl: ModalController,
    private fb: FormBuilder
  ) {
    this.contactForm = this.fb.group({
      name: ['', [Validators.required]],
      phone: ['', [Validators.required, Validators.pattern('^[0-9]{10}$')]],
      trusted: [false]
    });
  }

  async onSubmit() {
    if (this.contactForm.valid) {
      await this.modalCtrl.dismiss(this.contactForm.value);
    }
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }
}