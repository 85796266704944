import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { IonicModule, AlertController } from '@ionic/angular';
import { NetworkService, TrustedParent, ParentConnectionRequest } from './network.service';
import { Subscription } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { LanguageService } from '../../services/language.service';

@Component({
  selector: 'app-network',
  templateUrl: './network.component.html',
  styleUrls: ['./network.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    IonicModule,
    ReactiveFormsModule,
    TranslateModule
  ]
})
export class NetworkComponent implements OnInit, OnDestroy {
  trustedParents: TrustedParent[] = [];
  connectionRequests: ParentConnectionRequest[] = [];
  searchResults: TrustedParent[] = [];
  
  searchForm: FormGroup;
  connectionForm: FormGroup;
  
  isSearching = false;
  currentTab = 'network';

  private subscriptions: Subscription = new Subscription();

  constructor(
    private networkService: NetworkService,
    private formBuilder: FormBuilder,
    private alertController: AlertController,
    private languageService: LanguageService
  ) {
    this.searchForm = this.formBuilder.group({
      searchQuery: ['', [Validators.required, Validators.minLength(3)]]
    });
    
    this.connectionForm = this.formBuilder.group({
      parentEmail: ['', [Validators.required, Validators.email]]
    });
  }

  ngOnInit(): void {
    this.loadTrustedParents();
    this.loadConnectionRequests();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  loadTrustedParents(): void {
    const sub = this.networkService.getTrustedParents().subscribe(
      parents => {
        this.trustedParents = parents;
      },
      error => {
        console.error('Error loading trusted parents:', error);
      }
    );
    
    this.subscriptions.add(sub);
  }

  loadConnectionRequests(): void {
    const sub = this.networkService.getConnectionRequests().subscribe(
      requests => {
        this.connectionRequests = requests;
      },
      error => {
        console.error('Error loading connection requests:', error);
      }
    );
    
    this.subscriptions.add(sub);
  }

  searchParents(): void {
    if (this.searchForm.invalid) return;
    
    this.isSearching = true;
    const searchQuery = this.searchForm.get('searchQuery')?.value;
    
    const sub = this.networkService.searchParents(searchQuery).subscribe(
      results => {
        this.searchResults = results;
        this.isSearching = false;
      },
      error => {
        console.error('Error searching for parents:', error);
        this.isSearching = false;
      }
    );
    
    this.subscriptions.add(sub);
  }

  connectWithParent(parent: TrustedParent): void {
    const sub = this.networkService.sendConnectionRequest(parent.email).subscribe(
      async () => {
        const alert = await this.alertController.create({
          header: 'Request Sent',
          message: `A connection request has been sent to ${parent.name}`,
          buttons: ['OK']
        });
        
        await alert.present();
      },
      async error => {
        const alert = await this.alertController.create({
          header: 'Error',
          message: 'Failed to send connection request. Please try again.',
          buttons: ['OK']
        });
        
        await alert.present();
      }
    );
    
    this.subscriptions.add(sub);
  }

  sendConnectionRequest(): void {
    if (this.connectionForm.invalid) return;
    
    const parentEmail = this.connectionForm.get('parentEmail')?.value;
    
    const sub = this.networkService.sendConnectionRequest(parentEmail).subscribe(
      async () => {
        const alert = await this.alertController.create({
          header: 'Request Sent',
          message: `A connection request has been sent to ${parentEmail}`,
          buttons: ['OK']
        });
        
        await alert.present();
        this.connectionForm.reset();
      },
      async error => {
        const alert = await this.alertController.create({
          header: 'Error',
          message: 'Failed to send connection request. Please try again.',
          buttons: ['OK']
        });
        
        await alert.present();
      }
    );
    
    this.subscriptions.add(sub);
  }

  acceptRequest(request: ParentConnectionRequest): void {
    const sub = this.networkService.acceptConnectionRequest(request.id).subscribe(
      () => {
        // Remove from requests and add to trusted parents
        this.connectionRequests = this.connectionRequests.filter(r => r.id !== request.id);
        
        const newParent: TrustedParent = {
          id: request.requesterId,
          name: request.requesterName,
          email: request.requesterEmail,
          profileImage: request.requesterImage,
          verificationStatus: 'pending', // Default status when accepting
          connectionDate: new Date()
        };
        
        this.trustedParents.push(newParent);
      },
      error => {
        console.error('Error accepting connection request:', error);
      }
    );
    
    this.subscriptions.add(sub);
  }

  rejectRequest(request: ParentConnectionRequest): void {
    const sub = this.networkService.rejectConnectionRequest(request.id).subscribe(
      () => {
        // Remove from requests list
        this.connectionRequests = this.connectionRequests.filter(r => r.id !== request.id);
      },
      error => {
        console.error('Error rejecting connection request:', error);
      }
    );
    
    this.subscriptions.add(sub);
  }

  removeConnection(parent: TrustedParent): void {
    this.showConfirmationDialog(
      'Remove Connection',
      `Are you sure you want to remove ${parent.name} from your trusted network?`,
      () => {
        const sub = this.networkService.removeConnection(parent.id).subscribe(
          () => {
            this.trustedParents = this.trustedParents.filter(p => p.id !== parent.id);
          },
          error => {
            console.error('Error removing connection:', error);
          }
        );
        
        this.subscriptions.add(sub);
      }
    );
  }

  async showConfirmationDialog(header: string, message: string, onConfirm: () => void): Promise<void> {
    const alert = await this.alertController.create({
      header,
      message,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel'
        },
        {
          text: 'Confirm',
          handler: onConfirm
        }
      ]
    });
    
    await alert.present();
  }

  segmentChanged(event: any): void {
    this.currentTab = event.detail.value;
  }
}