import { createAction, props } from '@ngrx/store';
import { Car } from '@app/state/models/car';

// Car
export const getCar = createAction(
    '[Car] Get Car',
    props<{ id: string }>()
);

export const getCarSuccess = createAction(
    '[Car] Get Car Success',
    props<{ car: any }>()
);

export const getCarFailure = createAction(
    '[Car] Get Car Failure',
    props<{ error: any }>()
);

// All Cars
export const getAllCars = createAction(
    '[Car] Get All Cars',
);

export const getAllCarsSuccess = createAction(
    '[Car] Get All Cars Success',
    props<{ cars: any[] }>()
);

export const getAllCarsFailure = createAction(
    '[Car] Get All Cars Failure',
    props<{ error: any }>()
);

// Car Update
export const updateCar = createAction(
    '[Car] Update Car',
    props<{ car: any }>()
);

export const updateCarSuccess = createAction(
    '[Car] Update Car Success',
    props<{ car: any }>()
);

export const updateCarFailure = createAction(
    '[Car] Update Car Failure',
    props<{ error: any }>()
);

// Create Car
export const createCar = createAction(
    '[Car] Create Car',
    props<{ car: any }>()
);

export const createCarSuccess = createAction(
    '[Car] Create Car Success'
);

export const createCarFailure = createAction(
    '[Car] Create Car Failure',
    props<{ error: any }>()
);

// Car Delete
export const deleteCar = createAction(
    '[Car] Delete Car',
    props<{ id: string }>()
);

export const deleteCarSuccess = createAction(
    '[Car] Delete Car Success'
);

export const deleteCarFailure = createAction(
    '[Car] Delete Car Failure',
    props<{ error: any }>()
); 