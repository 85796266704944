// Rides
export class RideClass {
    bookedByUsers: {
        onwardRide: any[];
        returnRide: any[];
    };
    cancelled: boolean;
    description: any;
    dropAddress: any;
    dropLocation: {
        accuracy: number;
        latitude: number;
        longitude: number;
        timestamp: number;
    };
    offeredByUser: string;
    onwardRideDetails: any;
    pickupAddress: string;
    pickupLocation: {
        accuracy: number;
        latitude: number;
        longitude: number;
        timestamp: number;
    };
    returnRideDetails: any;
    seatsAvailable: {
        onwardRide: any;
        returnRide: any;
    };
    stopovers: any[];
    uid?: string;
    updatedAt: any;

    constructor() {
        this.bookedByUsers = {
            onwardRide: [],
            returnRide: []
        };
        this.cancelled = null;
        this.description = '';
        this.dropAddress = '';
        this.dropLocation = {
            accuracy: null,
            latitude: null,
            longitude: null,
            timestamp: null,
        };
        this.offeredByUser = '';
        this.onwardRideDetails = {};
        this.pickupAddress = '';
        this.pickupLocation = {
            accuracy: null,
            latitude: null,
            longitude: null,
            timestamp: null,
        };
        this.returnRideDetails = {};
        this.seatsAvailable = {
            onwardRide: null,
            returnRide: null,
        };
        this.stopovers = [];
        this.uid = ' ';
    }
};

// Update the Ride interface to include all properties used in templates
export interface Ride {
  id?: string;
  driverId: string;
  driverName?: string;
  driverPhoto?: string;
  pickupAddress?: string;
  dropAddress?: string;
  departureTime?: string | Date;
  availableSeats?: number;
  price?: number;
  description?: string;
  passengers?: any[];
  startDate?: string;
  startTime?: string;
  
  // Additional properties needed by home page
  createdBy: string; // ID of the user who created the ride
  journeyType?: 'oneWay' | 'roundTrip';
  status?: 'active' | 'completed' | 'cancelled';
  isOffered?: boolean; // Whether the ride was offered by the user or booked by the user
  
  // Time details
  returnTime?: string;
  returnDate?: string;
  
  // Ride details
  onwardRideDetails?: {
    startDate: string;
    startTime: string;
    returnDate?: string;
    returnTime?: string;
  };
  
  // Seat availability
  seatsAvailable?: {
    onwardRide: number;
    returnRide?: number;
  };
  
  // Status flags
  cancelled?: boolean;
  hidden?: boolean;
  
  // Location details
  pickupLocation?: {
    address: string;
    lat: number;
    lng: number;
    placeId: string | null;
  };
  dropoffLocation?: {
    address: string;
    lat: number;
    lng: number;
    placeId: string | null;
  };
  
  // Route information
  estimatedDistance?: string;
  estimatedDuration?: string;
  routePolyline?: string;
  
  // Ride preferences
  luggageSize?: string;
  isPetFriendly?: boolean;
  isSmokingAllowed?: boolean;
  isWheelchairAccessible?: boolean;
  
  // Timestamps
  createdAt?: string;
  updatedAt?: string;
  
  // Trusted network properties
  trustedOnly?: boolean;
  trustedGroups?: string[]; // IDs of trusted groups this ride is available to
  requiresParentApproval?: boolean;
  parentApproved?: boolean;
  parentApprovalRequestedAt?: number;
  parentApprovalRespondedAt?: number;
}

export type RidePropsArray = Array<keyof Ride>
