import { Injectable, NgZone } from '@angular/core';
import { User } from '@app/state/models/user';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/compat/firestore';
import { Observable, of, throwError, BehaviorSubject, from } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError, switchMap, tap, map, take, distinctUntilChanged, filter } from 'rxjs/operators';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { castObservable } from '../utils/rxjs-compat';
import { environment } from 'src/environments/environment';
import { StorageService } from './storage.service';

/**
 * Primary authentication service for the application.
 * Handles all Firebase authentication operations and user state management.
 */
@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private userData: any = null;
  private userState = new BehaviorSubject<any>(null);
  
  private idTokenSubject = new BehaviorSubject<string | null>(null);
  public idToken$ = this.idTokenSubject.asObservable().pipe(distinctUntilChanged());
  
  confirmationResult: firebase.auth.ConfirmationResult | null = null;
  private apiUrl = environment.apiUrl;
  private tokenRefreshTimeout: any;

  constructor(
    private afStore: AngularFirestore,
    private afAuth: AngularFireAuth,
    private router: Router,
    private ngZone: NgZone,
    private http: HttpClient,
    private storage: StorageService
  ) {
    this.afAuth.authState.subscribe(user => {
      if (user) {
        this.userData = user;
        this.userState.next(user);
        this.storage.set('user', JSON.stringify(this.userData));
        this.setupTokenRefresh(user);
      } else {
        this.userData = null;
        this.userState.next(null);
        this.storage.remove('user');
        this.clearTokenRefresh();
      }
    });
    
    this.afAuth.idToken.subscribe(token => {
      this.idTokenSubject.next(token);
    });
  }
  
  /**
   * Get Firebase Auth instance - public accessor for components that need it
   */
  get firebaseAuth(): AngularFireAuth {
    return this.afAuth;
  }

  /**
   * Get the current Firebase user
   */
  get currentUser(): Promise<firebase.User | null> {
    return this.afAuth.currentUser;
  }

  /**
   * Get the Firebase auth state as an observable
   */
  get authState(): Observable<firebase.User | null> {
    return this.afAuth.authState;
  }
  
  /**
   * Set up token refresh mechanism to ensure tokens don't expire
   */
  private setupTokenRefresh(user: firebase.User): void {
    // Clear any existing refresh
    this.clearTokenRefresh();
    
    user.getIdTokenResult()
      .then(idTokenResult => {
        // Token expiry time in milliseconds
        const expiryTime = new Date(idTokenResult.expirationTime).getTime();
        const currentTime = Date.now();
        
        // Calculate time to refresh (5 minutes before expiry)
        const timeToRefresh = Math.max(0, expiryTime - currentTime - (5 * 60 * 1000));
        
        // Set up refresh timeout
        this.tokenRefreshTimeout = setTimeout(() => {
          user.getIdToken(true)
            .then(newToken => {
              this.idTokenSubject.next(newToken);
              this.setupTokenRefresh(user); // Set up next refresh
            })
            .catch(error => {
              console.error('Failed to refresh token:', error);
              // Force re-login on token refresh failure
              this.signOut();
            });
        }, timeToRefresh);
      })
      .catch(error => {
        console.error('Error getting token expiration:', error);
      });
  }
  
  /**
   * Clear token refresh timeout
   */
  private clearTokenRefresh(): void {
    if (this.tokenRefreshTimeout) {
      clearTimeout(this.tokenRefreshTimeout);
      this.tokenRefreshTimeout = null;
    }
  }

  /**
   * Register a new user with email and password
   */
  async registerUser(email: string, password: string): Promise<boolean> {
    try {
      const result = await this.afAuth.createUserWithEmailAndPassword(email, password);
      if (result.user) {
        this.sendVerificationMail();
        this.router.navigate(['verify-email']);
        return true;
      }
      return false;
    } catch (error) {
      console.error('Registration error:', error);
      return false;
    }
  }

  /**
   * Send verification email to current user
   */
  async sendVerificationMail(): Promise<void> {
    const user = await this.afAuth.currentUser;
    if (user) {
      return user.sendEmailVerification();
    }
    throw new Error('No user is signed in');
  }

  /**
   * Send password reset email
   */
  async passwordRecover(email: string): Promise<boolean> {
    try {
      await this.afAuth.sendPasswordResetEmail(email);
      return true;
    } catch (error) {
      console.error('Password recovery error:', error);
      return false;
    }
  }

  /**
   * Check if user is logged in
   */
  get isLoggedIn(): boolean {
    try {
      const user = this.userData;
      
      // Check for user data, treat as not logged in if no data
      if (!user || !user.emailVerified) {
        return false;
      }
      
      // For session validation, we'll rely on Firebase's own token mechanism
      // which will automatically refresh tokens and maintain the session
      return true;
    } catch (error) {
      console.error('Error checking login status:', error);
      return false;
    }
  }

  /**
   * Check if user's email is verified
   */
  get isEmailVerified(): boolean {
    const user = this.getCurrentUser();
    return user ? user.emailVerified : false;
  }

  /**
   * Sign in with Google
   */
  googleAuth(): Promise<any> {
    return this.authLogin(new firebase.auth.GoogleAuthProvider());
  }

  /**
   * Auth logic to run auth providers
   */
  authLogin(provider: firebase.auth.AuthProvider): Promise<any> {
    return this.afAuth
      .signInWithPopup(provider)
      .then((result) => {
        this.setUserData(result.user);
        this.ngZone.run(() => {
          this.router.navigate(['tabs/home']);
        });
      })
      .catch((error) => {
        console.error('Authentication failed:', error);
        throw error;
      });
  }

  /**
   * Store user data in firestore on login
   */
  setUserData(user: any): Promise<void> {
    if (!user) return Promise.resolve();
    
    const userRef: AngularFirestoreDocument<any> = this.afStore.doc(`users/${user.uid}`);
    const userData = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL,
      emailVerified: user.emailVerified,
      lastLogin: new Date(),
    };
    
    return userRef.set(userData, {
      merge: true,
    });
  }

  /**
   * Sign out
   */
  async signOut(): Promise<void> {
    await this.afAuth.signOut();
    this.router.navigate(['login']);
  }

  /**
   * Sign in with phone number (first step)
   */
  signInWithPhoneNumber(recaptchaVerifier: firebase.auth.RecaptchaVerifier, phoneNumber: string): Promise<firebase.auth.ConfirmationResult> {
    return this.afAuth.signInWithPhoneNumber(phoneNumber, recaptchaVerifier)
      .then(confirmationResult => {
        this.confirmationResult = confirmationResult;
        return confirmationResult;
      });
  }

  /**
   * Verify phone code (second step of phone auth)
   */
  async enterVerificationCode(code: string): Promise<any> {
    if (!this.confirmationResult) {
      throw new Error('No confirmation result found. Try signing in again.');
    }
    
    try {
      const result = await this.confirmationResult.confirm(code);
      const user = result.user;
      this.setUserData(user);
      return user;
    } catch (error) {
      console.error('Error confirming verification code:', error);
      throw error;
    }
  }

  /**
   * Get the current user from firebase auth
   */
  getCurrentUser(): any {
    return this.userData;
  }

  /**
   * Get the current user's UID
   */
  async getCurrentUserId(): Promise<string | null> {
    const user = this.getCurrentUser();
    return user ? user.uid : null;
  }
  
  /**
   * Get current authentication token
   */
  getIdToken(): Observable<string> {
    return this.idToken$.pipe(
      filter(token => !!token),
      take(1)
    );
  }

  /**
   * Email & password sign in
   */
  async signIn(email: string, password: string): Promise<boolean> {
    try {
      console.log(`Attempting to sign in user: ${email}`);
      
      if (!email || !password) {
        console.error('Sign in error: Email or password is empty');
        return false;
      }
      
      // Normalize email to prevent case-sensitivity issues
      const normalizedEmail = email.toLowerCase().trim();
      
      // First attempt with standard method
      try {
        const result = await this.afAuth.signInWithEmailAndPassword(normalizedEmail, password);
        if (result && result.user) {
          console.log('Sign in successful via standard method');
          
          // Get the ID token to ensure the auth state is properly set
          const token = await result.user.getIdToken();
          this.idTokenSubject.next(token);
          
          // Set up token refresh
          this.setupTokenRefresh(result.user);
          
          // Update user data in Firestore
          await this.setUserData(result.user);
          
          // Navigate to home page
          this.ngZone.run(() => {
            this.router.navigate(['tabs/home']);
          });
          
          return true;
        }
      } catch (firstError) {
        console.warn('First login attempt failed:', firstError);
        // Continue to next method if this one fails
      }
      
      // Second attempt with direct Firebase method as fallback
      try {
        const auth = firebase.auth();
        const credential = await auth.signInWithEmailAndPassword(normalizedEmail, password);
        
        if (credential && credential.user) {
          console.log('Sign in successful via direct Firebase method');
          
          // Get the ID token
          const token = await credential.user.getIdToken();
          this.idTokenSubject.next(token);
          
          // Set user data
          this.userData = credential.user;
          this.userState.next(credential.user);
          this.storage.set('user', JSON.stringify(this.userData));
          
          // Navigate to home page
          this.ngZone.run(() => {
            this.router.navigate(['tabs/home']);
          });
          
          return true;
        }
      } catch (secondError) {
        console.error('Second login attempt failed:', secondError);
        // If both attempts fail, throw the error
        throw secondError;
      }
      
      // If we got here without a success or error, return false
      return false;
    } catch (error) {
      // Format the error for better debugging
      let errorMessage = 'Unknown error during sign in';
      
      if (error.code) {
        switch (error.code) {
          case 'auth/invalid-email':
            errorMessage = 'The email address is not valid.';
            break;
          case 'auth/user-disabled':
            errorMessage = 'This user account has been disabled.';
            break;
          case 'auth/user-not-found':
            errorMessage = 'No user found with this email address.';
            break;
          case 'auth/wrong-password':
            errorMessage = 'Incorrect password.';
            break;
          case 'auth/too-many-requests':
            errorMessage = 'Too many login attempts. Please try again later.';
            break;
          default:
            errorMessage = `Login error: ${error.message}`;
        }
      } else if (error.message) {
        errorMessage = error.message;
      }
      
      console.error('Sign in error:', errorMessage);
      throw new Error(errorMessage);
    }
  }

  /**
   * Verify government ID
   */
  verifyGovernmentId(userId: string, idData: FormData): Observable<any> {
    // Use idToken in request
    return this.getIdToken().pipe(
      switchMap(token => {
        return this.http.post(`${this.apiUrl}/users/${userId}/verify-id`, idData, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
      })
    );
  }

  /**
   * Add emergency contact
   */
  addEmergencyContact(userId: string, contactData: any): Observable<any> {
    return this.getIdToken().pipe(
      switchMap(token => {
        return this.http.post(`${this.apiUrl}/users/${userId}/emergency-contacts`, contactData, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
      })
    );
  }

  /**
   * Update verification status
   */
  updateVerificationStatus(userId: string, status: any): Observable<any> {
    return this.getIdToken().pipe(
      switchMap(token => {
        return this.http.patch(`${this.apiUrl}/users/${userId}/verification`, status, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
      })
    );
  }

  /**
   * Register a new user
   */
  register(userData: any): Observable<any> {
    return from(this.registerUser(userData.email, userData.password)).pipe(
      switchMap(user => {
        // Check if user is a valid object with uid property
        if (!user || typeof user !== 'object') {
          return throwError(() => new Error('Invalid user object'));
        }
        
        // Check if uid property exists on the user object
        const userObj = user as Record<string, any>;
        if (!userObj.uid) {
          return throwError(() => new Error('User object missing uid property'));
        }

        // Cast user to any to bypass TypeScript's type checking
        const userAny = user as any;
        
        const userProfile = {
          ...userData,
          uid: userAny.uid,
          createdAt: new Date(),
          emailVerified: false
        };
        
        // Remove password from stored profile data
        delete userProfile.password;
        
        return from(this.setUserData(userProfile)).pipe(
          map(() => user)
        );
      })
    );
  }
  
  /**
   * Reauthenticate user before sensitive operations
   */
  reauthenticate(password: string): Promise<firebase.auth.UserCredential> {
    const user = this.afAuth.currentUser;
    return from(user).pipe(
      switchMap(currentUser => {
        if (!currentUser) {
          throw new Error('No user logged in');
        }
        
        const credential = firebase.auth.EmailAuthProvider.credential(
          currentUser.email || '', 
          password
        );
        
        return currentUser.reauthenticateWithCredential(credential);
      })
    ).toPromise();
  }
  
  /**
   * Update user password
   */
  updatePassword(currentPassword: string, newPassword: string): Promise<void> {
    return this.reauthenticate(currentPassword)
      .then(() => {
        return this.afAuth.currentUser;
      })
      .then(user => {
        if (!user) throw new Error('No user logged in');
        return user.updatePassword(newPassword);
      });
  }
  
  /**
   * Update user email
   */
  updateEmail(currentPassword: string, newEmail: string): Promise<void> {
    return this.reauthenticate(currentPassword)
      .then(() => {
        return this.afAuth.currentUser;
      })
      .then(user => {
        if (!user) throw new Error('No user logged in');
        return user.updateEmail(newEmail);
      })
      .then(() => {
        return this.sendVerificationMail();
      });
  }
}