import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Child } from '../shared/models/child.model';

@Injectable({
  providedIn: 'root'
})
export class ChildService {
  private apiUrl = 'api/children';

  constructor(private http: HttpClient) {}

  getChildren(): Observable<Child[]> {
    return this.http.get<Child[]>(this.apiUrl);
  }

  uploadPhoto(childId: string, photo: File): Observable<any> {
    const formData = new FormData();
    formData.append('photo', photo);
    return this.http.post(`${this.apiUrl}/${childId}/photo`, formData);
  }

  updateChildPhoto(childId: string, photoUrl: string): Observable<Child> {
    return this.http.patch<Child>(`${this.apiUrl}/${childId}`, { photoUrl });
  }

  updateEmergencyInfo(childId: string, emergencyInfo: any): Observable<Child> {
    return this.http.patch<Child>(`${this.apiUrl}/${childId}/emergency-info`, emergencyInfo);
  }

  addApprovedPickup(childId: string, pickup: any): Observable<Child> {
    return this.http.post<Child>(`${this.apiUrl}/${childId}/approved-pickup`, pickup);
  }

  removeApprovedPickup(childId: string, pickupId: string): Observable<Child> {
    return this.http.delete<Child>(`${this.apiUrl}/${childId}/approved-pickup/${pickupId}`);
  }
}