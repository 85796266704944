import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Capacitor } from '@capacitor/core';
import { environment } from '../../environments/environment';
import { FirebaseMessaging, GetTokenOptions } from '@capacitor-firebase/messaging';
import { Injectable } from '@angular/core';
import { map, take, switchMap } from 'rxjs/operators';
import { Message, Topic } from '@app/state/models/notification';
import { Store, select } from '@ngrx/store';
import * as fromState from '@app/state';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { Notification } from '@app/models/chat.model';
import firebase from 'firebase/compat/app';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private platformMessaging;
  private token;
  private currentUserId: string | null = null;
  private unreadCountSubject = new BehaviorSubject<number>(0);

  constructor(
    private firestore: AngularFirestore,
    public ngFireAuth: AngularFireAuth,
    private store: Store<fromState.State>,
    private angularFireMessaging: AngularFireMessaging
  ) {
    if (Capacitor.getPlatform() == 'web') {
      this.platformMessaging = angularFireMessaging;
    } else {
      this.platformMessaging = FirebaseMessaging;
    }

    // Get current user ID
    this.ngFireAuth.authState.subscribe(user => {
      this.currentUserId = user ? user.uid : null;
      if (this.currentUserId) {
        this.updateUnreadCount();
      } else {
        this.unreadCountSubject.next(0);
      }
    });
  }

  async getToken(): Promise<string> {
    try {
      const options: GetTokenOptions = {
        vapidKey: environment.firebaseConfig.vapidKey,
      };
      const { token } = await FirebaseMessaging.getToken(options);
      console.log(token);
      this.token = token;
      return token;
    } catch (error) {
      console.error('Error getting token:', error);
      throw error;
    }
  }

  async deleteToken(): Promise<void> {
    try {
      await FirebaseMessaging.deleteToken();
      this.token = null;
    } catch (error) {
      console.error('Error deleting token:', error);
      throw error;
    }
  }

  async messageCreate(message: Message): Promise<any> {
    try {
      const messageRef = await this.firestore.collection('messages').add({
        ...message,
        createdAt: new Date().getTime(),
      });
      return messageRef.id;
    } catch (error) {
      console.error('Error creating message:', error);
      throw error;
    }
  }

  async topicCreate(topic: Topic): Promise<any> {
    try {
      const topicRef = await this.firestore.collection('topics').add({
        ...topic,
        createdAt: new Date().getTime(),
      });
      return topicRef.id;
    } catch (error) {
      console.error('Error creating topic:', error);
      throw error;
    }
  }

  async subscribeTopic(topic: string): Promise<void> {
    try {
      await FirebaseMessaging.subscribeToTopic({ topic });
      console.log(`Subscribed to topic: ${topic}`);
    } catch (error) {
      console.error(`Error subscribing to topic ${topic}:`, error);
      throw error;
    }
  }

  async unSubscribeTopic(topic: string): Promise<void> {
    try {
      await FirebaseMessaging.unsubscribeFromTopic({ topic });
      console.log(`Unsubscribed from topic: ${topic}`);
    } catch (error) {
      console.error(`Error unsubscribing from topic ${topic}:`, error);
      throw error;
    }
  }

  listenToMessages(): any {
    if (Capacitor.getPlatform() === 'web') {
      return this.angularFireMessaging.messages;
    } else {
      FirebaseMessaging.addListener('notificationReceived', (notification) => {
        console.log('Notification received:', notification);
        return notification;
      });
    }
  }

  // Get all notifications for the current user
  getNotifications(): Observable<Notification[]> {
    return this.ngFireAuth.authState.pipe(
      switchMap(user => {
        if (!user) return of([]);

        return this.firestore
          .collection<Notification>('notifications', ref => 
            ref.where('userId', '==', user.uid)
               .orderBy('timestamp', 'desc')
               .limit(50)
          )
          .valueChanges({ idField: 'id' })
          .pipe(
            map(notifications => notifications.map(notification => ({
              ...notification,
              timestamp: this.convertTimestamp(notification.timestamp)
            })))
          );
      })
    );
  }

  // Get only unread notifications
  getUnreadNotifications(): Observable<Notification[]> {
    return this.getNotifications().pipe(
      map(notifications => notifications.filter(notification => !notification.read))
    );
  }

  // Mark a notification as read
  async markAsRead(notificationId: string): Promise<void> {
    if (!this.currentUserId) return;

    await this.firestore
      .collection<Notification>('notifications')
      .doc(notificationId)
      .update({ read: true });
      
    this.updateUnreadCount();
  }

  // Mark all notifications as read
  async markAllAsRead(): Promise<void> {
    if (!this.currentUserId) return;

    const batch = this.firestore.firestore.batch();
    
    const unreadNotifications = await this.firestore
      .collection<Notification>('notifications', ref => 
        ref.where('userId', '==', this.currentUserId)
           .where('read', '==', false)
      )
      .get()
      .pipe(take(1))
      .toPromise();
      
    unreadNotifications.docs.forEach(doc => {
      batch.update(doc.ref, { read: true });
    });
    
    await batch.commit();
    this.updateUnreadCount();
  }

  // Create a new notification
  async createNotification(
    title: string, 
    message: string, 
    type: 'ride' | 'payment' | 'system' | 'message',
    userId: string,
    actionUrl?: string,
    metadata?: any
  ): Promise<string> {
    const notification: Notification = {
      userId,
      title,
      message,
      timestamp: Date.now(),
      read: false,
      type,
      actionUrl,
      metadata
    };
    
    const notificationRef = await this.firestore
      .collection<Notification>('notifications')
      .add(notification);
      
    return notificationRef.id;
  }

  // Delete a notification
  async deleteNotification(notificationId: string): Promise<void> {
    if (!this.currentUserId) return;
    
    const notification = await this.firestore
      .collection<Notification>('notifications')
      .doc(notificationId)
      .get()
      .pipe(take(1))
      .toPromise();
      
    if (!notification?.exists) return;
    
    const data = notification.data();
    if (data?.userId !== this.currentUserId) {
      throw new Error('Not authorized to delete this notification');
    }
    
    await this.firestore
      .collection<Notification>('notifications')
      .doc(notificationId)
      .delete();
      
    this.updateUnreadCount();
  }

  // Get unread count
  getUnreadCount(): Observable<number> {
    return this.unreadCountSubject.asObservable();
  }
  
  // Create a system notification for all users
  async createSystemNotification(title: string, message: string, actionUrl?: string): Promise<void> {
    // In a real system, this would ideally use a cloud function to create notifications for all users
    // For now, we're just creating a placeholder
    console.log('System notification created:', { title, message, actionUrl });
  }

  // Private method to update unread count
  private async updateUnreadCount(): Promise<void> {
    if (!this.currentUserId) return;
    
    const unreadSnapshot = await this.firestore
      .collection<Notification>('notifications', ref => 
        ref.where('userId', '==', this.currentUserId)
           .where('read', '==', false)
      )
      .get()
      .pipe(take(1))
      .toPromise();
      
    this.unreadCountSubject.next(unreadSnapshot.size);
  }
  
  // Private utility to convert Firestore timestamps to Date objects
  private convertTimestamp(timestamp: any): Date {
    if (!timestamp) return new Date();
    
    if (timestamp instanceof Date) return timestamp;
    
    if (timestamp && typeof timestamp.toDate === 'function') {
      return timestamp.toDate();
    }
    
    return new Date(timestamp);
  }
} 