import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators';
import * as CarActions from './car.actions';
import { Car } from '@app/state/models/car';
import { CarService } from '@app/services/car.service';

@Injectable()
export class CarEffects {

    createCar$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CarActions.createCar),
            map(action => action.car),
            exhaustMap((car: any) =>
                this.carService.carCreate(car).pipe(
                    map(() => CarActions.createCarSuccess()),
                    catchError((error) => of(CarActions.createCarFailure({ error })))
                )
            )
        )
    );

    getCar$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CarActions.getCar),
            map(action => action.id),
            exhaustMap((id) =>
                this.carService.getCar(id).pipe(
                    map((car: any) => CarActions.getCarSuccess({ car })),
                    catchError((error) => of(CarActions.getCarFailure({ error })))
                )
            )
        )
    );

    getAllCars$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CarActions.getAllCars),
            exhaustMap(() =>
                this.carService.getCarAll().pipe(
                    map((cars: any[]) => CarActions.getAllCarsSuccess({ cars })),
                    catchError((error) => of(CarActions.getAllCarsFailure({ error })))
                )
            )
        )
    );

    updateCar$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CarActions.updateCar),
            map(action => action.car),
            exhaustMap((car: any) =>
                this.carService.updateCar(car).pipe(
                    map(() => CarActions.updateCarSuccess({ car })),
                    catchError((error) => of(CarActions.updateCarFailure({ error })))
                )
            )
        )
    );

    deleteCar$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CarActions.deleteCar),
            map(action => action.id),
            exhaustMap((id) =>
                this.carService.deleteCar(id).pipe(
                    map(() => CarActions.deleteCarSuccess()),
                    catchError((error) => of(CarActions.deleteCarFailure({ error })))
                )
            )
        )
    );

    constructor(
        private actions$: Actions,
        private carService: CarService,
        private router: Router,
    ) { }
} 