import { createReducer, on } from '@ngrx/store';
import * as EventActions from './event.actions';

export const eventFeatureKey = 'event';

export interface State {
  events: any[];
  selectedEvent: any;
  loading: boolean;
  error: any;
}

export const initialState: State = {
  events: [],
  selectedEvent: null,
  loading: false,
  error: null
};

export const reducer = createReducer(
  initialState,
  
  // Create Event
  on(EventActions.eventCreate, state => ({
    ...state,
    loading: true
  })),
  
  on(EventActions.eventCreateSuccess, (state, { event }) => ({
    ...state,
    events: [...state.events, event],
    loading: false
  })),
  
  on(EventActions.eventCreateFailure, (state, { error }) => ({
    ...state,
    error,
    loading: false
  })),
  
  // Get Event
  on(EventActions.eventGet, state => ({
    ...state,
    loading: true
  })),
  
  on(EventActions.eventGetSuccess, (state, { event }) => ({
    ...state,
    selectedEvent: event,
    loading: false
  })),
  
  on(EventActions.eventGetFailure, (state, { error }) => ({
    ...state,
    error,
    loading: false
  })),
  
  // Get All Events
  on(EventActions.eventGetAll, state => ({
    ...state,
    loading: true
  })),
  
  on(EventActions.eventGetAllSuccess, (state, { events }) => ({
    ...state,
    events,
    loading: false
  })),
  
  on(EventActions.eventGetAllFailure, (state, { error }) => ({
    ...state,
    error,
    loading: false
  })),
  
  // Update Event
  on(EventActions.eventUpdate, state => ({
    ...state,
    loading: true
  })),
  
  on(EventActions.eventUpdateSuccess, (state, { event }) => ({
    ...state,
    events: state.events.map(e => e.id === event.id ? event : e),
    selectedEvent: event.id === state.selectedEvent?.id ? event : state.selectedEvent,
    loading: false
  })),
  
  on(EventActions.eventUpdateFailure, (state, { error }) => ({
    ...state,
    error,
    loading: false
  })),
  
  // Delete Event
  on(EventActions.eventDelete, state => ({
    ...state,
    loading: true
  })),
  
  on(EventActions.eventDeleteSuccess, state => ({
    ...state,
    events: state.events.filter(e => e.id !== state.selectedEvent?.id),
    selectedEvent: null,
    loading: false
  })),
  
  on(EventActions.eventDeleteFailure, (state, { error }) => ({
    ...state,
    error,
    loading: false
  }))
);

// Selectors
export const getEvents = (state: State) => state.events;
export const getSelectedEvent = (state: State) => state.selectedEvent;
export const getLoading = (state: State) => state.loading;
export const getError = (state: State) => state.error; 