import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, tap, catchError } from 'rxjs/operators';
import { 
  RideSchedule,
  RideRequest,
  RideOffer,
  RideTracking,
  Location
} from '../../shared/models/ride.model';

@Injectable({
  providedIn: 'root'
})
export class RideSchedulingService {
  private apiUrl = 'api/rides';
  private activeRidesSubject = new BehaviorSubject<RideSchedule[]>([]);
  private currentRideSubject = new BehaviorSubject<RideSchedule | null>(null);
  private rideTrackingSubject = new BehaviorSubject<RideTracking | null>(null);

  constructor(private http: HttpClient) {
    this.loadActiveRides();
  }

  /**
   * Get all active rides for the current parent
   */
  getActiveRides(): Observable<RideSchedule[]> {
    return this.activeRidesSubject.asObservable();
  }

  /**
   * Get current active ride being tracked
   */
  getCurrentRide(): Observable<RideSchedule | null> {
    return this.currentRideSubject.asObservable();
  }

  /**
   * Get real-time tracking for current ride
   */
  getRideTracking(): Observable<RideTracking | null> {
    return this.rideTrackingSubject.asObservable();
  }

  /**
   * Create a new ride request
   */
  createRideRequest(request: RideRequest): Observable<RideSchedule> {
    return this.http.post<RideSchedule>(`${this.apiUrl}/request`, request).pipe(
      tap(ride => {
        const currentRides = this.activeRidesSubject.value;
        this.activeRidesSubject.next([...currentRides, ride]);
      }),
      catchError(error => {
        console.error('Error creating ride request:', error);
        return of({
          id: 'temp-' + Date.now(),
          parentId: 'current',
          childIds: request.childIds,
          status: 'pending',
          pickupLocation: request.pickupLocation,
          dropoffLocation: request.dropoffLocation,
          pickupTime: request.pickupTime,
          estimatedDuration: 0,
          distance: 0,
          price: 0,
          safetyChecks: [],
          created: new Date(),
          updated: new Date()
        } as RideSchedule);
      })
    );
  }

  /**
   * Get ride offers for a specific ride request
   */
  getRideOffers(rideId: string): Observable<RideOffer[]> {
    return this.http.get<RideOffer[]>(`${this.apiUrl}/${rideId}/offers`).pipe(
      catchError(error => {
        console.error('Error fetching ride offers:', error);
        return of([]);
      })
    );
  }

  /**
   * Accept a ride offer
   */
  acceptRideOffer(rideId: string, offerId: string): Observable<RideSchedule> {
    return this.http.post<RideSchedule>(
      `${this.apiUrl}/${rideId}/offers/${offerId}/accept`, 
      {}
    ).pipe(
      tap(updatedRide => {
        const rides = this.activeRidesSubject.value;
        const index = rides.findIndex(r => r.id === rideId);
        if (index !== -1) {
          const updatedRides = [...rides];
          updatedRides[index] = updatedRide;
          this.activeRidesSubject.next(updatedRides);
        }
      }),
      catchError(error => {
        console.error('Error accepting ride offer:', error);
        return of(null);
      })
    );
  }

  /**
   * Cancel a scheduled ride
   */
  cancelRide(rideId: string, reason?: string): Observable<boolean> {
    return this.http.post<void>(
      `${this.apiUrl}/${rideId}/cancel`,
      { reason }
    ).pipe(
      map(() => {
        const rides = this.activeRidesSubject.value;
        this.activeRidesSubject.next(
          rides.filter(r => r.id !== rideId)
        );
        return true;
      }),
      catchError(error => {
        console.error('Error cancelling ride:', error);
        return of(false);
      })
    );
  }

  /**
   * Start tracking a specific ride
   */
  startTrackingRide(rideId: string): Observable<RideTracking> {
    return this.http.get<RideTracking>(`${this.apiUrl}/${rideId}/tracking`).pipe(
      tap(tracking => {
        this.rideTrackingSubject.next(tracking);
        const ride = this.activeRidesSubject.value.find(r => r.id === rideId);
        if (ride) {
          this.currentRideSubject.next(ride);
        }
      }),
      catchError(error => {
        console.error('Error starting ride tracking:', error);
        return of(null);
      })
    );
  }

  /**
   * Update ride safety check status
   */
  updateSafetyCheck(
    rideId: string, 
    checkType: string, 
    status: string, 
    data?: any
  ): Observable<RideSchedule> {
    return this.http.post<RideSchedule>(
      `${this.apiUrl}/${rideId}/safety-checks`,
      { type: checkType, status, ...data }
    ).pipe(
      tap(updatedRide => {
        const rides = this.activeRidesSubject.value;
        const index = rides.findIndex(r => r.id === rideId);
        if (index !== -1) {
          const updatedRides = [...rides];
          updatedRides[index] = updatedRide;
          this.activeRidesSubject.next(updatedRides);

          if (this.currentRideSubject.value?.id === rideId) {
            this.currentRideSubject.next(updatedRide);
          }
        }
      }),
      catchError(error => {
        console.error('Error updating safety check:', error);
        return of(null);
      })
    );
  }

  /**
   * Verify safety code for ride
   */
  verifySafetyCode(rideId: string, code: string): Observable<boolean> {
    return this.http.post<{ success: boolean }>(`${this.apiUrl}/${rideId}/verify-safety-code`, { code })
      .pipe(
        map(response => response.success),
        tap(success => {
          if (success) {
            // Update safety check status in the current ride
            const currentRide = this.currentRideSubject.value;
            if (currentRide && currentRide.id === rideId) {
              const updatedRide = { ...currentRide };
              const safetyCodeCheck = updatedRide.safetyChecks.find(
                check => check.type === 'safety_code_verification'
              );
              
              if (safetyCodeCheck) {
                safetyCodeCheck.status = 'completed';
                safetyCodeCheck.timestamp = new Date();
              }
              
              this.currentRideSubject.next(updatedRide);
            }
          }
        }),
        catchError(error => {
          console.error('Error verifying safety code:', error);
          return of(false);
        })
      );
  }

  /**
   * Add or update ride notes
   */
  updateRideNotes(rideId: string, notes: string): Observable<RideSchedule> {
    return this.http.patch<RideSchedule>(
      `${this.apiUrl}/${rideId}`,
      { notes }
    ).pipe(
      tap(updatedRide => {
        const rides = this.activeRidesSubject.value;
        const index = rides.findIndex(r => r.id === rideId);
        if (index !== -1) {
          const updatedRides = [...rides];
          updatedRides[index] = updatedRide;
          this.activeRidesSubject.next(updatedRides);
        }
      }),
      catchError(error => {
        console.error('Error updating ride notes:', error);
        return of(null);
      })
    );
  }

  /**
   * Get ride history for the current user
   */
  getRideHistory(): Observable<RideSchedule[]> {
    return this.http.get<RideSchedule[]>(`${this.apiUrl}/history`).pipe(
      catchError(error => {
        console.error('Error fetching ride history:', error);
        return of([]);
      })
    );
  }

  /**
   * Load all active rides
   */
  private loadActiveRides(): void {
    this.http.get<RideSchedule[]>(`${this.apiUrl}/active`)
      .subscribe(
        rides => this.activeRidesSubject.next(rides),
        error => {
          console.error('Error loading active rides:', error);
          this.activeRidesSubject.next([]);
        }
      );
  }

  /**
   * Schedule a new ride (alias for createRideRequest for backward compatibility)
   */
  scheduleRide(request: RideRequest): Observable<RideSchedule> {
    return this.createRideRequest(request);
  }
}