import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of, from, EMPTY, Observable } from 'rxjs';
import { catchError, exhaustMap, map, tap, take, switchMap, mergeMap } from 'rxjs/operators';
import * as ProfileActions from './profile.actions';
import { Profile } from '../models/profile.model';
import { ProfileService } from '../../services/profile.service';
import { AuthenticationService } from '../../services/auth.service';

@Injectable()
export class ProfileEffects {

  getProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileActions.profileGet),
      map(action => action.id),
      exhaustMap((userId: string) => {
        console.log('ProfileEffects: Getting profile for user ID:', userId);
        
        // First try to get from BehaviorSubject which is faster
        return this.profileService.getProfile$().pipe(
          take(1),
          switchMap((profile: Profile | null) => {
            // If profile exists and matches userId, return it
            if (profile && (profile.id === userId || profile.uid === userId)) {
              console.log('ProfileEffects: Got profile from subject:', profile);
              return of(ProfileActions.profileGetSuccess({ profile: [profile] }));
            }
            
            // Otherwise fetch from Firestore
            return from(this.profileService.getProfileById(userId)).pipe(
              switchMap(fetchedProfile => {
                if (fetchedProfile) {
                  console.log('ProfileEffects: Got profile from Firestore:', fetchedProfile);
                  
                  // If profile has photoURL, ensure it's valid by preloading
                  if (fetchedProfile.photoURL && fetchedProfile.photoURL.startsWith('http')) {
                    return this.preloadProfileImage(fetchedProfile.photoURL).pipe(
                      map(validUrl => {
                        // Update the photoURL if validation was successful
                        if (validUrl) {
                          fetchedProfile.photoURL = validUrl;
                        }
                        return ProfileActions.profileGetSuccess({ profile: [fetchedProfile] });
                      })
                    );
                  }
                  
                  return of(ProfileActions.profileGetSuccess({ profile: [fetchedProfile] }));
                } else {
                  // Try to get user from Firebase Auth
                  return from(this.authService.currentUser).pipe(
                    map(user => {
                      if (user) {
                        // Create profile from auth user
                        const basicProfile: Profile = {
                          id: user.uid,
                          uid: user.uid,
                          email: user.email || '',
                          displayName: user.displayName || '',
                          photoURL: user.photoURL || '',
                          verificationStatus: {
                            idVerified: false,
                            phoneVerified: user.phoneNumber ? true : false,
                            emailVerified: user.emailVerified || false
                          },
                          schoolEmailVerified: false,
                          backgroundCheckConsent: false,
                          backgroundCheckStatus: 'not_started',
                          createdAt: new Date().toISOString(),
                          updatedAt: new Date().toISOString()
                        };
                        return ProfileActions.profileGetSuccess({ profile: [basicProfile] });
                      }
                      return ProfileActions.profileGetFailure({ error: 'Profile not found' });
                    }),
                    catchError(error => {
                      console.error('ProfileEffects: Error getting user from auth:', error);
                      return of(ProfileActions.profileGetFailure({ error: error.message }));
                    })
                  );
                }
              }),
              catchError(error => {
                console.error('ProfileEffects: Error getting profile from Firestore:', error);
                return of(ProfileActions.profileGetFailure({ error: error.message }));
              })
            );
          }),
          catchError(error => {
            console.error('ProfileEffects: Error in profile$ effect:', error);
            return of(ProfileActions.profileGetFailure({ error: error.message }));
          })
        );
      })
    )
  );

  upsertProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileActions.profileUpsert),
      map(action => action.data),
      exhaustMap((profile: Profile) => {
        console.log('ProfileEffects: Upserting profile:', profile);
        
        // Handle photoURL preloading if it exists
        if (profile.photoURL && profile.photoURL.startsWith('http')) {
          return this.preloadProfileImage(profile.photoURL).pipe(
            switchMap(validUrl => {
              // Update photoURL if needed
              if (validUrl && validUrl !== profile.photoURL) {
                profile = { ...profile, photoURL: validUrl };
              }
              
              return this.profileService.upsertProfile(profile).pipe(
                map(success => {
                  if (success) {
                    console.log('ProfileEffects: Profile upsert succeeded');
                    // Update Auth user photoURL to keep them in sync
                    this.updateAuthUserPhoto(profile.photoURL);
                    return ProfileActions.profileUpsertSuccess();
                  } else {
                    console.log('ProfileEffects: Profile upsert failed');
                    return ProfileActions.profileUpsertFailure({ error: 'Failed to update profile' });
                  }
                }),
                catchError(error => {
                  console.error('ProfileEffects: Error upserting profile:', error);
                  return of(ProfileActions.profileUpsertFailure({ error: error.message }));
                })
              );
            }),
            catchError(error => {
              console.error('ProfileEffects: Error preloading image:', error);
              // Continue with profile update even if image preloading fails
              return this.profileService.upsertProfile(profile).pipe(
                map(success => success ? 
                  ProfileActions.profileUpsertSuccess() : 
                  ProfileActions.profileUpsertFailure({ error: 'Failed to update profile' })
                ),
                catchError(err => of(ProfileActions.profileUpsertFailure({ error: err.message })))
              );
            })
          );
        }
        
        return this.profileService.upsertProfile(profile).pipe(
          map(success => {
            if (success) {
              console.log('ProfileEffects: Profile upsert succeeded');
              return ProfileActions.profileUpsertSuccess();
            } else {
              console.log('ProfileEffects: Profile upsert failed');
              return ProfileActions.profileUpsertFailure({ error: 'Failed to update profile' });
            }
          }),
          catchError(error => {
            console.error('ProfileEffects: Error upserting profile:', error);
            return of(ProfileActions.profileUpsertFailure({ error: error.message }));
          })
        );
      })
    )
  );
  
  /**
   * Preload profile image to validate URL and ensure it's cached
   */
  private preloadProfileImage(url: string): Observable<string | null> {
    if (!url || !url.startsWith('http')) {
      return of(null);
    }
    
    return new Observable<string | null>(observer => {
      const img = new Image();
      
      img.onload = () => {
        observer.next(url);
        observer.complete();
      };
      
      img.onerror = () => {
        console.warn('Failed to load profile image:', url);
        // Return null to indicate image failed to load
        observer.next(null);
        observer.complete();
      };
      
      // Add cache-busting parameter to avoid caching issues
      const cacheBuster = `?t=${new Date().getTime()}`;
      img.src = url.includes('?') ? `${url}&_cb=${cacheBuster}` : `${url}${cacheBuster}`;
    });
  }
  
  /**
   * Update Firebase Auth user photoURL to keep in sync with profile
   */
  private updateAuthUserPhoto(photoURL: string | undefined) {
    if (!photoURL) return;
    
    this.authService.currentUser.then(user => {
      if (user && user.photoURL !== photoURL) {
        user.updateProfile({ photoURL })
          .then(() => console.log('Updated Auth user photoURL'))
          .catch(error => console.error('Error updating Auth user photoURL:', error));
      }
    }).catch(error => console.error('Error getting current user:', error));
  }

  constructor(
    private actions$: Actions,
    private profileService: ProfileService,
    private router: Router,
    private authService: AuthenticationService
  ) {}
} 