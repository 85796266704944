import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

export enum ToastType {
  SUCCESS = 'success',
  ERROR = 'danger',
  WARNING = 'warning',
  INFO = 'primary'
}

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  private currentToast: HTMLIonToastElement | null = null;

  constructor(private toastController: ToastController) {}

  /**
   * Show a toast message
   * @param message Message to display
   * @param type Type of toast (success, error, warning, info)
   * @param duration Duration in milliseconds (default 3000ms)
   * @param position Position on screen (default bottom)
   * @param showCloseButton Whether to show a close button (default true)
   */
  async showToast(
    message: string,
    type: ToastType = ToastType.INFO,
    duration: number = 3000,
    position: 'top' | 'bottom' | 'middle' = 'bottom',
    showCloseButton: boolean = true
  ): Promise<void> {
    // Dismiss any existing toast
    if (this.currentToast) {
      await this.currentToast.dismiss();
    }

    // Create and present new toast
    this.currentToast = await this.toastController.create({
      message,
      duration: duration,
      position: position,
      color: type,
      buttons: showCloseButton ? [
        {
          side: 'end',
          icon: 'close',
          role: 'cancel'
        }
      ] : [],
      cssClass: `toast-${type}`
    });

    await this.currentToast.present();

    // Clear reference after toast is dismissed
    this.currentToast.onDidDismiss().then(() => {
      this.currentToast = null;
    });
  }

  /**
   * Show a success toast
   * @param message Message to display
   * @param duration Duration in milliseconds (default 3000ms)
   */
  async showSuccess(message: string, duration: number = 3000): Promise<void> {
    return this.showToast(message, ToastType.SUCCESS, duration);
  }

  /**
   * Show an error toast
   * @param message Message to display
   * @param duration Duration in milliseconds (default 5000ms for errors)
   */
  async showError(message: string, duration: number = 5000): Promise<void> {
    return this.showToast(message, ToastType.ERROR, duration);
  }

  /**
   * Show a warning toast
   * @param message Message to display
   * @param duration Duration in milliseconds (default 4000ms)
   */
  async showWarning(message: string, duration: number = 4000): Promise<void> {
    return this.showToast(message, ToastType.WARNING, duration);
  }

  /**
   * Show an info toast
   * @param message Message to display
   * @param duration Duration in milliseconds (default 3000ms)
   */
  async showInfo(message: string, duration: number = 3000): Promise<void> {
    return this.showToast(message, ToastType.INFO, duration);
  }

  /**
   * Show a network error toast
   * @param message Optional custom message
   */
  async showNetworkError(message?: string): Promise<void> {
    const defaultMessage = 'Network connection problem. Please check your internet connection and try again.';
    return this.showError(message || defaultMessage, 5000);
  }

  /**
   * Show an authentication error toast
   * @param message Optional custom message
   */
  async showAuthError(message?: string): Promise<void> {
    const defaultMessage = 'Authentication error. Please sign in again.';
    return this.showError(message || defaultMessage, 5000);
  }

  /**
   * Show a server error toast
   * @param message Optional custom message
   */
  async showServerError(message?: string): Promise<void> {
    const defaultMessage = 'Server error. Please try again later.';
    return this.showError(message || defaultMessage, 5000);
  }

  /**
   * Show a permission error toast
   * @param message Optional custom message
   */
  async showPermissionError(message?: string): Promise<void> {
    const defaultMessage = 'You do not have permission to perform this action.';
    return this.showError(message || defaultMessage, 5000);
  }

  /**
   * Show a validation error toast
   * @param message Optional custom message
   */
  async showValidationError(message?: string): Promise<void> {
    const defaultMessage = 'Please fix the form errors and try again.';
    return this.showError(message || defaultMessage, 5000);
  }
} 