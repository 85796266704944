import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule, ModalController } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

export interface ConfirmationDialogData {
  title: string;
  message: string;
  confirmButtonText: string;
  cancelButtonText: string;
  icon?: string;
  iconColor?: 'primary' | 'secondary' | 'danger' | 'warning' | 'success';
  showCancelButton?: boolean;
}

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
  standalone: true,
  imports: [CommonModule, IonicModule, TranslateModule]
})
export class ConfirmationDialogComponent {
  @Input() data: ConfirmationDialogData = {
    title: '',
    message: '',
    confirmButtonText: 'common.confirm',
    cancelButtonText: 'common.cancel',
    icon: 'help-circle',
    iconColor: 'primary',
    showCancelButton: true
  };
  
  constructor(private modalController: ModalController) {}

  /**
   * Confirm action
   */
  confirm(): void {
    this.modalController.dismiss(true);
  }

  /**
   * Cancel action
   */
  cancel(): void {
    this.modalController.dismiss(false);
  }
}