import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-verification-photo',
  templateUrl: './verification-photo.component.html',
  styleUrls: ['./verification-photo.component.scss'],
  standalone: true,
  imports: [CommonModule, IonicModule, TranslateModule]
})
export class VerificationPhotoComponent implements OnInit {
  @Input() rideId: string;
  @Input() childName: string;
  @Output() photoUploaded = new EventEmitter<File>();
  
  selectedFile: File | null = null;
  previewUrl: string | null = null;
  
  /**
   * Handle file selection
   */
  onFileSelected(event: any): void {
    this.selectedFile = event.target.files[0];
    
    // Create a preview of the selected image
    if (this.selectedFile) {
      const reader = new FileReader();
      reader.onload = () => {
        this.previewUrl = reader.result as string;
      };
      reader.readAsDataURL(this.selectedFile);
      
      // Emit the selected file
      this.photoUploaded.emit(this.selectedFile);
    }
  }
  
  /**
   * Trigger the file input click
   */
  triggerFileInput(): void {
    document.getElementById('photo-upload')?.click();
  }

  ngOnInit(): void {
    // Additional initialization logic if needed
  }
}