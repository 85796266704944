
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { TabsPage } from './tabs.page';
import { NetworkComponent } from '@app/components/network/network.component';
import { ChildrenComponent } from '@app/components/parent/children/children.component';
import { SafetyComponent } from '@app/components/safety/safety.component';
import { ProfileComponent } from '@app/components/parent/profile/profile.component';

export const routes: Routes = [
  {
    path: 'tabs',
    component: TabsPage,
    children: [
      {
        path: 'home',
        loadChildren: () => import('../home/home.module').then(m => m.HomePageModule)
      },
      {
        path: 'rides',
        children: [
          {
            path: 'details',
            loadChildren: () => import('../ride-details/ride-details.module').then(m => m.RideDetailsPageModule)
          },
          {
            path: '',
            loadChildren: () => import('../find-ride/find-ride.module').then(m => m.FindRidePageModule)
          },
          {
            path: 'offer',
            loadChildren: () => import('../offer-ride-confirmation/offer-ride-confirmation.module').then(m => m.OfferRideConfirmationPageModule)
          }
        ]
      },
      {
        path: 'network',
        children: [
          {
            path: '',
            component: NetworkComponent
          },
          {
            path: 'children',
            component: ChildrenComponent
          }
        ]
      },
      {
        path: 'safety',
        component: SafetyComponent
      },
      {
        path: 'profile',
        component: ProfileComponent
      },
      {
        path: '',
        redirectTo: '/tabs/home',
        pathMatch: 'full'
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TabsPageRoutingModule {}
