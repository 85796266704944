// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* App-specific styles */
:host {
  display: block;
}

.calendar-import-modal {
  --border-radius: 16px;
  --backdrop-opacity: 0.6;
}
.calendar-import-modal .modal-wrapper {
  max-height: 90%;
  border-radius: 16px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
}
@media (min-width: 768px) {
  .calendar-import-modal {
    --width: 600px;
    --height: auto;
  }
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImFwcC5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQSx3QkFBQTtBQUNBO0VBQ0UsY0FBQTtBQUNGOztBQUdBO0VBQ0UscUJBQUE7RUFDQSx1QkFBQTtBQUFGO0FBRUU7RUFDRSxlQUFBO0VBQ0EsbUJBQUE7RUFDQSwwQ0FBQTtBQUFKO0FBR0U7RUFWRjtJQVdJLGNBQUE7SUFDQSxjQUFBO0VBQUY7QUFDRiIsImZpbGUiOiJhcHAuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyIvKiBBcHAtc3BlY2lmaWMgc3R5bGVzICovXG46aG9zdCB7XG4gIGRpc3BsYXk6IGJsb2NrO1xufVxuXG4vLyBDYWxlbmRhciBJbXBvcnQgTW9kYWxcbi5jYWxlbmRhci1pbXBvcnQtbW9kYWwge1xuICAtLWJvcmRlci1yYWRpdXM6IDE2cHg7XG4gIC0tYmFja2Ryb3Atb3BhY2l0eTogMC42O1xuICBcbiAgLm1vZGFsLXdyYXBwZXIge1xuICAgIG1heC1oZWlnaHQ6IDkwJTtcbiAgICBib3JkZXItcmFkaXVzOiAxNnB4O1xuICAgIGJveC1zaGFkb3c6IDAgMTBweCAyNXB4IHJnYmEoMCwgMCwgMCwgMC4xKTtcbiAgfVxuICBcbiAgQG1lZGlhIChtaW4td2lkdGg6IDc2OHB4KSB7XG4gICAgLS13aWR0aDogNjAwcHg7XG4gICAgLS1oZWlnaHQ6IGF1dG87XG4gIH1cbn0iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/app.component.scss"],"names":[],"mappings":"AAAA,wBAAA;AACA;EACE,cAAA;AACF;;AAGA;EACE,qBAAA;EACA,uBAAA;AAAF;AAEE;EACE,eAAA;EACA,mBAAA;EACA,0CAAA;AAAJ;AAGE;EAVF;IAWI,cAAA;IACA,cAAA;EAAF;AACF;AACA,44BAA44B","sourcesContent":["/* App-specific styles */\n:host {\n  display: block;\n}\n\n// Calendar Import Modal\n.calendar-import-modal {\n  --border-radius: 16px;\n  --backdrop-opacity: 0.6;\n  \n  .modal-wrapper {\n    max-height: 90%;\n    border-radius: 16px;\n    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);\n  }\n  \n  @media (min-width: 768px) {\n    --width: 600px;\n    --height: auto;\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
