import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '@environments/environment';

export interface GeocodingResult {
  lat: number;
  lng: number;
  address: string;
  placeId: string;
  name: string;
}

@Injectable({
  providedIn: 'root'
})
export class GeocodingService {
  private apiKey: string = ''; // Mock API key
  private geocodingUrl: string = ''; // Mock URL

  constructor(private http: HttpClient) {}

  /**
   * Geocode an address to get its coordinates
   * @param address The address to geocode
   * @returns Observable with geocoding result
   */
  geocodeAddress(address: string): Observable<GeocodingResult> {
    // For testing purposes, return a mock result
    // In a real implementation, this would call a geocoding API
    return of({
      lat: 37.7749,
      lng: -122.4194,
      address: address || 'San Francisco, CA',
      placeId: 'mock-place-id',
      name: 'Mock Location'
    });
  }

  reverseGeocode(lat: number, lng: number): Observable<GeocodingResult | null> {
    if (!lat || !lng) {
      return of(null);
    }

    // For testing purposes, return a mock result
    // In a real implementation, this would call a geocoding API
    return of({
      lat: lat,
      lng: lng,
      address: `${lat}, ${lng}`,
      placeId: 'mock-place-id',
      name: `Location at ${lat}, ${lng}`
    });
  }

  // Mock method for testing purposes
  mockGeocode(address: string): GeocodingResult {
    return {
      lat: 37.7749,
      lng: -122.4194,
      placeId: 'ChIJIQBpAG2ahYAR_6128GcTUEo',
      name: address,
      address: address
    };
  }
} 