import { createAction, props } from '@ngrx/store';
import { ChatMessage, ChatThread } from '@app/models/chat.model';

// Load messages
export const loadMessages = createAction(
  '[Message] Load Messages',
  props<{ threadId: string; page?: number; pageSize?: number }>()
);

export const loadMessagesSuccess = createAction(
  '[Message] Load Messages Success',
  props<{ messages: ChatMessage[] }>()
);

export const loadMessagesFailure = createAction(
  '[Message] Load Messages Failure',
  props<{ error: any }>()
);

// Load more messages (pagination)
export const loadMoreMessages = createAction(
  '[Message] Load More Messages',
  props<{ threadId: string; page: number; pageSize: number }>()
);

export const loadMoreMessagesSuccess = createAction(
  '[Message] Load More Messages Success',
  props<{ messages: ChatMessage[] }>()
);

export const loadMoreMessagesFailure = createAction(
  '[Message] Load More Messages Failure',
  props<{ error: any }>()
);

// Send message
export const sendMessage = createAction(
  '[Message] Send Message',
  props<{ threadId: string; content: string; messageType: 'text' | 'image' | 'location'; metadata?: any }>()
);

export const sendMessageSuccess = createAction(
  '[Message] Send Message Success',
  props<{ message: ChatMessage }>()
);

export const sendMessageFailure = createAction(
  '[Message] Send Message Failure',
  props<{ error: any }>()
);

// Load thread
export const loadThread = createAction(
  '[Message] Load Thread',
  props<{ threadId: string }>()
);

export const loadThreadSuccess = createAction(
  '[Message] Load Thread Success',
  props<{ thread: ChatThread }>()
);

export const loadThreadFailure = createAction(
  '[Message] Load Thread Failure',
  props<{ error: any }>()
);

// Delete message
export const deleteMessage = createAction(
  '[Message] Delete Message',
  props<{ threadId: string; messageId: string }>()
);

export const deleteMessageSuccess = createAction(
  '[Message] Delete Message Success',
  props<{ messageId: string }>()
);

export const deleteMessageFailure = createAction(
  '[Message] Delete Message Failure',
  props<{ error: any }>()
);

// Mark messages as read
export const markThreadAsRead = createAction(
  '[Message] Mark Thread As Read',
  props<{ threadId: string }>()
);

export const markThreadAsReadSuccess = createAction(
  '[Message] Mark Thread As Read Success'
);

export const markThreadAsReadFailure = createAction(
  '[Message] Mark Thread As Read Failure',
  props<{ error: any }>()
);

// Clear message state
export const clearMessageState = createAction(
  '[Message] Clear Message State'
); 