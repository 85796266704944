import { PipesModule } from '@app/pipes/pipes.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TabsPageRoutingModule } from './tabs-routing.module';
import { TabsPage } from './tabs.page';
import { NetworkComponent } from '@app/components/network/network.component';
import { ChildrenComponent } from '@app/components/parent/children/children.component';
import { SafetyComponent } from '@app/components/safety/safety.component';
import { ProfileComponent } from '@app/components/parent/profile/profile.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    TabsPageRoutingModule,
    ChildrenComponent,
    SafetyComponent,    
    ProfileComponent,
    NetworkComponent,
    PipesModule
  ],
  declarations: [
    TabsPage
  ]
})
export class TabsPageModule {}
