import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, from, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { UserClass, Credentials } from '@app/state/models/user';
import * as UserActions from '@app/state/user/user.actions';
import * as CarActions from '@app/state/car/car.actions';
import * as RideActions from '@app/state/ride/ride.actions';
import * as fromState from '@app/state';
import { selectUser } from '@app/state/user/user.selectors';
import { UserService } from './user.service';
import { UtilService } from './util.service';
import { AuthenticationService } from './auth.service';

/**
 * Service responsible for login interactions with NgRx state management and user redirection.
 * Delegates core authentication to AuthenticationService.
 */
@Injectable({
  providedIn: 'root',
})
export class LoginService {
  private isLoggedInValue: boolean = false;
  private stopLoop: boolean = false;
  private user;

  constructor(
    private store: Store<fromState.State>,
    private userService: UserService,
    private utilService: UtilService,
    private ngZone: NgZone,
    private router: Router,
    private authService: AuthenticationService
  ) {
    // Subscribe to the auth user from store
    this.store.pipe(select(selectUser)).subscribe((user) => {
      this.user = user;
      if (user) {
        this.isLoggedInValue = true;
      }
    });

    // Create a local user$ observable if it doesn't exist in the auth service
    const user$ = this.authService.authState || of(null);
    
    // Subscribe to the user observable
    user$.subscribe(user => {
      if (user) {
        // Dispatch user to store when auth state changes
        const userObj = {
          uid: user.uid,
          displayName: user.displayName,
          photoURL: user.photoURL,
          email: user.email,
          emailVerified: user.emailVerified,
          phoneNumber: user.phoneNumber,
          createdAt: Date.now()
        };
        
        // Use loginSuccess action instead of non-existent addUser
        this.store.dispatch(UserActions.loginSuccess({ user: userObj }));
      }
    });
  }
  
  /**
   * Sign in with email and password
   * Uses AuthenticationService and dispatches appropriate actions on success
   */
  SignIn({ username, password }: Credentials): Observable<any> {
    // Show loading indicator
    this.utilService.showLoading();

    // Use signIn method instead of signInWithEmailPassword
    return from(this.authService.signIn(username, password)).pipe(
      map(success => {
        // Hide loading indicator
        this.utilService.hideLoading();

        if (success) {
          // Navigate to home page on successful login
          this.ngZone.run(() => {
            this.router.navigate(['/home']);
          });
          return { success: true };
        } else {
          return { success: false, message: 'Login failed' };
        }
      }),
      catchError(error => {
        // Hide loading indicator
        this.utilService.hideLoading();
        
        // Handle specific error cases
        let errorMessage = 'An unexpected error occurred';
        
        if (error.code === 'auth/user-not-found') {
          errorMessage = 'User not found';
        } else if (error.code === 'auth/wrong-password') {
          errorMessage = 'Invalid password';
        } else if (error.code === 'auth/invalid-email') {
          errorMessage = 'Invalid email format';
        } else if (error.code === 'auth/user-disabled') {
          errorMessage = 'This account has been disabled';
        } else if (error.message) {
          errorMessage = error.message;
        }
        
        return of({ success: false, message: errorMessage });
      })
    );
  }

  /**
   * Register a new user
   * Delegates to AuthenticationService
   */
  RegisterUser(email: string, password: string): Promise<any> {
    return this.authService.registerUser(email, password);
  }

  /**
   * Send verification email
   * Delegates to AuthenticationService
   */
  SendVerificationMail(): Promise<void> {
    return this.authService.sendVerificationMail();
  }

  /**
   * Recover password
   * Delegates to AuthenticationService
   */
  PasswordRecover(passwordResetEmail: string): Promise<void> {
    // Convert the boolean result to void
    return this.authService.passwordRecover(passwordResetEmail)
      .then(() => {
        // Return void instead of boolean
        return;
      });
  }

  /**
   * Check if user is logged in
   */
  get isLoggedIn(): boolean {
    return this.authService.isLoggedIn;
  }

  /**
   * Check if user's email is verified
   */
  get isEmailVerified(): boolean {
    return this.authService.isEmailVerified;
  }

  /**
   * Sign in with Google
   * Delegates to AuthenticationService
   */
  GoogleAuth(): Promise<any> {
    return this.authService.googleAuth();
  }

  /**
   * Auth login with provider
   * Delegates to AuthenticationService
   */
  AuthLogin(provider: any): Promise<any> {
    return this.authService.authLogin(provider);
  }

  /**
   * Logout - sign out and clear state
   */
  Logout(): Observable<any> {
    return from(this.authService.signOut()).pipe(
      tap(() => {
        // Clear state using correct action names
        this.store.dispatch(UserActions.logout());
        
        // No clearCars action exists, so we'll just dispatch an action without parameters
        // that doesn't require a car ID to avoid errors
        this.store.dispatch(CarActions.getAllCars());
        
        // Use existing action instead of clearRides
        this.store.dispatch(RideActions.rideGetAll());
      })
    );
  }

  /**
   * Sign in with phone number
   * Delegates to AuthenticationService
   */
  public signInWithPhoneNumber(recaptchaVerifier: any, phoneNumber: string): Promise<any> {
    return this.authService.signInWithPhoneNumber(recaptchaVerifier, phoneNumber);
  }

  /**
   * Verify phone code
   * Delegates to AuthenticationService
   */
  public async enterVerificationCode(code: string): Promise<any> {
    return this.authService.enterVerificationCode(code);
  }

  /**
   * Check if email is verified
   */
  checkVerifiedEmail(data: any): Observable<boolean> {
    return of(data && data.emailVerified);
  }

  /**
   * Check if user can activate routes
   */
  async canActivate(): Promise<Observable<boolean>> {
    const user = this.authService.getCurrentUser();
    if (user) {
      return of(true);
    } else {
      // User is not logged in, redirect to login page
      this.router.navigate(['login']);
      return of(false);
    }
  }
} 