/**
 * Application constants for production environment
 * Contains all text and static data used throughout the application
 */

// Import all constants from the development file
// In a real production environment, you might want to modify some values
import * as devConstants from './constants';

// Export all constants from development
export const CHAT_DATA = devConstants.CHAT_DATA;
export const DELETE_RIDE = devConstants.DELETE_RIDE;
export const CAR_DATA = devConstants.CAR_DATA;
export const NEW_VECHILE_DATA = devConstants.NEW_VECHILE_DATA;
export const MARK_OPTIONS = devConstants.MARK_OPTIONS;
export const EDIT_RIDE = devConstants.EDIT_RIDE;
export const NOTIFICATIONS = devConstants.NOTIFICATIONS;
export const DOCUMENTS = devConstants.DOCUMENTS;
export const FOOTER_CHAT = devConstants.FOOTER_CHAT;
export const PROFILE_DATA = devConstants.PROFILE_DATA;
export const PROFILE_DETAILS = devConstants.PROFILE_DETAILS;
export const PROFILE = devConstants.PROFILE;
export const BIOGRAPHY = devConstants.BIOGRAPHY;
export const PREFERENCE = devConstants.PREFERENCE;
export const COUNTRIES = devConstants.COUNTRIES;
export const EMAIL_VERIFICATION = devConstants.EMAIL_VERIFICATION;
export const CARDS = devConstants.CARDS;
export const TAB_ROUTING = devConstants.TAB_ROUTING; 