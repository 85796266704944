import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProfileService } from '@app/services/profile.service';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AuthenticationService } from '@app/services/auth.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Profile } from '@app/state/models/profile.model';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    IonicModule,
    ReactiveFormsModule,
    TranslateModule
  ]
})
export class ProfileComponent implements OnInit, OnDestroy {
  profileForm: FormGroup;
  isLoading = false;
  isSaving = false;
  currentProfile: Profile | null = null;
  verificationStatus: { idVerified: boolean; schoolVerified: boolean; backgroundCheckComplete: boolean; } | null = null;
  private destroy$ = new Subject<void>();

  constructor(
    private formBuilder: FormBuilder,
    private profileService: ProfileService,
    private authService: AuthenticationService
  ) {
    this.initForm();
  }

  private initForm(): void {
    this.profileForm = this.formBuilder.group({
      fullName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', [Validators.required, Validators.pattern(/^\+?[1-9]\d{1,14}$/)]],
      bio: [''],
      location: ['', Validators.required],
      schoolEmail: ['', [Validators.email]],
      backgroundCheckConsent: [false]
    });
  }

  ngOnInit(): void {
    this.loadUserProfile();
    
    // Subscribe to profile changes
    this.profileService.currentProfile$
      .pipe(takeUntil(this.destroy$))
      .subscribe(profile => {
        if (profile) {
          this.currentProfile = profile;
          this.updateFormWithProfile(profile);
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private updateFormWithProfile(profile: Profile): void {
    this.profileForm.patchValue({
      fullName: profile.displayName || `${profile.firstName || ''} ${profile.lastName || ''}`.trim(),
      email: profile.email || '',
      phone: profile.phoneNumber || '',
      bio: profile.bio || '',
      location: typeof profile.address === 'string' ? profile.address : '',
      schoolEmail: profile.schoolEmail || '',
      backgroundCheckConsent: profile.backgroundCheckConsent || false
    });
  }

  async loadUserProfile(): Promise<void> {
    this.isLoading = true;
    try {
      const userId = await this.authService.getCurrentUserId();
      if (!userId) {
        throw new Error('No authenticated user found');
      }
      
      const profile = await this.profileService.getProfileById(userId);
      if (profile) {
        this.currentProfile = profile;
        this.updateFormWithProfile(profile);
      }
    } catch (error) {
      console.error('Error loading profile:', error);
    } finally {
      this.isLoading = false;
    }
  }

  async saveProfile(): Promise<void> {
    if (this.profileForm.invalid) return;
    
    this.isSaving = true;
    try {
      const userId = await this.authService.getCurrentUserId();
      if (!userId) {
        throw new Error('No authenticated user found');
      }

      const formValues = this.profileForm.value;
      const nameParts = formValues.fullName.trim().split(' ');
      const firstName = nameParts[0] || '';
      const lastName = nameParts.slice(1).join(' ') || '';
      
      const profileData: Partial<Profile> = {
        firstName,
        lastName,
        displayName: formValues.fullName.trim(),
        email: formValues.email,
        phoneNumber: formValues.phone,
        bio: formValues.bio || '',
        address: formValues.location,
        schoolEmail: formValues.schoolEmail || '',
        backgroundCheckConsent: formValues.backgroundCheckConsent
      };

      const success = await this.profileService.updateProfile(userId, profileData);
      if (!success) {
        throw new Error('Failed to update profile');
      }
    } catch (error) {
      console.error('Error saving profile:', error);
    } finally {
      this.isSaving = false;
    }
  }

  async verifySchoolEmail(): Promise<void> {
    if (!this.profileForm.get('schoolEmail')?.valid) return;
    
    const userId = await this.authService.getCurrentUserId();
    if (!userId) return;

    const schoolEmail = this.profileForm.get('schoolEmail')?.value;
    this.profileService.verifySchoolEmail(userId, schoolEmail).subscribe();
  }

  async updateBackgroundCheckConsent(): Promise<void> {
    const userId = await this.authService.getCurrentUserId();
    if (!userId) return;

    const consent = this.profileForm.get('backgroundCheckConsent')?.value;
    this.profileService.updateBackgroundCheckConsent(userId, consent).subscribe();
  }
}