import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule, ModalController, ToastController } from '@ionic/angular';
import { NetworkService } from '../../services/network.service';
import { Observable, map, catchError, of } from 'rxjs';
import { AddContactModalComponent } from './add-contact-modal/add-contact-modal.component';
import { finalize, tap } from 'rxjs/operators';
import { Contact } from '@app/state/models/contact.model';
import { TranslateModule } from '@ngx-translate/core';
import { LanguageService } from '../../services/language.service';

@Component({
  selector: 'app-network',
  templateUrl: './network.component.html',
  styleUrls: ['./network.component.scss'],
  standalone: true,
  imports: [
    CommonModule, 
    IonicModule,
    TranslateModule
  ]
})
export class NetworkComponent implements OnInit {
  loading$: Observable<boolean>;
  trustedContacts$: Observable<Contact[]>;
  otherContacts$: Observable<Contact[]>;

  constructor(
    private networkService: NetworkService,
    private modalController: ModalController,
    private toastCtrl: ToastController
  ) {
    this.loading$ = this.networkService.loading$;
    const contacts$ = this.networkService.contacts$;
    
    this.trustedContacts$ = contacts$.pipe(
      map(contacts => contacts.filter(c => c.trusted))
    );
    
    this.otherContacts$ = contacts$.pipe(
      map(contacts => contacts.filter(c => !c.trusted))
    );
  }

  ngOnInit(): void {
    this.networkService.loadContacts().pipe(
      catchError(async (error) => {
        console.error('Error loading contacts:', error);
        const toast = await this.toastCtrl.create({
          message: 'Failed to load contacts',
          duration: 3000,
          color: 'danger',
          position: 'bottom'
        });
        await toast.present();
        return of([]);
      })
    ).subscribe();
  }

  async onTrustedChange(contact: Contact): Promise<void> {
    try {
      await this.networkService.updateTrustedStatus(contact.id, !contact.trusted).toPromise();
      // Show success toast
      const toast = await this.toastCtrl.create({
        message: `${contact.name} ${!contact.trusted ? 'added to' : 'removed from'} trusted contacts`,
        duration: 2000,
        position: 'bottom',
        color: 'success'
      });
      await toast.present();
    } catch (error) {
      // Show error toast
      const toast = await this.toastCtrl.create({
        message: 'Failed to update contact status',
        duration: 2000,
        position: 'bottom',
        color: 'danger'
      });
      await toast.present();
    }
  }

  async onAddContact(): Promise<void> {
    const modal = await this.modalController.create({
      component: AddContactModalComponent,
      cssClass: 'add-contact-modal',
      breakpoints: [0, 0.5, 0.75, 1],
      initialBreakpoint: 0.75,
      backdropDismiss: true,
      showBackdrop: true
    });

    await modal.present();

    const { data, role } = await modal.onWillDismiss();
    if (role === 'confirm' && data) {
      // Handle new contact data
    }
  }

  getPhotoUrl(photo?: string): string {
    return photo || 'assets/avatar-placeholder.png';
  }
}