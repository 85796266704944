export interface Message {
  id?: string;
  threadId: string;
  senderId: string;
  senderName: string;
  senderAvatar?: string;
  recipientId: string;
  recipientName: string;
  recipientAvatar?: string;
  content: string;
  timestamp: number;
  read: boolean;
  type: MessageType;
  metadata?: any;
}

export enum MessageType {
  TEXT = 'text',
  IMAGE = 'image',
  LOCATION = 'location',
  RIDE_REQUEST = 'ride_request',
  RIDE_CONFIRMATION = 'ride_confirmation'
}

export interface ChatThread {
  id?: string;
  participants: string[];
  participantNames: Record<string, string>;
  participantAvatars: Record<string, string>;
  lastMessage?: {
    content: string;
    senderId: string;
    timestamp: number;
    type: MessageType;
  };
  unreadCount?: Record<string, number>;
  createdAt: number;
  updatedAt: number;
  rideId?: string;
} 