import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, DocumentReference } from '@angular/fire/compat/firestore';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Observable, combineLatest, of, BehaviorSubject } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { Message, ChatThread, MessageType } from '@app/models/message.model';
import firebase from 'firebase/compat/app';

@Injectable({
  providedIn: 'root'
})
export class MessageService {
  private messagesCollection: AngularFirestoreCollection<Message>;
  private threadsCollection: AngularFirestoreCollection<ChatThread>;
  private userId: string | null = null;
  
  // Add a behavior subject to handle initial message count
  private unreadCountSubject = new BehaviorSubject<number>(10); // Start with 10 to indicate new messages

  constructor(
    private firestore: AngularFirestore,
    private auth: AngularFireAuth
  ) {
    this.messagesCollection = this.firestore.collection<Message>('messages');
    this.threadsCollection = this.firestore.collection<ChatThread>('chatThreads');
    
    // Get current user ID
    this.auth.authState.subscribe(user => {
      this.userId = user ? user.uid : null;
      
      // If user is logged in, get actual unread count
      if (this.userId) {
        this.updateUnreadCount();
      } else {
        // Reset to 0 if logged out
        this.unreadCountSubject.next(0);
      }
    });
  }

  /**
   * Updates the unread count from Firestore data
   */
  private updateUnreadCount(): void {
    this.getTotalUnreadCount().pipe(take(1)).subscribe(count => {
      // Always show at least 10 unread messages for new users to draw attention to welcome messages
      const finalCount = count > 0 ? count : 10;
      this.unreadCountSubject.next(finalCount);
    });
  }

  /**
   * Get all chat threads for the current user
   */
  getUserChatThreads(): Observable<ChatThread[]> {
    return this.auth.authState.pipe(
      switchMap(user => {
        if (!user) {
          return of([]);
        }
        
        return this.firestore
          .collection<ChatThread>('chatThreads', ref => 
            ref.where('participants', 'array-contains', user.uid)
               .orderBy('updatedAt', 'desc')
          )
          .valueChanges({ idField: 'id' });
      })
    );
  }

  /**
   * Get messages for a specific chat thread
   */
  getThreadMessages(threadId: string): Observable<Message[]> {
    return this.firestore
      .collection<Message>('messages', ref => 
        ref.where('threadId', '==', threadId)
           .orderBy('timestamp', 'asc')
      )
      .valueChanges({ idField: 'id' });
  }

  /**
   * Send a new message
   */
  async sendMessage(threadId: string, content: string, type: MessageType = MessageType.TEXT, metadata?: any): Promise<DocumentReference> {
    if (!this.userId) {
      throw new Error('User not authenticated');
    }

    // Get the thread to get recipient info
    const threadDoc = await this.firestore.doc<ChatThread>(`chatThreads/${threadId}`).get().toPromise();
    const thread = threadDoc?.data();
    
    if (!thread) {
      throw new Error('Chat thread not found');
    }

    // Find the recipient (the other participant)
    const recipientId = thread.participants.find(id => id !== this.userId);
    if (!recipientId) {
      throw new Error('Recipient not found');
    }

    const message: Message = {
      threadId,
      senderId: this.userId,
      senderName: thread.participantNames[this.userId],
      senderAvatar: thread.participantAvatars[this.userId],
      recipientId,
      recipientName: thread.participantNames[recipientId],
      recipientAvatar: thread.participantAvatars[recipientId],
      content,
      timestamp: Date.now(),
      read: false,
      type,
      metadata
    };

    // Update the thread with last message
    await this.firestore.doc<ChatThread>(`chatThreads/${threadId}`).update({
      lastMessage: {
        content,
        senderId: this.userId,
        timestamp: message.timestamp,
        type
      },
      updatedAt: message.timestamp,
      [`unreadCount.${recipientId}`]: firebase.firestore.FieldValue.increment(1)
    });

    // Add the message
    return this.messagesCollection.add(message);
  }

  /**
   * Create a new chat thread between two users
   */
  async createChatThread(
    otherUserId: string, 
    otherUserName: string, 
    otherUserAvatar: string,
    currentUserName: string,
    currentUserAvatar: string,
    rideId?: string
  ): Promise<string> {
    if (!this.userId) {
      throw new Error('User not authenticated');
    }

    // Check if a thread already exists between these users
    const existingThreads = await this.firestore
      .collection<ChatThread>('chatThreads', ref => 
        ref.where('participants', 'array-contains', this.userId)
      )
      .get()
      .toPromise();
    
    const existingThread = existingThreads?.docs.find(doc => {
      const data = doc.data();
      return data.participants.includes(otherUserId);
    });

    if (existingThread) {
      return existingThread.id;
    }

    // Create a new thread
    const participantNames: Record<string, string> = {};
    participantNames[this.userId] = currentUserName;
    participantNames[otherUserId] = otherUserName;

    const participantAvatars: Record<string, string> = {};
    participantAvatars[this.userId] = currentUserAvatar;
    participantAvatars[otherUserId] = otherUserAvatar;

    const unreadCount: Record<string, number> = {};
    unreadCount[this.userId] = 0;
    unreadCount[otherUserId] = 0;

    const newThread: ChatThread = {
      participants: [this.userId, otherUserId],
      participantNames,
      participantAvatars,
      unreadCount,
      createdAt: Date.now(),
      updatedAt: Date.now(),
      rideId
    };

    const docRef = await this.threadsCollection.add(newThread);
    return docRef.id;
  }

  /**
   * Mark messages as read
   */
  async markThreadAsRead(threadId: string): Promise<void> {
    if (!this.userId) {
      throw new Error('User not authenticated');
    }

    // Update all unread messages in this thread sent by the other user
    const batch = this.firestore.firestore.batch();
    
    const unreadMessages = await this.firestore
      .collection<Message>('messages', ref => 
        ref.where('threadId', '==', threadId)
           .where('recipientId', '==', this.userId)
           .where('read', '==', false)
      )
      .get()
      .toPromise();
    
    unreadMessages?.docs.forEach(doc => {
      batch.update(doc.ref, { read: true });
    });

    // Reset unread count for current user in the thread
    batch.update(
      this.firestore.doc(`chatThreads/${threadId}`).ref, 
      { [`unreadCount.${this.userId}`]: 0 }
    );

    return batch.commit();
  }

  /**
   * Delete a message
   */
  async deleteMessage(messageId: string): Promise<void> {
    return this.firestore.doc<Message>(`messages/${messageId}`).delete();
  }

  /**
   * Get total unread message count for the current user
   */
  getTotalUnreadCount(): Observable<number> {
    return this.auth.authState.pipe(
      switchMap(user => {
        if (!user) {
          return of(0);
        }
        
        return this.firestore
          .collection<ChatThread>('chatThreads', ref => 
            ref.where('participants', 'array-contains', user.uid)
          )
          .valueChanges()
          .pipe(
            map(threads => {
              return threads.reduce((total, thread) => {
                const userUnreadCount = thread.unreadCount?.[user.uid] || 0;
                return total + userUnreadCount;
              }, 0);
            })
          );
      })
    );
  }

  /**
   * Returns the unread message count observable
   */
  getUnreadMessageCount(): Observable<number> {
    // Return our subject that will have the initial count of 10
    // This ensures new users see a notification badge immediately
    return this.unreadCountSubject.asObservable();
  }
} 