import { createAction, props } from '@ngrx/store';
import { Profile } from '../models/profile.model';

// Profile Get
export const profileGet = createAction(
  '[Profile] Get Profile',
  props<{ id: string }>()
);

export const profileGetSuccess = createAction(
  '[Profile API] Get Profile Success',
  props<{ profile: Profile[] }>()
);

export const profileGetFailure = createAction(
  '[Profile API] Get Profile Failure',
  props<{ error: string }>()
);

// Profile Get All
export const profileGetAll = createAction(
  '[Profile] Get All Profiles'
);

export const profileGetAllSuccess = createAction(
  '[Profile API] Get All Profiles Success',
  props<{ profiles: Profile[] }>()
);

export const profileGetAllFailure = createAction(
  '[Profile API] Get All Profiles Failure',
  props<{ error: string }>()
);

// Profile Upsert
export const profileUpsert = createAction(
  '[Profile] Upsert Profile',
  props<{ data: Profile }>()
);

export const profileUpsertSuccess = createAction(
  '[Profile API] Upsert Profile Success'
);

export const profileUpsertFailure = createAction(
  '[Profile API] Upsert Profile Failure',
  props<{ error: string }>()
);

// Profile Delete
export const profileDelete = createAction(
  '[Profile] Delete Profile',
  props<{ id: string }>()
);

export const profileDeleteSuccess = createAction(
  '[Profile API] Delete Profile Success',
  props<{ id: string }>()
);

export const profileDeleteFailure = createAction(
  '[Profile API] Delete Profile Failure',
  props<{ error: string }>()
); 