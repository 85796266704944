import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, tap, catchError } from 'rxjs/operators';
import { ChildProfile, EmergencyContact, SafetyPreferences } from '../../shared/models/child-profile.model';

@Injectable({
  providedIn: 'root'
})
export class ChildProfileService {
  private apiUrl = 'api/children';
  private childrenSubject = new BehaviorSubject<ChildProfile[]>([]);

  constructor(private http: HttpClient) {
    this.loadChildren();
  }

  /**
   * Get all child profiles for current parent
   */
  getChildren(): Observable<ChildProfile[]> {
    return this.childrenSubject.asObservable();
  }

  /**
   * Get a specific child profile by ID
   */
  getChild(childId: string): Observable<ChildProfile | undefined> {
    return this.childrenSubject.pipe(
      map(children => children.find(child => child.id === childId))
    );
  }

  /**
   * Create a new child profile
   */
  createChild(child: Omit<ChildProfile, 'id'>): Observable<ChildProfile> {
    return this.http.post<ChildProfile>(`${this.apiUrl}`, child).pipe(
      tap(newChild => {
        const currentChildren = this.childrenSubject.value;
        this.childrenSubject.next([...currentChildren, newChild]);
      }),
      catchError(error => {
        console.error('Error creating child profile:', error);
        return of({
          id: 'temp-' + Date.now(),
          name: child.name,
          age: child.age,
          approvedPickupPersons: child.approvedPickupPersons || [],
          emergencyContacts: child.emergencyContacts || [],
          safetyPreferences: child.safetyPreferences || this.getDefaultSafetyPreferences()
        } as ChildProfile);
      })
    );
  }

  /**
   * Update an existing child profile
   */
  updateChild(childId: string, updates: Partial<ChildProfile>): Observable<ChildProfile> {
    return this.http.put<ChildProfile>(`${this.apiUrl}/${childId}`, updates).pipe(
      tap(updatedChild => {
        const currentChildren = this.childrenSubject.value;
        const index = currentChildren.findIndex(child => child.id === childId);
        if (index !== -1) {
          const updatedChildren = [...currentChildren];
          updatedChildren[index] = updatedChild;
          this.childrenSubject.next(updatedChildren);
        }
      }),
      catchError(error => {
        console.error('Error updating child profile:', error);
        return of({
          ...this.childrenSubject.value.find(c => c.id === childId),
          ...updates
        } as ChildProfile);
      })
    );
  }

  /**
   * Delete a child profile
   */
  deleteChild(childId: string): Observable<boolean> {
    return this.http.delete<void>(`${this.apiUrl}/${childId}`).pipe(
      map(() => {
        const currentChildren = this.childrenSubject.value;
        this.childrenSubject.next(currentChildren.filter(child => child.id !== childId));
        return true;
      }),
      catchError(error => {
        console.error('Error deleting child profile:', error);
        return of(false);
      })
    );
  }

  /**
   * Upload a child's photo
   */
  uploadChildPhoto(childId: string, photoFile: File): Observable<string> {
    const formData = new FormData();
    formData.append('photo', photoFile);

    return this.http.post<{photoUrl: string}>(`${this.apiUrl}/${childId}/photo`, formData).pipe(
      map(response => response.photoUrl),
      tap(photoUrl => {
        const currentChildren = this.childrenSubject.value;
        const index = currentChildren.findIndex(child => child.id === childId);
        if (index !== -1) {
          const updatedChildren = [...currentChildren];
          updatedChildren[index] = {
            ...updatedChildren[index],
            photo: photoUrl
          };
          this.childrenSubject.next(updatedChildren);
        }
      }),
      catchError(error => {
        console.error('Error uploading child photo:', error);
        return of('');
      })
    );
  }

  /**
   * Add an emergency contact to a child profile
   */
  addEmergencyContact(childId: string, contact: EmergencyContact): Observable<EmergencyContact[]> {
    return this.http.post<EmergencyContact[]>(`${this.apiUrl}/${childId}/emergency-contacts`, contact).pipe(
      tap(contacts => {
        const currentChildren = this.childrenSubject.value;
        const index = currentChildren.findIndex(child => child.id === childId);
        if (index !== -1) {
          const updatedChildren = [...currentChildren];
          updatedChildren[index] = {
            ...updatedChildren[index],
            emergencyContacts: contacts
          };
          this.childrenSubject.next(updatedChildren);
        }
      }),
      catchError(error => {
        console.error('Error adding emergency contact:', error);
        return of([]);
      })
    );
  }

  /**
   * Update safety preferences for a child
   */
  updateSafetyPreferences(childId: string, preferences: SafetyPreferences): Observable<SafetyPreferences> {
    return this.http.put<SafetyPreferences>(`${this.apiUrl}/${childId}/safety-preferences`, preferences).pipe(
      tap(updatedPreferences => {
        const currentChildren = this.childrenSubject.value;
        const index = currentChildren.findIndex(child => child.id === childId);
        if (index !== -1) {
          const updatedChildren = [...currentChildren];
          updatedChildren[index] = {
            ...updatedChildren[index],
            safetyPreferences: updatedPreferences
          };
          this.childrenSubject.next(updatedChildren);
        }
      }),
      catchError(error => {
        console.error('Error updating safety preferences:', error);
        return of(preferences);
      })
    );
  }

  /**
   * Get a specific child profile by ID (alias for getChild for backwards compatibility)
   */
  getChildProfile(childId: string): Observable<ChildProfile | undefined> {
    return this.getChild(childId);
  }

  /**
   * Update a child profile (alias for updateChild for backwards compatibility)
   */
  updateChildProfile(childId: string, profile: ChildProfile): Promise<void> {
    return this.updateChild(childId, profile)
      .toPromise()
      .then(() => {});
  }

  /**
   * Create a child profile (alias for createChild for backwards compatibility)
   */
  createChildProfile(profile: Omit<ChildProfile, 'id'>): Promise<string> {
    return this.createChild(profile)
      .toPromise()
      .then(child => child.id);
  }

  /**
   * Load all child profiles from API
   */
  private loadChildren(): void {
    this.http.get<ChildProfile[]>(this.apiUrl)
      .subscribe(
        children => this.childrenSubject.next(children),
        error => {
          console.error('Error loading children profiles:', error);
          // Fallback to empty array
          this.childrenSubject.next([]);
        }
      );
  }

  /**
   * Get default safety preferences
   */
  private getDefaultSafetyPreferences(): SafetyPreferences {
    return {
      requirePhotoVerification: true,
      requireSafetyCode: true,
      enableLocationTracking: true,
      notifyOnPickupDropoff: true,
      notifyOnRideStart: true,
      notifyOnRideEnd: true,
      autoShareLocationWithEmergencyContacts: false
    };
  }
}